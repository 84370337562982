import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';
import { FollowStoryUpdateEvent, StoryFollowersResponse, StoryFollowStatusResponse } from '@models/ncx/followers';
import { User } from '@models/users';
import { Router } from '@angular/router';

@Component({
  selector: 'app-follow-story',
  templateUrl: './follow-story.component.html',
  styleUrls: ['./follow-story.component.scss']
})
export class FollowStoryComponent {

  isLoaded = true;

  @Input() isFollowing;

  @Input() storyId: number = 0;

  @Input() followers: number = 0;

  // How should the display look
  @Input() type: 'button' | 'text' = 'button';

  // Show the number of followers
  @Input() showFollowCount: boolean = true;

  // Show the status (Follow|Unfollow)
  @Input() showFollowStatus: boolean = false;

  @Input() isDiscussionPost: boolean = false;
  
  // Button Size (small|large)
  @Input() buttonSize: 'small' | 'default' = 'default';

  // Whether to return a more detailed list of followers,
  // besides the normal callback from the follow, unfollow functions
  @Input() returnDetailedFollowerList: boolean = false;

  @Output() updatedFollower: EventEmitter<FollowStoryUpdateEvent> = new EventEmitter();

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) {}

  changeFollowStatus() {

    if (this.isFollowing) {

      this.unfollow();
    
    } else {

      this.follow();
    
    }

  }

  follow() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('post', environment.storyFollower, `/${this.storyId}`).subscribe({

      next: async (data: any) => {

        const response = data as StoryFollowStatusResponse;

        console.log('Follow Response', response);

        this.isFollowing = true;

        let users: User[] = [];

        if (this.returnDetailedFollowerList) {

          const details = await this.getFollowerDetails();

          users = details.userList || [];
        
        }

        this.updatedFollower.emit({ type: true, storyId: this.storyId, data: response, users });

        this.toastService.createMessage('success', 'You are now following this story');

      },
      error: (error: any) => {

        console.error('Follow Story', error);

        this.toastService.createMessage('error', 'Error following story. Please try again.');

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  unfollow() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('delete', environment.storyFollower, `/${this.storyId}`).subscribe({

      next: async (data: any) => {

        const response = data as StoryFollowStatusResponse;

        console.log('UnFollow Response', response);

        this.isFollowing = false;

        let users: User[] = [];

        if (this.returnDetailedFollowerList) {

          const details = await this.getFollowerDetails();

          users = details.userList || [];
        
        }

        this.updatedFollower.emit({ type: false, storyId: this.storyId, data: response, users });

        this.toastService.createMessage('success', 'You have unfollowed this story');

      },
      error: (error: any) => {

        console.error('Unfollow', error);
        this.toastService.createMessage('error', 'Error unfollowing story. Please try again.');

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Get additional details about the followers after follow/unfollow call
   *
   */
  async getFollowerDetails(): Promise<StoryFollowersResponse> {

    return new Promise((resolve) => {

      const getFollowersAPI = environment.getStoriesAPI + `/${this.storyId}/storyfollower`;

      this.cService.serviceRequestCommon('get', getFollowersAPI).subscribe({
        next: (data: any) => {

          resolve(data);
        
        },
        error: (error: any) => {

          console.error('Get Follower Details', error);
          resolve({} as StoryFollowersResponse);
        
        }
      });

    });
  
  }

  viewFollowers() {

    this.router.navigate(['ncx/following/:' + this.storyId]);

  }

}
