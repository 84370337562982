<div
   [ngClass]="{ 'draft-filter-container': true, 'drawer': isDrawer }"
   [attr.data-component]="'ncx/drafts/drafts-filter/drafts-filter.component.html'">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Filter</span>
      <button
         class="close"
         (click)="close()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>

   <nz-divider style="margin: 0"></nz-divider>

   <nz-card [nzBordered]="!isDrawer">
      <div class="section">
         <div class="storyStateCreated">
            <p>
               <i
                  nz-icon
                  nzType="file"
                  nzTheme="outline"></i>
               Draft Type
            </p>
            <section>
               <nz-radio-group [(ngModel)]="radioPostTypeValue">
                  <label
                     *ngFor="let item of draftType"
                     (click)="addFilters(item.label)"
                     [nzValue]="item.value"
                     class="radio-labels"
                     nz-radio>
                     {{ item.label }}
                  </label>
               </nz-radio-group>
            </section>
         </div>
      </div>
   </nz-card>

   <nz-divider *ngIf="isDrawer"></nz-divider>

   <nz-card [nzBordered]="!isDrawer">
      <div class="section">
         <div class="storyStateCreated">
            <p>
               <i
                  nz-icon
                  nzType="flag"
                  nzTheme="outline"></i>
               Labels
            </p>
            <section>
               <nz-radio-group [(ngModel)]="radioDraftOrderValue">
                  <label
                     *ngFor="let item of orderedBy"
                     (click)="orderFilter(item.label)"
                     [nzValue]="item.value"
                     class="radio-labels"
                     nz-radio>
                     {{ item.label }}
                  </label>
               </nz-radio-group>
            </section>
         </div>
      </div>
   </nz-card>

   <div class="options">
      <button
         (click)="clear()"
         nzBlock="true"
         nz-button>
         Clear
      </button>
   </div>
</div>
