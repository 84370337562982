import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-move-discussion',
  templateUrl: './move-discussion.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class MoveDiscussionComponent implements OnInit, OnChanges {

  @Input() isVisible;

  @Input() shareMessage;

  @Input() groupId;

  @Input() isMovePost;

  @Input() discussionDetails;

  @Output() closeMovePost = new EventEmitter();

  @Output() isDiscussionUpdated = new EventEmitter();

  // value = '';
  @Output() closedSharedContent = new EventEmitter();

  groupsList: string[];

  selectedgroupsList = [];

  groupsListNames: any = [];

  getGroupsList: string;

  subject: Subject<string> = new Subject();

  value: string;

  groupsNameList: any = [];

  notificationURL: string;

  showMessage: any;

  discussionURL: string;

  moveGroupId: any;

  isLoaded = true;

  constructor(
    private cService: CommonService,
    private toastService: ToastService
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.fetchgroupsList(searchTextValue);

    });

  }

  ngOnInit() {

    this.getGroupsList = environment.getGroupsURL;
    this.notificationURL = environment.getNotificationURL;
    this.discussionURL = environment.getDiscussionURL;

    // this.groupsList = ['test', 'test1'];
    // this.fetchgroupsList();

  }

  ngOnChanges() {

    if (this.shareMessage) {

      this.showMessage = `Check out ${this.shareMessage} in NewsConnect`;

    }

  }

  onChange(value, event) {

    // console.log('Doc value', value);
    this.subject.next(event.target.value);

  }

  titleMatch(value) {

    const obj = this.groupsListNames.filter(group => group.groupId === value)[0];

    const obj1 = this.selectedgroupsList.filter(group => group.groupId === value)[0];

    if ((!(obj === null || obj === undefined) && (obj1 === null || obj1 === undefined))) {

      setTimeout(() => {

        this.value = obj.groupName;

      }, 100);
      if (this.groupsNameList && !this.groupsNameList.length && parseFloat(obj.groupId) !== parseFloat(this.groupId)) {

        this.moveGroupId = obj.groupId;
        this.groupsNameList.push(obj);

      } else if (parseFloat(obj.groupId) === parseFloat(this.groupId)) {

        this.toastService.createMessage('warning', 'Please select different Group to move');
        setTimeout(() => {

          this.value = '';

        }, 100);

      }
      setTimeout(() => {

        this.groupsListNames = [];
        this.selectedgroupsList = [];

      }, 100);

    }

  }

  onClose(userId) {

    console.log('onClose : ', userId);
    this.value = '';

  }

  handleOk(): void {

    if (!this.groupId || this.groupId === null || this.groupId === undefined) {

      this.toastService.createMessage('warning', 'No Discussion Content to share');
      return;

    }
    if (!this.groupsNameList.length) {

      this.toastService.createMessage('warning', 'Please select Group to share content');
      return;

    }
    this.moveDiscussion();
    console.log('handleOk ', this.groupsNameList, this.groupId);
    this.value = '';

  }

  handleCancel(): void {

    this.groupsNameList = [];
    this.value = '';
    this.closeMovePost.emit(true);

  }

  deleteGroupFromList(_index: number, _groupId: string) {

    this.groupsNameList = [];

  }

  createPayload(groupDetails) {

    const obj = {
      groupId: this.moveGroupId,
      discussionId: groupDetails.discussionId
    };  // JSON.parse(JSON.stringify(groupDetails));

    return obj;

  }

  bindDropdownVal(_list: string[]) {

    const groups = [];

    this.groupsList.forEach((user: any) => {

      groups.push({ groupName: user.groupName, groupId: user.groupId });

    });
    this.groupsListNames = groups;
    console.log('bindDropdownVal : ', this.groupsListNames);

  }

  resetValues() {

    this.groupsNameList = [];
    this.groupsListNames = [];
    this.showMessage = null;
    this.isVisible = false;

  }

  /*API for User Typahead and Share Email Start */
  fetchgroupsList(searchTextValue) {

    this.groupsList = [];
    const queryStr = `/name?groupName=${searchTextValue}`;

    this.cService.serviceRequestCommon('get', this.getGroupsList, queryStr).subscribe((res: any) => {

      console.log('fetchgroupsList success: ', res);
      this.groupsList = [...res];
      this.bindDropdownVal(this.groupsList);

      // this.resetValues();

    }, (err) => {

      console.log('fetchgroupsList err : ', err);

      // this.resetValues();

    });

  }

  moveDiscussion() {

    const queryStr = `/${this.discussionDetails.discussionId}/move`;

    const payload = this.createPayload(this.discussionDetails);

    this.isLoaded = false;
    this.cService.serviceRequestCommon('patch', this.discussionURL, queryStr, payload).subscribe((res: any) => {

      console.log('moveDiscussion success: ', res);
      this.resetValues();
      this.toastService.createMessage('success', 'Discussion Successfully moved.');
      this.closeMovePost.emit(true);
      this.isDiscussionUpdated.emit({ sucess: true, groupId: this.moveGroupId });

    }, (err) => {

      console.log('moveDiscussion err : ', err);
      this.resetValues();
      this.toastService.createMessage('error', 'Error in moving Discussion. Please try again.');
      this.closeMovePost.emit(true);

    }).add(() => {

      this.isLoaded = true;

    });

  }

}
