<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<nz-layout [attr.data-component]="'ncx/activity/activity.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon
      [nzGhost]="false">
      <nz-page-header-title class="largescreen">
         <div>
            <nz-button-group>
               <button
                  class="secondary"
                  nz-button
                  (click)="openPost(postTypeSelected, 'type')">
                  Activity
               </button>
               <button
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu1">
                  {{postTypeSelected}}
                  <i
                     nz-icon
                     nzType="down"></i>
               </button>
               <nz-dropdown-menu #menu1="nzDropdownMenu">
                  <ul nz-menu>
                     <li
                        nz-menu-item
                        *ngFor="let item of filterType"
                        (click)="changeActivityStatus(item,'type')">
                        <label [nzValue]="item.value">{{item.label}}</label>
                     </li>
                  </ul>
               </nz-dropdown-menu>
            </nz-button-group>
            <nz-button-group>
               <button
                  class="secondary"
                  nz-button
                  (click)="openPost(labelStatus,'labels')">
                  <i
                     nz-icon
                     nzType="flag"
                     nzTheme="outline"></i>
                  Labels
               </button>
               <button
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu2">
                  Select
                  <i
                     nz-icon
                     nzType="down"></i>
               </button>
               <nz-dropdown-menu #menu2="nzDropdownMenu">
                  <ul nz-menu>
                     <!-- (click)="changeActivityStatus(item,'labels')" -->
                     <li
                        nz-menu-item
                        *ngFor="let item of labels">
                        <label
                           nz-checkbox
                           nzValue="item.label"
                           [ngModel]="item.checked"
                           (ngModelChange)="changeLabelStatus($event, item ,'labels' )"
                           [nzDisabled]="item.disabled">
                           {{item.label}}
                        </label>
                     </li>
                  </ul>
               </nz-dropdown-menu>
            </nz-button-group>
            <nz-button-group>
               <button
                  class="secondary"
                  nz-button>
                  <i
                     class="trigger"
                     nz-icon
                     [nzType]="isToggle ? 'sort-ascending' : 'sort-descending'"></i>
                  Order
               </button>
               <button
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu3">
                  {{creationDateSelected}}
                  <i
                     nz-icon
                     nzType="down"></i>
               </button>
               <nz-dropdown-menu #menu3="nzDropdownMenu">
                  <ul nz-menu>
                     <li
                        nz-menu-item
                        *ngFor="let item of creationDate"
                        (click)="changeDate(item,'date')">
                        <label [nzValue]="item.value">{{item.label}}</label>
                     </li>
                  </ul>
               </nz-dropdown-menu>
            </nz-button-group>
            <button
               class="headerRefresh"
               *ngIf="refreshStatus"
               nz-button
               (click)="refresh()">
               <i
                  nz-icon
                  nzType="reload"
                  nzTheme="outline"></i>
            </button>
         </div>
      </nz-page-header-title>
      <nz-page-header-title class="smallerscreen">
         <div nz-row>
            <div
               class="filterClass"
               nz-col
               [nzXs]="4"
               [nzSm]="2"
               [nzMd]="2"
               [nzLg]="2">
               <button
                  nz-button
                  (click)="toggleSider()">
                  <i
                     nz-icon
                     nzType="filter"
                     nzTheme="outline"
                     class="iconClass"></i>
               </button>
            </div>
            <div
               id="searchUnitTestCase"
               class="filterClass"
               nz-col
               nzXs="20"
               nzSm="22"
               nzMd="22"
               nzLg="22">
               <nz-input-group [nzPrefix]="prefixTemplateUser">
                  <input
                     #searchText
                     (keyup)="filterItems($event, 'searchInput')"
                     type="text"
                     nz-input
                     placeholder="Search Page Contents here......"
                     [(ngModel)]="activityText" />
                  <ng-template #prefixTemplateUser>
                     <i
                        nz-icon
                        nzType="file-search"
                        (click)="searchPost()"></i>
                  </ng-template>
               </nz-input-group>
            </div>
         </div>
      </nz-page-header-title>
      <nz-page-header-extra class="largescreen">
         <div nz-row>
            <div
               style="padding-right: 4px"
               nz-col
               nzXs="0"
               nzSm="0"
               nzMd="0"
               nzLg="0"
               nzXl="24"
               nzXXl="24">
               <div>
                  <nz-input-group [nzPrefix]="prefixTemplateUser">
                     <input
                        #searchText
                        (keydown)="filterItems($event, 'searchInput')"
                        type="text"
                        nz-input
                        placeholder="Search Page Contents here......"
                        [(ngModel)]="activityText" />
                     <ng-template #prefixTemplateUser>
                        <i
                           nz-icon
                           nzType="file-search"
                           (click)="searchPost()"></i>
                     </ng-template>
                  </nz-input-group>
               </div>
            </div>
         </div>
      </nz-page-header-extra>
   </nz-page-header>
   <nz-layout>
      <div
         class="storyCard"
         id="storycontainerId"
         infiniteScroll
         [infiniteScrollDistance]="2"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         [ngStyle]="checkScreen()">
         <div
            *ngIf="isLoaded && activityDetails.length === 0"
            class="notFoundCard">
            <nz-empty [nzNotFoundContent]="contentTpl">
               <ng-template #contentTpl>
                  <span>No Results Found</span>
               </ng-template>
            </nz-empty>
         </div>

         <!-- ACTIVITY STORY CARDS -->
         <div
            class="activityDetails"
            *ngFor="let story of activityDetails; index as i">
            <ncx-story
               [story]="story"
               [index]="i"
               [showNumAttachments]="false"
               [showNumComments]="false"></ncx-story>
         </div>
      </div>

      <nz-drawer
         [nzClosable]="false"
         [nzVisible]="!isCollapsed"
         nzPlacement="right"
         [nzWrapClassName]="'no-padding'"
         [nzWidth]="320"
         (nzOnClose)="closeSlider()">
         <ng-container *nzDrawerContent>
            <app-activity-filter
               [radioFilterOptions]="filterType"
               [isDrawer]="true"
               [currentStatus]="{activity: activityTypeStatus, labels: labelsStatusSelected, date: dateStatus}"
               [checkOptionsLabel]="labels"
               [radioCreationDateOptions]="creationDate"
               [labelStatus]="labelStatus"
               [activityText]="activityText"
               (closeFilter)="closeSlider()"
               (filterApplied)="applyFilters($event)"></app-activity-filter>
         </ng-container>
      </nz-drawer>
   </nz-layout>
</nz-layout>
