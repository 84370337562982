import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

/**
 * Add members to Group
 *
 */

@Component({
  selector: 'app-add-members',
  templateUrl: './add-members.component.html',
  styleUrls: ['./add-members.component.scss']

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class AddMembersComponent implements OnInit, OnChanges {

  @Input() invitePeople;

  @Input() groupMembers;

  @Input() groupMessage;

  @Input() groupId;

  @Output() closeInvite = new EventEmitter();

  @Output() refreshMemberList = new EventEmitter();

  auto;

  showGroupMessage;

  userList: string[];

  selectedUserList = [];

  userListNames: any = [];

  getUsers: string;

  subject: Subject<string> = new Subject();

  value: string;

  userEmailList: any = [];

  inviteURL: string;

  showMessage: any;

  isVisible: boolean;

  isLoaded = false;

  constructor(
    private cService: CommonService,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {

    this.getUsers = environment.getUsersOnDutyURL;
    this.inviteURL = environment.getGroupsURL;
    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.fetchUserList(searchTextValue);

    });

    // this.userList = ['test', 'test1'];
    // this.fetchUserList();
    this.isLoaded = true;

  }

  ngOnChanges() {

    if (this.groupMessage) {

      // tslint:disable-next-line: max-line-length
      this.showGroupMessage = `You’ve been invited to join ${this.groupMessage}. Go to your NewsConnect Alerts page to accept this invitation.`;

    }

  }

  onChange(value, event) {

    // console.log('Doc value', value);
    this.subject.next(event.target.value);

  }

  titleMatch(value) {

    const obj = this.userListNames.filter(user => user.userId === value)[0];

    const obj1 = this.selectedUserList.filter(user => user.userId === value)[0];

    const obj2 = this.userEmailList.filter(user => user.userId === value)[0];

    if ((!(obj === null || obj === undefined) && (obj1 === null || obj1 === undefined))) {

      if ((obj2 === null || obj2 === undefined)) {

        this.userEmailList.push(obj);

      }
      setTimeout(() => {

        this.value = '';
        this.userListNames = [];
        this.selectedUserList = [];

      }, 100);

    }

  }

  onClose(userId) {

    const userIndex = this.userEmailList.findIndex(user => user.userId === userId);

    if (userIndex !== -1) {

      this.userEmailList.splice(userIndex, 1);

    }

  }

  handleOk(): void {

    if (!this.userEmailList.length) {

      this.toastService.createMessage('warning', 'Please select users to send Invite.');
      return;

    }
    const userSSO = [];

    this.userEmailList.forEach(user => {

      userSSO.push(user.userId);

    });
    this.sendInvite(userSSO);

  }

  handleCancel(): void {

    this.userEmailList = [];
    this.closeInvite.emit(true);

  }

  createPayload(userIds) {

    const userList = [];

    let userObj;

    if (userIds && userIds.length) {

      userIds.forEach(user => {

        userObj = {
          groupId: this.groupId,
          userId: user,
          invitationNote: this.showGroupMessage,
          membershipCategory: 'INVITATION',
          membershipType: 'MEMBER',
          membershiptInvitationToken: null,
          isDeleted: false
        };
        userList.push(userObj);

      });

    }
    return userList;

  }

  bindDropdownVal(_userList) {

    const users = [];

    this.userList.forEach((user: any) => {

      let firstName = '';

      let lastName = '';

      if (!(user.name.preferredName === undefined || user.name.preferredName === null)) {

        firstName = (user.name.preferredName.split(',').length > 1) ? user.name.preferredName.split(',')[1].trim() : user.name.preferredName.trim();
        lastName = (user.name.preferredName.split(',').length > 1) ? user.name.preferredName.split(',')[0].trim() : '';

      }
      users.push({
        fullName: firstName + ' ' + lastName
        , userId: (user.userId === undefined || user.userId === null) ? '' : user.userId
      });

    });
    this.userListNames = users;

  }

  resetValues() {

    this.userEmailList = [];
    this.userListNames = [];
    this.showMessage = null;
    this.isVisible = false;
    this.closeInvite.emit(true);

  }

  checkMemberList(userIds) {

    const existingMembers = [];

    // Check if request contains already existing members of a Group
    if (this.groupMembers && this.groupMembers.length) {

      userIds.forEach(userId => {

        const userIndex = this.groupMembers.findIndex(user => user.userId === userId);

        if (userIndex !== -1) {

          existingMembers.push(userId);

        }

      });

    }
    if (existingMembers && existingMembers.length) {

      this.toastService.createMessage('warning', 'Please select users other than existing members to send Invite.');
      return false;

    } else {

      return true;

    }

  }

  /*API for User Typahead and Share Email Start */
  fetchUserList(searchTextValue) {

    this.userList = [];
    const queryStr = `?name=${searchTextValue}`;

    this.cService.serviceRequestCommon('get', this.getUsers, queryStr).subscribe((res: any) => {

      this.userList = [...res];
      this.bindDropdownVal(this.userList);

    }, (err) => {

      console.log('fetchUserList err : ', err);

    });

  }

  sendInvite(userIds) {

    const isMember = this.checkMemberList(userIds);

    if (!isMember) {

      return;

    }
    const queryStr = `/${this.groupId}/members/invite`;

    const payload = this.createPayload(userIds);

    console.log('Payload cerated : ', payload, this.inviteURL, queryStr);
    this.isLoaded = false;
    this.cService.serviceRequestCommon('post', this.inviteURL, queryStr, payload).subscribe(() => {

      this.resetValues();
      this.refreshMemberList.emit(true);
      this.toastService.createMessage('success', 'Invite Successfully sent.');

    }, () => {

      this.resetValues();
      this.toastService.createMessage('error', 'Error in Sending Invite. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /*API for User Typahead and Share Email End */

}
