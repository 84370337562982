import { Component } from '@angular/core';
import { Profile } from '@models/users';
import { AppJsonDataService } from '@services/app-json.service';
import { AppSingletonService } from '@services/app-singleton.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-transferinformation',
  templateUrl: './TransferInformation.component.html',
  styleUrls: ['./TransferInformation.component.css'],
})
export class TransferInformationComponent {

  public userFunctionAbility = {};

  public userInfoDetails = {
    userId: '',
    role: '',
  };

  public storyPayload =
    {
      markAs: [],
      contentType: [],
      story: {
        filter: {
          following: '',
          date: {},
        },
      },
    };

  public datePayload = {
    fieldName: '',
    startTime: '',
    endTime: '',
  };

  public postPayload = {
    markAs: [], contentType: [],
  };

  public postStartDateFilter = '';

  public postEndDateFilter = '';

  public postAuthorSSO: number;

  public postSortField = '';

  public postSortOrder = '';

  public storySortField = '';

  public storySortOrder = '';

  public userRole = '';

  public globalSearchText = '';

  public postCount = '';

  public storyCount = '';

  public angleCount = '';

  public groupCount = '';

  public peopleCount = '';

  public groupSort = '';

  public peopleSort = 'asc';

  public linkSelectedDetailsSource = new BehaviorSubject<any>('');

  currentLinkDetails = this.linkSelectedDetailsSource.asObservable();

  public linkRoleDetailSource = new BehaviorSubject<string>('');

  linkRoleDetail = this.linkRoleDetailSource.asObservable(); // linkUserDetails

  public linkUserDetailsSource = new BehaviorSubject<Profile>({} as Profile);

  userProfile = this.linkUserDetailsSource.asObservable();

  public linkLoggedInDetailsSource = new BehaviorSubject<boolean>(false);

  currentLoginDetails = this.linkLoggedInDetailsSource.asObservable();

  public linkLoggedInErrSource = new BehaviorSubject<any>({ errorStatus: false, errorCode: 0, errMsg: '' });

  errSource = this.linkLoggedInErrSource.asObservable();

  public storiesSiderSource = new BehaviorSubject<boolean>(false);

  currentstoriesSiderStatus = this.storiesSiderSource.asObservable();

  public SettingsSource = new BehaviorSubject<any>('');

  currentSettingsDetails = this.SettingsSource.asObservable();

  public avatarPicUrl = new BehaviorSubject<any>('');

  avatarPicUrlDetails = this.avatarPicUrl.asObservable();

  public sessionInactivity = new BehaviorSubject<any>(new Date());

  sessionInactivityDetails = this.sessionInactivity.asObservable();

  public userLogged = new BehaviorSubject<any>('');

  userLogged$ = this.userLogged.asObservable();

  public alertCount = new BehaviorSubject<any>(0);

  alertCountDetails = this.alertCount.asObservable();

  uncheckReportable: boolean;

  private userFunctionAbility$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private aSS: AppSingletonService, private jsonService: AppJsonDataService) { }

  getSelectedLinkDetails(message: any) {

    this.linkSelectedDetailsSource.next(message);

  }

  getLogingStatus(isLoggedIn: boolean) {

    this.linkLoggedInDetailsSource.next(isLoggedIn);

  }

  getLogingErrorStatus(errorStatus: boolean, errorCode: any, errMsg) {

    const obj = { errorStatus, errorCode, errMsg };

    this.linkLoggedInErrSource.next(obj);

  }

  getUserDetails(user: Profile) {

    this.linkUserDetailsSource.next(user);

  }

  getUserRole(role: string) {

    this.linkRoleDetailSource.next(role);

  }

  getStoriesSider(status: boolean) {

    this.storiesSiderSource.next(status);

  }

  getSettingsDetails(message: any) {

    this.SettingsSource.next(message);

  }

  getAvtarLinkUrl(message: any) {

    this.avatarPicUrl.next(message);

  }

  getSessionTime(message: any) {

    this.sessionInactivity.next(message);

  }

  getUserFunctionAbility(): Observable<any> {

    return this.userFunctionAbility$.asObservable();

  }

  setUserFunctionAbilitySubject(obj: any) {

    this.userFunctionAbility$.next(obj);

  }

  setUserFunctionAbility(ability) {

    // console.log('setUserFunctionAbility : ', ability, this.aSS);
    if (this.aSS && this.aSS.nccXJsonStore && this.aSS.nccXJsonStore.functionAbility) {

      this.assignFAValues(ability);

    } else {

      this.jsonService.getNcxMenuJson().subscribe(
        (data: any) => {

          console.log('Json Data 1', data);

          if (data) {

            this.aSS.nccXJsonStore = data;
            this.assignFAValues(ability);

          }

        },
        (error: any) => {

          console.log(error);

        },
      );

    }

  }

  setAlertCount(count) {

    this.alertCount.next(count);

  }

  private assignFAValues(ability) {

    this.userFunctionAbility = { ...this.aSS.nccXJsonStore.functionAbility };
    for (const key of Object.keys(this.userFunctionAbility)) {

      this.userFunctionAbility[key] = ability.some(role => role === key);

    }

    this.setUserFunctionAbilitySubject(this.userFunctionAbility);

  }

}
