<section class='support-tab-body'
         [attr.data-component]="'ncx/support/versions'">

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='May 9th, 2024'
                   icon='code'>

    <ul>
      <li>New Angle feature (beta)</li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='January 18th, 2024'
                   icon='code'>

    <ul>
      <li><span class='bold'>Followed Stories:</span> Under the <a href='/#/ncx/followed-stories'
                                                                   target='_blank'>Following</a> tab, you'll now find a
                                                      page for
                                                      Stories that lets you
                                                      review and unfollow stories quickly.
      </li>
      <li><span class='bold'>NCX in Slack Alerts:</span> Push notifications now include the post title instead of the
                                                         URL.
      </li>
      <li><span class='bold'>NCX in Slack Story Channels:</span> We now have guardrails to prevent accidentally
                                                                 unsubscribing a
                                                                 channel from NCX alerts.
      </li>
      <li><span class='bold'>Links on Mobile:</span> We've fixed mobile links so they now open to the correct page in
                                                     NCX instead
                                                     of the homepage.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='September 12th, 2023'
                   icon='code'>

    <ul>
      <li>Published Video Stories: Cut video published to the websites will automatically appear in 4 new NCX stories:
      </li>
      <li><a href='/#/ncx/landing-story/:1694490067731'
             target='_blank'>Published Video Content - All</a></li>
      <li><a href='/#/ncx/landing-story/:1694490111228'
             target='_blank'>Published Video Content - MSNBC</a></li>
      <li><a href='/#/ncx/landing-story/:1694490125820'
             target='_blank'>Published Video Content - Nightly News</a></li>
      <li><a href='/#/ncx/landing-story/:1694490090295'
             target='_blank'>Published Video Content - Today</a></li>
      <li>Tab Name Updates: Browser tab shows detailed page names instead of just "NewsConnect"</li>
      <li>New Slack Channel: The full text of all HOT and Important updates now send instantly to <a href=''
                                                                                                     target='_blank'>
        #newsconnect-hot-important</a> in Slack
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='April 12th, 2022'
                   icon='code'>

    <ul>
      <li>Ability to Send Cleared Video to WG01/06 <a href=''
                                                      target='_blank'>Watch video</a></li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='March 17th, 2022'
                   icon='code'>

    <ul>
      <li><span class='bold'>Log Label:</span> Use the new Log label as an additional way to identify posts in
                                               NewsConnect that
                                               contain logs! When you add the label to your post, others can search and
                                               filter
                                               for Logs in NewsConnect more easily.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='December 16th, 2021'
                   icon='code'>

    <ul>
      <li><span class='bold'>"Did you mean...?":</span> Misspell a search term and the system will suggest a correction
                                                        or
                                                        alternate term. Click on the suggested term to run your search
                                                        again with
                                                        the correct spelling.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='December 1st, 2021'
                   icon='code'>

    <ul>
      <li><span class='bold'>Relevancy Refinements:</span> When posts include all your search terms, they will appear
                                                           higher in
                                                           relevance search results.
      </li>
      <li><span class='bold'>REM & MediaID Recognition:</span> Include a REM or MediaID in a post and NewsConnect will
                                                               identify
                                                               it as a video for better discoverability and filtering.
      </li>
      <li><span class='bold'>Search for Tagged Stories:</span> If tags were added when creating a story, they are now
                                                               searchable.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='November 4th, 2021'
                   icon='code'>

    <ul>
      <li><span class='bold'>Dark Mode:</span> Change your preferred view from standard to dark mode.</li>
      <li><span class='bold'>Collapsed Navigation:</span> Collapse the left navigation and the app will remember that
                                                          setting
                                                          until you open it again.
      </li>
      <li><span class='bold'>Relevancy Search:</span> Sort search results to find content more relevant to your terms.
      </li>
      <li><span class='bold'>Search Result Count:</span> See how many results your search term generated.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='September 23rd, 2021'
                   icon='code'>

    <ul>
      <li><span class='bold'>Keyword Bold:</span> When you search, your keywords will show up in purple bold in the
                                                  results.
      </li>
      <li><span class='bold'>Keyword in Context:</span> You now get a preview of where your keyword appears in the post
                                                        description.
      </li>
      <li><span class='bold'>New Filters:</span> You can now filter elements by PDF and Word documents.</li>
      <li><span class='bold'>Search on Element Details Fields:</span> Information you enter into the Credit Name and
                                                                      Link to
                                                                      Source fields for elements are now searchable.
      </li>
      <li><span class='bold'>Tech Upgrade:</span> Behind the scenes, we've given the technical framework a big upgrade!
      </li>
      <li><span class='bold'>iOS 15 App Fix:</span> We've pushed out a new version of the mobile app that is compatible
                                                    with iOS
                                                    15.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='June 3rd, 2021'
                   icon='code'>

    <ul>
      <li>The label sections are now divided into <span class='bold'>Editorial/Standards</span> and <span class='bold'>R&C/Legal</span>
      </li>
      <li>One new label has been added to the Editorial/Standards section: <span class='bold'>Not Reportable</span></li>
      <li>Two new labels have been added to the R&C/Legal section: <span class='bold'>Licensed and Limited
                                                                                      License</span></li>
      <li>Use these labels to more accurately describe the status of licensing agreements and explain access or
          restrictions to video we've obtained or are trying to obtain.
      </li>
      <li>Four labels have been retired: Flagged, Buzzworthy, Logistics, and Under Review.</li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='April 28th, 2021'
                   icon='code'>

    <ul>
      <li><span class='bold'>Submitted Column Retired:</span> Now just navigate between stories in Working and Ready.
      </li>
      <li><span class='bold'>Story Slug Field Removed:</span> Slugs will no longer be needed when creating stories.</li>
      <li><span class='bold'>Feed Page Hidden:</span> The Feed page has been removed from the navigation, but you can
                                                      still reach
                                                      the page if you have it bookmarked.
      </li>
      <li><span class='bold'>New "Published/Aired" Label:</span> Use this new label to identify content that has been
                                                                 published
                                                                 or made it to air.
      </li>
      <li><span class='bold'>HOT Label Color Change:</span> The HOT label now has a red outline and text with a white
                                                            background.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='April 16th, 2021'
                   icon='code'>

    <ul>
      <li><span class='bold'>Linking Emailed Posts:</span> Posts added to multiple stories via email are now linked to
                                                           those
                                                           stories instead of creating duplicate posts in each.
      </li>
      <li><span class='bold'>New Search Filters:</span> Advanced filters are now available when searching for stories.
      </li>
      <li><span class='bold'>Search with Special Characters:</span> Use special characters like &commat; when searching.
      </li>
      <li><span class='bold'>Improved In-Story Search:</span> The search bars in each story now use the same algorithm
                                                              as the
                                                              main magnifying glass search.
      </li>
      <li><span class='bold'>Post History in Profile:</span> See the history of posts someone has created when viewing
                                                             their
                                                             profile.
      </li>
    </ul>

  </app-collapsable>

  <app-collapsable title='Release Notes'
                   iconTheme='outline'
                   altText='March 25th, 2021'
                   icon='code'>

    <p><span class='bold'>Search Improvements</span></p>

    <ul>
      <li>Search for exact phrases using quotation marks (e.g. "AstraZeneca revises vaccine data").</li>
      <li>See element pictures/thumbnails while searching.</li>
      <li>Results appear in order of most recently modified for stories and posts.</li>
      <li>When you hit enter, you will be defaulted to the Post results tab instead of the Stories tab.</li>
    </ul>

    <nz-divider></nz-divider>

    <p><span class='bold'>Design and Experience Improvements</span></p>

    <ul>
      <li>Increased post title font size.</li>
      <li>Groups tab now ordered by most recently modified.</li>
      <li>In search preview pane, Stories and People sections have been swapped.</li>
    </ul>

  </app-collapsable>

</section>

