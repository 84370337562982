<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout
   class="group-followers"
   [attr.data-component]="'ncx/Group/group-followers.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon
      [nzGhost]="false">
      <nz-page-header-title>
         <nz-button-group>
            <button
               nz-button
               (click)="toggleSortOrder()">
               <i
                  nz-icon
                  [nzType]="sortOrderSelected === 'A - Z' ? 'sort-ascending' : 'sort-descending'"></i>
               Order
            </button>

            <button
               nz-button
               nz-dropdown
               [nzDropdownMenu]="menu4">
               {{ sortOrderSelected }}

               <i
                  nz-icon
                  nzType="down"></i>

               <nz-dropdown-menu #menu4="nzDropdownMenu">
                  <ul nz-menu>
                     <li nz-menu-item>
                        <label (click)="changeSortOrder('A - Z')">A - Z</label>
                     </li>
                     <li nz-menu-item>
                        <label (click)="changeSortOrder('Z - A')">Z - A</label>
                     </li>
                  </ul>
               </nz-dropdown-menu>
            </button>
         </nz-button-group>
      </nz-page-header-title>

      <nz-page-header-extra>
         <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input
               type="text"
               #searchFollower
               [(ngModel)]="searchInput"
               (keyup)="filterFollowers()"
               nz-input
               placeholder="Search Followers..." />
            <ng-template #prefixTemplateUser>
               <i
                  nz-icon
                  nzType="search"></i>
            </ng-template>
         </nz-input-group>

         <!-- OPEN GROUP DETAILS -->
         <button
            (click)="toggleSider()"
            class="slider-btn">
            <i
               nz-icon
               nzType="setting"
               nzTheme="outline"></i>
         </button>
      </nz-page-header-extra>
   </nz-page-header>
   <nz-layout>
      <nz-content>
         <nz-card>
            <div class="storyTitle">
               <div>
                  <span *ngIf="groupDetails && groupDetails.groupName">
                     {{ groupDetails.groupName }}
                  </span>
                  <span style="float: right">
                     <app-follow-group
                        *ngIf="groupDetails"
                        type="text"
                        [groupId]="groupDetails.groupId"
                        [isFollowing]="groupDetails.isUserFollowing"
                        [followers]="groupDetails.groupFollowerCount"
                        [showFollowCount]="true"
                        [showFollowStatus]="false"
                        (updatedFollower)="updateFollower($event)"></app-follow-group>
                  </span>
               </div>
            </div>
         </nz-card>
         <nz-card class="following">
            <app-stories-following
               [storyFollowingDetails]="filteredFollowers"
               (isFollowingCollapsed)="closeDrawer()"></app-stories-following>
         </nz-card>
      </nz-content>
      <nz-sider
         *ngIf="!isMobile"
         nzCollapsible
         [nzWidth]="295"
         [(nzCollapsed)]="groupDetailsIsCollapsed"
         [nzTrigger]="null"
         nzCollapsedWidth="0">
         <app-toggle-section
            [gdCollapsed]="gdCollapsed"
            [groupDetails]="groupDetails"
            (gdCloseSider)="toggleSider()"
            (isEdit)="redirectToEditGroup($event)"
            (gotoManageMembers)="goToManageMembers()"></app-toggle-section>
      </nz-sider>
   </nz-layout>
</nz-layout>

<nz-drawer
   *ngIf="isMobile"
   [nzClosable]="false"
   [nzVisible]="!groupDetailsIsCollapsed"
   [nzWrapClassName]="'no-padding'"
   nzPlacement="right"
   [nzWidth]="320"
   (nzOnClose)="closeSlider()">
   <ng-container *nzDrawerContent>
      <app-toggle-section
         [gdCollapsed]="gdCollapsed"
         [groupDetails]="groupDetails"
         (gdCloseSider)="toggleSider()"
         (isEdit)="redirectToEditGroup($event)"
         (gotoManageMembers)="goToManageMembers()"></app-toggle-section>
   </ng-container>
</nz-drawer>
