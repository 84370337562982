<app-loader-component
   [isLoaded]="isLoaded"
   loaderType="PAGE_LOADER"></app-loader-component>

<nz-layout
   class="discussion-details"
   [attr.data-component]="'ncx/Group/discussion-details.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon
      (nzBack)="back()">
      <nz-page-header-title>
         <nz-divider nzType="vertical"></nz-divider>
         <span class="headerTitle">{{ groupName }}</span>
      </nz-page-header-title>

      <nz-page-header-extra class="extra-header">
         <button
            nz-button
            (click)="toggleSider()">
            <i
               nz-icon
               nzType="setting"
               nzTheme="outline"></i>
         </button>
      </nz-page-header-extra>
   </nz-page-header>

   <nz-layout>
      <nz-content>
         <div
            *ngIf="
               discussionDetails && discussionDetails.locked && loggedInUserId !== discussionDetails.lockedByUser.userId
            "
            class="lockedMessage">
            <i
               nz-icon
               nzType="check-circle"
               nzTheme="fill"></i>
            This discussion is being edited by &nbsp;
            <button
               class="custom-popover"
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'bottomLeft'"
               *ngIf="
                  discussionDetails.lockedByUser?.displayName &&
                  discussionDetails.lockedByUser.displayName.split(',').length > 1
               ">
               {{ discussionDetails.lockedByUser.displayName.split(',')[1].trim() }}
               {{ discussionDetails.lockedByUser.displayName.split(',')[0] }}
            </button>
            <button
               class="custom-popover"
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'bottomLeft'"
               *ngIf="
                  discussionDetails.lockedByUser?.displayName &&
                  discussionDetails.lockedByUser.displayName.split(',').length === 1
               ">
               {{ discussionDetails.lockedByUser.displayName.trim() }}
            </button>
            &nbsp; and is locked
            <ng-template #profileCardTemplate>
               <app-profile-overlay [profileData]="discussionDetails.lockedByUser"></app-profile-overlay>
            </ng-template>
         </div>
         <app-archive-banner
            [archiveUrl]="archiveUrl"
            *ngIf="
               discussionDetails &&
               discussionDetails.discussionVersionState &&
               discussionDetails.discussionVersionState === 'Archived' &&
               urlCheckFlag
            "></app-archive-banner>
         <app-draft-banner
            *ngIf="discussionDetails && discussionDetails.discussionVersionState === 'Draft'"></app-draft-banner>
         <div class="discussionContent">
            <div class="ddTitle">
               {{ discussionDetails.discussionSubject }}
            </div>
            <div class="creationDetails">
               <span
                  class="creatorDetails"
                  *ngIf="discussionDetails && discussionDetails.createUser">
                  Created by:
                  <span class="creators">
                     <button
                        nz-popover
                        [nzPopoverContent]="profileCardTemplate"
                        [nzPopoverPlacement]="'bottomLeft'"
                        *ngIf="
                           discussionDetails.createUser?.displayName &&
                           discussionDetails.createUser.displayName.split(',').length > 1
                        ">
                        {{ discussionDetails.createUser.displayName.split(',')[1].trim().substr(0, 1) }}
                        .{{ discussionDetails.createUser.displayName.split(',')[0] }}
                     </button>
                     <button
                        nz-popover
                        [nzPopoverContent]="profileCardTemplate"
                        [nzPopoverPlacement]="'bottomLeft'"
                        *ngIf="
                           discussionDetails &&
                           discussionDetails.createUser?.displayName &&
                           discussionDetails.createUser.displayName.split(',').length === 1
                        ">
                        {{ discussionDetails.createUser.displayName.trim().substr(0, 1) }}
                     </button>
                     <ng-template #profileCardTemplate>
                        <app-profile-overlay [profileData]="discussionDetails.createUser"></app-profile-overlay>
                     </ng-template>
                     <!-- {{discussionDetails.createUser.firstName}} {{discussionDetails.createUser.lastName}} -->
                  </span>
               </span>
               <span class="createdTime">
                  <i
                     nz-icon
                     nzType="clock-circle"
                     nzTheme="fill"></i>
                  <!-- {{ utcToLocal1(discussionDetails.createDateTime) }} -->
                  {{ utcToLocal(discussionDetails.createDateTime, 'TIME') }}
               </span>
               <span
                  class="createdDate"
                  *ngIf="discussionDetails && discussionDetails.createDateTime">
                  <i
                     nz-icon
                     nzType="calendar"
                     nzTheme="fill"></i>
                  {{ discussionDetails.createDateTime | date: 'M/d/yy' }}
               </span>
            </div>

            <!-- Discussion Description. Profile view on mouse over. If the description has the format @username then the profile will be shown -->
            <span
               class="custom-popover"
               id="triggerClick"
               nzPopoverTrigger="click"
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'topLeft'"></span>
            <ng-template
               cdkConnectedOverlay
               #profileCardTemplate>
               <app-profile-overlay [profileData]="profileInDescription"></app-profile-overlay>
            </ng-template>
            <div
               class="discontent fr-element fr-view"
               #discussionContent
               *ngIf="discussionDetails?.discussionContent">
               <span [innerHtml]="discussionDetails?.discussionContent | safeHtml"></span>
            </div>

        <div class="discussion-attachments" *ngIf="discussionDetails.discussionAttachments">
          <app-view-attachments [postAttachments]="discussionDetails.discussionAttachments" [showAllAttachments]="true">
          </app-view-attachments>
        </div>
        <div nz-row>
          <div class="options">
            <span>
              <i nz-icon
                 nzType="paper-clip"
                 nzTheme="outline"></i>
              <span *ngIf="discussionDetails && discussionDetails.discussionAttachments && discussionDetails.discussionAttachments.length">
                {{discussionDetails.discussionAttachments.length}}
              </span>
            </span>
            <span>
              <i nz-icon
                 nzType="comment"
                 nzTheme="outline"></i>
              <span>
                {{(discussionDetails && discussionDetails.discussionCommentCount)
                ? discussionDetails.discussionCommentCount
                : 0 }}
              </span>
            </span>
            <button (click)="shareContent()">
              <i nz-icon
                 nzType="share-alt"
                 nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="discussionDetails">
        <app-html-comments [discussionVersionGroupId]="discussionDetails.discussionVersionGroupId"></app-html-comments>
      </div>
    </nz-content>
    <nz-sider *ngIf="!isMobile"
              nzCollapsible
              [nzWidth]="295"
              [(nzCollapsed)]="discussionDetailsIsCollapsed"
              [nzTrigger]="null"
              nzCollapsedWidth="0">
      <app-toggle-section (ddCloseSider)="toggleSider()"
                          [ddCollapsed]="ddCollapsed"
                          [groupId]="groupId"
                          (isDelete)="deleteDiscussion($event)"
                          (editDiscussion)="editDiscussion($event)"
                          [discussionId]="discussionId"
                          (movePostDiscussion)="openDiscussionMovePost()"
                          (discussionlocked)="discussionLocked($event)"
                          (downloadDiscPDF)="downloadDiscPDF($event)"
                          (openGroupVersions)="openGroupVersions()"
                          (unlockthePost)="unlockPost($event)">
      </app-toggle-section>
    </nz-sider>
  </nz-layout>
</nz-layout>

<nz-drawer
   *ngIf="isMobile"
   [nzClosable]="false"
   [nzVisible]="!discussionDetailsIsCollapsed"
   [nzWrapClassName]="'no-padding'"
   [nzMaskClosable]="true"
   nzPlacement="right"
   [nzWidth]="320"
   (nzOnClose)="closeSlider()">
   <ng-container *nzDrawerContent>
      <app-toggle-section
         (ddCloseSider)="toggleSider()"
         [ddCollapsed]="ddCollapsed"
         [groupId]="groupId"
         (isDelete)="deleteDiscussion($event)"
         (editDiscussion)="editDiscussion($event)"
         [discussionId]="discussionId"
         (movePostDiscussion)="openDiscussionMovePost()"
         (discussionlocked)="discussionLocked($event)"
         (downloadDiscPDF)="downloadDiscPDF($event)"
         (openGroupVersions)="openGroupVersions()"
         (unlockthePost)="unlockPost($event)"></app-toggle-section>
   </ng-container>
</nz-drawer>

<app-share-discussion
   [isVisible]="isVisible"
   [discussionId]="discussionId"
   [message]="discussionDetails.discussionSubject"
   (closedSharedContent)="handleCancel()"></app-share-discussion>

<app-move-discussion
   [isMovePost]="isMovePostDiscussion"
   (closeMovePost)="closeMovePost()"
   (isDiscussionUpdated)="updateGroupDetails($event)"
   [groupId]="groupId"
   [discussionDetails]="discussionDetails"></app-move-discussion>
