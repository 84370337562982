import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-linked-stories-view',
  templateUrl: './linked-stories-view.component.html',
  styleUrls: ['./linked-stories-view.component.scss']
})
export class LinkedStoriesViewComponent implements OnInit {

  @Input() relatedStoryTitles: any = [];

  header: string;

  constructor() { }

  ngOnInit() {

    // this.relatedStoryTitles.forEach(story => {

    //   story.autoGeneratedStoryId = 'HardCoded - SA47FL0001';

    // });

    this.header = this.relatedStoryTitles.length == 0 ? 'Linked Stories' : `Linked Stories ( ${this.relatedStoryTitles.length} )`;

  }

  redirectTo(storyId) {

    const url = window.location.origin + '/#/ncx/landing-story/:' + storyId;

    window.open(url, '_self');

  }

}
