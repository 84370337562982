<nz-collapse
   nzExpandIconPosition="right"
   nzBordered="false">
   <nz-collapse-panel
      nzHeader="Element Details"
      nzActive="true">
      <div class="elements">
         <div
            class="elementDetails"
            *ngIf="postDetails.attachmentDetails?.linkToSource">
            <div class="elementDetailsLabel">Link to Source:</div>
            <div class="elementDetailsLinkText">
               <a
                  href="{{ openLinkToSource(postDetails.attachmentDetails?.linkToSource) }}"
                  target="_blank">
                  {{ postDetails.attachmentDetails?.linkToSource }}
               </a>
            </div>
         </div>
         <div
            class="elementDetails"
            *ngIf="postDetails.attachmentDetails?.captureDate">
            <div class="elementDetailsLabel">Date of Capture:</div>
            <div class="elementDetailsText">{{ captureDate }}</div>
         </div>
         <div
            class="elementDetails"
            *ngIf="postDetails.attachmentDetails?.creditName">
            <div class="elementDetailsLabel">Mandatory Credit:</div>
            <div class="elementDetailsText">{{ postDetails.attachmentDetails?.creditName }}</div>
         </div>
         <div
            class="elementDetails"
            *ngIf="postDetails.attachmentDetails?.clearanceStatus">
            <div class="elementDetailsLabel">Cleared for NBCU Partners?</div>
            <div class="elementDetailsText">
               {{ postDetails.attachmentDetails?.clearanceStatus === 'yes' ? 'Yes' : 'No' }}
            </div>
         </div>
      </div>
   </nz-collapse-panel>
</nz-collapse>
