import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@environments/environment';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { UrlRedirectService } from '@services/url-redirect.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnChanges {

  attachmentTitle = '';

  selectedValue = 'Lucy';

  drop = false;

  fileList: NzUploadFile[] = [];

  toastFlag = false;

  subject: Subject<string> = new Subject();

  @Input() attachmentDetails = null;

  @Input() attachments = [];

  @Input() rteValue;

  @Input() postTitle;

  @Input() isAttachmentDisabled;

  @Input() savedpostId;

  @Input() saveDiscussionId;

  @Input() hideElements;

  @Input() screenType;

  @Output() attachmentsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output() attachmentDetailsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output() isAttachmentDeleted = new EventEmitter<any>();

  @ViewChild('creditName') public creditName: ElementRef<any>;

  isNewPost = true;

  mandatory;

  postId: any = 0;

  isAttachmentsBinded = false;

  disbaleUpload = true;

  header: HttpHeaders;

  postAttachmentAPIURL: any;

  removedAttachmentIds: any = [];

  constructor(private msg: NzMessageService, private http: HttpClient,
              private router: ActivatedRoute, private toastService: ToastService,
              private commonSer: CommonService, private commonFunctionsHelper: CommonFunctionsHelper,
              private urlRedirectService: UrlRedirectService
  ) {

    this.subject.pipe(
      debounceTime(700)
    ).subscribe(() => {

      this.updateElementDetails();

    });

  }

  ngOnInit() {

    this.postAttachmentAPIURL = environment.getPostApi;
    this.removedAttachmentIds = [];
    this.router.params.subscribe(
      (params: Params) => {

        this.setParams(params);

      });
    if (!this.hideElements) {

      this.attachmentTitle = 'Attachments/Elements';

    } else {

      this.attachmentTitle = 'Attachments';

    }

  }

  setParams(params) {

    if (params && params.postId) {

      const id = params.postId;

      this.postId = id.substr(1);
      this.isNewPost = false;

      // if (!(this.postTitle && this.rteValue)) {
      //   // this.disbaleUpload = true;
      // } else {
      //   // this.disbaleUpload = false;
      // }

    } else {

      this.isNewPost = true;
      this.isAttachmentsBinded = true;
      this.fileList = [];

    }

  }

  ngOnChanges() {

    // if (this.attachments && this.fileList.length > 0) {
    //   this.drop = !this.drop;
    // }
    // tslint:disable-next-line:max-line-length
    this.disbaleUpload = (this.isAttachmentDisabled || this.isAttachmentDisabled === undefined ||
      this.isAttachmentDisabled === null) ? true : false;
    if (this.isAttachmentDisabled === undefined) {

      this.disbaleUpload = true;

    }
    this.bindAttachments();

  }

  bindAttachments() {

    if (this.attachmentDetails && this.fileList.length > 0) {

      this.drop = !this.drop;

    }
    const dummy = [];

    if (this.attachments && this.attachments.length) {

      this.fileList = [];
      this.attachments.forEach(element => {

        if (element.attachmentId !== '') {

          dummy.push({
            uid: element.attachmentId,
            name: element.attachmentName,
            filename: element.attachmentName,
            url: this.urlRedirectService.getRedirectAPIURL(element.attachmentPath) + `?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`, // element.attachmentBinaries
            status: element.status,
            size: element.size
          });
          this.isAttachmentsBinded = true;

        }

      });
      this.fileList = [...dummy];

    }

  }

  showElements() {

    this.drop = !this.drop;

  }

  handleChange(event) {

    if (this.attachments === null || this.attachments === undefined) {

      this.attachments = [];

    }
    console.log(event);
    console.log(this.attachments);
    if (event.type === 'success') {

      this.drop = true;

    }

    if (event.type === 'removed' && event.file.uid) {

      let index = -1;

      // Created
      if (event.file.response) {

        index = this.attachments.findIndex((attachment) => attachment.attachmentId === event.file.response.attachmentId);

      } else {

        // Edited
        index = this.attachments.findIndex((attachment) => attachment.attachmentId === event.file.uid);

      }

      if (index >= 0) {

        this.attachments.splice(index, 1);
        this.attachmentsUpdated.emit({ attachments: this.attachments, isDeleted: true });

      }

    }

  }

  // dummyRequest = ({ file, onSuccess }) => {
  //   setTimeout(() => {
  //     onSuccess('ok');
  //   }, 0);
  // }

  calculateUploadSize(currentAttachmetSize) {

    let totalSize = 0;

    this.fileList.forEach((file) => {

      if (file.size && file.status !== 'error') {

        totalSize += file.size;

      }

    });
    if (((totalSize + currentAttachmetSize) / 1000000) > 307.2 && !this.toastFlag) { // 307.2

      this.toastFlag = true;
      return false;

    } else {

      return true;

    }

  }

  calculateFileCount() {

    let totalCount = 0;

    this.fileList.forEach((file) => {

      if (file.size && file.status !== 'error') {

        totalCount++;

      }

    });
    if (totalCount >= 25) { // 25 file count

      this.toastFlag = true;
      return false;

    } else {

      return true;

    }

  }

  beforeUpload = (file: NzUploadFile): boolean => {

    if (!this.calculateUploadSize(file.size)) {

      setTimeout(() => {

        if (this.toastFlag) {

          this.toastService.createMessage('error', 'Maximum File Size Limit!');
          this.toastFlag = false;

        }

      }, 500);

    }
    if (!this.calculateFileCount()) {

      setTimeout(() => {

        if (this.toastFlag) {

          this.toastService.createMessage('error', 'Maximum File Upload Limit is 25!');
          this.toastFlag = false;

        }

      }, 500);

    }
    return (this.fileList.length < 25 && !this.toastFlag) ? true : false;

  };

  setMediaUploadHeaders = (_file: NzUploadFile) => {

    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    };

  };

  // getBasePath() {
  //     const domain = window.location.origin;
  //     if ((domain === AppConstants.domain.dev_url) || (domain === AppConstants.domain.dev1_url)
  //           || (domain === AppConstants.domain.prod_url) || (domain === AppConstants.domain.qa_url)
  //           || (domain === AppConstants.domain.qa1_url) || (domain === AppConstants.domain.stg_url)) {
  //         return `${domain}/ncx-gateway`;
  //     } else {
  //         return `${AppConstants.domain.qa1_url}/ncx-gateway`;
  //     }
  // }

  customReq = (item: NzUploadXHRArgs) => {

    const basePath = `${this.commonSer.getAPIEndpoint()}/ncx-gateway`;

    let queryStr;

    const messageId = this.commonFunctionsHelper.generateUUID();

    const userId = localStorage.getItem('userId');

    const ncxjwttoken = localStorage.getItem('ncxjwttoken');

    const headers = new HttpHeaders({
      messageId,
      userId,
      ncxjwttoken
    });

    if (this.saveDiscussionId) {

      this.postAttachmentAPIURL = environment.getDiscussionURL;
      queryStr = `${this.postAttachmentAPIURL}/${this.saveDiscussionId}/attachment`;

    } else if (this.savedpostId) {

      this.postAttachmentAPIURL = environment.getPostApi;
      queryStr = `${this.postAttachmentAPIURL}/${this.savedpostId}/attachment`;

    }
    const formData = new FormData();

    formData.append('attachmentFile', item.file as any);

    // New Upload progres Bar Updated Code
    const req = new HttpRequest('POST', basePath + queryStr, formData, {
      headers,
      reportProgress: true
    });

    setTimeout(() => {

      // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
      return this.http.request(req).subscribe(

        // tslint:disable-next-line no-any
        (event: HttpEvent<any>) => {

          if (event.type === HttpEventType.UploadProgress) {

            // tslint:disable-next-line:no-non-null-assertion
            if (event.total! > 0) {

              // tslint:disable-next-line:no-any
              // tslint:disable-next-line:no-non-null-assertion
              (event as any).percent = (event.loaded / event.total!) * 100;

            }

            // tslint:disable-next-line:no-non-null-assertion
            item.onProgress!(event, item.file!);

          } else if (event instanceof HttpResponse) {

            // tslint:disable-next-line:no-non-null-assertion
            item.onSuccess!(event.body, item.file!, event);
            console.log('this.attachments : ', event);
            this.attachments.push(event.body);
            this.attachmentsUpdated.emit({ attachments: this.attachments, isDeleted: false });

          }

        },
        err => {

          // tslint:disable-next-line:no-non-null-assertion
          item.onError!(err, item.file!);

        }
      );

    }, 500);

    // New Upload progres Bar Updated Code
    // return this.commonSer.post(queryStr, formData, headers)
    //   .subscribe((event: HttpEvent<{}>) => {
    //     console.log('Uplaod Attachment :',  event);
    //     this.attachments.push(event);
    //     this.attachmentsUpdated.emit({attachments: this.attachments, isDeleted: false});
    //     // tslint:disable-next-line:no-string-literal
    //     if (event['status'] === 'success') {
    //       // tslint:disable-next-line:no-string-literal
    //       item.onSuccess(event['userId'], item.file, event);
    //     }
    //     if (event.type === HttpEventType.UploadProgress) {
    //       if (event.total > 0) {
    //         (event as any).percent = event.loaded / event.total * 100;
    //       }
    //       item.onProgress(event, item.file);
    //     } else if (event instanceof HttpResponse) {
    //       item.onSuccess(event.body, item.file, event);
    //       // this.toastService.createMessage('success', this.Translate.instant('toaster.toastMessageProfile'));
    //       // this.isLoaded = true;
    //       // // tslint:disable-next-line:no-string-literal
    //       // this.avatarImgUrl = event['picLink'] + '?random+\=' + Math.random();
    //       // this.userInfo.getAvtarLinkUrl(this.avatarImgUrl);
    //     }
    //   }
    //   , (err) => {
    //     item.onError(err, item.file);
    //     this.msg.error(`Error in upload ${item.file.name}, please remove and upload another file.`);
    //     return;
    //   }
    //   );

  };

  // handleChange({ file, fileList }: UploadChangeParam): void {
  //   const status = file.status;
  //   if (status !== 'uploading') {
  //     console.log(file, fileList);
  //   }
  //   if (status === 'done') {
  //     this.msg.success(`${file.name} file uploaded successfully.`);
  //   } else if (status === 'error') {
  //     this.msg.error(`${file.name} file upload failed.`);
  //   }
  // }
  // handleChange({ file, fileList }: UploadChangeParam): void {
  //   console.log('handleChange', file, fileList);
  //   const status = file.status;
  //   if (status !== 'uploading') {
  //     console.log(file, fileList);
  //   }
  //   if (status === 'done') {
  //     this.msg.success(`${file.name} file uploaded successfully.`);
  //   } else if (status === 'error') {
  //     this.msg.error(`${file.name} file upload failed.`);
  //   }
  // }
  // linkToSource: string;
  // clearanceStatus: string;
  // captureDate: string;
  // isMandatoryCredit: boolean;
  // creditName: string

  onChange(_event, _from?) {

    this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

  }

  onCreditChange(value) {

    if (value === 'yes') {

      if (this.attachmentDetails.creditName === '' || this.attachmentDetails.creditName === null) {

        this.toastService.createMessage('warning', 'Please provide Credit Name');
        this.creditName.nativeElement.style.border = '1px solid red';
        this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: false });
        return;

      }

    } else if (value === 'no') {

      this.attachmentDetails.creditName = '';
      this.creditName.nativeElement.style.border = '1px solid #d9d9d9';
      this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

    } else {

      this.attachmentDetails.isMandatoryCredit = '';
      this.attachmentDetails.creditName = '';
      this.creditName.nativeElement.style.border = '1px solid #d9d9d9';
      this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

    }

  }

  onCreditNameChange(creditName) {

    this.subject.next(creditName);
    this.attachmentDetails.creditName = creditName;
    this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

  }

  updateElementDetails() {

    if (this.attachmentDetails.isMandatoryCredit === 'yes') {

      if (this.attachmentDetails.creditName === '' || this.attachmentDetails.creditName === null) {

        this.creditName.nativeElement.style.border = '1px solid red';
        this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: false });
        return;

      } else if (this.attachmentDetails.isMandatoryCredit === 'no') {

        this.attachmentDetails.creditName = '';
        this.creditName.nativeElement.style.border = '1px solid #d9d9d9';
        this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

      } else {

        // this.attachmentDetails.creditName = '';
        this.creditName.nativeElement.style.border = '1px solid #d9d9d9';
        this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

      }

    }

  }

}
