import { AfterViewChecked, AfterViewInit, Component, ContentChild, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { BodyTemplateDirective } from '@directives/layout-directives';

@Component({
  selector: 'app-scroll-container',
  templateUrl: './scroll-container.component.html',
  styleUrls: ['./scroll-container.component.scss']
})
export class ScrollContainerComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  // Custom Body Container Height Like Padding Margin
  @Input() additionalPadding: number = 0;

  // Body Footer Height
  @Input() footerHeight: number = 0;

  // Body Footer Height
  @Input() maxHeight: number = 0;

  // If Side Bar container
  @Input() sideBarContainer: boolean = false;

  // CSS Variable name
  contentHeightVariable = '';

  // Body Element
  @ViewChild('bodyElement', { static: false }) bodyElement: ElementRef | any;

  // Body Template Element
  @ContentChild(BodyTemplateDirective, { static: true }) bodyTemplate!: BodyTemplateDirective;

  /**
   * Default method of the class that is executed when the class is instantiated
   * Setup Dependency Injection
   */
  constructor(
    private element: ElementRef
  ) { }

  /**
   * ngAfterViewInit Angular Life cycle
   */
  ngAfterViewInit(): void {

    this.element.nativeElement.querySelector('.scroll-container').children[0].classList.add('scroll-container-content');

  }

  /**
   * ngAfterViewChecked Angular Life cycle
   */
  ngAfterViewChecked(): void {

    const curHeight = this.maxHeight == 0 ? window.innerHeight : this.maxHeight;

    const contentHeight = curHeight - this.bodyElement?.nativeElement?.getBoundingClientRect()?.top - (this.footerHeight + this.additionalPadding);

    this.element.nativeElement.style.setProperty('--scroll-container-content-height', contentHeight + 'px');

  }

  /**
   * ngOnDestroy Angular Life cycle
   * Use for any custom cleanup that needs to occur when the instance is destroyed
   */
  ngOnDestroy(): void {

    document.documentElement.style.removeProperty(`--${this.contentHeightVariable}`);

  }

}
