<div class="standardGuidanceSection">
   <div class="standard-guidance-title">Standards Guidance</div>
   <div class="standard-guidance-text">
      <span [innerHtml]="postDetails.standardsGuidance?.notes | safeHtml"></span>
   </div>

   <div
      class="created-updated-details"
      *ngIf="
         postDetails &&
         postDetails.standardsGuidance &&
         postDetails.standardsGuidance?.createUser &&
         postDetails.standardsGuidance?.createDateTime === postDetails.standardsGuidance?.updateDateTime
      ">
      <div class="created-updated-label pd-r-8">Created By:</div>
      <div class="created-updated-text pd-r-8">
         <span
            class="created-updated-profile"
            *ngIf="postDetails.standardsGuidance.createUser?.displayName?.split(',').length > 1"
            nz-popover
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'">
            {{ postDetails.standardsGuidance.createUser?.displayName.split(',')[1].trim() }}
            {{ postDetails.standardsGuidance.createUser?.displayName.split(',')[0].trim() }}
         </span>

         <span
            class="created-updated-profile"
            *ngIf="postDetails.standardsGuidance.createUser?.displayName?.split(',').length === 1"
            nz-popover
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'">
            {{ postDetails.standardsGuidance.createUser.displayName.trim() }}
         </span>
         <!-- <span nz-popover [nzPopoverContent]="profileCardTemplate" [nzPopoverPlacement]="'bottomLeft'">
        {{postDetails.standardsGuidance.createUser.firstName.trim()}}
        {{postDetails.standardsGuidance.createUser.lastName.trim()}}
      </span> -->

         <span class="pd-l-8">
            {{ utcToLocal(postDetails.standardsGuidance?.createDateTime, 'DATE') }}
         </span>
         <span>at {{ utcToLocal(postDetails.standardsGuidance?.createDateTime, 'TIME') }}</span>
      </div>
      <ng-template #profileCardTemplate>
         <app-profile-overlay [profileData]="postDetails.standardsGuidance?.createUser"></app-profile-overlay>
      </ng-template>
   </div>

   <div
      class="created-updated-details"
      *ngIf="
         postDetails &&
         postDetails.standardsGuidance &&
         postDetails.standardsGuidance.updateUser &&
         postDetails.standardsGuidance.createDateTime !== postDetails.standardsGuidance.updateDateTime
      ">
      <div class="created-updated-label pd-r-8">Modified By:</div>
      <div class="created-updated-text pd-r-8">
         <span
            class="created-updated-profile"
            *ngIf="postDetails.standardsGuidance.updateUser.displayName?.split(',').length > 1"
            nz-popover
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'">
            {{ postDetails.standardsGuidance.updateUser.displayName.split(',')[1].trim() }}
            {{ postDetails.standardsGuidance.updateUser.displayName.split(',')[0].trim() }}
         </span>

         <span
            class="created-updated-profile"
            *ngIf="postDetails.standardsGuidance.updateUser.displayName?.split(',').length === 1"
            nz-popover
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'">
            {{ postDetails.standardsGuidance.updateUser.displayName.trim() }}
         </span>
         <!-- <span nz-popover [nzPopoverContent]="profileCardTemplate" [nzPopoverPlacement]="'bottomLeft'">
      {{postDetails.standardsGuidance.updateUser.firstName.trim()}}
      {{postDetails.standardsGuidance.updateUser.lastName.trim()}}
    </span> -->

         <span class="pd-l-8">
            {{ utcToLocal(postDetails.standardsGuidance.updateDateTime, 'DATE') }}
         </span>
         <span>at {{ utcToLocal(postDetails.standardsGuidance.updateDateTime, 'TIME') }}</span>
      </div>
      <ng-template #profileCardTemplate>
         <app-profile-overlay [profileData]="postDetails.standardsGuidance.updateUser"></app-profile-overlay>
      </ng-template>
   </div>
</div>
