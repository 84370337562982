<div class="story-team-section">
   <div class="privacy-collaborators">
      <div class="link-story-team">
         <div class="link-story-team-label">
            Privacy
            <i
               nz-icon
               nzType="question-circle"
               nzTheme="outline"
               nz-tooltip
               nzTooltipPlacement="topRight"
               [nzTooltipTitle]="privacyToolTipTemplate"
               class="mr-l-4"
               (click)="toggleTooltip()"
               (mouseover)="showTooltipEvent()"
               (mouseout)="hideTooltip()"
               [nzTooltipTrigger]="tooltipTrigger"
               [nzTooltipVisible]="showToolTip"></i>
         </div>

         <div class="privacy-group">
            <div class="privacy-icon-text">
               <button
                  nz-button
                  class="storyHeaderBtn"
                  [disabled]="disableAccess"
                  nzType="default"
                  (click)="toggleAccess()">
                  <i
                     nz-icon
                     [nzType]="privacy ? 'eye-invisible' : 'eye'"
                     nzTheme="outline"></i>
               </button>
            </div>
            <div class="privacy-icon-text">
               <nz-select
                  class="storySelectBtn"
                  [(ngModel)]="access"
                  (ngModelChange)="updateAccess()"
                  [disabled]="disableAccess"
                  style="border-radius: 0">
                  <nz-option
                     nzValue="private"
                     nzLabel="Private"
                     *ngIf="functionAbility.fa_create_private_angle"></nz-option>
                  <nz-option
                     nzValue="public"
                     nzLabel="Public"
                     *ngIf="functionAbility.fa_create_public_angle"></nz-option>
               </nz-select>
            </div>
         </div>
      </div>

      <div class="link-story-team">
         <div class="link-story-team-label">Collaborators</div>
         <div class="input-group">
            <nz-input-group [nzSuffix]="suffixIconSearch">
               <input
                  type="text"
                  nz-input
                  placeholder="Search for Collaborators"
                  [(ngModel)]="searchInput"
                  [disabled]="!accessToEditCollaborators"
                  class="search-text"
                  (keydown)="onchange($event)"
                  (keyup)="onchange($event)"
                  [nzAutocomplete]="auto"
                  (ngModelChange)="selectedUser($event)" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
               <i
                  nz-icon
                  nzType="search"
                  class="search-icon"></i>
            </ng-template>
            <nz-autocomplete
               [nzDataSource]="searchedUserNames"
               #auto></nz-autocomplete>
         </div>
      </div>
   </div>

   <div
      *ngIf="availableUsers"
      class="linked-story-team"
      [class.bottomPad]="availableUsers.length > 0">
      <ng-container *ngFor="let user of availableUsers; let first = first">
         <span
            class="story-team"
            *ngIf="user">
            <span class="story-gap">
               <span class="story-team-details">
                  <div class="name">{{ user.firstName }} {{ user.lastName }}</div>
                  <div
                     class="role"
                     [ngClass]="user.jobTitle === '' ? 'empty' : ''">
                     {{ user.jobTitle }}
                  </div>
               </span>

               <span class="story-team-delete">
                  <span
                     nz-icon
                     nzType="delete"
                     nzTheme="outline"
                     *ngIf="!(createdUser.userId === user.userId) && accessToEditCollaborators"
                     (click)="unlinkCollaborators(user.userId)"></span>
               </span>
            </span>
         </span>
      </ng-container>
   </div>
</div>

<ng-template #privacyToolTipTemplate>
   <span class="tool-tip">Private angles can only be viewed and edited by those listed as Collaborators</span>
</ng-template>
