import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoryContentSearchResult } from '@models/ncx/global-search';
import { BreakpointService } from '@services/breakpoint.service';
import { NavigationService } from '@services/navigation-service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';

@Component({
  selector: 'ncx-post-search',
  templateUrl: './post-search.component.html',
  styleUrls: ['../../cards-common.scss', './post-search.component.scss']
})
export class PostSearchComponent {

  // Story/Post information
  @Input() post: StoryContentSearchResult = {} as StoryContentSearchResult;

  @Output() sharePost: EventEmitter<StoryContentSearchResult> = new EventEmitter<StoryContentSearchResult>();

  @Output() viewPost: EventEmitter<StoryContentSearchResult> = new EventEmitter<StoryContentSearchResult>();

  @Output() viewStory: EventEmitter<StoryContentSearchResult> = new EventEmitter<StoryContentSearchResult>();

  public readonly Common = Common;

  constructor(
    private breakpointService: BreakpointService,
    private urlRedirectService: UrlRedirectService,
    private navigationService: NavigationService
  ) {}

  onShare() {

    this.sharePost.emit(this.post);

  }

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewPost() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewPost.emit(this.post);
      return true;
    
    } else {

      this.viewPost.emit(this.post);
      window.open(this.urlRedirectService.getRedirectURL(this.post.targetURL), '_blank');
      return false;
    
    }

  }

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewStory() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewStory.emit(this.post);
      return true;
    
    } else {

      this.viewStory.emit(this.post);
      window.open(this.urlRedirectService.getRedirectURL(this.post.storyURL), '_blank');
      return false;
    
    }

  }

  get date(): string {

    const { updatedDateTime, createDateTime } = this.post;

    const created = new Date(createDateTime);

    const updated = updatedDateTime ? new Date(updatedDateTime) : null;

    if (updated && created < updated) {

      return `updated on ${Time.convertingUtcToLocalDate(updatedDateTime)} at ${Time.convertingUtcToLocalTime(updatedDateTime)}`;
    
    } else {

      return `posted on ${Time.convertingUtcToLocalDate(createDateTime)} at ${Time.convertingUtcToLocalTime(createDateTime)}`;
    
    }

  }

  // get isMobile(): boolean {

  //   return true;//this.breakpointService.isMobile.value;

  // }

}
