import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})

export class Time {

  /**
   * Convert UTC timestamp to local date/time '01/01/2023 12:00am'
   *
   */
  public static convertingUtcToLocal(date: string) {

    try {

      return moment.utc(date).local().format('MM/DD/YYYY h:mm a');

    } catch (error) {

      console.log('convertingUtcToLocal', error);

      return date;

    }

  }

  /**
   * Convert UTC timestamp to Local ISO Date/time '2014-09-08T08:02:17-05:00'
   *
   */
  public static convertingUtcTimestampToLocal(timestamp: number): string {

    try {

      return moment.utc(timestamp).local().format();

    } catch (error) {

      console.log('convertingUtcTimestampToLocal', error);

      return String(timestamp);

    }

  }

  /**
   * Convert UTC timestamp to local time '12:00am'
   *
   */
  public static convertingUtcToLocalTime(date: string): string {

    try {

      return moment.utc(date).local().format('h:mm a');

    } catch (error) {

      console.log('convertingUtcToLocalTime', error);

      return date;

    }

  }

  /**
   * Convert UTC timestamp to local date '01/01/2023'
   *
   */
  public static convertingUtcToLocalDate(date: string): string {

    try {

      return moment.utc(date).local().format('MM/DD/YYYY');

    } catch (error) {

      console.log('convertingUtcToLocalDate', error);

      return date;

    }

  }

  /**
   * Convert UTC timestamp to UTC date/time '01/01/2023 01:01:00'
   *
   */
  public static convertLocalToUTC(date: Date): string | Date {

    try {

      return moment.utc(date).format('MM/DD/YYYY HH:mm:ss');

    } catch (error) {

      console.log('convertLocalToUTC', error);

      return date;

    }

  }

  /**
   * Convert local timestamp to local date/time '01/01/2023 01:01:00'
   *
   */
  public static convertLocalToLocal(date: string): string {

    try {

      return moment(date).format('MM/DD/YYYY HH:mm:ss');

    } catch (error) {

      console.log('convertLocalToLocal', error);

      return date;

    }

  }

  /**
   * Convert UTC timestamp to UTC date '01/01/2023'
   *
   */
  public static convertUtcToUtcDate(date: string): string {

    try {

      return moment(date).format('L');

    } catch (error) {

      console.log('convertUtcToUtcDate', error);

      return date;

    }

  }

  /**
   * Convert UTC timestamp to Unix millisecond timestamp
   *
   */
  public static convertUTCToTimeStamp(date: string): string {

    try {

      return moment(date).format('x');

    } catch (error) {

      console.log('convertUTCToTimeStamp', error);

      return date;

    }

  }

  /**
   * Convert UTC timestamp to Local timestamp '01:01:01'
   *
   */
  public static convertingUtcToLocalTimeOfhhmmss(date: string): string {

    try {

      return moment.utc(date).local().format('HH:mm:ss');

    } catch (error) {

      console.log('convertUtcToLocalTimeOfhhmmss', error);

      return date;

    }

  }

  /**
   * Convert UNIX milliseconds to Local timestamp date/time '01/01/2023 at 12:00am'
   *
   */
  public static convertMilliSecondsToLocalTime(milliseconds: number): string {

    try {

      const m = moment(milliseconds);

      return m.format('MM/DD/YYYY') + ' at ' + m.format('h:mm a');

    } catch (error) {

      console.log('convertUtcToLocalTimeOfhhmmss', error);

      return String(milliseconds);

    }

  }

  /**
   * Get the client side timezone.
   *
   * @returns Time Zone Name
   * @example
   * // For Time zone in New York with UTC-04/05
   * // 'America/New_York'
   * localTimeZone()
   *
   */
  public static localTimeZone(): string {

    try {

      return Intl.DateTimeFormat().resolvedOptions().timeZone;

    } catch (error) {

      return '';

    }

  }

  /**
   * Get the time difference between UTC and client side timezone.
   *
   * @returns {(+|-)HH:mm} - Where `HH` is 2 digits hours and `mm` 2 digits minutes.
   * @example
   * // For Indian/Reunion with UTC+4
   * // '+04:00'
   * getTimeDifferenceInUTCAndLocalTime()
   */
  public static timeDifferenceBetweenUTCAndLocalTime(): string {

    try {

      const timezoneOffset = new Date().getTimezoneOffset();

      const offset = Math.abs(timezoneOffset);

      const offsetOperator = timezoneOffset < 0 ? '+' : '-';

      const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');

      const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

      return `${offsetOperator}${offsetHours}:${offsetMinutes}`;

    } catch (error) {

      return '';

    }

  }

  /**
   * Calculate time from now (45 sec, 1 week, 2 days...)
   *
   */
  public static calculateDiff(date: string): string {

    const apiDate = moment.utc(date).local().format('MM/DD/YYYY h:mm a');

    if (apiDate) {

      const seconds = Math.floor((+new Date() - +new Date(apiDate)) / 1000);

      if (seconds < 29) {

        return 'Just now';

      }

      const intervals = {
        yr: 31536000,
        mo: 2592000,
        wk: 604800,
        day: 86400,
        hr: 3600,
        min: 60,
        sec: 1
      };

      let counter;

      for (const i in intervals) {

        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {

          if (counter === 1) {

            return counter + ' ' + i; // singular (1 day ago)

          } else {

            return counter + ' ' + i + 's'; // plural (2 days ago)

          }

        }

      }

    }

  }

}


