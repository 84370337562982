import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProfileSearchResult } from '@models/ncx/global-search';
import { User } from '@models/users';
import { Common } from '@utilities/common';

@Component({
  selector: 'ncx-profile-search',
  templateUrl: './profile-search.component.html',
  styleUrls: ['../../cards-common.scss', './profile-search.component.scss']
})
export class ProfileSearchComponent {

  @Input() profile: ProfileSearchResult = {} as ProfileSearchResult;

  @Output() viewProfile: EventEmitter<ProfileSearchResult> = new EventEmitter<ProfileSearchResult>();

  readonly Common = Common;

  onViewProfile() {

    this.viewProfile.emit(this.profile);

  }

  get profileName(): string {

    return Common.formatName({ displayName: this.profile.displayName } as User);

  }

}
