<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/google-docs'">
         <p class="header">Google Docs</p>

         <div class="body">
            <section>
               <p class="form-label">URL</p>

               <div class="form-element">
                  <form nz-form></form>

                  <nz-input-group [nzPrefix]="prefixTemplateUser">
                     <input
                        nz-input
                        placeholder=""
                        required
                        [(ngModel)]="googleDocLink"
                        [ngClass]="{ 'red-border-class': !isValid, 'no-border-class': isValid }" />
                  </nz-input-group>

                  <ng-container *ngIf="!isValid">
                     <p class="form-hint error">Please provide a valid Google Document URL</p>
                  </ng-container>

                  <ng-template #prefixTemplateUser>
                     <i
                        nz-icon
                        nzType="link"
                        nzTheme="outline"></i>
                  </ng-template>
               </div>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         (click)="handleOk('insert')">
         Insert
      </button>
   </ng-template>
</nz-modal>
