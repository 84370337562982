import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { SubSink } from 'subsink';

const Breakpoints: { [key: string]: string } = {

  // mobile
  '(max-width: 576px)': 'xsmall',

  // mobile
  '(min-width: 577px) and (max-width: 768px)': 'small',

  // mobile
  '(min-width: 769px) and (max-width: 992px)': 'medium',

  // desktop
  '(min-width: 993px) and (max-width: 1200px)': 'large',

  // desktop
  '(min-width: 1201px) and (max-width: 1600px)': 'xlarge',

  // desktop
  '(min-width: 1601px)': 'xxlarge'
};

/**
 * This service listens for Breakpoint changes to inform
 * other services and components to make changes to the layout and CSS styles.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class BreakpointService implements OnDestroy {

  // Tracks only the smallest breakpoints
  public readonly isSmall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isSmall$: Observable<boolean> = this.isSmall.asObservable();

  // Tracks only the mobile breakpoints (tablet and smaller)
  public readonly isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isMobile$: Observable<boolean> = this.isMobile.asObservable();

  // Tracks only the mobile breakpoints (Extra Small To Large)
  public readonly isXSToLarge: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isXSToLarge$: Observable<boolean> = this.isXSToLarge.asObservable();

  // Tracks only desktop breakpoints (larger than tablet)
  public readonly isDesktop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isDesktop$: Observable<boolean> = this.isDesktop.asObservable();

  // Tracks changes to any breakpoint
  public readonly breakpoints: BehaviorSubject<object> = new BehaviorSubject<object>({});

  public breakpoints$: Observable<object> = this.breakpoints.asObservable();

  // Tracks changes to any breakpoint
  public readonly orientation: BehaviorSubject<string> = new BehaviorSubject<string>('portrait');

  public orientation$: Observable<string> = this.orientation.asObservable();

  private subs = new SubSink();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {

    this.subs.sink = this.breakpointObserver.observe(Object.keys(Breakpoints)).subscribe((state: BreakpointState) => {

      // Map the breakpoint values to their small,xsmall,medium values (true|false)
      const data: { [key: string]: any } = {};

      Object.keys(state.breakpoints).map((key: string) => {

        const value = Breakpoints[key];

        data[value] = state.breakpoints[key];
      
      });

      const { xsmall, small, medium, large, xlarge, xxlarge } = data;

      // Get the current breakpoint
      data.current = Object.keys(data).find(key => data[key]) || 'xlarge';

      // Small Only
      this.isSmall.next(xsmall || small);

      // Mobile and Tablet
      this.isMobile.next(xsmall || small || medium);

      this.isXSToLarge.next(xsmall || small || medium || large);

      // Desktop Only
      this.isDesktop.next(large || xlarge || xxlarge);

      // Current breakpoint
      this.breakpoints.next(data);

      console.log('Breakpoints from Breakpoint service',
        {
          breakpoints: data,
          isXSToLarge: this.isXSToLarge.value,
          isSmall: this.isSmall.value,
          isMobile: this.isMobile.value,
          isDesktop: this.isDesktop.value
        });

    });

    this.subs.sink = this.breakpointObserver.observe(['(orientation: landscape)']).subscribe((state: BreakpointState) => {

      const orientation = state.matches ? 'landscape' : 'portrait';

      this.orientation.next(orientation);

    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

}
