<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isMergeStoryVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/merge-stories'">
         <p class="header">Merge Stories</p>

         <div class="body">
            <section>
               <div class="form-element">
                  <nz-input-group [nzSuffix]="suffixIconSearch">
                     <input
                        placeholder="Search for the story here..."
                        nz-input
                        [(ngModel)]="value"
                        (ngModelChange)="titleMatch($event)"
                        (keydown)="onChange(storyInput.value, $event)"
                        [nzAutocomplete]="auto"
                        #storyInput />
                  </nz-input-group>
                  <nz-autocomplete
                     [nzDataSource]="filteredOptions"
                     #auto></nz-autocomplete>
                  <ng-template #suffixIconSearch>
                     <i
                        nz-icon
                        nzType="search"></i>
                  </ng-template>
               </div>
            </section>

            <section *ngIf="destinationStory">
               <ul class="list-selector">
                  <li>
                     {{ destinationStory }}
                     <button
                        class="delete"
                        (click)="delete()">
                        <i
                           nz-icon
                           nzType="delete"></i>
                     </button>
                  </li>
               </ul>
               <p class="form-hint">
                  * When merging a story, all posts will be moved to the selected story and this story will be archived.
               </p>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="handleOk()">
         Submit
      </button>
   </ng-template>
</nz-modal>
