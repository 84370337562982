import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AngleTitle } from '@models/ncx/angle';
import { Post } from '@models/ncx/post';
import { CommonService } from '@services/common-service';
import { NotificationService } from '@services/notification/notification.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-angle',
  templateUrl: './add-angle.component.html',
  styleUrls: ['./add-angle.component.scss']
})
export class AddAngleComponent implements OnInit, OnDestroy {

  data: any;

  postId: number;

  postTitle: string;

  callFrom: string

  angleForm!: UntypedFormGroup;

  // Any angles already linked
  linkedAnglesID: number[] = [];

  // All new Angles to choose from
  angleTitles: AngleTitle[] = [];

  // The user's selected Angle
  selectedAngle: AngleTitle = {} as AngleTitle;

  // Modal/API loader indicator
  isLoaded = true;

  // When Post information is loaded from API
  isPostLoaded = false;

  // When Angles information is loaded from API
  isAnglesLoaded = false;

  // When user selects an Angle
  isSelectAngle = false;

  // Is Delete button disabled
  isDisabled = false;

  anglesAPISubscription: Subscription = new Subscription();

  @ViewChild('successTpl', { static: false }) successTpl: TemplateRef<any>;

  constructor(
    private modal: NzModalRef,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cService: CommonService,
    private toastService: ToastService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this.data = this.modal.getConfig().nzData;

    this.postId = this.data.postId;
    this.postTitle = this.data.postTitle;

    this.callFrom = this.data.callFrom;

    this.angleForm = this.fb.group({
      searchBox: ['']
    });

    this.loadPost();

    this.formValueChanges();

  }

  ngOnDestroy() {

    this.anglesAPISubscription.unsubscribe();

  }

  /**
   * Load any existing Angles for this Post
   *
   */
  loadPost() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', environment.getPostApi, '/' + this.postId).subscribe((data: any) => {

      const post: Post = data as Post;

      this.isPostLoaded = true;

      if (post.relatedAngles) {

        post.relatedAngles.forEach(relatedAngles => {

          relatedAngles.angleAccess = 'private';
          if (relatedAngles.angleId) {

            this.linkedAnglesID.push(relatedAngles.angleId);

          }

        });

      }

    }, (error: any) => {

      console.error('loadPost', error);

      this.toastService.createMessage('error', 'Unable to load Post');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  formValueChanges() {

    this.angleForm.get('searchBox')?.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {

      // value = value.replace(/[^a-zA-Z0-9. ]/g, "");

      if (!value.trim()) {

        this.angleTitles = [];
        return;

      }

      if (this.anglesAPISubscription) {

        this.anglesAPISubscription.unsubscribe();

      }

      this.loadAngles(value);

    });

  }

  /**
   * Load Angles based on search string
   *
   */
  loadAngles(value: string) {

    const queryStr = '?searchText=' + value.trim();

    const angleURL = environment.getAngleTitle;

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', angleURL, queryStr).subscribe((responses: any) => {

      this.angleTitles = [];

      const angles: AngleTitle[] = responses as AngleTitle[];

      this.isAnglesLoaded = true;

      this.angleTitles = angles.filter((angle: AngleTitle) => !this.linkedAnglesID.includes(angle.angleId));

    }, (error: any) => {

      console.error('loadAngles', error);

      this.toastService.createMessage('error', 'Unable to load Angles');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  selectAngle(selectedAngle: AngleTitle) {

    this.angleForm.get('searchBox')?.setValue('');
    this.isSelectAngle = true;
    this.selectedAngle = selectedAngle;

  }

  deselectAngle() {

    this.isSelectAngle = false;
    this.selectedAngle = {} as AngleTitle;

  }

  /**
   * Confirm add selected Angle
   *
   */
  confirm() {

    const queryStr = `/${this.postId}/angle/${this.selectedAngle.angleId}/link`;

    this.isLoaded = false;

    this.isDisabled = true;

    this.cService.serviceRequestCommon('patch', environment.getPostApi, queryStr).subscribe((res: any) => {

      if (res.deleted === true) {

        this.toastService.createMessage('info', 'Selected angle no longer exists. Please select another angle');
        this.isDisabled = false;

      } else {

        this.destroyModal();

        this.notificationService.notify.template(this.successTpl, { nzDuration: 5000 });

      }

    }, (error: any) => {

      console.error('confirm', error);
      if (error === 'POST-001') {

        this.destroyModal();

        if (this.callFrom === 'StoryPosts' || this.callFrom === 'elements') {
          this.toastService.createMessage('error', `Post doesn't exist to link the angle.`);
        } else {
          setTimeout(() => {

            this.router.navigate(['ncx/stories-dashboard']);

          }, 500);
        }

      } else {

        this.toastService.createMessage('error', 'Unable to link Angle');

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  destroyModal(): void {

    this.modal.destroy();

  }

}
