import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({

  // tslint:disable-next-line: component-selector
  selector: 'app-reportable-new',
  templateUrl: './reportable-new.component.html',
  styleUrls: ['./reportable-new.component.scss']
})
export class ReportableNewComponent implements OnChanges {

  inputValue = '';

  initObj = { isOfficial: '', seniorApprovalName: '', additionalNotes: '' };

  subject: Subject<string> = new Subject();

  @Input() postReportableContent: any = {};

  @Output() reportableDataUpdated: EventEmitter<any> = new EventEmitter<any>();

  isValid: boolean;

  @Output() postEdited: EventEmitter<any> = new EventEmitter<any>();

  constructor() {

    this.subject.pipe(
      debounceTime(700)
    ).subscribe(() => {

      this.reportableDataUpdated.emit({ value: this.postReportableContent, valid: this.isValid });

    });

  }

  ngOnChanges() {

    console.log('ngOnChanges postReportableContent:', this.postReportableContent);
    if (this.postReportableContent === undefined || this.postReportableContent === null) {

      this.postReportableContent = this.initObj;

    }

  }

  onChange(value, type) {

    this.postEdited.emit({});

    // console.log('Reportable : ', value, type);
    if (type === 'isOfficial' && value === 'Yes') {

      this.postReportableContent.seniorApprovalName = 'From an official named source, no need for senior approval';
      this.isValid = true;

    } else if (type === 'isOfficial' && value === 'No') {

      this.postReportableContent.seniorApprovalName = '';
      this.isValid = false;

    }
    if (type === 'seniorApprovalName' && value !== '') {

      this.isValid = true;

    } else if (type === 'seniorApprovalName' && value === '') {

      this.isValid = false;

    }
    setTimeout(() => {

      // this.reportableDataUpdated.emit({value : this.postReportableContent, valid: this.isValid  });
      this.subject.next(value);

    }, 100);

  }

  // onApprovalNameChange(data) {
  //   // this.postReportableContent.seniorApprovalName = data;
  //   this.reportableDataUpdated.emit(this.postReportableContent);
  // }
  // onAdditionalChanges(data) {
  //   // this.postReportableContent.additionalNotes = data;
  //   this.reportableDataUpdated.emit(this.postReportableContent);
  // }

}
