import { Injectable } from '@angular/core';

@Injectable()
export class AppSingletonService {

  public nccXJsonStore: any;

  constructor() {

    /** Nothing to do */
  }

}
