import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerSessionResizeObserverService {

  bannerContainer: any;

  sessionExpireContainer: any;

  bannerObserver: any;

  sessionExpireObserver: any;

  private bannerHeightSubject: Subject<string> = new Subject();

  private sessionExpireHeightSubject: Subject<string> = new Subject();

  constructor(private zone: NgZone) { }

  setBannerHeight(element: string) {

    this.bannerContainer = document.querySelector(element);
    this.bannerObserver = new ResizeObserver(entries => {

      this.zone.run(() => {

        const height = Math.ceil(entries[0].contentRect.height);

        this.bannerHeightSubject.next(height + 'px');
      
      });
    
    });
    this.bannerObserver.observe(this.bannerContainer);
  
  }

  setSessionExpireHeight(element: string) {

    this.sessionExpireObserver = new ResizeObserver(entries => {

      this.zone.run(() => {

        const height = Math.ceil(entries[0].contentRect.height);

        this.sessionExpireHeightSubject.next(height + 'px');
      
      });
    
    });

    this.sessionExpireContainer = document.querySelector(element);
    if (this.sessionExpireContainer) {

      this.sessionExpireObserver.observe(this.sessionExpireContainer);
    
    }

  }

  setBannerSessionHeight() {

    document.documentElement.style.setProperty('--banner-container-height', '0px');
    document.documentElement.style.setProperty('--session-expire-container-height', '0px');
    this.setBannerHeight('.banner-container');
    this.setSessionExpireHeight('.session-expire-banner');
    this.bannerHeightSubject.subscribe(height => {

      document.documentElement.style.setProperty('--banner-container-height', height);
    
    });
    this.sessionExpireHeightSubject.subscribe(height => {

      document.documentElement.style.setProperty('--session-expire-container-height', height);
    
    });
  
  }

  unsetBannerSessionHeight() {

    document.documentElement.style.removeProperty('--banner-container-height');
    document.documentElement.style.removeProperty('--session-expire-container-height');

    try {

      this.bannerObserver.unobserve(this.bannerContainer);
      this.sessionExpireObserver.unobserve(this.sessionExpireContainer);
      this.bannerHeightSubject.complete();
      this.sessionExpireHeightSubject.complete();
    
    } catch (error) {

      // silent error
    }
  
  }

}
