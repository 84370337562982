import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { AddAngleComponent } from '@modals/ncx/add-angle/add-angle.component';
import { Post } from '@models/ncx/post';
import { Stories } from '@models/ncx/story';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Time } from '@utilities/time';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

type Tab = 'Standards' | 'All' | 'Element';

@Component({
  selector: 'app-story-posts',
  templateUrl: './story-posts.component.html',
  styleUrls: ['../story-landing-tabs-common.scss', './story-posts.component.scss']
})
export class StoryPostsComponent implements OnInit, OnDestroy, OnChanges {

  isLoaded: boolean = true;

  // Currently loaded data
  page: number = 1;

  total: number = 0;

  limit: number = 10;

  options = [
    { label: 'All', value: 'All', icon: 'unordered-list', disabled: false },
    { label: 'Elements', value: 'Elements', icon: 'file-image', disabled: false },
    { label: 'Standards', value: 'Standards', icon: 'audit', disabled: false }
  ];

  // List of posts/elements to display
  data: Post[] = [];

  // Do we need to load additional items to engage the scrollbar?
  initLoadingMoreForScroll: boolean = false;

  // Story information
  @Input() storyId: number = 0;

  @Input() storyDetails: Stories = {} as Stories;

  // Filters from parent component
  @Input() filters!: UntypedFormGroup;

  // Is this tab visible to the user?
  @Input() isVisible: boolean = false;

  // Parent controls the opening/closing of filter drawer
  @Input() filterDrawerOpen: boolean = false;

  // Height of the footer for viewport calculations
  @Input() footerHeight: number = 0;

  // Tell parent component when body is scrolling
  @Output() isScrolling: EventEmitter<void> = new EventEmitter<void>();

  // Tell parent component to open/close drawer
  @Output() toggleFilterDrawer: EventEmitter<void> = new EventEmitter<void>();

  // Tell parent comment to select/de-select labels
  @Output() selectLabel: EventEmitter<{ label: string, checked: boolean, disable: boolean }> = new EventEmitter<{
    label: '',
    checked: false,
    disable: false
  }>();

  //standards input passed to be selected as default
  @Input() standards: boolean = false;

  isStandardsLabelIncludedFromAllOrElementsTab: boolean = false;

  segmentSelectedIndex: number = 0;

  // Container of scrollable list
  // @ViewChild('bodyElement', { static: false }) bodyElement: ElementRef | any;

  @ViewChild('closeIconTpl', { static: false }) closeIconTpl: any;

  private subs = new SubSink();

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NzModalService,
    private breakpointService: BreakpointService,
    private cService: CommonService,
    private toastService: ToastService
  ) { }

  ngOnInit() {

    this.subs.sink = this.filters.valueChanges.pipe(debounceTime(500)).subscribe(() => {

      this.loadPosts();

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    // Reload data whenever the tab in the parent component has changed
    if ('isVisible' in changes && changes.isVisible.currentValue === true && !('standards' in changes && changes.standards.currentValue === true)) {

      setTimeout(() => {

        this.loadPosts();

      }, 500);

      // this.setHeight();
      // this.windowResizeEvent();

    }

    if ('standards' in changes && changes.standards.currentValue === true) {

      this.segmentSelectedIndex = 2;
      setTimeout(() => {

        this.selectPostType(2);

      }, 500);

    }

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Make API call to get the posts based on filters
   *
   */
  loadPosts() {

    this.isLoaded = false;

    const filters = this.filters.getRawValue();

    const { sort, labels, date, search, type } = filters;

    // Query Params
    const apiParams: any = {
      pageType: 'StoryDetails',
      isPublished: true,
      contentPage: this.page,
      searchString: ''
    };

    // Body Data
    const apiBody: any = {

      // I know, 'story' is misspelled ... ugh
      stroyId: +this.storyId,
      markAs: labels,
      postType: type,
      contentType: []
    };

    switch (type) {

      case 'Standards':
        apiBody.postType = '';
        break;

    }

    switch (sort) {

      case 'ascending':
      case 'descending':
        apiParams.postSortField = 'modificationdate';
        apiParams.postSortOrder = sort;
        break;

      default:
        apiParams.postSortField = 'modificationdate';
        apiParams.postSortOrder = 'descending';
        break;

    }

    if (Array.isArray(date) && date.length) {

      const [start, end] = date;

      const startDateTime = Time.convertLocalToLocal(start);

      const endDateTime = Time.convertLocalToLocal(end);

      const startDate = Time.convertUtcToUtcDate(startDateTime) + ' 00:00:00';

      const endDate = Time.convertUtcToUtcDate(endDateTime) + ' 23:59:59';

      const startDateTimeStamp = Time.convertUTCToTimeStamp(startDate);

      const endDateTimeStamp = Time.convertUTCToTimeStamp(endDate);

      apiParams.startDate = startDateTimeStamp;
      apiParams.endDate = endDateTimeStamp;

    }

    if (search) {

      apiParams.searchString = encodeURIComponent(search);

    }

    const queryStr = `?${Object.keys(apiParams).map((key: string) => `${key}=${apiParams[key]}`).join('&')}`;

    this.cService.serviceRequestCommon('post', environment.getInpageSearchURL, queryStr, apiBody).subscribe((res: any) => {

      const posts: Post[] = res?.posts || [];

      if (Array.isArray(posts)) {

        this.data = this.page === 1 ? [...res.posts] : [...this.data, ...res.posts];

        console.log('loadPosts', { posts: this.data, res });

        this.total = res.totalCount || 0;

      }

    }, (error: any) => {

      console.error('Load Posts', error);

      this.toastService.createMessage('error', 'Error while loading Posts. Please try again.');

    }).add(() => {

      this.isLoaded = true;

      // this.windowResizeEvent();

      // Do this whenever we're at Page 1 in order to fill the viewport with scrollable content
      if (this.page === 1) {

        setTimeout(() => {

          this.initLoadingMoreForScroll = false;
          this.checkIfShouldLoadMore();

        }, 1000);

      }

    });

  }

  /**
   * Scroll Down Event call when page is scroll down, fetch the next set of records.
   *
   */
  onLoadMore() {

    this.page += 1;

    this.loadPosts();

  }

  /**
   * Scroll event to change "sticky" mode of header
   *
   */
  scrollContainer() {

    this.isScrolling.emit();

  }

  /**
   * Select Tab Posts, Elements, Standards
   *
   */
  selectPostType($event) {

    // This is here only to prevent the "No xxx Found" card from showing earlier than necessary
    this.isLoaded = false;

    this.data = [];

    this.page = 1;

    //if standards label was included from All or Elements tab then it should be selected when switching back from Standards Tab
    //if standards label was excluded from All or Elements tab then it should be deselected when switching back from Standards Tab
    //by default the Standards Label should be selected and disabled when switching to Standards Tab
    if (this.filters.value.type != 'Standards') {

      if (this.filters.value.labels?.includes('STANDARDS')) {

        this.isStandardsLabelIncludedFromAllOrElementsTab = true;

      } else {

        this.isStandardsLabelIncludedFromAllOrElementsTab = false;

      }

    }

    // de-select the "STANDARDS" label
    this.selectLabel.emit({ label: 'STANDARDS', checked: this.isStandardsLabelIncludedFromAllOrElementsTab, disable: false });

    switch ($event) {

      case 2:
        this.selectLabel.emit({ label: 'STANDARDS', checked: true, disable: true });
        break;

    }

    const selectedType = $event == 0 ? 'All' : $event == 1 ? 'Element' : 'Standards';

    this.filters.patchValue({ type: selectedType });

    this.scrollTop();

  }

  /**
   * Clear Search
   *
   */
  clearSearch() {

    this.filters.patchValue({ search: '' });

  }

  /**
   * [Enter] is clicked on Search bar
   *
   */
  onSearch() {

    this.loadPosts();

  }

  /**
   * Add to Angle Modal
   *
   */
  showAddToAngleModal(post: Post): void {

    this.modalService.create({
      nzContent: AddAngleComponent,
      nzData: {
        postId: post.postId,
        postTitle: post.postTitle,
        callFrom: 'StoryPosts'
      },
      nzWidth: 605,
      nzBodyStyle: {
        padding: '16px'
      },
      nzCloseIcon: this.closeIconTpl,
      nzFooter: null
    });

  }

  /**
   * If there isn't enough content in the viewport to allow the user to scroll, call the onLoadMore
   *  function to force more content to load automatically.
   *
   */
  checkIfShouldLoadMore() {

    if (this.initLoadingMoreForScroll) {

      return;

    }

    this.initLoadingMoreForScroll = true;

    const storyContainer = document.getElementById('storyContainer');

    const scrollableContainer = document.querySelector('[data-scrollable-container]');

    if (!storyContainer || !scrollableContainer) {

      return;

    }

    const container = storyContainer.getBoundingClientRect();

    const scrollable = scrollableContainer.getBoundingClientRect();

    if (scrollable.height < container.height && this.page * this.limit < this.total) {

      console.log('Should Load More', { container: container.height, scrollable: scrollable.height });
      this.onLoadMore();
      this.initLoadingMoreForScroll = true;

    }

  }

  /**
   * Calculate the height of the scrollable interface
   *
   */
  // windowResizeEvent() {

  //   const bodyElementTab = (this.bodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();
  //   const bodyElementTabHeight = window.innerHeight - bodyElementTab?.top - this.footerHeight;

  //   document.documentElement.style.setProperty('--body-tab-height', bodyElementTabHeight + 'px');

  // }

  /**
   * Open/Close Filter Drawer
   *
   */
  onToggleFilterDrawer() {

    this.toggleFilterDrawer.emit();

  }

  /**
   * Scroll page to top
   *
   */
  scrollTop() {

    const container = document.querySelector('[data-scrollable-container]');

    if (container) {

      (container as HTMLElement).scrollTo({ top: 0, behavior: 'smooth' });

    }

  }

  /**
   * Can user scroll to more records, based on number of total records
   * and the current page they're on
   *
   */
  get canScrollMore(): boolean {

    return this.total > 0 && this.total > (this.page + this.limit);

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  get isSearching(): boolean {

    return !!this.filters?.get('search')?.value;

  }

  /**
   * Are filters in place?
   *
   */
  get isFiltering(): boolean {

    const filters = this.filters.getRawValue();

    return filters.labels?.length > 0 || filters.date?.length > 0 || filters.sort !== 'descending';

  }

}
