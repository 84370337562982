import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { IFunctionAbility } from '@models/users';

@Component({
  selector: 'app-content-types',
  templateUrl: './content-types.component.html',
  styleUrls: ['./content-types.component.scss']
})
export class ContentTypesComponent implements OnInit {

  @Input() singleContentView: boolean = false;

  @Input() contentType: string = '';

  functionAbility = {} as IFunctionAbility;

  constructor(private tI: TransferInformationComponent, private router: Router) {
  }

  ngOnInit() {

    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });
  
  }

  toggleContentTypes(type: string) {

    (type === 'Post') ? this.router.navigate(['ncx/post']) : (type === 'Angle')
      ? this.router.navigate(['ncx/angle'])
      : this.router.navigate(['ncx/create-story']);
  
  }

}


