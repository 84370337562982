import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './custom-svg.component.html'
})
export class CustomSvgComponent implements OnInit {

  @Input() icon!: string;

  @Input() width?: number;

  @Input() height?: number;

  @Input() size?: number = 24;

  @Input() fill?: string;

  @Input() class?: string;

  ngOnInit(): void {

    if (!this.width || !this.height) {

      this.width = this.size;
      this.height = this.size;
    
    }
  
  }

  get iconUrl(): string {

    return `./assets/svg/${this.icon}.svg#${this.icon}`;
  
  }

}
