import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';

@Component({
  selector: 'app-support-faq',
  templateUrl: './support-faq.component.html',
  styleUrls: ['../support-page.component.scss'],
})
export class SupportFaqComponent implements OnChanges {

  @Input() isVisible: boolean = false;

  constructor(
    private gaService: GoogleAnalyticsEventService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible.currentValue) {
      this.sendGATracking();
    }
  }

  /**
   * Track Google Analytics
   *
   */
  sendGATracking() {

    this.gaService.eventEmitter('Support', 'FAQ', 'View');

  }

}
