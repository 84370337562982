import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Post } from '@models/ncx/post';
import { BreakpointService } from '@services/breakpoint.service';
import { PermissionsService } from '@services/profile/permissions.service';
import { Common } from '@utilities/common';

@Component({
  selector: 'ncx-post',
  templateUrl: './post.component.html',
  styleUrls: ['../cards-common.scss', './post.component.scss']
})
export class PostComponent implements AfterViewInit {

  // Post Story Id
  @Input() storyId: string = '';

  // Post information
  @Input() post: Post = {} as Post;

  // Used for highlighting text when searching
  @Input() searchText: string = '';

  // Show 'Add to Angle' button
  @Input() isAddToAngle: boolean = true;

  // Permission for Add to Angle
  canAddToAngle = false;

  // Open Angle dialog box
  @Output() addToAngle: EventEmitter<Post> = new EventEmitter<Post>();

  // Content shared
  @Output() contentShared: EventEmitter<Post> = new EventEmitter<Post>();

  showShareModal: boolean = false;

  public readonly Common = Common;

  constructor(
    private breakpointService: BreakpointService,
    private permissions: PermissionsService
  ) { }


  /**
   * 'Add To Angle' option should not be available to read only user.
   * Editor and Jounalist can add only the angles where they have rights. Either the Creator of the Angle or as a Collaborator in the angle.
   * Angle filtration is happening in the backend
   */
  ngAfterViewInit() {

    this.canAddToAngle = !this.permissions.isReadOnlyRole;

  }

  onShare() {

    this.showShareModal = true;
    this.contentShared.emit(this.post);

  }

  closeShareModal() {

    this.showShareModal = false;

  }

  showAddToAngle(post: Post) {

    this.addToAngle.emit(post);

  }

  get shareMessage(): string {

    return `Check out ${this.post.postTitle}`;

  }

  get isPostUpdated(): boolean {

    return new Date(this.post.updateDateTime) > new Date(this.post.createDateTime);
  
  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
