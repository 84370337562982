import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { Angle, AngleMode, RelatedStory } from '@models/ncx/angle';
import { StoryMode } from '@models/ncx/story';
import { IFunctionAbility, User } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-create-angle',
  templateUrl: './create-angle.component.html',
  styleUrls: ['./create-angle.component.scss']
})

export class CreateAngleComponent implements OnInit, AfterViewChecked, AfterViewInit {

  radioValue: 'Angle';

  angle: Angle = {} as Angle;

  angleApiUrl: string = '';

  angleId: number;

  angleTitle: string = '';

  angleContent: string = '';

  anglePlainContent: string = '';

  anglePrivacyMode: boolean = true;

  angleCreator: User = {} as User;

  angleAccess: string = AngleMode.Public;

  relatedStories: RelatedStory[] = [];

  collaborators: number[] = [];

  collaboratorsDetails: User[] = [];

  disableSearch: boolean = false;

  disableStory: boolean = false;

  functionAbility = {} as IFunctionAbility;

  angleBodyHeight: number = 0;

  footerHeight: number = 62;

  screenSize: string = '';

  windowWidth: number;

  desktopMode: boolean = true;

  isMobile: boolean = false;

  highlightMandatoryStory: boolean = false;

  storyDetailsTooltip: string = 'Find the story or stories that this Angle should be linked to';

  isLoaded = false;

  googleDocs = '';

  isVisible = false;

  userDetails: any;

  state: any;

  userInfoDetails;

  subject: Subject<string> = new Subject();

  isEmitted: boolean = true;

  private resizeSubject = new Subject<Event>();

  angleHasPrivateStory: boolean = false;

  @ViewChild('angleTitleText') angleTitleText: ElementRef;

  @ViewChild('appRtEditor', { static: false }) appRtEditorElement: ElementRef | any;

  @ViewChild('angleBody', { static: false }) angleBodyElement: ElementRef | any;

  @ViewChild('metadataTabBody', { static: false }) metadataTabBodyElement: ElementRef | any;

  @ViewChild('footer', { static: false }) footerElement: ElementRef | any;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {

    this.resizeSubject.next(event);

  }

  constructor(
    private route: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router,
    private location: Location,
    private tI: TransferInformationComponent,
    private breakpointService: BreakpointService,
    private changeDetector: ChangeDetectorRef
  ) {

    this.state = window.history.state;

  }

  ngOnInit() {

    this.isLoaded = false;
    this.radioValue = 'Angle';
    this.angleApiUrl = environment.getAngleApi;

    this.tI.userProfile.subscribe(user => {

      this.userDetails = user || {};

    });

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });

    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role
    };

    this.route.params.subscribe(
      (params: Params) => {

        if (params && params.angleId) {

          const angleId = params.angleId.substr(1);

          console.log({ angleId });
          this.isEmitted = false;
          this.getAngle(angleId);

        } else {

          this.isEmitted = true;
          if (this.functionAbility.fa_create_private_angle || this.functionAbility.fa_create_public_angle) {

            this.addCreatorToTeam();
            this.angleAccess = AngleMode.Public;

          } else {

            this.toastService.createMessage('error', 'Please contact administrator for Create-Angle rights.');
            this.redirectToStoriesDashboard();

          }

        }

      });

    this.route.queryParams.subscribe((params: Params) => {

      const { storyId } = params;

      if (storyId) {

        this.getStoryDetails(storyId);

      }

    });

    this.resizeSubject.pipe(debounceTime(2000)).subscribe(() => {

      this.windowResizeEvent();

    });

  }

  ngAfterViewInit() {

    this.angleTitleText.nativeElement.focus();
    this.changeDetector.detectChanges();

  }

  /**
   * After view checked angular event - to get the window size at runtime
   **/
  ngAfterViewChecked() {

    // this.appRtEditorElement.initialiseEditor();
    this.changeDetector.detectChanges();
    this.windowResizeEvent();
    this.isLoaded = true;

  }

  /**
   * To toggle between Story - Post - Angle
   **/
  toggleStoryPostAngle(type: any) {

    (type === 'Post') ? this.router.navigate(['ncx/post']) : (type === 'Angle')
      ? this.router.navigate(['ncx/angle'])
      : this.router.navigate(['ncx/create-story']);

  }

  /**
   * Will be used to create the draft version. In  release 1 - Draft version is not needed
   **/
  titleChange() {

    this.subject.next('');

  }

  /**
   * To get the Rich text editor Angle description
   **/
  getRTEAngleData(data: any) {

    this.angleContent = data;

  }

  /**
   * To save the draft when the content has desc
   * As of now Save Draft is not needed in Create Angle
   * Will be enabled in future
   **/
  saveDraft(_event) {

  }

  /**
   * Angle details from API based on the angle id
   **/
  async getAngle(angleId) {

    const responseFromApi = await this.getAngleDetailsFromAPI(angleId);

    this.angle = responseFromApi as Angle;
    console.log(this.angle);

    /**
     * if the angle was already deleted then show the msg and redirect to stories dashboard
     */
    if (this.angle.deleted === true) {

      this.toastService.createMessage('error', 'Requested angle does not exist. Redirecting to stories dashboard');
      this.redirectToStoriesDashboard();

    } else {

      this.angleId = this.angle.angleId;
      this.angleTitle = this.angle.angleTitle;
      this.angleContent = this.angle.angleContent;
      this.anglePlainContent = this.angle.angleContent;
      this.angleAccess = this.angle.angleAccess;
      this.anglePrivacyMode = this.angle.angleAccess == AngleMode.Public ? false : true;
      this.angleCreator = this.angle.createdUser;
      this.relatedStories = this.angle.relatedStories;
      this.enableDisableSearch();
      this.collaboratorsDetails = this.angle.collaboratorsDetails;
      this.collaborators = this.collaboratorsDetails.map(x => x.userId);
      this.updateCollaboratorsBasedOnStoryAccess(false);

    }

  }

  /**
   * Redirect to Stories Dashboard
   */
  redirectToStoriesDashboard() {

    setTimeout(() => {

      this.router.navigate(['ncx/stories-dashboard']);

    }, 500);

  }

  /**
   * API call to get the Angle details based on the angle id
   **/
  async getAngleDetailsFromAPI(angleId): Promise<any> {

    return new Promise((resolve, reject) => {

      const queryStr = `/${angleId}`;

      this.cService.serviceRequestCommon('get', this.angleApiUrl, queryStr).subscribe(
        (res: any) => {

          resolve(res);

        },
        (error: any) => {

          console.log('Error', error, this.angle);
          this.toastService.createMessage('error', error);
          reject(error);

        });

    });

  }

  /**
   * To validate the required fields in an Angle
   **/
  validateAngle(): boolean {

    if (this.angle.angleTitle.trim() === '') {

      this.toastService.createMessage('warning', 'Please give your angle a title to publish');
      return false;

    }

    if (!(this.angle.relatedStories && this.angle.relatedStories.length)) {

      this.toastService.createMessage('warning', 'Select a story to publish');
      this.highlightMandatoryStory = true;
      return false;

    }
    return true;

  }

  /**
   * To get the user input details of an Angle
   **/
  getAngleDetails() {

    this.angle.angleTitle = this.angleTitle.trim();
    this.angle.angleContent = this.angleContent;
    this.angle.relatedStories = this.relatedStories;
    this.angle.collaborators = this.collaborators;
    this.angle.angleAccess = this.angleAccess;
    this.angle.createdUser = this.angleCreator
    if (!this.angle.angleId) {

      this.angle.anglePublished = false;
      this.angle.deleted = false;

    }

  }

  /**
   * To get the required properties to create / update Angle
   **/
  getSaveAngleProperties() {

    const {
      anglePosts,
      createDateTime,
      createDateTimeString,
      createdUser,
      collaboratorsDetails,
      updateDateTime,
      updateDateTimeString,
      updateUser,
      anglePlainContent,
      ...createOrUpdateAngle
    } = this.angle;

    return createOrUpdateAngle;

  }

  /**
   * Event call to create / update angle
   **/
  publishAngle() {

    this.getAngleDetails();
    if (this.validateAngle()) {

      this.saveAngle(this.getSaveAngleProperties());

    }

  }

  /**
   * Event call from child to get the selected stories
   **/
  attachStoryToAngle(data) {

    this.relatedStories = data.relatedStories ?? [];
    this.highlightMandatoryStory = this.relatedStories?.length > 0 ? false : true;
    this.updateCollaboratorsBasedOnStoryAccess(true);
    this.enableDisableSearch();

  }

  /**
   * 
   * if the angle has any private story then it can have only the creator as the collaborator
   * other collaborators should be removed
   * 
   */
  updateCollaboratorsBasedOnStoryAccess(showToastMsg) {

    this.angleHasPrivateStory = false;
    let relatedPrivateStories: RelatedStory[] = [];

    relatedPrivateStories = this.relatedStories.filter(story => story.storyAccess === StoryMode.Private);

    if (relatedPrivateStories.length > 0) {

      this.angleHasPrivateStory = true;
      this.collaboratorsDetails = [];
      this.collaboratorsDetails.push(this.angleCreator);
      this.collaborators = [];
      this.collaborators = this.collaboratorsDetails.map(x => x.userId);

      if (showToastMsg) {

        this.toastService.createMessage('info', 'Angles added to private stories cannot have collaborators');

      }

    }

  }


  /**
   * API call to create / update angle
   **/
  saveAngle(createOrUpdateAngle) {

    this.isLoaded = false;
    const type = this.angle.angleId == null ? 'post' : 'put';

    const queryStr = this.angle.angleId == null ? '' : `/${this.angle.angleId}`;

    this.cService.serviceRequestCommon(type, this.angleApiUrl, queryStr, createOrUpdateAngle).subscribe((data: any) => {

      /**
       * if the angle was already deleted then show the msg and redirect to stories dashboard
       */
      if (data.deleted == true) {

        this.toastService.createMessage('error', 'Requested angle does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else {

        const successMsg = (type === 'post') ? 'Angle successfully created' : 'Angle successfully updated';

        this.toastService.createMessage('success', successMsg);
        this.router.navigate(['ncx/landing-angle/:' + data.angleId]);
        this.isLoaded = true;

      }

    },
      (error) => {

        console.warn({ error });
        this.toastService.createMessage('error', error + ' Redirecting to stories dashboard');
        setTimeout(() => {

          this.router.navigate(['ncx/stories-dashboard']);

        }, 500);
        this.isLoaded = true;

      });

  }

  /**
   * To enable or disable linked stories component
   **/
  enableDisableSearch() {

    if (this.relatedStories?.length > 0) {

      this.disableSearch = true;

    } else {

      this.disableSearch = false;

    }

  }

  /**
   * To go back to the previous page
   **/
  goBackToCreate() {

    this.location.back();

  }

  /**
   * To go back to the previous page
   **/
  redirectTo() {

    this.isLoaded = false;
    this.location.back();
    this.isLoaded = true;

  }

  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    // #region For development mode
    this.windowWidth = window.innerWidth;

    // if (window.innerWidth > 1600) {
    //   this.screenSize = 'XXL';
    //   this.desktopMode = true;
    // } else if (window.innerWidth <= 1600 && window.innerWidth > 1200) {
    //   this.screenSize = 'XL';
    //   this.desktopMode = true;
    // } else if (window.innerWidth <= 1200 && window.innerWidth > 992) {
    //   this.screenSize = 'LG';
    //   this.desktopMode = true;
    // } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
    //   this.screenSize = 'MD';
    //   this.desktopMode = false;
    // } else if (window.innerWidth <= 768 && window.innerWidth > 576) {
    //   this.screenSize = 'SM';
    //   this.desktopMode = false;
    // } else if (window.innerWidth <= 576) {
    //   this.screenSize = 'XS';
    //   this.desktopMode = false;
    // }
    // #endregion
    const angleBody = (this.angleBodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    this.angleBodyHeight = window.innerHeight - angleBody?.top - this.footerHeight;
    document.documentElement.style.setProperty('--bodyTabBodyHeightInPx', this.angleBodyHeight + 'px');

  }

  /**
   * Event call from child to get the selected collaborator details
   *
   */
  updateInTeam($event) {

    this.collaboratorsDetails = $event;
    this.collaborators = this.collaboratorsDetails.map(x => x.userId);

  }

  /**
   * Event call from child to get the selected Access details
   */
  updateInAccess($event) {

    this.angleAccess = $event;

  }

  /**
   * Event call from child to get the logged in user details
   **/
  loggedInUser($event) {

    this.collaboratorsDetails = $event;
    this.collaborators = this.collaboratorsDetails.map(x => x.userId);

  }

  /**
   * Event for embedy link
   **/
  checkFlag(event) {

    console.log('check Googgle Flag ', event);
    if (event === 'true') {

      this.isVisible = true;
      console.log('check Googgle Flag inside ', event, this.isVisible, '');
      document.getElementById('dummy-Google-Doc-Embedy-Link').click();
      this.googleDocs = '';

    } else {

      this.isVisible = false;
      this.googleDocs = '';

    }

  }

  /**
   * Don't delete this. Dummy event is required
   **/
  dummyGoogleDocEmbedyLinkClick() {
  }

  /**
   * Event for google doc link
   **/
  getDocLink(value) {

    if (value !== 'close') {

      this.googleDocs = value;
      this.updateRTELinkContent();

    }
    this.isVisible = false;

  }

  /**
   * Event for google doc link
   **/
  updateRTELinkContent() {

    if (this.angleContent === '<p><br></p>') {

      this.angleContent = `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0" src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>`;

    } else {

      this.angleContent += `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0" src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>`;

    }

  }

  /**
   * Event to add creator to team in Angle
   **/
  addCreatorToTeam() {

    let loggedInUser: User = {} as User;

    loggedInUser = this.getUser(this.userDetails);
    this.collaboratorsDetails = [];
    this.collaboratorsDetails.push(loggedInUser);
    this.collaborators = this.collaboratorsDetails.map(x => x.userId);
    this.angleCreator = loggedInUser;

  }

  /**
   * Format the user details from user model
   **/
  getUser(user) {

    return {
      displayName: user.name.preferredName,
      email: user.email.workEmail,
      firstName: user.name.firstName,
      fullName: ((user.name.preferredName.split(',')[1]?.trim() ?? user.name.preferredName.split(',')[1])?.trim() + ' ' +
        (user.name.preferredName.split(',')[0]?.trim() ?? user.name.preferredName.split(',')[0]).trim()),
      jobTitle: user.workInfo.jobTitle,
      lastName: user.name.lastName,
      middleName: user.name.middleName,
      profilePictureURL: user.profilePictureURL,
      ssoId: user.ssoId,
      userId: user.userId
    } as User;

  }

  /**
   * API call to get the story details based on story id
   **/
  getStoryDetails(storyId: any) {

    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', environment.getStoriesAPI + '/', storyId).subscribe((response: any) => {

      if (response && response.storyId) {

        const story = {
          createUserId: response.createUser.userId,
          isPrimary: false,
          storyAccess: response.storyAccess,
          storyId: response.storyId,
          storyTitle: response.storyTitle,
          autoGeneratedStoryId: response.autoGeneratedStoryId
        };

        //autoGeneratedStoryId: 'HardCoded - SA47FL0001'


        this.relatedStories = [];
        this.relatedStories.push(story);
        this.enableDisableSearch();
        this.updateCollaboratorsBasedOnStoryAccess(true);

      }
      this.isLoaded = true;

    },
      (err) => {

        this.toastService.createMessage('error', 'Error While getting story details');
        this.isLoaded = true;
        console.log({ err });

      });

  }

}
