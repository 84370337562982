<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout [attr.data-component]="'ncx/Gruop/create-group.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title class="headerLabel">Back</nz-page-header-title>
   </nz-page-header>

   <nz-content class="create-group content">
      <div class="title">Group Name</div>
      <div
         class="newStoryInput"
         nz-row>
         <div class="example-input">
            <input
               placeholder="Group Name goes here.........."
               nz-input
               [(ngModel)]="groupText"
               required
               pattern="[a-zA-Z0-9\s]+"
               #groupName="ngModel"
               #searchGroupTitleText />
            <div *ngIf="groupName?.errors?.pattern">
               <div class="errorMessage">Group name should not contain Special Characters.</div>
            </div>
         </div>
      </div>

      <section>
         <app-privacy
            [groupAccess]="groupDetails.groupAccess"
            (groupPrivacyValue)="getGroupPrivacy($event)"></app-privacy>
      </section>

      <section>
         <nz-card nzTitle="Slack Integration">
            <div class="example-input">
               <nz-input-group [nzSuffix]="suffixTemplate">
                  <input
                     placeholder="Enter slack channel name here"
                     nz-input
                     [(ngModel)]="slackName"
                     required
                     pattern="[a-zA-Z0-9\s]+"
                     #slackChannelName="ngModel"
                     (keypress)="AddSlackChannel($event)"
                     #searchGroupTitleText />
               </nz-input-group>
               <ng-template #suffixTemplate>
                  <i
                     nz-icon
                     nzType="plus-circle"
                     nzTheme="outline"
                     (click)="AddSlackChannelonClick($event)"></i>
               </ng-template>
            </div>
            <div class="div-Slack">
               <div
                  *ngFor="let slack of slackChannelNames"
                  class="slack-list"
                  (ngModelChange)="slackNameLength()">
                  {{ slack }}
                  <i
                     nz-icon
                     nzType="close"
                     nzTheme="outline"
                     (click)="removeTag(slack)"></i>
               </div>
            </div>
            <div nz-row>
               <div
                  nz-col
                  nzSpan="24">
                  <nz-radio-group
                     [(ngModel)]="groupDetails.isSlackIntegration"
                     [nzDisabled]="slackIntegration">
                     <label
                        nz-radio
                        nzValue="enable">
                        Enable
                     </label>
                     <label
                        nz-radio
                        nzValue="disable">
                        Disable
                     </label>
                  </nz-radio-group>
               </div>
            </div>
         </nz-card>
      </section>

      <section>
         <app-select-admin
            [groupMembersList]="groupDetails.groupMembership"
            (updatedGroupAdmin)="getGroupAdmin($event)"></app-select-admin>
      </section>
   </nz-content>

   <nz-footer class="create-group site-page-footer">
      <button
         nz-button
         nzType="primary"
         nzDanger="true"
         (click)="redirectTo()">
         Cancel
      </button>
      <button
         nz-button
         nzDanger="true"
         nzGhost
         *ngIf="groupId"
         (click)="deleteGroupById(groupId)">
         Delete
      </button>
      <button
         nz-button
         nzType="primary"
         [disabled]="isPublishDisable"
         (click)="saveGroupDetails()">
         {{ buttonLabel }}
      </button>
   </nz-footer>
</nz-layout>
