<div class="view-post-drawer">
   <app-loader-component
      loaderType="SECTION_LOADER"
      [isLoaded]="!isLoading"></app-loader-component>

   <nz-collapse
      [nzDisabled]="canChangeLabels"
      nzExpandIconPosition="right"
      class="mb-16"
      nzBordered="false">
      <nz-collapse-panel
         [nzHeader]="editorialStandardsHeader"
         nzActive="true">
         <div *ngFor="let opt of filterList?.markAsType">
            <label
               nz-checkbox
               [(ngModel)]="opt.checked"
               [disabled]="
                  opt.disabled ||
                  isLoading ||
                  !canViewLabel(opt.value) ||
                  (postDetails?.locked && userId !== postDetails.lockedByUser?.userId)
               "
               (ngModelChange)="onSelect(opt.value, $event, 'markAsType')">
               {{ opt.label }}
            </label>
         </div>
      </nz-collapse-panel>
   </nz-collapse>

   <nz-collapse
      [nzDisabled]="canChangeLabels"
      nzExpandIconPosition="right"
      nzBordered="false">
      <nz-collapse-panel
         [nzHeader]="rcLegalHeader"
         nzActive="true">
         <div *ngFor="let opt of filterList?.statusType">
            <label
               nz-checkbox
               [(ngModel)]="opt.checked"
               [disabled]="
                  opt.disabled ||
                  isLoading ||
                  !canViewLabel(opt.value) ||
                  (postDetails?.locked && userId !== postDetails.lockedByUser?.userId)
               "
               (ngModelChange)="onSelect(opt.value, $event, 'statusType')">
               {{ opt.label }}
            </label>
         </div>
      </nz-collapse-panel>
   </nz-collapse>

   <!-- Show Topics when in Edit Mode or in View Mode and Topics exist -->
   <nz-collapse
      nzExpandIconPosition="right"
      nzBordered="false"
      *ngIf="!viewMode || (viewMode && hasTopics)">
      <nz-collapse-panel
         [nzHeader]="topicHeader"
         nzActive="true">
         <div class="metadataSection">
            <app-additional-topic-new
               [assignedTopics]="postDetails.topicDetails"
               (addedTopics)="updateTopics($event)"
               [viewMode]="viewMode"></app-additional-topic-new>
         </div>
      </nz-collapse-panel>
   </nz-collapse>

   <!-- Show Tags when in Edit/Create Mode or in View Mode and Tags exist -->

   <div *ngIf="!viewMode || (viewMode && hasTags)">
      <div class="metadataSection">
         <app-tags-new
            [tagContent]="postDetails.postContentTags"
            [isGenerateTags]="postDetails.isGenerateTags"
            [title]="postDetails.postTitle"
            [description]="postDetails.postContent"
            (tagsUpdated)="updateTags($event)"
            (generateFlag)="generateTags($event)"
            [viewMode]="viewMode"></app-tags-new>
      </div>
   </div>
</div>

<ng-template #editorialStandardsHeader>
   <i
      nz-icon
      nzType="audit"></i>
   Editorial / Standards
</ng-template>

<ng-template #rcLegalHeader>
   <i
      nz-icon
      nzType="trademark"></i>
   R&C / Legal
</ng-template>

<ng-template #topicHeader>
   <i
      nz-icon
      nzType="message"></i>
   Topic
</ng-template>

<!-- MODALS FOR THE 'VIEW MODE' OF A POST -->

<app-reportable-modal
   [isVisible]="modals.visible['REPORTABLE']"
   [isLoaded]="!isLoading"
   (updated)="updatePostForReportable($event)"
   (cancelled)="modalCancelled('REPORTABLE')"></app-reportable-modal>

<app-limited-license-modal
   [isVisible]="modals.visible['LIMITED LICENSE']"
   [isLoaded]="!isLoading"
   (updated)="updatePostForLimitedLicense($event)"
   (cancelled)="modalCancelled('LIMITED LICENSE')">
   S
</app-limited-license-modal>

<app-standards-guidance-modal
   [isVisible]="modals.visible['STANDARDS']"
   [isLoaded]="!isLoading"
   (updated)="updatePostForStandardsGuidance($event)"
   (cancelled)="modalCancelled('STANDARDS')"></app-standards-guidance-modal>
