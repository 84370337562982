import { Injectable } from '@angular/core';
import { NzMessageDataOptions, NzMessageService } from 'ng-zorro-antd/message';

@Injectable()

export class ToastService {

  constructor(
    private message: NzMessageService
  ) {}

  createMessage(type: 'success' | 'info' | 'warning' | 'error', message: string, option?:NzMessageDataOptions): void {

    this.message.create(type, message, option);
  
  }

}
