import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { TransferInformationComponent } from '../shared/components/TransferInformation/TransferInformation.component';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { ToastService } from './toastService/toastMessage.service';
import { environment } from '@environments/environment';

@Injectable()
export class HTTPCommonInterceptor implements HttpInterceptor {

  isvalid: any = true;

  constructor(
    private userInfo: TransferInformationComponent,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private toastService: ToastService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    /*Need To Configure Header with JWT, MessageId (UUID) aND UserId for API's
     Session Inactivity code */
    console.log('intercept : ', req.url);
    const currentDate = new Date();

    let sessionTransactionTime;

    // Validation Check for Last Transaction Time at Browser Level
    if (localStorage.hasOwnProperty('sessionTransactionTime')) {

      sessionTransactionTime = localStorage.getItem('sessionTransactionTime');

    } else {

      localStorage.setItem('sessionTransactionTime', currentDate.toString());
      sessionTransactionTime = localStorage.getItem('sessionTransactionTime');

    }
    if (!window.location.href.includes('#access_token')) {

      localStorage.setItem('referenceUrl', window.location.href);

    }
    const lastTransactionTime = new Date(this.userInfo.sessionInactivity.value);

    const diff = ((currentDate.getTime() - lastTransactionTime.getTime()) / 60000);

    const sessionDiff = ((currentDate.getTime() - new Date(sessionTransactionTime).getTime()) / 60000);

    if (diff > 480 && sessionDiff > 480) {

      setTimeout(() => {

        // this.isvalid = true;
        if (this.isvalid) {

          // console.log('Session In active for 30 min Logging Out');
          this.toastService.createMessage('error', 'Session Inactive for 8 hrs Logging Out');
          this.commonFunctionsHelper.clearLocalStorageForAuth();

          const oAuthLogoutUrl = environment.oAuthLogoutUrl;

          window.location.href = oAuthLogoutUrl;
          this.isvalid = false;

        }
        return;

      }, 1000);

    } else {

      this.userInfo.getSessionTime(currentDate);
      localStorage.setItem('sessionTransactionTime', currentDate.toString());

    }
    const authReq = req.clone(); // { setHeaders: { jwt , messageId } }

    return next.handle(authReq);

  }

}
