import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-news-connect',
  template: `<i nz-icon
                [ngClass]='classes'
                [ngStyle]='style'>
    <svg viewBox='0 0 32 20'
         xmlns='http://www.w3.org/2000/svg'>

      <!-- logo with colors -->
      <ng-container *ngIf='useDefaultColors'>
        <path d='M32.0001 6.85996L25.6134 0.666626L19.1901 6.89996L12.8101 0.709959L6.4234 6.90329L6.42673 6.90663L6.43007 6.90329L12.8134 13.0966V13.1L19.1934 19.29L25.5801 13.0966L25.5734 13.0933L32.0001 6.85996Z'
              fill='#645FA9' />
        <path d='M0 13.1399L6.38667 19.3333L12.8133 13.0999L6.42667 6.90662L0 13.1399Z'
              fill='#0088CF' />
      </ng-container>

      <!-- logo without colors -->
      <ng-container *ngIf='!useDefaultColors'>
        <path d='M32.0001 6.85996L25.6134 0.666626L19.1901 6.89996L12.8101 0.709959L6.4234 6.90329L6.42673 6.90663L6.43007 6.90329L12.8134 13.0966V13.1L19.1934 19.29L25.5801 13.0966L25.5734 13.0933L32.0001 6.85996Z' />
        <path d='M0 13.1399L6.38667 19.3333L12.8133 13.0999L6.42667 6.90662L0 13.1399Z' />
      </ng-container>

    </svg>

  </i>`,
})
export class NewsConnectIcon {

  @Input() classes: { [key: string]: boolean; } = {};

  @Input() styles: { [key: string]: number | string } = {};

  @Input() useDefaultColors: boolean = true;

  get style() {

    return { ...this.styles };

  }

}
