import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class NcxJwtInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log('Interceptor 2');
    const ncxjwttoken = localStorage.getItem('ncxjwttoken');

    const modifiedReq = req.clone({
      setHeaders: { ncxjwttoken: (ncxjwttoken !== undefined && ncxjwttoken !== null) ? ncxjwttoken : '' }
    });

    return next.handle(modifiedReq).pipe(tap((event: HttpEvent<any>) => {

      if (event instanceof HttpResponse) {

        if (event.headers.get('ncxjwttoken') && event.headers.get('ncxjwttoken') !== null) {

          localStorage.setItem('ncxjwttoken', event.headers.get('ncxjwttoken'));
          localStorage.setItem('ncx_token_expires_in', event.headers.get('ncx_token_expires_in'));
          localStorage.removeItem('access_token');
          localStorage.removeItem('expires_in');
          localStorage.removeItem('id_token');
          if (window.location.href.includes('#access_token')) {

            const search = window.location.href.split('#');

            localStorage.setItem('referenceUrl', search[0] + '#' + search[1]);
            window.history.pushState({}, document.title, localStorage.getItem('referenceUrl'));

          }

        }

      }
      return event;

    }));

  }

  constructor() { }

}
