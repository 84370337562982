import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '@services/toastService/toastMessage.service';
import { LimitedLicense } from '@models/ncx/limited-license';

@Component({
  selector: 'app-limited-license-modal',
  templateUrl: './limited-license.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class LimitedLicenseModalComponent implements OnInit {

  @Input() isVisible: boolean = false;

  @Input() isLoaded: boolean = true;

  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Output() updated: EventEmitter<LimitedLicense> = new EventEmitter<LimitedLicense>();

  postLimitedLicenseContent: LimitedLicense = {} as LimitedLicense;

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit() {

    this.postLimitedLicenseContent = {
      additionalNotes: ''
    };

  }

  handleCancel() {

    this.cancelled.emit();

  }

  handleOk() {

    if (this.postLimitedLicenseContent.additionalNotes !== '') {

      this.updated.emit(this.postLimitedLicenseContent);

    } else {

      this.toastService.createMessage('warning', 'Please list all license limitations here');

    }

  }

  onChange(value, type) {

    if (type === 'additionalNotes' && value === '') {

      this.toastService.createMessage('warning', 'Please list all license limitations here');

    }

  }

}
