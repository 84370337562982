import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-render-alerts',
  templateUrl: './render-alerts.component.html',
  styleUrls: ['./render-alerts.component.scss']
})
export class RenderAlertsComponent implements OnInit {

  @Input() alert;

  @Input() buttons;

  @Output() updateAlertId = new EventEmitter();

  @Output() updateAlertCount = new EventEmitter();

  alertAPI: string;

  inviteURL: string;

  constructor(
    private router: ActivatedRoute,
    private http: HttpClient,
    private cService: CommonService,
    private reRoute: Router,
    private toastService: ToastService
  ) { }

  ngOnInit() {

    this.alertAPI = environment.getProfileAPIURL;
    this.inviteURL = environment.getGroupsURL;

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  createPayload(alert) {

    const payload = JSON.parse(JSON.stringify(alert));

    payload.readFlag = true;
    payload.createDateTime = null;
    payload.updateDateTime = null;
    return payload;

  }

  createGroupPayload(groupObj, _typeOfRequest) {

    if (groupObj.alertType === 'GROUP_REQUEST') {

      const userObj = {
        groupId: groupObj.alertContentId,
        userId: groupObj.body,

        // tslint:disable-next-line:max-line-length
        invitationNote: `You’ve been invited to join ${groupObj.subject} . Go to your Newsconnect Alerts page to accept this invitation .`,
        membershipCategory: 'MEMBER',
        membershipType: 'MEMBER',
        membershiptInvitationToken: null,
        isDeleted: false
      };

      return userObj;

    } else {

      const userId = localStorage.getItem('userId');

      const userObj = {
        groupId: groupObj.alertContentId,
        userId,

        // tslint:disable-next-line:max-line-length
        invitationNote: `You’ve been invited to join ${groupObj.subject} . Go to your Newsconnect Alerts page to accept this invitation .`,
        membershipCategory: 'MEMBER',
        membershipType: 'MEMBER',
        membershiptInvitationToken: null,
        isDeleted: false
      };

      return userObj;

    }

  }

  viewAlert(alert) {

    if (alert.alertGroup === 'POST') {

      if (alert.alertType === 'POST_REPLY' && alert.contentLink) {

        this.reRoute.navigate(([alert.contentLink.split('#')[1]]));

      } else {

        this.reRoute.navigate((['/ncx/view-post/:' + alert.alertContentId]));

      }

    } else if (alert.alertGroup === 'STORY') {

      this.reRoute.navigate((['/ncx/landing-story/:' + alert.alertContentId]));

    } else if (alert.alertGroup === 'GROUP') {

      switch (alert.alertType) {

      case 'NEW_DISCUSSION' :

        // tslint:disable-next-line:max-line-length
        this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: alert.parentContentId, discussionId: alert.alertContentId } });
        break;
      case 'GROUP_DISCUSSION_ALERT' :

        // tslint:disable-next-line:max-line-length
        this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: alert.parentContentId, discussionId: alert.alertContentId } });
        break;
      case 'DISCUSSION_COMMENT' :

        // tslint:disable-next-line:max-line-length
        this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: alert.parentContentId, discussionId: alert.alertContentId } });
        break;
      case 'REQUEST_ALERT' :
      case 'GROUP_INVITE' :
      case 'GROUP_PUBLISH' :
      case 'GROUP_UPDATE' :
        this.reRoute.navigate(['/group-details/:' + alert.alertContentId]);
        break;
      default:
        break;

      }

    } else if (alert.alertGroup === 'GENERAL') {

      switch (alert.alertType) {

      case 'USER_FOLLOWING' :

        // tslint:disable-next-line:max-line-length
        this.reRoute.navigate(['/profile/:' + alert.alertContentId]);
        break;
      case 'SYSTEM_ANNOUNCEMENT':
        this.reRoute.navigate(['/ncx-admin/create-announcement/:' + alert.alertContentId]);
        break;
      default:
        break;

      }

    }

  }

  dismissAlert(alert) {

    this.updateAlert(alert, 'DISMISS');

  }

  updateAlertList(alertId, alertCount) {

    this.updateAlertId.emit(alertId);
    if (alertCount && alertCount >= 0) {

      this.updateAlertCount.emit(alertCount);

    } else {

      this.updateAlertCount.emit(-1);

    }

  }

  updateAlert(alert, type) {

    const querStr = `/alert/${alert.alertId}`;

    const payload = this.createPayload(alert);

    this.cService.serviceRequestCommon('put', this.alertAPI, querStr, payload).subscribe((res: any) => {

      console.log('updateAlert', res);
      if (type === 'VIEW') {

        this.viewAlert(alert);

      }
      this.updateAlertList(alert.alertId, res.totalCount);

    }, () => {

      this.toastService.createMessage('error', 'Error while Updating please try again');

    });

  }

  /* Accepting and Declining Invite */
  sendRequest(groupObj) {

    const queryStr = `/${groupObj.alertContentId}/members`;

    const payload = this.createGroupPayload(groupObj, groupObj.alertType);

    this.cService.serviceRequestCommon('put', this.inviteURL, queryStr, payload).subscribe(() => {

      this.updateAlertList(groupObj.alertId, -1);
      if (groupObj.alertType === 'GROUP_INVITE') {

        this.toastService.createMessage('success', 'Invite Successfully Accepted.');

      } else {

        this.toastService.createMessage('success', 'Request Successfully Accepted.');

      }

    }, () => {

      if (groupObj.alertType === 'GROUP_INVITE') {

        this.toastService.createMessage('error', 'Error in Accepting Invite. Please try again.');

      } else {

        this.toastService.createMessage('error', 'Error in Accepting Request. Please try again.');

      }

    });
    this.updateAlert(groupObj, '');

  }

  deleteRequest(groupObj) {

    if (groupObj.alertType === 'GROUP_REQUEST') {

      const userId = groupObj.body;

      const queryStr = `/${groupObj.alertContentId}/members?memberIdToRemove=${userId}`;

      this.cService.serviceRequestCommon('delete', this.inviteURL, queryStr).subscribe((res: any) => {

        console.log('deleteRequest : ', res);
        this.updateAlertList(groupObj.alertId, -1);
        if (groupObj.alertType === 'INVITATION') {

          this.toastService.createMessage('success', 'Invite Successfully Deleted.');

        } else {

          this.toastService.createMessage('success', 'Request Successfully Deleted.');

        }

      });

    } else {

      const userId = localStorage.getItem('userId');

      const queryStr = `/${groupObj.alertContentId}/members?memberIdToRemove=${userId}`;

      this.cService.serviceRequestCommon('delete', this.inviteURL, queryStr).subscribe((res: any) => {

        console.log('deleteRequest : ', res);
        this.updateAlertList(groupObj.alertId, -1);
        if (groupObj.alertType === 'INVITATION') {

          this.toastService.createMessage('success', 'Invite Successfully Deleted.');

        } else {

          this.toastService.createMessage('success', 'Request Successfully Deleted.');

        }

      });

    }
    this.updateAlert(groupObj, '');

  }

}
