<nz-collapse
   nzExpandIconPosition="right"
   nzBordered="false">
   <nz-collapse-panel
      nzHeader="Reportable Approver"
      nzActive="true"
      class="approver-collapse">
      <div class="elements">
         <div class="pd-t-16 elementDetails">
            <span
               class="elementDetailsText"
               *ngIf="postDetails.reportableApprover">
               {{ postDetails.reportableApprover?.additionalNotes }}
            </span>
         </div>

         <!-- <div class="sub-title" *ngIf="postDetails.reportableApprover.isOfficial == 'No'">
        <span class="subTitleLabel pd-r-8">Approved By:</span>
        <span class="subTitleLabel">{{postDetails.reportableApprover.seniorApprovalName.trim()}}</span> -->
         <!-- <span class=" subTitleProfileText pd-r-8" nz-popover [nzPopoverContent]="profileCardTemplate"
          [nzPopoverPlacement]="'bottomLeft'" *ngIf="postDetails && postDetails.reportableApprover">
          {{postDetails.reportableApprover.seniorApprovalName.trim()}}</span>
        <ng-template #profileCardTemplate>
          <app-profile-overlay [profileData]="postDetails.reportableApprover.seniorApprovalName"></app-profile-overlay>
        </ng-template> -->
         <!-- </div> -->

         <!-- *ngIf="postDetails.reportableApprover.isOfficial == 'Yes'" -->

         <div class="pd-t-16">
            <span class="officialApprovalText">{{ postDetails.reportableApprover?.seniorApprovalName }}</span>
         </div>

         <!-- <div class="sub-title">
        <span class="">
          <span class="subTitleLabel">Approved By:</span>
          <span class="subTitleProfileText pd-r-8" nz-popover [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'"
            *ngIf="postDetails && postDetails.standardsGuidance.updateUser && postDetails.standardsGuidance.updateUser.displayName?.split(',').length > 1">
            {{postDetails.standardsGuidance.updateUser.displayName.split(',')[1].trim()}}
            {{postDetails.standardsGuidance.updateUser.displayName.split(',')[0]}}</span>
          <span class="subTitleProfileText pd-r-8" nz-popover [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'"
            *ngIf="postDetails && postDetails.standardsGuidance.updateUser && (postDetails.standardsGuidance.updateUser.displayName?.split(',').length == 1)">
            {{postDetails.standardsGuidance.updateUser.displayName.trim()}}</span>
        </span>
        <ng-template #profileCardTemplate>
          <app-profile-overlay [profileData]="postDetails.standardsGuidance.updateUser"></app-profile-overlay>
        </ng-template>

        <span class="subTitleText"
          *ngIf="postDetails &&  postDetails.reportableApprover && postDetails.standardsGuidance.updateDateTime">
          {{utcToLocal(postDetails.standardsGuidance.updateDateTime , 'DATE') }}
        </span>

        <span class="subTitleText"
          *ngIf="postDetails && postDetails.reportableApprover && postDetails.standardsGuidance.updateDateTime">
          ({{utcToLocal(postDetails.standardsGuidance.updateDateTime, 'TIME')}})
        </span>
      </div> -->
      </div>
   </nz-collapse-panel>
</nz-collapse>
