<nz-badge
   nzStandalone
   class="beta"
   [ngStyle]="{
      'width.px': width,
      'margin-top.px': margin.top,
      'margin-right.px': margin.right,
      'margin-bottom.px': margin.bottom,
      'margin-left.px': margin.left,
      'color': color,
      'background': bgColor,
      'border-width.px': border.width,
      'border-style': 'solid',
      'border-color': border.color,
   }">
   {{ betaText }}
</nz-badge>
