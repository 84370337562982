import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PamDetails, Post, PostAttachment } from '@models/ncx/post';
import { Common } from '@utilities/common';
import { Sort } from '@utilities/sort';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-media-central-details-view',
  templateUrl: './media-central-details-view.component.html',
  styleUrls: ['./media-central-details-view.component.scss']
})
export class MediaCentralDetailsViewComponent implements OnChanges {

  @Input() postDetails = null as Post;

  @Output() viewAttachment: EventEmitter<number> = new EventEmitter<number>();

  sendToMediaCentralHistory: PamDetails[] = [];

  public readonly Common = Common;

  ngOnChanges(changes: SimpleChanges) {

    if ('postDetails' in changes) {

      const postDetailsChanged = JSON.stringify(changes.postDetails.currentValue) !== JSON.stringify(changes.postDetails.previousValue);

      if (postDetailsChanged) {

        this.getMediaCentralHistory();
      
      }
    
    }

  }

  /**
   * Create the array to show all files that were sent to PAM
   *
   */
  getMediaCentralHistory() {

    if (Array.isArray(this.postDetails.postAttachments) && this.postDetails.postAttachments.length) {

      // Gather all of the PamDetails array from the attachments
      const pamDetails = this.postDetails.postAttachments.reduce((acc: any, current: PostAttachment, index: number) => {

        if (Array.isArray(current.pamDetails) && current.pamDetails.length) {


          // Add the Attachment Name to the Pam Details information
          const pam = current.pamDetails.map((details: PamDetails) => {

            details.attachmentName = current.attachmentName;
            details.attachmentIndex = index;
            details.mediaId = this.postDetails.postAttachments[index].attachmentMediaId;
            return details;
          
          });

          acc = acc.concat([...pam]);
        
        }
        return acc;
      
      }, [])

        // Sort chronologically descending (most recent item first)
        .sort((a: PamDetails, b: PamDetails) => Sort.numeric(+b.pamDateTime, +a.pamDateTime));

      this.sendToMediaCentralHistory = pamDetails;

    }

  }

  /**
   * Open lightbox from WG01/PAM list
   *
   */
  openLightbox(index: number) {

    this.viewAttachment.emit(index);

  }

  milliSeconsToLocal(apiDateTime) {

    return Time.convertMilliSecondsToLocalTime(Number(apiDateTime));
  
  }

}
