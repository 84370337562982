import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Group } from '@models/ncx/groups-discussions';
import { CommonService } from '@services/common-service';
import { environment } from '@environments/environment';
import { UtilityService } from '@services/utility.service';
import { FollowGroupUpdateEvent } from '@models/ncx/followers';
import { BreakpointService } from '@services/breakpoint.service';

@Component({
  selector: 'app-group-followers',
  templateUrl: './group-followers.component.html',
  styleUrls: ['./group-followers.component.scss']
})
export class GroupFollowersComponent implements OnInit {

  getApiURL: any;

  groupId: any;

  groupDetails: Group;

  groupDiscussionDetails = [];

  groupFollowers;

  filteredFollowers;

  isLoaded = false;

  sortOrderSelected = 'A - Z';

  searchInput;

  groupDetailsIsCollapsed = true;

  gdCollapsed = true;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private cService: CommonService,
    private breakpointService: BreakpointService,
    private uS: UtilityService
  ) { }

  ngOnInit() {

    this.isLoaded = false;
    this.getApiURL = environment.getGroupsURL; // 'assets/jsons/groupMockData.json';
    this.router.params.subscribe(
      (params: Params) => {

        const id = params.groupId;

        this.groupId = id.substr(1);
        this.loadGroupDetails(this.groupId);
        this.changeSortOrder('A - Z');

      }).add(() => {

      this.isLoaded = true;

    });
    this.groupDetailsIsCollapsed = this.breakpointService.isMobile.value;

  }

  filterFollowers() {

    const regex = new RegExp(`${this.searchInput}`, 'i');

    const list = this.groupFollowers.filter((o: any) => {

      if (this.searchInput) {

        return !!(o.displayName || o.lastName || '').match(regex);

      } else {

        return true;

      }

    }).sort((o1: any, o2: any) => this.uS.sortAlphabetical(o1.displayName, o2.displayName));

    this.filteredFollowers = this.sortOrderSelected === 'A - Z' ? list : list.reverse();

  }

  toggleSortOrder() {

    this.sortOrderSelected = this.sortOrderSelected === 'A - Z' ? 'Z - A' : 'A - Z';

    this.filterFollowers();

  }

  changeSortOrder(order: string) {

    this.sortOrderSelected = order;

    this.filterFollowers();

  }

  loadGroupDetails(groupId) {

    this.isLoaded = false;

    const queryStr = `/${groupId}?isDiscussions=false`;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.groupDetails = res;
      this.groupDiscussionDetails = res.groupDiscussions;
      this.groupFollowers = res.groupFollowers;
      this.filteredFollowers = res.groupFollowers;

    }, (error: any) => {

      console.error('Load Group Details', error);

    }).add(() => {

      this.isLoaded = true;

    });

  }

  updateFollower(_status: FollowGroupUpdateEvent) {

    this.loadGroupDetails(this.groupId);

  }

  goToManageMembers() {

    this.route.navigate(['/manage-members/:' + this.groupId]);

  }

  redirectToEditGroup(type) {

    if (type && this.groupDetails && this.groupDetails.groupMembership) {

      const isAdminOrOwner = this.uS.hasAdminAccess(this.groupDetails.groupMembership);

      if (isAdminOrOwner) {

        this.route.navigate((['/ncx/create-group/:' + this.groupDetails.groupId]));

      }

    }

  }

  toggleSider(): void {

    this.groupDetailsIsCollapsed = !this.groupDetailsIsCollapsed;

  }

  closeSlider() {

    this.groupDetailsIsCollapsed = true;

  }

  closeDrawer() {

    this.groupDetailsIsCollapsed = true;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
