<section class='support-tab-body'
         [attr.data-component]="'ncx/support/training'">

  <app-collapsable title='Getting Started with NewsConnect'
                   iconTheme='outline'
                   icon='star'>

    <ul>
      <li>This lesson will teach you how to get started with NewsConnect (NCX) – the newsgathering tool for
          journalists
          across this news organization.
      </li>
      <li>We'll cover the basic terms and functions of NCX. You will learn how to sign into NCX on your desktop, set
          up
          the email alerts you get from NCX and load the app onto your mobile device.
      </li>
      <li>You'll learn about news alerts and how to follow and contribute to stories – from your desktop, from email
          and
          on the go.
      </li>
      <li>By the time you're finished, you should understand how NCX works. You should also feel comfortable using NCX
          to keep informed and contribute to stories.
      </li>
    </ul>

    <p>Get started with NBCU Academy: <a href=''
                                         target='_blank'>Visit now to begin your lessons</a></p>

  </app-collapsable>

  <app-collapsable title='NewsConnect Training Videos'
                   iconTheme='outline'
                   icon='video-camera'>

    <p><span class='bold'>NewsConnect Custom Homepage</span></p>

    <div class='indent'>
      <p>How to customize your homepage presentation. <a href=''
                                                         target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Send NewsConnect Video to WG01/06</span></p>

    <div class='indent'>
      <p>How to send videos from NewsConnect to WG's. <a href=''
                                                         target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Darkmode & Relevance Search</span></p>

    <div class='indent'>
      <p>How to configure your NewsConnect settings for Darkmode and Relevance. <a href=''
                                                                                   target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>NewsConnect Stories/Slack Integration</span></p>

    <div class='indent'>
      <p>Slack integration within NewsConnect stories. <a href=''
                                                          target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>NewsConnect Groups/Slack Integration</span></p>

    <div class='indent'>
      <p>Slack integration within NewsConnect Groups. <a href=''
                                                         target='_blank'>Watch video</a>.</p>
    </div>

  </app-collapsable>

  <app-collapsable title='Training Signup'
                   iconTheme='outline'
                   icon='solution'>

    <p>Register for upcoming trainings and receive communications related to your selections as those sessions become available. <a href='https://forms.office.com/pages/responsepage.aspx?id=-SY1T9aXLUGTOk4wpzEQ9G3smpXJB2dAgDe6ksKYDYhUQ0Y2NTZWVk1OMEZZNEw3V0cxRVVBUDBJOS4u'
                                                                                                                                    target='_blank'>Register here</a>.</p>

  </app-collapsable>

  <app-collapsable title='DEI Words Guide'
                   iconTheme='outline'
                   icon='sort-ascending'>

    <p>NBCU News Group Diversity, Equity & Inclusion Words Usage Guide. <a href=''
                                                                           target='_blank'>View guide.</a></p>

  </app-collapsable>

  <app-collapsable title='NBCU News Workflows & Tools Training Guide'
                   iconTheme='outline'
                   icon='coffee'>

    <p>NBCU News Group Workflows & Tools Training Guide. <a href=''
                                                            target='_blank'>View guide.</a></p>

  </app-collapsable>

</section>

