import { Component, Input, OnInit } from '@angular/core';
import { Post } from '@models/ncx/post';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-element-details-view',
  templateUrl: './element-details-view.component.html',
  styleUrls: ['./element-details-view.component.scss']
})
export class ElementDetailsViewComponent implements OnInit {

  @Input() postDetails: Post = {} as Post;

  captureDate: string = '';

  constructor() { }

  ngOnInit() {

    this.captureDate = Time.convertingUtcToLocalDate(this.postDetails.attachmentDetails?.captureDate);

  }

  openLinkToSource(linkToSource) {

    return linkToSource?.startsWith('http') ? linkToSource : 'http://' + linkToSource;

  }

}
