import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { AuthService } from '@services/auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-invalid-user',
  templateUrl: './invalid-user.component.html',
  styleUrls: ['./invalid-user.component.scss']
})
export class InvalidUserComponent {

  errorDetails;

  constructor(
    private authService: AuthService,
    private userInfo: TransferInformationComponent,
    private router: Router
  ) {

    /**
     * Session should be validated only if the user has successfully logged in to the application.
     * If the user is disabled in the Admin screen, then the 'Disabled-user' screen should be shown and the user should not be redirected to the log off screen.
     */


    this.authService.checkSession.next(false);

    this.errorDetails = this.userInfo.linkLoggedInErrSource.value;

  }

  clearStorage() {

    const isCollapsedValue = localStorage.getItem('isCollapsed');

    localStorage.clear();
    localStorage.setItem('isCollapsed', isCollapsedValue);
    this.authService.getOpenIdToken();

  }

  logout() {

    const isCollapsedValue = localStorage.getItem('isCollapsed');

    localStorage.clear();
    localStorage.setItem('isCollapsed', isCollapsedValue);

    const oAuthLogoutUrl = environment.oAuthLogoutUrl;

    window.location.replace(oAuthLogoutUrl);

  }

  refresh() {

    this.router.navigate(['ncx/stories-dashboard']);

  }

}
