<app-standard-header
   title="Back"
   [defaultBackAction]="true"></app-standard-header>

<div
   class="tools-container"
   [attr.data-component]="'ncx/other-tools/other-tools.component.html'">
   <section>
      <p>Production</p>
      <div class="grid">
         <a
            nz-button
            *ngFor="let index of productionDetails"
            [href]="index.url"
            target="_blank">
            {{ index.value }}
         </a>
      </div>
   </section>
   <section>
      <p>Requests</p>
      <div class="grid">
         <a
            nz-button
            *ngFor="let index of requestDetails"
            [href]="index.url"
            target="_blank">
            {{ index.value }}
         </a>
      </div>
   </section>
   <section>
      <p>Monitoring</p>
      <div class="grid">
         <a
            nz-button
            *ngFor="let index of monitoringDetails"
            [href]="index.url"
            target="_blank">
            {{ index.value }}
         </a>
      </div>
   </section>
   <section>
      <p>Info</p>
      <div class="grid">
         <a
            nz-button
            *ngFor="let index of infoDetails"
            [href]="index.url"
            target="_blank">
            {{ index.value }}
         </a>
      </div>
   </section>
   <section>
      <p>Integrations</p>
      <div class="grid">
         <a
            nz-button
            *ngFor="let index of integrationDetails"
            [href]="index.url"
            target="_blank">
            {{ index.value }}
         </a>
      </div>
   </section>
</div>
