import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Group } from '@models/ncx/groups-discussions';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebSocketService } from '@services/websocket.service';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { UtilityService } from '@services/utility.service';
import { Title } from '@angular/platform-browser';
import { Common } from '@utilities/common';
import { User } from '@models/users';
import { FollowGroupUpdateEvent } from '@models/ncx/followers';
import { BreakpointService } from '@services/breakpoint.service';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit, OnDestroy {

  isLoaded: boolean;

  getApiURL: any;

  groupId: any;

  shareMessage: string;

  isVisible: boolean;

  groupFollowersInit: any;

  subject: Subject<string> = new Subject();

  pageStartCount: any;

  pageLength: any;

  searchText = '';

  didScroll: boolean;

  getDiscApiURL: string;

  getDiscSearchApiURL: string;

  discussionId: any;

  isAdminOrOwner: boolean;

  isGroupDetailsCollapsed = true;

  gdCollapsed = true;

  groupDetails: Group;

  groupDiscussionDetails = [];

  groupFollowers;

  value = '';

  tabSelected = false;

  private subscriptionsWs: Subscription = new Subscription();

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  @HostListener('scroll', [])
  scrollDiv() {

    const el = document.querySelector('[data-scrollable]');

    if (el) {

      this.didScroll = el.scrollTop > 150;

    }

  }

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private wS: WebSocketService,
    private http: HttpClient,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private breakpointService: BreakpointService,
    private uS: UtilityService,
    private titleService: Title
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(() => {

      this.pageStartCount = 1;
      this.groupDiscussionDetails = [];
      this.getNextDiscussions(this.groupId);

    });

  }

  ngOnInit() {

    this.pageStartCount = 1;
    this.pageLength = 10;
    this.getApiURL = environment.getGroupsURL;
    this.getDiscApiURL = environment.getDiscussionURL;
    this.getDiscSearchApiURL = environment.getInpageSearchURL;

    this.router.params.subscribe((params: Params) => {

      this.setParams(params);

    });

    this.subscriptionsWs = this.wS.doSocketData$.subscribe(data => {

      this.updateGroupLanding(data);

    });

    this.isGroupDetailsCollapsed = this.breakpointService.isMobile.value;

    // Close the right-hand drawer if the browser is minimized to handset and the drawer is open
    this.breakpointService.isMobile.subscribe((isMobile: boolean) => {

      if (isMobile && !this.isGroupDetailsCollapsed) {

        this.isGroupDetailsCollapsed = true;

      }

    });

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  setParams(params) {

    const id = params.groupId;

    this.groupId = id.substr(1);
    this.getGroupDetails(this.groupId);
    this.getGroupMembers(this.groupId, 'MEMBER');

  }

  toggleSider(): void {

    this.isGroupDetailsCollapsed = !this.isGroupDetailsCollapsed;

  }

  closeSlider() {

    this.isGroupDetailsCollapsed = true;

  }

  onScrollDown() {

    this.pageStartCount += this.pageLength;
    this.getNextDiscussions(this.groupId);

  }

  updateFollower(status: FollowGroupUpdateEvent) {

    this.groupDetails.isUserFollowing = status.type;

    // If unfollowing, we have to update the follower count ourselves
    if (!status.type) {

      this.groupDetails.groupFollowerCount = this.groupDetails.groupFollowerCount - 1;

    } else {

      this.groupDetails.groupFollowerCount = status.data.groupFollowerCount;

    }

  }

  createDiscussion() {

    if (this.groupId) {

      this.route.navigate(['/create-discussion'], { queryParams: { groupId: this.groupId, discussionId: null } });

    }

  }

  discussionDetails(discussionId) {

    this.route.navigate(['/discussion-details'], { queryParams: { groupId: this.groupId, discussionId } });

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  fetchMemberList(groupMembership) {

    const users = [];

    groupMembership.forEach(element => {

      users.push(element.userId);

    });
    this.groupFollowers = users;
    this.groupFollowersInit = JSON.parse(JSON.stringify(users));

  }

  shareContent(discussionObj: any): void {

    if (discussionObj && discussionObj.discussionId) {

      this.shareMessage = discussionObj.discussionSubject;
      this.discussionId = discussionObj.discussionId;

    } else {

      this.shareMessage = '';
      this.discussionId = null;

    }
    this.isVisible = true;

  }

  handleCancel() {

    this.isVisible = false;

  }

  goToGroupFollowers() {

    this.route.navigate(['/group-followers/:' + this.groupId]);

  }

  redirectToEditGroup(type) {

    if (type && this.groupDetails && this.groupDetails.groupMembership) {

      this.isAdminOrOwner = this.uS.hasAdminAccess(this.groupDetails.groupMembership);
      if (this.isAdminOrOwner) {

        this.route.navigate((['/ncx/create-group/:' + this.groupDetails.groupId]));

      }

    }

  }

  exportMembersCSV(event) {

    if (event === true) {

      const ncxjwttoken = localStorage.getItem('ncxjwttoken');

      const messageId = this.commonFunctionsHelper.generateUUID();

      const headers = new HttpHeaders({
        messageId,
        ncxjwttoken,
        'Content-Type': 'application/json'
      });

      this.isLoaded = false;
      const queryStr = `/export/${this.groupId}/members/GroupMembers.csv`;

      this.http.get(this.cService.getAPIEndpoint() + `/ncx-gateway${this.getApiURL}` + queryStr, { headers, responseType: 'blob' as const }).
        subscribe((val) => {

          const blob = new Blob([val], {
            type: 'text/csv'

            // type: 'application/octet-stream'
          });

          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          link.download = `${this.groupDetails.groupName}.csv`;
          link.click();
          window.URL.revokeObjectURL(link.href);
          this.isLoaded = true;

        }, () => {

          this.toastService.createMessage('error', 'Error While Downloading please try again');
          this.isLoaded = true;

        });

    }

  }

  createQueryStr(groupId) {

    let queryStr = `?groupId=${groupId}&isPublished=true&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    if (this.searchText !== '') {

      queryStr = `?pageType=GroupDetails&searchString=${encodeURIComponent(
        this.searchText)}&contentPage=${this.pageStartCount}&postSortField=modificationdate&postSortOrder=descending`;

    }
    return queryStr;

  }

  getGroupDetails(groupId) {

    this.isLoaded = false;
    const queryStr = `/${groupId}?isDiscussions=true`; // = this.createQueryStr(groupId); //

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.setGroupLanding(res);
      this.titleService.setTitle(res.groupName);
      this.isLoaded = true;

    }, err => {

      if (err === 'GROUP-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Group');
        setTimeout(() => {

          this.route.navigate(['ncx/group-landing']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }

    });

  }

  setGroupLanding(res) {

    this.groupDetails = res;
    if (this.groupDetails && this.groupDetails.groupMembership) {

      this.isAdminOrOwner = this.uS.hasAdminAccess(this.groupDetails.groupMembership);

    }
    if (!this.groupDetails.isUserMember && this.groupDetails.groupAccess === 'PRIVATE' && !this.isAdminOrOwner) {

      this.toastService.createMessage('warning', 'You do not have Permission to access this group');
      setTimeout(() => {

        this.route.navigate((['/ncx/group-landing']));

      }, 1000);
      this.isLoaded = true;
      return;

    }
    this.groupDiscussionDetails = res.groupDiscussions;

  }

  getGroupMembers(groupId, tab) {

    this.isLoaded = false;
    const queryStr = `/${groupId}/memberDetails?category=${tab}`; // members category='MEMBER'

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      if (res && res.groupMembership) {

        this.fetchMemberList(res.groupMembership);

      }
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;

    });

  }

  createPayload(groupId) {

    return {
      markAs: [],
      contentType: [],
      stroyId: groupId
    };

  }

  getNextDiscussions(groupId) {

    this.isLoaded = false;
    const queryStr = this.createQueryStr(groupId);

    const payLoad = this.createPayload(groupId);

    if (this.searchText !== '') {

      this.cService.serviceRequestCommon('post', this.getDiscSearchApiURL, queryStr, payLoad).subscribe((res: any) => {

        if (res && res.discussions != null && res.discussions.length > 0) {

          this.groupDiscussionDetails = [...this.groupDiscussionDetails, ...res.discussions];

        } else if ((this.pageStartCount + 1) > this.pageLength) {

          this.pageStartCount -= this.pageLength;

        } else if ((this.pageStartCount > 1)) {

          console.log('hi');

        }
        this.isLoaded = true;

      }, () => {

        this.isLoaded = true;

      });

    } else {

      this.isLoaded = false;

      // tslint:disable-next-line:no-shadowed-variable
      const queryStr = this.createQueryStr(groupId);

      this.cService.serviceRequestCommon('get', this.getDiscApiURL, queryStr).subscribe((res: any) => {

        if (res && res.discussions.length > 0) {

          this.groupDiscussionDetails = [...this.groupDiscussionDetails, ...res.discussions];

        } else if ((this.pageStartCount + 1) > this.pageLength) {

          this.pageStartCount -= this.pageLength;

        }
        this.isLoaded = true;

      }, () => {

        this.isLoaded = true;

      });

    }

  }

  goToManageMembers(_event) {

    this.route.navigate(['/ncx/manage-members/:' + this.groupId]);

  }

  isDiscussionUpdated(discussionDetails) {

    const createdDate = Time.convertingUtcToLocal(discussionDetails.createDateTime);

    const modifiedDate = Time.convertingUtcToLocal(discussionDetails.updateDateTime);

    const dt1 = new Date(modifiedDate);

    const dt2 = new Date(createdDate);

    if (discussionDetails && discussionDetails.createDateTime !== discussionDetails.updateDateTime) {

      let diff = (dt1.getTime() - dt2.getTime()) / 1000;

      diff /= (60 * 60);
      diff = Math.abs(Math.round(diff));
      if (diff > 0) {

        return true;

      } else {

        return false;

      }

    }
    return false;

  }

  isDiscussionupdated(discussion) {

    const modifiedDate = moment.utc(discussion.updateDateTime).local().format('YYYY-MM-DD HH:mm:ss');

    const dt1 = new Date(modifiedDate);

    const dt3 = new Date();

    if (discussion && discussion.createDateTime !== discussion.updateDateTime) {

      let diff = (dt3.getTime() - dt1.getTime()) / 1000;

      diff /= (60 * 60);
      diff = Math.abs(Math.round(diff));
      return diff < 24;

    }
    return false;

  }

  calculateDiff(apiDate) {

    apiDate = Time.convertingUtcToLocal(apiDate);
    if (apiDate) {

      const seconds = Math.floor((+new Date() - +new Date(apiDate)) / 1000);

      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'

        return 'Just now';

      }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };

      let counter;

      // tslint:disable-next-line: forin
      for (const i in intervals) {

        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {

          if (counter === 1) {

            return counter + ' ' + i; // singular (1 day ago)

          } else {

            return counter + ' ' + i + 's'; // plural (2 days ago)

          }

        }

      }

    }
    return apiDate;

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  onChange(value, event, filterFollower) {

    if (!filterFollower) {

      setTimeout(() => {

        this.subject.next(event.target.value);

      }, 100);
      return;

    }

    setTimeout(() => {

      this.filterFollowers(value);

      // this.subject.next(event.target.value);

    }, 100);

  }

  filterFollowers(value) {

    const groupUsers = [];

    this.groupFollowersInit.forEach((item) => {

      if (item.userId !== null && item.firstName !== null) {

        const fullName = item.displayName.toLowerCase();

        if (fullName.includes(value.toLowerCase())) {

          groupUsers.push(item);

        }

      }

    });
    this.groupFollowers = groupUsers;

  }

  navTo(navType) {

    (navType === 'members') ? this.tabSelected = true : this.tabSelected = false;

  }

  private updateGroupLanding(data) {

    if (data.group && data.group.groupId === Number(this.groupId)) {

      // tslint:disable-next-line:max-line-length
      const cloneGroupDiscussionDetails = (this.groupDiscussionDetails && this.groupDiscussionDetails.length) ? JSON.parse(
        JSON.stringify(this.groupDiscussionDetails)) : [];

      if (data.group.groupDiscussions !== null && data.group.groupDiscussions.length > 0) {

        const index = cloneGroupDiscussionDetails.findIndex(dis => dis.discussionId === data.group.groupDiscussions[0].discussionId);

        if (index !== -1) {

          cloneGroupDiscussionDetails.splice(index, 1);

        }
        if (!data.group.groupDiscussions[0].isDeleted &&
          (this.uS.checkSearchText(this.searchText, data.group.groupDiscussions[0], 'discussionSubject') ||
            this.uS.checkSearchText(this.searchText, data.group.groupDiscussions[0], 'discussionPlainContent'))) {

          if (data.group.groupDiscussions[0].isPinnedOnTop) {

            cloneGroupDiscussionDetails.unshift(data.group.groupDiscussions[0]);
          
          } else {

            cloneGroupDiscussionDetails.push(data.group.groupDiscussions[0]);
          
          }

        }

      } else {

        data.group.groupDiscussions = cloneGroupDiscussionDetails;

      }
      this.groupDiscussionDetails = JSON.parse(JSON.stringify(cloneGroupDiscussionDetails));

      // this.setGroupLanding(data.group);
      this.toastService.createMessage('success', 'New Discussion Updated/Added/Deleted');

    }

  }

  getUserName(user: User): string {

    return Common.formatName(user, true);

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
