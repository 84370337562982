<div
   class="topicSection"
   *ngIf="!viewMode">
   <form [formGroup]="topicForm">
      <div [ngClass]="{ inputInvalidBorder: formControls.topic.invalid && showErrorMsg }">
         <nz-select
            class="topicSelect"
            formControlName="topic"
            (nzFocus)="raiseTouchedEvent()"
            (nzOpenChange)="onDropdownOpenChange($event)"
            nzMode="multiple"
            nzPlaceHolder="Select Topic"
            [nzShowArrow]="true"
            [nzMaxTagCount]="1"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            [nzFilterOption]="filterOption">
            <ng-container *ngIf="formattedTopics?.length > 0">
               <nz-option
                  *ngFor="let topic of formattedTopics"
                  [nzLabel]="topic.formattedLabel"
                  [nzValue]="topic"></nz-option>
            </ng-container>
         </nz-select>
         <ng-template
            #tagPlaceHolder
            let-selectedList>
            + {{ selectedTopics.length - 1 }}
         </ng-template>
         <div
            *ngIf="formControls.topic.invalid && showErrorMsg"
            class="alert alert-danger">
            <div
               class="errorMessage"
               *ngIf="formControls.topic.errors?.required">
               <span>Please select a topic before publishing the story.</span>
            </div>
         </div>
      </div>
   </form>
</div>

<div
   class="viewTopics"
   *ngIf="viewMode">
   <ng-Container
      *ngFor="let topic of selectedTopics; let i = index"
      [ngClass]="{ 'topics-label': true, 'de-active': topic.deleted, 'active': !topic.deleted }">
      <div class="title">{{ topic.topicName }}</div>
      <div
         class="icon"
         *ngIf="topic.deleted">
         <i
            nz-icon
            nzType="info-circle"
            nzTheme="outline"
            nz-tooltip
            nzTooltipPlacement="topRight"
            [nzTooltipTitle]="topicToolTipTemplate"
            [nzTooltipTitleContext]="{ notes: topic.notes }"
            nzTooltipClass="topic-tool-tip"
            class="mr-8"
            (click)="toggleTopicTooltip(i)"
            (mouseover)="showTopicTooltipEvent(i)"
            (mouseout)="hideTopicTooltip()"
            [nzTooltipTrigger]="tooltipTrigger"
            [nzTooltipVisible]="hoveredIndex === i && showTopicToolTip"></i>
      </div>
   </ng-Container>
</div>

<ng-template
   #topicToolTipTemplate
   let-data="notes">
   <span class="tool-tip">
      <div>Notice: Topic update</div>
      <div [innerHtml]="data"></div>
   </span>
</ng-template>
