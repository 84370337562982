import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Common } from '@utilities/common';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-stories-following',
  templateUrl: './stories-following.component.html',
  styleUrls: ['./stories-following.component.scss']
})
export class StoriesFollowingComponent implements OnInit, OnChanges {

  @Input() storyFollowingDetails;

  @Input() filterApplied;

  @Input() screen;

  @Output() isFollowingCollapsed = new EventEmitter();

  @Output() deleteUser = new EventEmitter();

  isViewProfile = false;


  // @Input() storyFollowingDetails = {} as FollowingDetails;
  isStoryFollowing = true;

  storyFollowingData: any = [];

  isFollowingingCollapsed = false;

  getApiURL = '';

  originalAPIData: any;

  originalFollowingDetails: any;

  public readonly Common = Common;

  constructor(
    private cService: CommonService,
    private toastService: ToastService
  ) { }

  ngOnInit() {

    this.isStoryFollowing = true;
    this.getApiURL = environment.getProfileAPIURL;
    if (this.storyFollowingDetails) {

      this.originalFollowingDetails = this.storyFollowingDetails;

    }

  }

  ngOnChanges() {

    if (this.filterApplied && (this.filterApplied.order !== '' || this.filterApplied.value !== '')) {

      this.filterFollowers(this.filterApplied);

    } else if (this.filterApplied && this.filterApplied.value === '') {


      // this.originalFollowingDetails = this.storyFollowingDetails;
      this.filterFollowers(this.filterApplied);

    } else {

      this.originalFollowingDetails = this.storyFollowingDetails;

    }

  }

  filterFollowers(filterApplied) {


    // filter list of users
    this.storyFollowingDetails = Object.assign([], this.originalFollowingDetails).filter(
      (item) => {

        if (item.userId !== null && item.firstName !== null) {

          const fullName = item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase();

          if (fullName.includes(filterApplied.value.toLowerCase())) {

            return item;

          }

        }

      }
    );

    // Order by based upon filter
    this.applyOrder();

  }

  applyOrder() {

    if (this.filterApplied.order === 'asc') {

      this.storyFollowingDetails.sort((a: any, b: any) => {

        if (a.firstName < b.firstName) {

          return -1;

        } else if (a.firstName > b.firstName) {

          return 1;

        } else {

          return 0;

        }

      });

    } else {

      this.storyFollowingDetails.sort((a: any, b: any) => {

        if (a.firstName > b.firstName) {

          return -1;

        } else if (a.firstName < b.firstName) {

          return 1;

        } else {

          return 0;

        }

      });

    }

  }

  deleteProfile(userObj) {

    this.deleteUser.emit({ userObj, typeofRequest: 'DELETE_USER' });

  }

  toggleSider(userId, _index): void {

    this.isFollowingingCollapsed = !this.isFollowingingCollapsed;
    this.getProfileMappingList(userId);

  }

  onClose() {


    // console.log('Slider Close');
    this.isFollowingingCollapsed = false;

  }

  closeFollow() {

    this.isStoryFollowing = false;

  }

  closeDrawer() {


    // this.isFollowingCollapsed.emit(true);
    this.isFollowingingCollapsed = false;

  }

  getProfileMappingList(userId) {

    this.isViewProfile = (userId.toString() !== localStorage.getItem('userId').toString()) ? true : false;
    const url = `${environment.getProfileAPIURL}/${userId.toString()}`;

    this.cService.serviceRequestCommon('get', url).subscribe((res) => {

      this.originalAPIData = JSON.parse(JSON.stringify(res));
      this.storyFollowingData = this.originalAPIData;

    },
    (error) => {

      this.toastService.createMessage('error', error);

    });

  }

}
