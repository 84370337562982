<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<nz-page-header
   class="site-page-header pageHeader"
   nzBackIcon
   (nzBack)="back()">
   <nz-page-header-title class="headerLabel">Back</nz-page-header-title>
</nz-page-header>
<nz-content [attr.data-component]="'ncx/Group/group-versions.component.html'">
   <div class="discussionVersions">
      <nz-table
         #nzTable
         [nzData]="discussionVersions"
         [nzShowPagination]="false">
         <thead>
            <tr>
               <th
                  class="expandable"
                  nzShowExpand></th>
               <th>Version</th>
               <th>State</th>
               <th class="versionChangedByHeading">Changed By</th>
               <th class="versionTimestamp">Time Stamp</th>
               <th class="actionHeading">Action</th>
               <th class="versionMore"></th>
            </tr>
         </thead>
         <tbody>
            <ng-template
               ngFor
               let-data
               [ngForOf]="nzTable.data">
               <tr *ngIf="data.discussionVersionNumber">
                  <td
                     class="expandable"
                     nzShowExpand
                     [(nzExpand)]="mapOfExpandData[data.discussionVersionNumber]"
                     (nzExpandChange)="showMoreData(data.name, data.discussionVersionNumber)"></td>
                  <td>
                     <a
                        (click)="openDiscussion(data.groupId, data.discussionId)"
                        class="versionIdChangedBy">
                        {{ data.discussionVersionNumber }}
                     </a>
                  </td>
                  <td>{{ data.discussionVersionState }}</td>
                  <td class="versionChangedBy">
                     <ng-container *ngIf="data && data.updateUser">
                        <span
                           nz-popover
                           [nzPopoverContent]="profileCardTemplate"
                           [nzPopoverPlacement]="'bottomLeft'"
                           *ngIf="
                              data.updateUser && data.updateUser && data.updateUser.displayName.split(',').length > 1
                           ">
                           <!-- {{discussionDetails.createUserId.firstName | slice:0:1}}.{{discussionDetails.createUserId.lastName}} -->
                           {{ data.updateUser.displayName.split(',')[1] }}
                           {{ data.updateUser.displayName.split(',')[0] }}
                        </span>
                        <span
                           nz-popover
                           [nzPopoverContent]="profileCardTemplate"
                           [nzPopoverPlacement]="'bottomLeft'"
                           *ngIf="
                              data.updateUser && data.updateUser && data.updateUser.displayName.split(',').length === 1
                           ">
                           {{ data.updateUser.displayName.trim() }}
                        </span>
                        <ng-template #profileCardTemplate>
                           <app-profile-overlay [profileData]="data.updateUser"></app-profile-overlay>
                        </ng-template>
                     </ng-container>
                  </td>
                  <td class="versionTimestamp">
                     {{ utcToLocal(data.updateDateTime, 'DATE') | date: 'M/d/yyyy - '
                     }}{{ utcToLocal(data.updateDateTime, 'TIME') }}
                  </td>
                  <td class="versionAction">
                     <a
                        class="versionAction"
                        (click)="deleteVersion(data.discussionId)"
                        *ngIf="data.discussionVersionState !== 'Live'">
                        Delete
                     </a>
                     <nz-divider
                        nzType="vertical"
                        *ngIf="
                           data.discussionVersionState !== 'Live' && data.discussionVersionState !== 'Draft'
                        "></nz-divider>
                     <a
                        class="versionAction"
                        (click)="createVersion(data)"
                        *ngIf="data.discussionVersionState !== 'Live' && data.discussionVersionState !== 'Draft'">
                        Restore
                     </a>
                  </td>
                  <td *ngIf="formatDataGrid()">
                     <div *ngIf="data.discussionVersionState !== 'Live'">
                        <i
                           nz-popover
                           [nzPopoverContent]="contentTemplate"
                           nzPopoverPlacement="left"
                           [nzPopoverTrigger]="'click'"
                           nz-icon
                           nzType="more"
                           nzTheme="outline"></i>
                     </div>
                     <div *ngIf="data.discussionVersionState === 'Live'">
                        <i></i>
                     </div>
                  </td>
                  <ng-template #contentTemplate>
                     <div
                        class="popoverLinks"
                        (click)="deleteVersion(data.discussionId)"
                        *ngIf="data.discussionVersionstate !== 'Live'">
                        Delete
                     </div>
                     <div
                        class="popoverLinks"
                        (click)="createVersion(data)"
                        *ngIf="data.discussionVersionstate !== 'Live' && data.discussionVersionstate !== 'Draft'">
                        Restore
                     </div>
                  </ng-template>
               </tr>
               <tr
                  [nzExpand]="mapOfExpandData[data.discussionVersionNumber]"
                  *ngFor="let item of itemName">
                  <td
                     colspan="24"
                     *ngIf="item === 'timestamp'">
                     Timestamp: {{ utcToLocal(data.updateDateTime, 'DATE') | date: 'M/d/yyyy - '
                     }}{{ utcToLocal(data.updateDateTime, 'TIME') }}
                  </td>
                  <td
                     colspan="24"
                     *ngIf="item === 'changedBy'">
                     <ng-container *ngIf="data && data.updateUser && data.updateUser.displayName.split(',').length > 1">
                        Changed By:
                        <!-- {{data.updateUser}} -->
                        {{ data.updateUser.displayName.split(',')[1] }} {{ data.updateUser.displayName.split(',')[0] }}
                     </ng-container>
                     <ng-container
                        *ngIf="data && data.updateUser && data.updateUser.displayName.split(',').length === 1">
                        Changed By:
                        <!-- {{data.updateUser}} -->
                        {{ data.updateUser.displayName.trim() }}
                     </ng-container>
                  </td>
               </tr>
            </ng-template>
         </tbody>
      </nz-table>
   </div>
</nz-content>
