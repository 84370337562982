<section class='support-tab-body'
         [attr.data-component]="'ncx/support/faq'">

  <app-collapsable title='NewsConnect Search Tips'
                   iconTheme='outline'
                   icon='file-search'>

    <section>

      <p class='mb-16'>When searching for an exact phrase match, put your query within quotation marks.</p>

      <p class='bold'>Utilize Boolean operators:</p>

      <ul class='ml-12'>
        <li><span class='bold'>AND:</span> All keywords must appear in your results. AND will narrow down a search.</li>
        <li><span class='bold'>OR:</span> Either of the keywords must appear in the results. OR will give you more
                                          results.
        </li>
        <li><span class='bold'>NOT:</span> Excludes certain keywords from your results. NOT will narrow down a search.
        </li>
      </ul>

      <nz-divider></nz-divider>

      <p class='bold'>Filters</p>

      <div class='indent'>

        <p>Filters allow you to narrow results to a particular type, such as:</p>

        <ul>
          <li>Video</li>
          <li>News</li>
          <li>Images</li>
          <li>Web</li>
          <li>The "Web" filter contains text-based links to websites.</li>
          <li>The exact filters and the order in which they appear is dynamic. It's based on what our systems might
              think are most helpful to your query. If you don't find a particular filter that you want, use the "All"
              option to check others that are available.
          </li>
        </ul>

      </div>

      <nz-divider></nz-divider>

      <p class='bold'>Preview Pane Results</p>

      <div class='indent'>

        <p>If you enter a search keyword or phrase without hitting "Enter", you'll get a preview pane of search results across content types.</p>

      </div>

    </section>

  </app-collapsable>

  <app-collapsable title='Clearance Language'
                   iconTheme='outline'
                   icon='file-protect'>

    <p class='mb-16'><span class='bold'>Clearing content found on social platforms:</span></p>

    <div class='indent'>

      <p>Please use the following language when seeking permission to use video and photos that are circulating on social platforms. Please contact the <a href='mailto:NBCNewsArchivesRC@nbcuni.com'>Rights and Clearances team</a> with any questions. </p>

      <p>The <span class='bold'>"irrevocable language"</span> is preferred and should be used when communicating with someone privately: </p>

      <ul>
        <li>Did you film this video[/take this photo] yourself? If so, for my official records, will you grant
            NBCUniversal irrevocable non-exclusive permission for unlimited use by all partners, platforms and media,
            worldwide, in perpetuity?
        </li>
      </ul>

      <p>The <span class='bold'>"simple language"</span> should be used when you can only communicate with someone publicly: </p>

      <ul>
        <li>Did you film this video [/take this photo] yourself? If so, will you give NBCU permission to share it on all
            our platforms and partners, with credit to you?
        </li>
      </ul>

    </div>

  </app-collapsable>

  <app-collapsable title='Labels'
                   iconTheme='outline'
                   icon='group'>

    <p><span class='bold'>Reportable, Cleared, Do Not Use</span></p>

    <div class='indent'>

      <p>Editors and Senior Editors can add many different labels to posts:</p>

      <ul>
        <li><span class='bold'>Reportable:</span> Information has been confirmed and can be reported.</li>
        <li><span class='bold'>Cleared:</span> Content has been cleared for use.</li>
        <li><span class='bold'>Copyight Risk:</span> NBC News has been able to verify that the image or video is
                                                     authentic - but
                                                     we have not obtained the rights to use the image or video. It's
                                                     "Copyright
                                                     Risk" which means a Senior Producer should approve its use on
                                                     broadcast or
                                                     digital.
        </li>
        <li><span class='bold'>Do Not Use:</span> Something’s wrong with the information or content - and it should not
                                                  be used.
        </li>

        <li><span class='bold'>Hot:</span> Big breaking news sent out as a Hot Alert.</li>
      </ul>

      <p>In addition to being sent as emails to all users, for the first 30 minutes after they’re sent, Hot Alerts display in red at the top of NCX on desktop and mobile.</p>

      <nz-divider></nz-divider>

      <p><span class='bold'>Only Senior Editors can send out Hot Alerts.</span></p>

      <ul>
        <li><span class='bold'>Important:</span> Major developments in any story.</li>
        <li><span class='bold'>Legal:</span> Legal has weighed in - or has been asked to weigh in.</li>
        <li><span class='bold'>Standards:</span> Standards has weighed in - or has been asked to weigh in.</li>
        <li><span class='bold'>Great Video:</span> Posts with great video.</li>
      </ul>

      <p><span class='bold'>Labels help certain posts stand out.</span></p>

      <p>You can use the sorting functions in NCX to pull out just those posts marked with any label. For example, you can go into any story and look for all posts with Reportable information or Cleared content by sorting the posts in that story for the Reportable or Cleared labels. </p>

      <p>This kind of sorting can be used for any label – and works from any story page or All Activity. There are more details on how to do this in the section called "Actions from a Story Page."</p>

    </div>

  </app-collapsable>

  <app-collapsable title='Angles'
                   iconTheme='outline'
                   icon='folder-view'>

    <p><span class='bold'>What is an angle?</span></p>

    <div class='indent'>
      <p>Angle is a new feature in NewsConnect meant for planning, organizing, and collaboration! It lets you get specific about what you’re working on, who you’re working with, and what content and info is relevant to your assignments.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>How do I use this?</span></p>

    <div class='indent'>
      <p>You can create angles for assignments, projects, reports, or stories you and your team are working on. You can then add existing NewsConnect content to your angle and upload new content you want to use into your angle. </p>
      <p>You can also draft scripts, write notes, outlines, plans, summaries, and other pre-production material within the angle. </p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Who can see my angles?</span></p>

    <div class='indent'>
      <p>Anyone who is logged into NewsConnect can see all created angles. Coming soon, we will have a feature to create private angles.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Do story followers get notified when new angles are created?</span></p>

    <div class='indent'>
      <p>No, for this beta phase of the feature, we have not implemented any new notifications related to angle. You will continue to only get emails, based on your settings, for new posts in stories you follow, from people you follow, Hot, Standards, etc. Interested in angle-related notifications? Submit your ideas here: <a href='https://app.smartsheet.com/b/form/310add75a9d1492fac602f95f0f6228b'
                                                                                                                                                                                                                                                                                                                                       target='_blank'>Feedback Form.</a> </p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Do collaborators get notified/emailed when I add them?</span></p>

    <div class='indent'>
      <p>For our beta phase, angles generate no emails, including when collaborators are added. Interested in angle-related notifications? Submit your ideas here: <a href='https://app.smartsheet.com/b/form/310add75a9d1492fac602f95f0f6228b'
                                                                                                                                                                      target='_blank'>Feedback Form.</a> </p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>What are some examples of angles I could create?</span></p>

    <div class='indent'>

      <p>For the story <a href='/#/ncx/landing-story/:1711435090069'
                          target='_blank'>"Francis Scott Key Bridge in Baltimore Maryland has partially collapsed"</a> consider the following angles: </p>
      <ul>
        <li>Rescue Efforts/Missing Persons</li>
        <li>Other Bridge Notable Collapses</li>
        <li>Structurally Deficient Bridges, - ARTBA</li>
      </ul>

      <p>For the story <a href='/#/ncx/landing-story/:9983'
                          target='_blank'>"Paris 2024 Olympics"</a> consider the following angles: </p>

      <ul>
        <li>Profiles of Athletes - US Team</li>
        <li>Construction & Economics of the Athlete Village</li>
        <li>Olympics Sponsorships & Athlete Appearances/Endorsements</li>
      </ul>

    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Where do angles appear in NewsConnect?</span></p>

    <div class='indent'>
      <p>You can find angles on the new tab on story landing page and via the NewsConnect global search feature (the large magnifying glass). At this time, we are not showing Angles on the All Activity, Hot, and Standards pages, the Stories homepage, or the History tab within user profiles.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>I noticed since this release the post/element/summary dropdown when I publish a post is gone.  How do I publish an Element or Summary?</span></p>

    <div class='indent'>
      <p>All Post vs Element categorization now happens automatically! If you upload any file, add any information into the Element Details section, or include a REM or MediaID in the description of your post, NewsConnect will automatically classify your post as an element! </p>
      <p>If you’re trying to classify something as an element but the file is too big to upload, put the link to the element in the Link to Source field and NCX will take care of the rest. </p>
      <p>We have retired the Summary feature. You can still use the summary templates in the body of your post, but we no longer have filters or features for manual summaries. More to come soon on automated summaries! </p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Can I follow an angle?  Or add posts to angles via email?</span></p>

    <div class='indent'>
      <p>No, currently you cannot follow angles or post to them via email.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>I have ideas for more features or improvements!  Who do I talk to about them?</span></p>

    <div class='indent'>
      <p>This feature is in Beta, which means we would LOVE to hear from you! What do you like, what’s hard to use, what would be great to see next. NewsConnect is a fully internally built and managed application, so we have a lot of flexibility to mold it to our needs. If you have any ideas, big or small, please submit them here: <a href='https://app.smartsheet.com/b/form/310add75a9d1492fac602f95f0f6228b'
                                                                                                                                                                                                                                                                                                                                                target='_blank'>Feedback Form.</a> </p>
    </div>

  </app-collapsable>

</section>

