<div class="alerts">
   <app-loader-component
      loaderType="SECTION_LOADER"
      [isLoaded]="!isLoading"></app-loader-component>

   <!-- LIST OF ALERTS -->

   <app-render-alerts
      *ngFor="let alert of alertList"
      [alert]="alert"
      [buttons]="false"></app-render-alerts>

   <!-- BUTTONS -->

   <nz-button-group
      class="button-group"
      *ngIf="alertList?.length">
      <button
         nz-button
         (click)="clearAlerts()">
         Clear Alerts
      </button>
      <button
         nz-button
         (click)="viewAlerts()">
         See More
      </button>
   </nz-button-group>

   <!-- ERROR MESSAGES -->

   <ng-container *ngIf="!isLoading">
      <div *ngIf="!alertList?.length">
         <nz-empty [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
               <span>No Recent Alerts</span>
            </ng-template>
         </nz-empty>
      </div>

      <div *ngIf="alertError">
         <nz-empty [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
               <span>There was an error loading Alerts</span>
            </ng-template>
         </nz-empty>
      </div>
   </ng-container>
</div>
