import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFunctionAbility } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { CSSThemeService } from '@services/css-theme.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { environment } from 'src/environments/environment';
import { TransferInformationComponent } from '../TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-linked-stories',
  templateUrl: './linked-stories.component.html',
  styleUrls: ['./linked-stories.component.scss']
})
export class LinkedStoriesComponent implements OnInit, OnChanges, AfterViewChecked {

  value = '';

  options: string[] = [];

  @Input() postRelatedStories = [];

  @Input() isPinnedOnTop;

  @Output() addedStories = new EventEmitter();

  @Input() showCrown: boolean = true;

  @Input() showPin: boolean = true;

  @Input() disableSearch: boolean = false;

  @Input() showStoryChangeInfo: boolean = true;

  @Input() tooltip: string = '';

  @Input() showMandatoryAsterisk: boolean = true;

  @Input() highlightMandatoryText: boolean = true;

  @Input() mandatoryMsg: string = '';

  getStoryTitlesAPI;

  listofStories = [];

  emptyStories = ['No Stories Found'];

  relatedStoryTitles = [];

  filteredOptions: string[] = [];

  filteredOptionsInit: any[];

  isLightMode: boolean = false;

  isMobile: boolean = false;

  showToolTip: boolean = false;

  tooltipTrigger = 'manual';

  subject: Subject<string> = new Subject();

  public functionAbility = {} as IFunctionAbility;

  constructor(
    private cService: CommonService,
    private tI: TransferInformationComponent,
    private themeService: CSSThemeService,
    private breakpointService: BreakpointService
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.getStoryTitlesList(searchTextValue);

    });

  }

  ngOnInit() {

    this.getStoryTitlesAPI = environment.getStoryTitles;
    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;
    console.log('related stories', this.postRelatedStories);

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

  }

  ngAfterViewChecked() {

    this.isLightMode = this.themeService.currentTheme !== 'dark';

  }

  ngOnChanges(changes: SimpleChanges) {

    if ('postRelatedStories' in changes) {

      const newValue = changes.postRelatedStories.currentValue || [];

      this.relatedStoryTitles = newValue.map((story) => {

        return {
          storyId: story.storyId,
          storyTitle: (story && story.storyTitle) ? story.storyTitle : '',
          isPrimary: story.isPrimary,
          isPinnedOnTop: this.isPinnedOnTop,
          topicDetails: story.topicDetails
        };

      });

    }

  }

  resetOptions() {

    this.filteredOptions = [];
    this.filteredOptionsInit = [];
    this.listofStories = [];

  }

  getStoryTitlesList(queryStr?) {

    let params;

    if (queryStr) {

      params = `?storyTitle=${queryStr}`;

    } else {

      this.filteredOptions = [];
      return;

    }
    this.cService.serviceRequestCommon('get', this.getStoryTitlesAPI, params).subscribe((data: any) => {

      const tempStoiesObj = data.map((story) => {

        return {
          storyId: story.storyId,
          storyTitle: story.storyTitle,
          isPrimary: false,
          isPinnedOnTop: this.isPinnedOnTop,
          topicDetails: story.topicDetails,
          storyAccess: story.storyAccess,
          createUserId: story.createUserId
        };

      });

      this.listofStories = JSON.parse(JSON.stringify(tempStoiesObj));
      this.filteredOptions = data.length ? this.listofStories.map((story) => story.storyTitle) : this.emptyStories;
      this.filteredOptionsInit = data.length ? this.listofStories.map((story) => story.storyTitle) : this.emptyStories;

    });

  }

  titleMatch(value) {

    const obj = this.listofStories.filter(story => story.storyTitle === value)[0];

    const obj1 = this.relatedStoryTitles.filter(story => story.storyTitle === value)[0];

    if ((!(obj === null || obj === undefined) && (obj1 === null || obj1 === undefined))) {

      if (!(this.relatedStoryTitles && this.relatedStoryTitles.length)) {

        obj.isPrimary = true;

      }
      obj.isPinnedOnTop = this.isPinnedOnTop;
      this.relatedStoryTitles.push(obj);
      this.mapAdditionalStoryToPost();
      setTimeout(() => {

        this.value = '';

      }, 100);

    }
    if (this.filteredOptionsInit && this.filteredOptionsInit.length) {

      this.filteredOptions = [...this.filteredOptionsInit];

    }

  }

  onChange(value: string, event: any): void {

    setTimeout(() => {

      this.subject.next(event.target.value);

    }, 200);

  }

  assignValues(val) {

    if (!val) {

      this.filteredOptions = this.filteredOptionsInit;

    }
    this.filteredOptions = this.filteredOptionsInit.filter((item) => {

      if (item !== null && item !== undefined) {

        const storyTitle = item.toLowerCase();

        // console.log('storyTitle ', value, event);
        if (storyTitle.includes(val.toLowerCase())) {

          return item;

        }

      }

    });

  }

  mapAdditionalStoryToPost() {

    this.addedStories.emit({ relatedStories: this.relatedStoryTitles, isPinnedOnTop: this.isPinnedOnTop });
    this.resetOptions();

  }

  pinStory(storyId, value) {

    this.isPinnedOnTop = !value;
    this.relatedStoryTitles = this.relatedStoryTitles.map(element => {

      element.isPinnedOnTop = !value;
      return element;

    });
    this.mapAdditionalStoryToPost();

  }

  postAsPrimaryStory(storyId) {

    this.relatedStoryTitles.map((element) => {

      if (element.storyId === storyId) {

        element.isPrimary = true;

        //(document.getElementsByClassName('primaryStory')[index] as HTMLElement).style.backgroundColor = '#1890FF';

      } else {

        element.isPrimary = false;

        //(document.getElementsByClassName('primaryStory')[index] as HTMLElement).style.backgroundColor = '#fff';

      }

    });
    this.mapAdditionalStoryToPost();

  }

  deleteRelatedStories(index, storyId) {

    this.relatedStoryTitles.forEach((element, itemindex) => {

      if (storyId === element.storyId) {

        this.relatedStoryTitles.splice(itemindex, 1);

      }

    });
    if (index.isPrimary) {

      if (this.relatedStoryTitles && this.relatedStoryTitles.length) {

        this.relatedStoryTitles[0].isPrimary = true;

      }

    }
    if (this.relatedStoryTitles.length == 0) {

      this.isPinnedOnTop = false;

    }

    this.addedStories.emit({ relatedStories: this.relatedStoryTitles, isPinnedOnTop: this.isPinnedOnTop });
    this.resetOptions();

  }

  toggleTooltip() {

    if (this.isMobile) {

      this.showToolTip = !this.showToolTip;

    }

  }

  showTooltipEvent() {

    if (!this.isMobile && !this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = true;

      }, 300);

    }

  }

  hideTooltip() {

    if (!this.isMobile && this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = false;

      }, 300);

    }

  }

}
