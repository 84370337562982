import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { CommonService } from './common-service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  public getSocketData: Subject<{}> = new Subject<{}>();

  public getBannerSocketData: Subject<{}> = new Subject<{}>();

  public stompClient = null;

  private subscriptionWs: Subscription = new Subscription();

  private pageCode = '';

  constructor(private cService: CommonService) {}

  get doSocketData$(): Observable<any> {

    return this.getSocketData.asObservable();
  
  }

  get doBannerSocketData$(): Observable<any> {

    return this.getBannerSocketData.asObservable();
  
  }

  switchPageCode(pageCode, that) {

    let returnCheck = false;

    if (this.pageCode === pageCode) {

      return true;
    
    }
    if (this.stompClient !== null && this.subscriptionWs !== null) {

      this.pageCode = pageCode;
      returnCheck = true;
      this.subscriptionWs.unsubscribe();
      this.subscriptionWs = this.stompClient.subscribe(environment.webSocketNotificationUrl, function(data) {

        const parsedData = JSON.parse(data.body);

        that.getSocketData.next(parsedData);
        that.getBannerSocketData.next(parsedData);
        console.info(`Subscription state for page code ${pageCode}`, { parsedData });
      
      }, { pageCode });
    
    }
    return returnCheck;
  
  }

  connect(pageCode) {

    const that = this;

    const ncxjwttoken = localStorage.getItem('ncxjwttoken');

    const checkConnection = this.switchPageCode(pageCode, this);

    console.info(`Connection state for page code ${pageCode}`, { checkConnection });
    if (ncxjwttoken && !checkConnection || this.stompClient === null) {

      this.pageCode = pageCode;
      let wSUrl = this.cService.getWebSocketEndpoint();

      wSUrl += environment.webSocketUrl;
      const socket = new SockJS(wSUrl);

      this.stompClient = null;
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect({ ncxjwttoken: `${ncxjwttoken}`, pageCode }, function(frame) {

        that.subscriptionWs = that.stompClient.subscribe(environment.webSocketNotificationUrl, function(data) {

          const parsedData = JSON.parse(data.body);

          that.getSocketData.next(parsedData);
          that.getBannerSocketData.next(parsedData);
          console.info(`Subscription state for page code ${pageCode}`, { parsedData });
        
        });
      
      }, () => {

        setTimeout(() => {

          this.stompClient = null;
          this.connect(pageCode);
        
        }, 5000);
      
      });
    
    }
  
  }

  sendMessage(message) {

    if (this.stompClient !== null && this.stompClient.connected) {

      this.stompClient.send(environment.webSocketNotificationUrl, {}, message);
    
    }
  
  }

  disconnect() {

    if (this.stompClient !== null && this.stompClient.connected) {

      this.stompClient.disconnect();
      this.stompClient = null;
    
    }
  
  }

}
