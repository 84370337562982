<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/share/post'">
         <p class="header">Share Content</p>

         <div class="body">
            <section>
               <p class="form-label">Who do you want to email?</p>
               <div class="form-element">
                  <nz-input-group
                     nzSearch
                     [nzAddOnAfter]="suffixIconButton">
                     <input
                        placeholder="Enter Name"
                        nz-input
                        #input
                        [(ngModel)]="searchValue"
                        (keydown)="onSearchEmails(input.value, $event)"
                        (ngModelChange)="titleMatch($event)"
                        [nzAutocomplete]="auto" />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                     <button
                        nz-button
                        nzSearch>
                        <i
                           nz-icon
                           nzType="search"
                           nzTheme="outline"></i>
                     </button>
                  </ng-template>
                  <nz-autocomplete #auto>
                     <ng-container *ngFor="let user of userListNames">
                        <nz-auto-option [nzValue]="user.userId">{{ user.fullName }}</nz-auto-option>
                     </ng-container>
                  </nz-autocomplete>
               </div>
               <div class="tags-container">
                  <span *ngFor="let user of userEmailList">
                     <nz-tag
                        nzMode="closeable"
                        (nzOnClose)="removeUser(user.userId)">
                        {{ user.fullName }}
                     </nz-tag>
                  </span>
               </div>
            </section>

            <section>
               <p class="form-label">Message</p>
               <div class="form-element">
                  <textarea
                     nz-input
                     placeholder="Checkout this content in NewsConnect"
                     [(ngModel)]="message"></textarea>
               </div>
               <p class="form-hint">Max Character Limit 765</p>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="onFinish()">
         Submit
      </button>
   </ng-template>
</nz-modal>
