<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/limited-license'">
         <p class="header">License Limitations</p>

         <div class="body">
            <section>
               <textarea
                  rows="6"
                  nz-input
                  placeholder="List all license limitations here"
                  [(ngModel)]="postLimitedLicenseContent.additionalNotes"
                  (ngModelChange)="onChange($event, 'additionalNotes')"></textarea>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         [disabled]="!isLoaded"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="handleOk()">
         Submit
      </button>
   </ng-template>
</nz-modal>
