<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout [attr.data-component]="'ncx/profile-section/history/history.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title>
         <nz-button-group>
            <button
               nz-button
               (click)="switchTab('Profile')">
               Profile
            </button>
            <button
               nz-button
               (click)="switchTab('History')"
               nzType="primary">
               History
            </button>
         </nz-button-group>
      </nz-page-header-title>
   </nz-page-header>

   <nz-layout>
      <section class="profile">
         <div class="container">
            <div class="avatar">
               <nz-avatar
                  *ngIf="avatarImgUrl !== '' && avatarImgUrl !== null"
                  [nzSize]="40"
                  [nzSrc]="avatarImgUrl"
                  nzIcon="user"></nz-avatar>
               <span class="name">{{ profileName }}</span>
            </div>

            <!-- TOGGLE SEARCH BAR -->
            <button
               nz-button
               *ngIf="isMobile"
               (click)="toggleSearch()"
               [nzType]="isSearchVisible ? 'primary' : 'default'">
               <i
                  nz-icon
                  nzType="file-search"></i>
            </button>

            <!-- SEARCH BAR DESKTOP -->
            <ng-container *ngIf="!isMobile">
               <ng-container
                  [ngTemplateOutlet]="searchBar"
                  [ngTemplateOutletContext]="{ isMobile: isMobile }"></ng-container>
            </ng-container>
         </div>

         <!-- SEARCH BAR MOBILE -->
         <ng-container *ngIf="isMobile && isSearchVisible">
            <ng-container
               [ngTemplateOutlet]="searchBar"
               [ngTemplateOutletContext]="{ isMobile: isMobile }"></ng-container>
         </ng-container>

         <!-- COMMON SEARCH BAR -->
         <ng-template
            #searchBar
            let-isMobile="isMobile">
            <div [ngClass]="{ 'search': true, 'mobile-search': isMobile }">
               <nz-input-group [nzSuffix]="!isMobile ? searchIcon : null">
                  <input
                     #searchText
                     type="text"
                     nz-input
                     placeholder="Search history here..."
                     [(ngModel)]="searchPostInput"
                     (keyup)="searchHistory(searchText.value)" />
               </nz-input-group>

               <button
                  nz-button
                  *ngIf="isMobile"
                  nzType="primary"
                  (click)="searchHistory(searchText.value)">
                  Search
               </button>

               <ng-template #searchIcon>
                  <button (click)="this.searchPostInput ? clearSearch() : undefined">
                     <i
                        nz-icon
                        [nzType]="this.searchPostInput ? 'close' : 'search'"></i>
                  </button>
               </ng-template>
            </div>
         </ng-template>
      </section>

      <nz-layout
         class="feedLayout"
         id="storycontainerId"
         infiniteScroll
         [infiniteScrollDisabled]="!canScrollMore"
         [infiniteScrollDistance]="2"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false">
         <ncx-story
            *ngFor="let story of activityDetails"
            [story]="story"></ncx-story>

      <div class="notFoundCard"
           *ngIf="isLoaded && !activityDetails.length && storyDataLoaded">
        <nz-empty [nzNotFoundContent]="contentTpl">
          <ng-template #contentTpl>
            <span>No Results Found</span>
          </ng-template>
        </nz-empty>
      </div>

    </nz-layout>
  </nz-layout>
</nz-layout>
