import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastService } from '@services/toastService/toastMessage.service';
import { PostReportable } from '@models/ncx/post-reportable';

@Component({
  selector: 'app-reportable-modal',
  templateUrl: './reportable.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class ReportableModalComponent implements OnInit {

  @Input() isVisible: boolean = false;

  @Input() isLoaded: boolean = true;

  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Output() updated: EventEmitter<PostReportable> = new EventEmitter<PostReportable>();

  postReportableContent: PostReportable = {} as PostReportable;

  isValid: boolean;

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit() {

    this.postReportableContent = {
      isOfficial: 'No',
      seniorApprovalName: '',
      additionalNotes: ''
    };
  
  }

  handleCancel() {

    this.cancelled.emit();
  
  }

  handleOk() {

    console.log('Updated content', this.postReportableContent);

    if (this.postReportableContent.isOfficial === 'Yes') {

      this.postReportableContent.seniorApprovalName = 'From an official named source, no need for senior approval';
      this.updated.emit(this.postReportableContent);
    
    } else if (this.postReportableContent.isOfficial === 'No' && this.postReportableContent.seniorApprovalName === '') {

      this.toastService.createMessage('warning', 'Provide senior approver name to publish');
    
    } else if (this.postReportableContent.isOfficial === 'No' && this.postReportableContent.seniorApprovalName !== '') {

      this.updated.emit(this.postReportableContent);
    
    }
  
  }

  onChange(value, type) {

    console.log('Reportable : ', value, type);

    if (type === 'isOfficial' && value === 'Yes') {

      this.postReportableContent.seniorApprovalName = 'From an official named source, no need for senior approval';
    
    } else if (type === 'isOfficial' && value === 'No') {

      this.postReportableContent.seniorApprovalName = '';
      this.toastService.createMessage('warning', 'Provide senior approver name to publish');
    
    }

  }

}
