<div class="media-central-section">
  <div class="media-central-title">Sent to WG01/06</div>
  <div *ngFor="let obj of sendToMediaCentralHistory"
       class="media-central-inner-section">
    <div class="media-central-details">
      <div class="media-central-label">Media ID: </div>
      <div class="media-central-text media-central-id">
        {{ obj.mediaId || ''}}
      </div>
    </div>
    <div class="media-central-details">
      <div class="media-central-label">File: </div>
      <button class="media-central-text media-central-link"
              (click)="openLightbox(obj.attachmentIndex)">{{ obj.attachmentName }}</button>
    </div>
    <div class="media-central-details">
      <div class="media-central-label">Sent By:</div>
      <div class="media-central-sent-text">

        <button class="media-central-text media-central-link"
                nz-popover
                [nzPopoverContent]="profileCardTemplate"
                [nzPopoverPlacement]="'bottomLeft'">
          {{Common.formatName(
          { firstName: obj.sentToPamUserFirstName, lastName: obj.sentToPamUserLastName, displayName: '' })}}

               <!-- profile -->
               <ng-template #profileCardTemplate>
                  <app-profile-overlay [profileData]="{ userId: obj.sentToPamUserId }"></app-profile-overlay>
               </ng-template>
            </button>
            <span *ngIf="obj.pamDateTime">&nbsp;{{ milliSeconsToLocal(obj.pamDateTime) }}</span>
         </div>
      </div>
   </div>
</div>
