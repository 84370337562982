import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';

type NBCApp = 'guest-tracker' | 'ncx' | 'news-search' | 'producer-dashboard';

@Component({
  selector: 'app-bento-box',
  templateUrl: './bento-box.component.html',
  styleUrls: ['./bento-box.component.scss'],
})
export class BentoBoxComponent {

  // Links to show as options
  @Input() links: NBCApp[] = ['guest-tracker', 'ncx', 'news-search', 'producer-dashboard'];

  // Links to disable
  @Input() disabled: NBCApp[] = [];

  constructor(
    private gaService: GoogleAnalyticsEventService,
  ) {}

  /**
   * Open selected NBC app for the environment
   *
   */
  openNBCApp(key: NBCApp) {

    let url;

    const { environmentName } = environment;

    switch (key) {

      case 'guest-tracker':
        if (environmentName === 'prod' || environmentName === 'local') {
          url = 'https://guesttracker.nbcuni.com';
        } else if (environmentName === 'stg' || environmentName === 'stgrts') {
          url = 'https://stg.guesttracker.nbcuni.com';
        } else if (environmentName === 'qa' || environmentName === 'qarts') {
          url = 'https://qa.guesttracker.nbcuni.com';
        } else if (environmentName === 'dev' || environmentName === 'devrts') {
          url = 'https://dev.guesttracker.nbcuni.com';
        }
        break;

      case 'ncx':
        if (environmentName === 'prod' || environmentName === 'local') {
          url = 'https://newsconnect.nbcuni.com';
        } else if (environmentName === 'stg') {
          url = 'https://stgui.newsconnect.nbcuni.com';
        } else if (environmentName === 'qa') {
          url = 'https://qaui.newsconnect.nbcuni.com';
        } else if (environmentName === 'dev') {
          url = 'https://devui.newsconnect.nbcuni.com';
        } else if (environmentName === 'stgrts') {
          url = 'https://stg.newsconnect.nbcuni.com';
        } else if (environmentName === 'qarts') {
          url = 'https://qa.newsconnect.nbcuni.com';
        } else if (environmentName === 'devrts') {
          url = 'https://dev.newsconnect.nbcuni.com';
        }
        break;

      case 'news-search':
        if (environmentName === 'prod' || environmentName === 'local') {
          url = 'https://unifiedsearch.nbcuni.com';
        } else if (environmentName === 'stg') {
          url = 'https://stg.unifiedsearch.nbcuni.com';
        } else if (environmentName === 'qa') {
          url = 'https://qa.unifiedsearch.nbcuni.com';
        } else if (environmentName === 'dev') {
          url = 'https://dev.unifiedsearch.nbcuni.com';
        }
        break;

      case 'producer-dashboard':
        if (environmentName === 'prod' || environmentName === 'local') {
          url = 'https://producerdashboard.nbcuni.com';
        } else if (environmentName === 'stg') {
          url = 'https://stgui.producerdashboard.nbcuni.com';
        } else if (environmentName === 'qa') {
          url = 'https://qaui.producerdashboard.nbcuni.com';
        } else if (environmentName === 'dev') {
          url = 'https://devui.producerdashboard.nbcuni.com';
        } else if (environmentName === 'stgrts') {
          url = 'https://stg.producerdashboard.nbcuni.com';
        } else if (environmentName === 'qarts') {
          url = 'https://qa.producerdashboard.nbcuni.com';
        } else if (environmentName === 'devrts') {
          url = 'https://dev.producerdashboard.nbcuni.com';
        }
        break;

    }

    if (url) {
      const win = window.open();
      if (win) {
        win.opener = null;
        win.location.href = url;
        this.gaService.eventEmitter('Bento Box', `Open ${key}`, url);
      }
    }

  }

}
