import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumn'
})
export class FilterColumnPipe implements PipeTransform {

  transform(items: any[], searchText: string, TagName: string): any {

    if (!items) {

      return [];
    
    }
    if (!searchText) {

      return items;
    
    }
    if (typeof searchText === 'string') {

      searchText = searchText.toLocaleLowerCase();
    
    }

    if (TagName === 'Person') {

      return items.filter(it => {

        return it.fullName.toLocaleLowerCase().includes(searchText);
      
      });
    
    }
    if (TagName === 'Group') {

      return items.filter(it => {

        return it.groupName.toLocaleLowerCase().includes(searchText);
      
      });
    
    }
    if (TagName === 'Topic') {


      // tslint:disable-next-line:no-string-literal
      return items.filter(it => {

        return it.topicName.toLocaleLowerCase().includes(searchText);
      
      });
    
    }
    return items.filter(it => {

      return it.storyTitle.toLocaleLowerCase().includes(searchText);
    
    });

  }

}
