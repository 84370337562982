<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<app-standard-header
   title="Back"
   [defaultBackAction]="true"></app-standard-header>

<div
   class="followingHeading"
   [attr.data-component]="'ncx/following/connections'">
   <span>{{ name }}:</span>
   <span class="followers">
      <nz-radio-group
         [(ngModel)]="radioValue"
         [nzButtonStyle]="'solid'"
         (ngModelChange)="toggleConnections($event)">
         <label
            nz-radio-button
            nzValue="following">
            Following
         </label>
         <label
            nz-radio-button
            nzValue="followers">
            Followers
         </label>
      </nz-radio-group>
   </span>
</div>
<div
   *ngIf="users && users.length"
   class="followersCard">
   <nz-card
      *ngFor="let index of users"
      class="followingCard">
      <div
         class="viewProfileLeft"
         *ngIf="index.displayName.split(',').length > 1">
         <nz-card-meta
            nz-popover
            (mouseover)="handleMouseOver($event, '', 'Left')"
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="placement"
            [nzAvatar]="avatarTemplate"
            nzTitle="{{ index.displayName.split(',')[1] }} {{ index.displayName.split(',')[0] }}"
            nzDescription="{{ index.jobTitle }}"></nz-card-meta>
      </div>
      <div
         class="viewProfileLeft"
         *ngIf="index.displayName.split(',').length === 1">
         <nz-card-meta
            nz-popover
            (mouseover)="handleMouseOver($event, '', 'Left')"
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="placement"
            [nzAvatar]="avatarTemplate"
            nzTitle="{{ index.displayName.trim() }}"
            nzDescription="{{ index.jobTitle }}"></nz-card-meta>
      </div>
      <div class="followers">
         <button
            *ngIf="checkFollowing(index.userId)"
            (click)="followUser(index, index.userId, 'delete')"
            nz-button
            nzType="default">
            Unfollow
         </button>
         <button
            *ngIf="!checkFollowing(index.userId)"
            (click)="followUser(index, index.userId, 'post')"
            nz-button
            nzType="primary">
            Follow
         </button>
      </div>
      <ng-template #avatarTemplate>
         <nz-avatar
            *ngIf="index.profilePictureURL !== '' && index.profilePictureURL !== null"
            [nzSize]="48"
            [nzSrc]="Common.formS3Link(index.profilePictureURL)"></nz-avatar>
         <nz-avatar
            *ngIf="
               index.profilePictureURL === '' ||
               index.profilePictureURL === null ||
               index.profilePictureURL === undefined
            "
            [nzSize]="48"
            nzIcon="user"></nz-avatar>
      </ng-template>
      <ng-template #profileCardTemplate>
         <app-profile-overlay
            [profileData]="index"
            [screenType]="screenType"
            (emitFollow)="awaitFollow(index.userId, $event)"></app-profile-overlay>
      </ng-template>
   </nz-card>
</div>
