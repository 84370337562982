import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Group } from '@models/ncx/groups-discussions';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from '@environments/environment';
import { Common } from '@utilities/common';

@Component({
  selector: 'app-manage-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss']
})
export class ManageMembersComponent implements OnInit {

  isLoaded: boolean;

  getApiURL: any;

  groupId: any;

  groupFollowers;

  groupDetails: Group;

  searchInput;

  invitePeople = false;

  testVar = '#';

  selectedIndex = 0;

  isAdminUser: boolean;

  inviteURL: any;

  groupDetailsInit: any;

  requestList: any[];

  invitationList: any[];

  currentSelectedTab;

  invitationListInit: any;

  requestListInit: any;

  groupFollowersInit: any;

  isAdminOrOwner: any;

  isPrivateGroupFlag: any;

  public readonly Common = Common;

  constructor(
    private router: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private uS: UtilityService,
    private reRoute: Router
  ) { }

  ngOnInit() {

    this.getApiURL = environment.getGroupsURL; // 'assets/jsons/groupMockData.json';
    this.inviteURL = environment.getGroupsURL;
    this.currentSelectedTab = 0;
    this.router.params.subscribe(
      (params: Params) => {

        this.setParams(params);

      });

  }

  setParams(params) {

    this.isLoaded = true;
    this.isPrivateGroupFlag = false;
    const id = params.groupId;

    this.groupId = id.substr(1);
    this.getGroupDetailsByCategory(this.groupId, 'MEMBER');
    this.getGroupDetailsByCategory(this.groupId, 'INVITATION');
    this.getGroupDetailsByCategory(this.groupId, 'REQUEST');

  }

  onTabChnage(tab) {

    this.currentSelectedTab = tab;

  }

  fetchMemberList(groupMembership) {

    const users = [];

    groupMembership.forEach(element => {

      users.push(element.userId);

    });
    return users;

    // this.groupFollowers = users;

  }

  checkAdminAceess() {

    this.isAdminUser = false;
    const userId = localStorage.getItem('userId');

    this.groupDetails.groupMembership.forEach((member) => {

      if (member && member.userId && member.userId.userId) {

        // tslint:disable-next-line:max-line-length
        if (parseFloat(member.userId.userId) === parseFloat(userId) && (member.membershipType === 'ADMIN' || member.membershipType === 'OWNER')) {

          this.isAdminUser = true;

        }

      }

    });

  }

  assignValues(groupObj, tab) {

    switch (tab) {

    case 'MEMBER':
      this.groupFollowers = [];
      this.groupDetails = JSON.parse(JSON.stringify(groupObj));
      this.groupDetailsInit = JSON.parse(JSON.stringify(groupObj.groupMembership));
      this.groupFollowers = this.fetchMemberList(groupObj.groupMembership);
      this.groupFollowersInit = JSON.parse(JSON.stringify(this.groupFollowers));

      // this.checkAdminAceess();
      if (this.groupDetails && this.groupDetails.groupMembership && this.groupDetails.groupMembership.length) {

        this.isAdminOrOwner = this.uS.hasAdminAccess(this.groupDetails.groupMembership);

      }
      break;
    case 'INVITATION':
      this.invitationList = [];
      this.invitationList = [...this.fetchMemberList(groupObj.groupMembership)];
      this.invitationListInit = JSON.parse(JSON.stringify(this.invitationList));
      break;
    case 'REQUEST':
      this.requestList = [];
      this.requestList = this.fetchMemberList(groupObj.groupMembership);
      this.requestListInit = JSON.parse(JSON.stringify(this.requestList));
      break;
    case 'default':
      break;

    }

  }

  addMembers() {

    this.invitePeople = true;

  }

  deleteUser(eventObj) {

    this.deleteRequest(eventObj.userObj, eventObj.typeofRequest);

  }

  closeMembers() {

    this.invitePeople = false;

  }

  goToProfile(_userId) {
  }

  onChange(value, _event) {

    setTimeout(() => {

      this.filterFollowers(value);

    }, 100);

  }

  filterFollowers(value) {

    // filter list of users
    const groupUsers = [];

    let memberList = [];

    switch (this.currentSelectedTab) {

    case 0:
      memberList = this.groupFollowersInit;
      break;
    case 1:
      memberList = this.invitationListInit;
      break;
    case 2:
      memberList = this.requestListInit;
      break;
    case 'default':
      break;

    }
    memberList.forEach((item) => {

      if (item.userId !== null && item.firstName !== null) {

        const fullName = item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase();

        if (fullName.includes(value.toLowerCase())) {

          groupUsers.push(item);

        }

      }

    });
    switch (this.currentSelectedTab) {

    case 0:
      this.groupFollowers = groupUsers;
      break;
    case 1:
      this.invitationList = groupUsers;
      break;
    case 2:
      this.requestList = groupUsers;
      break;
    case 'default':
      break;

    }

    // this.groupDetails = groupUsers;
    // this.groupFollowers = groupUsers;
    // this.groupDetails = Object.assign([], this.groupDetailsInit).filter(
    //   (item) => {
    //     if (item.userId !== null && item.userId.firstName !== null) {
    //       const fullName = item.userId.firstName.toLowerCase() + ' ' + item.userId.lastName.toLowerCase();
    //       if (fullName.includes(value.toLowerCase())) {
    //         return item;
    //       }
    //     }
    //     }
    //   );

  }

  refreshMemberList(event) {

    if (event) {

      this.getGroupDetailsByCategory(this.groupId, 'INVITATION');

    }

  }

  createPayload(userId, typeOfRequest) {

    const userObj = {
      groupId: this.groupId,
      userId,

      // tslint:disable-next-line:max-line-length
      invitationNote: `You’ve been invited to join ${this.groupDetails.groupName} . Go to your Newsconnect Alerts page to accept this invitation .`,
      membershipCategory: (typeOfRequest === 'INVITATION') ? 'INVITATION' : 'MEMBER',
      membershipType: 'MEMBER',
      membershiptInvitationToken: null,
      isDeleted: false
    };

    return userObj;

  }

  getGroupDetailsByCategory(groupId, tab) {

    this.isLoaded = false;
    const queryStr = `/${groupId}/memberDetails?category=${tab}`; // members category='MEMBER'

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.assignValues(res, tab);

      // if (res && res.groupMembership && res.groupMembership.length) {
      //   this.isAdminOrOwner = this.uS.hasAdminAccess(res.groupMembership);
      // }
      // console.log('getGroupDetailsByCategory : ', this.isAdminOrOwner, res.isUserMember);
      // if (!this.isAdminOrOwner && !res.isUserMember) {
      //   this.toastService.createMessage('warning', 'You do not have Permission to access this group');
      //   setTimeout(() => {
      //     this.reRoute.navigate((['/ncx/group-landing']));
      //   }, 1000);
      //   this.isLoaded = true;
      // }
      this.isLoaded = true;

    }, err => {

      if (err === 'GROUP-003' && !this.isPrivateGroupFlag) {

        this.isPrivateGroupFlag = true;
        this.toastService.createMessage('error', 'You Do Not Have Access to the Group');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/group-landing']);

        }, 500);

      } else if (err !== 'GROUP-003') {

        this.toastService.createMessage('error', err);

      }
      this.isLoaded = true;

    });

  }

  sendRequest(userId, typeOfRequest) {

    const queryStr = `/${this.groupId}/members`;

    const payload = this.createPayload(userId, typeOfRequest);

    this.cService.serviceRequestCommon('put', this.inviteURL, queryStr, payload).subscribe(() => {

      if (typeOfRequest === 'INVITATION') {

        this.toastService.createMessage('success', 'Invite Successfully sent.');
        this.getGroupDetailsByCategory(this.groupId, 'INVITATION');

      } else {

        this.toastService.createMessage('success', 'Request Successfully Accepted.');
        this.getGroupDetailsByCategory(this.groupId, 'REQUEST');
        this.getGroupDetailsByCategory(this.groupId, 'MEMBER');

      }

    }, () => {

      if (typeOfRequest === 'INVITATION') {

        this.toastService.createMessage('error', 'Error in Sending Invite. Please try again.');

      } else {

        this.toastService.createMessage('error', 'Error in Accepting Request. Please try again.');

      }

    });

  }

  deleteRequest(userObj, typeOfRequest) {

    this.isLoaded = false;
    const queryStr = `/${this.groupId}/members?memberIdToRemove=${userObj.userId}`;

    this.cService.serviceRequestCommon('delete', this.inviteURL, queryStr).subscribe(() => {

      if (typeOfRequest === 'INVITATION') {

        this.getGroupDetailsByCategory(this.groupId, 'INVITATION');
        this.toastService.createMessage('success', 'Invite Successfully Deleted.');

      } else if (typeOfRequest === 'DELETE_USER') {

        this.toastService.createMessage('success', 'Member Successfully Deleted.');
        this.getGroupDetailsByCategory(this.groupId, 'MEMBER');

      } else {

        this.getGroupDetailsByCategory(this.groupId, 'REQUEST');
        this.toastService.createMessage('success', 'Request Successfully Deleted.');

      }

    }, () => {

      this.isLoaded = true;

    });

  }

}
