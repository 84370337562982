import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnChanges {

  @Input() groupAccess;

  @Output() groupPrivacyValue = new EventEmitter();

  checked = true;

  unChecked = false;

  groupPrivacy = 'PUBLIC';

  ngOnChanges() {

    console.log('toggleGroup : ', this.groupAccess);

    if (this.groupAccess) {

      this.groupPrivacy = this.groupAccess;

    }

  }

  toggleGroup() {

    console.log('toggleGroup : ', this.groupPrivacy);
    this.groupPrivacyValue.emit(this.groupPrivacy);

  }

}
