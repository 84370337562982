import { Directive, OnInit, HostListener, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {

  @Input() debounceTime = 5000;

  @Output() debounceClick = new EventEmitter();

  @Output() debounceChange = new EventEmitter();

  private clicks = new Subject();

  private change = new Subject();

  private subs = new SubSink();

  @HostListener('click', ['$event'])
  clickEvent(event) {

    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  
  }

  @HostListener('change', ['$event'])
  changeEvent(event) {

    event.preventDefault();
    event.stopPropagation();
    this.change.next(event);
  
  }

  ngOnInit() {

    this.subs.sink = this.clicks.pipe(debounceTime(this.debounceTime)).subscribe(e => {

      this.debounceClick.emit(e);
    
    });

    this.subs.sink = this.change.pipe(debounceTime(this.debounceTime)).subscribe(e => {

      this.debounceChange.emit(e);
    
    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();
  
  }

}
