import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-video-thumbnails',
  templateUrl: './view-video-thumbnails.component.html',
  styleUrls: ['./view-video-thumbnails.component.scss']
})
export class ViewVideoThumbnailsComponent {

    @Input() item = 'item1.src';

    public videoJsConfigObj = {
      preload: 'metadata',
      controls: true,
      autoplay: true,
      overrideNative: true,
      techOrder: ['html5', 'flash'],
      html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        hls: {
          withCredentials: false,
          overrideNative: true,
          debug: true
        }
      }
    };

  // addNewItem(value: string) {
  //   this.newItemEvent.emit(value);
  // }

}
