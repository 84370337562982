<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<div class="angle-post-container">
   <div class="post-section">
      <!-- All and Standards Tab -->
      <ng-container *ngIf="materialSelectedTab !== 'elements'">
         <ncx-post
            *ngFor="let post of postDetails"
            [post]="post"
            [searchText]="searchPostInput"
            [isAddToAngle]="false"></ncx-post>
      </ng-container>
      <!-- All and Standards Tab -->

      <!-- Elements Tab -->
      <ng-container *ngIf="materialSelectedTab === 'elements'">
         <div *ngIf="materialSelectedTab === 'elements'" class="element-section" data-scrollable-container >
            <ncx-element
               *ngFor="let post of postDetails; let postIndex = index"
               [post]="post"
               [postIndex]="postIndex"
               [postDetails]="postDetails"
               [isAddToAngle]="false"
               ></ncx-element>
         </div>
      </ng-container>

      <div
         class="notFoundCard"
         *ngIf="postDetails?.length === 0 && isLoaded">
         <nz-empty [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
               <span *ngIf="materialSelectedTab === 'standards'">
                  There is no Standards Guidance on this angle at this time
               </span>
               <span *ngIf="materialSelectedTab === 'all'">No Posts Found</span>
               <span *ngIf="materialSelectedTab === 'elements'">No Elements Found</span>
            </ng-template>
         </nz-empty>
      </div>
      <!-- Elements Tab -->
   </div>
</div>
