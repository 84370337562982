import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Sort {

  /**
   * Sort by String field
   *
   */
  public static string(str1: string, str2: string): number {

    try {

      return String(str1).localeCompare(String(str2), undefined, {
        numeric: true,
        sensitivity: 'base',
        ignorePunctuation: true
      });

    } catch (error) {

      console.error('Sort.string', error);

      return 0;

    }

  }

  /**
   * Sort By Boolean field
   *
   *
   */
  public static bool(b1: boolean, b2: boolean): number {

    try {

      return (+b1 === +b2) ? 0 : b1 ? -1 : 1;

    } catch (error) {

      console.error('Sort.bool', error);

      return 0;

    }

  }

  /**
   * Sort By Numeric field
   *
   */

  public static numeric(n1: number, n2: number): number {

    try {

      return (+n1 || 0) - (+n2 || 0);

    } catch (error) {

      console.error('Sort.numeric', error);

      return 0;

    }

  }

  /**
   * Sort By Date field
   *
   */
  public static date(n1: Date, n2: Date): number {

    try {

      return n1.valueOf() - n2.valueOf();

    } catch (error) {

      console.error('Sort.date', error);

      return 0;

    }

  }

}
