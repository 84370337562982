import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { TransferInformationComponent } from '../../../shared/components/TransferInformation/TransferInformation.component';
import { IFunctionAbility } from '@models/users';
import { AppConstants } from '@constants/app.constants';
import { WebSocketService } from '@services/websocket.service';
import { UtilityService } from '@services/utility.service';
import { BreakpointService } from '@services/breakpoint.service';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-group-landing',
  templateUrl: './group-landing.component.html',
  styleUrls: ['./group-landing.component.scss']
})
export class GroupLandingComponent implements OnInit, OnDestroy {

  public functionAbility = {} as IFunctionAbility;

  groupApiURL: string;

  isLoaded: boolean;

  expanded = false;

  expandedId: any;

  itemName: any[];

  groupsFilter = 'All Groups';

  userInfoDetails;

  pageStartCount: any;

  pageLength: any;

  groupTypeSelected = 'ALL GROUPS';

  searchInput = '';

  isScrolled: boolean;

  sortByField = 'MODIFIEDDATE';

  sortOrder: any = 'DESC';

  isToggle = true;

  total = 250;

  pageIndex = 1;

  private subscriptionsWs: Subscription = new Subscription();

  constructor(
    private route: Router,
    private breakpointService: BreakpointService,
    private tI: TransferInformationComponent,
    private wS: WebSocketService,
    private cService: CommonService,
    private toastService: ToastService,
    private uS: UtilityService
  ) { }

  groupLandingIsCollapsed = true;

  glCollapsed = true;


  // tslint:disable-next-line: member-ordering
  mapOfExpandData: { [key: string]: boolean } = {};

  listOfData = [];

  ngOnInit() {

    this.isToggle = true;
    this.groupApiURL = environment.getALLGroupsURL;
    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;
    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role
    };
    this.pageStartCount = 1;
    this.pageLength = 25;

    // const querStr = `s?pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;
    const payload = this.createQueryStr();

    this.getGroupList(payload.querStr, payload.isSearched);
    this.subscriptionsWs = this.wS.doSocketData$.subscribe(data => {

      this.updateGroups(data);

    });

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  toggleSider(): void {

    this.groupLandingIsCollapsed = !this.groupLandingIsCollapsed;

  }

  toggleFlag() {

    this.pageStartCount = 1;
    this.pageLength = 25;
    this.isToggle = !this.isToggle;
    this.sortByField = 'NAME';
    this.sortOrder = (this.isToggle) ? 'ASC' : 'DESC';
    const payload = this.createQueryStr();

    this.getGroupList(payload.querStr, payload.isSearched);

  }

  createGroup() {

    this.route.navigate(['/create-group']);

  }

  closeSlider() {

    this.groupLandingIsCollapsed = !this.groupLandingIsCollapsed;

  }

  onChange(event) {

    if (event.keyCode === 13) {

      this.changeGroupStatus('searchText', event.target.value, null);

    }

  }

  onScrollDown() {

    this.pageIndex += 1;

    // return;
    this.pageStartCount += this.pageLength;
    this.changeGroupStatus(null, null, null);
    this.isScrolled = true;

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  sortGroupByType(filterObj) {

    if (filterObj) {

      this.pageStartCount = 1;
      this.pageLength = 25;
      this.groupTypeSelected = filterObj.value;
      this.groupsFilter = filterObj.label;
      this.changeGroupStatus('filterType', filterObj.value, filterObj.label);

    }

  }

  changeGroupStatus(type, value, label) {

    let querStr = '';

    if (type === 'filterType') {

      this.pageStartCount = 1;
      this.listOfData = [];
      this.groupTypeSelected = value;
      this.groupsFilter = label;

    } else if (type === 'searchText') {

      this.pageStartCount = 1;
      this.listOfData = [];

    }
    querStr = `?pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;
    if (this.groupTypeSelected) {

      querStr += `&filterType=${this.groupTypeSelected}`;

    }
    if (this.searchInput !== '') {

      querStr += `&searchText=${encodeURIComponent(this.searchInput)}`;

    }
    if (this.sortOrder !== '') {

      querStr += `&sortByField=${this.sortByField}&sortOrder=${this.sortOrder}`;

    }
    this.getGroupList(querStr, (this.searchInput !== ''));

  }

  clearGroupStatus() {

    this.searchInput = '';

    this.changeGroupStatus('searchText', 'FOLLOWING', 'search');

  }

  redirectTo(groupObj) {

    console.log('redirectTo : ', groupObj, this.userInfoDetails.role);
    if (this.userInfoDetails.role === 'EDITOR' && groupObj.groupAccess === 'PRIVATE' && !groupObj.isUserMember) {

      this.toastService.createMessage('warning', 'You do not have Permission to access this group');
      return;

    }
    if (groupObj.isUserMember || groupObj.groupAccess === 'PUBLIC' || groupObj.clickable
      || this.userInfoDetails.role === 'Admin' || this.userInfoDetails.role === 'ADMIN') {

      this.route.navigate(['/group-details/:' + groupObj.groupId]);

    } else {

      this.toastService.createMessage('warning', 'You do not have Permission to access this group');

    }

  }

  showMoreData(groupId) {

    this.expanded = !this.expanded;
    this.expandedId = groupId;

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  formatDataGrid() {

    this.itemName = [];
    if (window.innerWidth >= 1200) {

      return false;

    } else if (window.innerWidth >= 768 && window.innerWidth <= 1199) {

      this.itemName.push('created');
      return true;

    } else if (window.innerWidth >= 576 && window.innerWidth <= 766) {

      this.itemName.push('Members');
      this.itemName.push('Following');
      this.itemName.push('created');
      return true;

    } else if (window.innerWidth >= 300 && window.innerWidth <= 575) {

      this.itemName.push('Privacy');
      this.itemName.push('Members');
      this.itemName.push('Following');
      this.itemName.push('created');
      return true;

    } else {

      return true;

    }

  }

  private checkAccess(data) {

    if (data.groupAccess.toLowerCase() === 'public') {

      return this.functionAbility.fa_access_public_group;

    } else if (data.groupAccess.toLowerCase() === 'private') {

      let clickOption = this.functionAbility.fa_access_private_group;

      if (this.userInfoDetails.role.toLowerCase() === AppConstants.userRoleCheck) {


        // if (data.createUserId !== this.userInfoDetails.userId) {
        //   clickOption = false;
        // }
        clickOption = (data.createUserId === this.userInfoDetails.userId) ? true : false;

      }
      return clickOption;

    }

  }

  updateGroupList(groupId, type) {

    const groupObj = this.listOfData.filter((group) => parseFloat(group.groupId) === parseFloat(groupId))[0];

    if (!(groupObj === null || groupObj === undefined)) {

      groupObj.isUserMember = (type === 'LEAVE') ? false : true;

    }

  }

  createPayload(groupObj, userId, typeOfRequest) {

    const userObj = [
      {
        groupId: groupObj.groupId,
        userId: parseFloat(userId),

        // tslint:disable-next-line:max-line-length
        invitationNote: `You’ve been invited to join ${groupObj.groupName}. Go to your Newsconnect Alerts page to accept this invitation .`,
        membershipCategory: (typeOfRequest === 'REQUEST' && groupObj.groupAccess === 'PRIVATE') ? 'REQUEST' : 'MEMBER',
        membershipType: 'MEMBER',
        membershiptInvitationToken: null,
        isDeleted: false
      }
    ];

    return userObj;

  }

  sortChange(event, column) {


    // console.log('sortChange : ', event, column);
    this.isScrolled = false;
    this.pageStartCount = 1;
    this.pageLength = 25;
    this.sortByField = column;
    if (event === 'ascend') {

      this.sortOrder = 'ASC';

    } else if (event === 'descend') {

      this.sortOrder = 'DESC';

    } else {

      this.sortOrder = '';

    }
    const payload = this.createQueryStr();

    this.getGroupList(payload.querStr, payload.isSearched);

  }

  sort(sort: { key: string; value: string }): void {

    this.sortChange(sort.value, sort.key);

  }

  searchData() {

    if (!(this.pageStartCount > this.total)) {

      const payload = this.createQueryStr();

      this.getGroupList(payload.querStr, payload.isSearched);

    }

  }

  createQueryStr() {

    let querStr = `?pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    if (this.sortOrder !== '') {

      querStr += `&sortByField=${this.sortByField}&sortOrder=${this.sortOrder}`;

    }
    if (this.groupTypeSelected !== '') {

      querStr += `&filterType=${this.groupTypeSelected}`;

    }
    if (this.searchInput !== '') {

      querStr += `&searchText=${encodeURIComponent(this.searchInput)}`;
      return { querStr, isSearched: true };

    }
    return { querStr, isSearched: false };

  }

  /* API to GET list of Groups Start*/
  getGroupList(queryStr, _isSearched) {

    this.isLoaded = false;

    // const queryStr = `s?pageStartCount=1&pageLength=25`;
    console.log({ queryStr });
    this.cService.serviceRequestCommon('get', this.groupApiURL, queryStr).subscribe((res: any) => {


      // this.listOfData = res.groups;
      // if (isSearched) {
      //   this.pageStartCount = 1;
      //   this.pageLength = 25;
      //   this.listOfData = [];
      // }
      if (res && res.groups && res.groups.length > 0 && this.isScrolled) {

        res = res.groups;
        this.listOfData = [...this.listOfData, ...res];

      } else if ((res && res.groups && res.groups.length > 0)) {

        res = res.groups;
        this.listOfData = [...res];

      } else if ((this.pageStartCount + 1) > this.pageLength) {

        this.pageStartCount -= this.pageLength;

      } else if (res && (res.groups === null || res.groups === undefined) && !this.isScrolled) {

        this.listOfData = [];

      }

      // if (res && res.groups && res.groups.length > 0) {
      //   this.listOfData = [...this.listOfData , ...res.groups];
      //   console.log('getAlertList : ', this.listOfData);
      // } else if ((this.pageStartCount + 1) > this.pageLength) {
      //     this.pageStartCount -= this.pageLength;
      // }
      this.listOfData.filter(list => {

        list.clickable = this.checkAccess(list);

      });
      this.isLoaded = true;
      this.isScrolled = false;

    },
    (err) => {

      this.isLoaded = true;
      this.isScrolled = false;
      this.toastService.createMessage('error', err);

    }
    );

  }

  sendRequest(groupObj, typeOfRequest) {

    const userId = localStorage.getItem('userId');

    const queryStr = `/${groupObj.groupId}/members`;

    const payload = this.createPayload(groupObj, userId, typeOfRequest);

    this.cService.serviceRequestCommon('post', environment.getMEMBERGroupsURL, queryStr, payload).subscribe(() => {

      if (groupObj.groupAccess === 'PUBLIC') {

        this.updateGroupList(groupObj.groupId, 'JOIN');

      } else {

        this.toastService.createMessage('success', 'Request Successfully Sent.');

      }

    }, () => {

      this.toastService.createMessage('error', 'Error in sending Request. Please try again.');

    });

  }

  deleteRequest(groupObj, _typeOfRequest) {

    const userId = localStorage.getItem('userId');

    this.isLoaded = false;
    const queryStr = `/${groupObj.groupId}/members?memberIdToRemove=${userId}`;

    this.cService.serviceRequestCommon('delete', environment.getMEMBERGroupsURL, queryStr).subscribe((res: any) => {

      console.log('deleteRequest : ', res);

      // this.toastService.createMessage('success', 'Request Successfully Deleted.');
      this.isLoaded = true;
      this.updateGroupList(groupObj.groupId, 'LEAVE');

    }, () => {

      this.isLoaded = true;

    });

  }

  /* API to GET list of Groups End */

  private updateGroups(data) {

    if (data.group && this.uS.CheckGroup(this.groupTypeSelected, data.group)) {

      const cloneList = this.uS.deepClone(this.listOfData);

      this.listOfData = [];
      let currentLength = (this.pageStartCount === 1) ? this.pageLength : cloneList.length;

      const index = cloneList.findIndex(group => group.groupId === data.group.groupId);

      if (index !== -1) {

        cloneList.splice(index, 1);
        currentLength -= (this.pageStartCount === 1 && currentLength > 0) ? 0 : 1;

      }
      const insertIndex = this.uS.checkSorting(cloneList, this.sortByField, this.sortOrder, data.group);


      // tslint:disable-next-line:max-line-length
      if (insertIndex !== -1 && insertIndex < currentLength && this.uS.checkSearchText(this.searchInput, data.group, 'groupName')) { // insertIndex <= this.pageLength

        cloneList.splice(insertIndex, 0, data.group);

      }
      this.listOfData = cloneList;
      this.listOfData.filter(list => {

        list.clickable = this.checkAccess(list);

      });
      this.toastService.createMessage('success', 'New Group Updated/Added');

    }

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
