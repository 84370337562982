<nz-radio-group
   class="contentTypes"
   [(ngModel)]="contentType"
   [nzButtonStyle]="'solid'"
   (ngModelChange)="toggleContentTypes($event)">
   <span *ngIf="!singleContentView">
      <label
         class="story-border"
         nz-radio-button
         nzValue="Story">
         Story
      </label>
      <label
         nz-radio-button
         nzValue="Post">
         Post
      </label>
      <label
         class="angle-border"
         nz-radio-button
         [nzDisabled]="!(functionAbility.fa_create_public_angle || functionAbility.fa_create_private_angle)"
         nzValue="Angle">
         Angle
         <app-beta
            [showBInMobile]="true"
            [isRadioSelected]="contentType === 'Angle' ? true : false"></app-beta>
      </label>
   </span>
   <span *ngIf="singleContentView">
      <label
         class="single-label"
         nz-radio-button
         [nzValue]="contentType">
         {{ contentType }}
      </label>
   </span>
</nz-radio-group>
