<nz-collapse
   nzExpandIconPosition="right"
   nzBordered="false">
   <nz-collapse-panel
      nzHeader="Linked Angles"
      nzActive="false">
      <div
         *ngFor="let angle of relatedAngles"
         class="angle-flex">
         <div class="angle-icon-title-split">
            <span
               class="eye-invisible-icon"
               *ngIf="angle.angleAccess === 'private'">
               <span
                  nz-icon
                  nzType="eye-invisible"
                  nzTheme="outline"></span>
            </span>
            <span
               class="eye-invisible-hidden-icon"
               *ngIf="angle.angleAccess !== 'private' && hasPrivateAngle">
               <span
                  nz-icon
                  nzType="eye-invisible"
                  nzTheme="outline"></span>
            </span>
         </div>
         <div class="angle-icon-title-split">
            <a
               class="angle-text"
               (click)="redirectTo(angle.angleId)">
               <span [innerHTML]="'(' + angle.angleId + ')' + ' ' + angle.angleTitle | safeHtml"></span>
            </a>
         </div>
      </div>
   </nz-collapse-panel>
</nz-collapse>
