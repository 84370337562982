import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlRedirectService } from '@services/url-redirect.service';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })
export class CommonFunctionsHelper {

  constructor(
    private urlRedirectService: UrlRedirectService,
    private router: Router
  ) { }

  public resolve() {

    this.clearLocalStorageForAuth();

  }

  clearLocalStorageForAuth() {

    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token_issuedate');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('referenceUrl');
    localStorage.removeItem('nonce_key');
    localStorage.removeItem('ssoId');
    localStorage.removeItem('userId');
    localStorage.removeItem('ncxjwttoken');
    localStorage.removeItem('ncx_token_expires_in');
    localStorage.removeItem('lastLoggedInFlag');

    // localStorage.removeItem('sessionTime');

  }

  generateUUID() {

    let uid = uuid();

    uid = uid.replace(/[^\w\s]/gi, '');
    return uid;

  }

  // Safely redirecting using sanitized and validated input
  isValidUrl(url) {

    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    return urlPattern.test(url);

  }

  sanitizeUrl(url) {

    const domainNameSplit = url.trim().split('/');

    const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

    return this.urlRedirectService.validDomains.includes(currentDomain);

  }

  /**
   *
   * if the url is an valid url then that url will be returned otherwise the base url will be returned
   */
  validateAndSanitizeUrl(url) {

    if (this.sanitizeUrl(url)) {

      return url;

    } else {

      console.error(`${url} is an invalid Url.`);
      return '';

      // Handle the error, e.g., display an error message to the user

    }

  }

  /**
   * 
   * @param url 
   * @returns url
   * to show the attachments from the link
   */
  redirectTo(url) {

    if (this.sanitizeUrl(url)) {

      window.location.href = url;

      //this.router.navigate(['/']); NRT-13708
    
    } else {

      console.error(`${url} is an invalid Url.`);
      this.router.navigate(['/']);
    
    }
  
  }

}
