import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IFunctionAbility } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from 'src/app/services/common-service';
import { environment } from 'src/environments/environment';
import { TransferInformationComponent } from '../TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-additional-topic-new',
  templateUrl: './additional-topic-new.component.html',
  styleUrls: ['./additional-topic-new.component.scss']
})
export class AdditionalTopicNewComponent implements OnInit, OnChanges {

  @Input() viewMode = false;

  @Input() showErrorMsg = false;

  @Output() addedTopics: EventEmitter<{ topicId: string, topicName: string }[]> = new EventEmitter();

  @Output() touchedEvent: EventEmitter<boolean> = new EventEmitter();

  @Output() dropdownOpenChangeEvent: EventEmitter<boolean> = new EventEmitter();

  public functionAbility = {} as IFunctionAbility;

  @Input() assignedTopics;

  topics: any[] = [];

  formattedTopics: any[] = [];

  selectedTopics: any[] = [];

  topicForm: FormGroup;

  showTopicToolTip: boolean = false;

  tooltipTrigger = 'manual';

  isMobile: boolean = false;

  hoveredIndex: number | null = null;


  constructor(private cService: CommonService, private tI: TransferInformationComponent, private fb: FormBuilder, private changeDetector: ChangeDetectorRef, private breakpointService: BreakpointService) {


    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

    this.topicForm = this.fb.group({
      topic: [[], Validators.required]
    });

  }

  get formControls() {

    return this.topicForm.controls;

  }

  ngOnInit() {


    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });

    this.topicForm.get('topic').valueChanges.subscribe(value => {

      this.selectTopics(value);

    });

  }

  ngOnChanges(changes: any) {

    if (changes.assignedTopics && changes.assignedTopics.currentValue) {

      this.selectedTopics = changes.assignedTopics.currentValue;

    }

    if (this.formattedTopics.length == 0) {

      this.getTopicsAndPatchData();

    } else {

      this.patchData();

    }


    if (changes.showErrorMsg && changes.showErrorMsg.currentValue) {

      this.showErrorMsg = changes.showErrorMsg.currentValue;
      if (this.showErrorMsg) {

        this.raiseTouchedEvent();

      }

    }

  }

  patchData() {

    const topics = [];

    this.selectedTopics.forEach((item) => {

      const foundTopic = this.formattedTopics.find(topic => topic.topicId === item.topicId);

      if (foundTopic) {

        topics.push(foundTopic);

      }

    });

    this.topicForm.patchValue({ topic: topics });

  }

  getTopicsAndPatchData() {
    const pageStartCount = 1;
    const pageLength = 1000;
    const queryStr = `?excludeDeleted=true&pageStartCount=${pageStartCount}&pageLength=${pageLength}`;
    this.cService.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStr).subscribe((data: any) => {

      this.topics = data.topics;

      this.formattedTopics = this.topics.map(topic => ({
        ...topic,
        formattedLabel: `${topic.topicCode} - ${topic.topicName}`
      }));

      this.patchData();

    });

  }

  selectTopics($event) {

    /**
     * if the event is empty, return. Don't remove this check
     */
    // if ($event.length === 0) {

    //   return;

    // }

    this.addedTopics.emit($event);

  }


  raiseTouchedEvent() {

    if (this.topicForm.controls.topic.invalid) {

      this.topicForm.controls.topic.markAsTouched();

    }

    this.changeDetector.detectChanges();
    this.touchedEvent.emit(true);

  }

  onDropdownOpenChange(event) {
    this.dropdownOpenChangeEvent.emit(event);
  }


  toggleTopicTooltip(index: number) {

    if (this.isMobile) {

      if (this.hoveredIndex === index) {
        this.showTopicToolTip = !this.showTopicToolTip;
      } else {
        this.showTopicToolTip = true;

        this.hoveredIndex = index;
      }



    }

  }

  showTopicTooltipEvent(index: number) {

    if (!this.isMobile && !this.showTopicToolTip) {

      setTimeout(() => {

        this.showTopicToolTip = true;

        this.hoveredIndex = index;


      }, 500);

    }

  }

  hideTopicTooltip() {

    if (!this.isMobile && this.showTopicToolTip) {

      setTimeout(() => {

        this.showTopicToolTip = false;

        this.hoveredIndex = null;


      }, 500);

    }

  }

  filterOption(input: string, option: any): boolean {

    const filteringField = input.length <= 2 ? option.nzValue.topicCode : option.nzValue.topicName;

    const regex = new RegExp(input.length <= 2 ? `^${input}` : `${input}`, 'i');

    return !!filteringField.match(regex);
  }
}
