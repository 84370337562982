<button (click)="onClick()">
   <i
      nz-icon
      nzType="share-alt"
      nzTheme="outline"></i>
   <span
      *ngIf="showShareText"
      class="share-txt">
      Share
   </span>
</button>
