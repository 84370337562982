import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { Alert, AlertResponse } from '@models/ncx/alerts';

@Component({
  selector: 'app-alerts-popup-component',
  templateUrl: './alerts-popup.component.html',
  styleUrls: ['./alerts-popup.component.scss']
})
export class AlertsPopupComponent implements OnInit, OnDestroy {

  // List of all alerts to show
  alertList: Alert[] = [];

  // Number of unread alerts
  alertCount: number = 0;

  // Was there an error loading alerts?
  alertError: boolean = false;

  // Loading indicator
  isLoading: boolean = false;

  @Input() alertsToShow: number = 3;

  private subs = new SubSink();

  public constructor(
    private router: Router,
    private toastService: ToastService,
    private userInfo: TransferInformationComponent,
    private cService: CommonService
  ) {

    this.subs.sink = this.userInfo.alertCount.subscribe(count => {

      this.alertCount = count || 0;
    
    });

  }

  ngOnInit() {

    this.loadAlerts();

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Load most recent alerts
   *
   */
  loadAlerts() {

    this.isLoading = true;

    const queryStr = `/alert/notifications?readFlag=false&pageStartCount=1&pageLength=${this.alertsToShow}`;

    this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr).subscribe({

      next: (res: any) => {

        const alertResponse: AlertResponse = res as AlertResponse;

        if (Array.isArray(alertResponse.alerts) && alertResponse.alerts.length) {

          this.alertList = alertResponse.alerts;
        
        }

        this.userInfo.setAlertCount(alertResponse.totalCount || 0);

      },
      error: (error: any) => {

        console.error('getAlertList', error);

        this.alertError = true;

      }
    }).add(() => {

      this.isLoading = false;
    
    });

  }

  clearAlerts() {

    const alertIds = this.getAlertIds();

    if (alertIds.length) {

      this.isLoading = true;

      const queryStr = '/alert/notifications';

      this.cService.serviceRequestCommon('put', environment.getProfileAPIURL, queryStr, { alertIds }).subscribe({
        next: () => {

          this.resetAlertCount();
          this.loadAlerts();
          this.toastService.createMessage('success', 'Alerts successfully cleared');
        
        },
        error: (error: any) => {

          console.error('clearAlerts', error);
          this.toastService.createMessage('error', 'Error while clearing Alerts. Please try again.');
        
        }
      }).add(() => {

        this.isLoading = false;
      
      });

    }

  }

  /**
   * Get visible alert Ids
   *
   */
  getAlertIds(): number[] {

    const alertIds = this.alertList.map((alert: Alert) => {

      return alert.alertId;
    
    });

    return alertIds;

  }

  /**
   * Clear all alerts and count
   *
   */
  resetAlertCount() {

    this.alertCount = 0;

    this.alertList = [];

  }

  /**
   * View Alerts page
   *
   */
  viewAlerts() {

    this.router.navigate(['ncx/alerts']);

  }

}
