<!-- ********************************* Merge Alert ******************************* -->

<nz-card
   *ngIf="alert.alertGroup === 'GENERAL' && alert.alertType === 'MERGE_STORY'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (merge)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="moveAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      *ngIf="buttons"
      class="alerts">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #moveAvatar>
   <nz-avatar
      nzIcon="swap"
      class="mentionAvatar"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- ********************************* New Post Alert ***************************** -->

<nz-card
   *ngIf="
      (alert.alertGroup === 'STORY' && alert.alertType === 'NEW_STORY') ||
      (alert.alertGroup === 'POST' && alert.alertType === 'NEW_POST')
   "
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (new post)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="Template"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      *ngIf="buttons"
      class="alerts">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #Template>
   <nz-avatar
      nzIcon="file-add"
      class="addDetails"
      [nzSize]="48"></nz-avatar>
</ng-template>
<ng-template #alertDate>
   {{ utcToLocal(alert?.updateDateTime, 'TIME') }} - {{ utcToLocal(alert?.updateDateTime, 'DATE') | date: 'M/d/yyyy' }}
   <!-- {{ 00:00am}} - {{MM/DD/YYYY}} -->
</ng-template>

<!-- ********************************* Replies Alert ***************************** -->

<nz-card
   *ngIf="alert.alertGroup === 'POST' && alert.alertType === 'POST_REPLY'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (replies)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="message"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #message>
   <nz-avatar
      nzIcon="message"
      class="addDetails"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- ********************************* Following Alert ***************************** -->

<nz-card
   *ngIf="alert.alertGroup === 'GENERAL' && alert.alertType === 'USER_FOLLOWING'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (following)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="followAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #followAvatar>
   <nz-avatar
      nzIcon="star"
      class="addDetails"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- ********************************* General Group Alert ***************************** -->

<nz-card
   *ngIf="
      alert.alertGroup === 'GROUP' &&
      (alert.alertType === 'GENERAL_GROUP_ALERT' ||
         alert.alertType === 'GROUP_PUBLISH' ||
         alert.alertType === 'GROUP_UPDATE')
   "
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (group alert)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="groupAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #groupAvatar>
   <nz-avatar
      nzIcon="team"
      class="group"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- ********************************* Group Discussion Alert ***************************** -->

<nz-card
   *ngIf="
      alert.alertGroup === 'GROUP' &&
      (alert.alertType === 'NEW_DISCUSSION' ||
         alert.alertType === 'GROUP_DISCUSSION_ALERT' ||
         alert.alertType === 'DISCUSSION_COMMENT')
   "
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (group discussion)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="groupMessage"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="groupDescription"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #groupMessage>
   <nz-avatar
      nzIcon="solution"
      class="group"
      [nzSize]="48"></nz-avatar>
</ng-template>
<ng-template #groupDescription>
   {{ utcToLocal(alert?.updateDateTime, 'TIME') }} - {{ utcToLocal(alert?.updateDateTime, 'DATE') | date: 'M/d/yyyy' }}
   <!-- {{ 00:00am}} - {{MM/DD/YYYY}} -->
</ng-template>

<!-- ********************************* Group Invite Alert ***************************** -->
<!-- GROUP_REQUEST -->
<nz-card
   *ngIf="alert.alertGroup === 'GROUP' && alert.alertType === 'GROUP_INVITE'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (group invite)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="groupInviteAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="groupInivite"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="deleteRequest(alert)">
         Decline
      </button>
      <button
         nz-button
         nzType="primary"
         (click)="sendRequest(alert)">
         Accept
      </button>
   </div>
</nz-card>
<ng-template #groupInviteAvatar>
   <nz-avatar
      nzIcon="usergroup-add"
      class="group"
      [nzSize]="48"></nz-avatar>
</ng-template>
<ng-template #groupInivite>
   <!-- {{(alert.memberCount)?alert.memberCount:0}} -->
   {{ utcToLocal(alert?.updateDateTime, 'TIME') }} - {{ utcToLocal(alert?.updateDateTime, 'DATE') | date: 'M/d/yyyy' }}
   <!-- {{ 00:00am}} - {{MM/DD/YYYY}} -->
</ng-template>

<!-- *********************************Request Alert **************************** -->

<nz-card
   *ngIf="alert.alertGroup === 'GROUP' && (alert.alertType === 'REQUEST_ALERT' || alert.alertType === 'GROUP_REQUEST')"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (request alert)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="requestAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="deleteRequest(alert)">
         Decline
      </button>
      <button
         nz-button
         nzType="primary"
         (click)="sendRequest(alert)">
         Accept
      </button>
   </div>
</nz-card>
<ng-template #requestAvatar>
   <nz-avatar
      nzIcon="user-add"
      class="group"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- ********************************* Announcement Alert ***************************** -->

<nz-card
   *ngIf="alert.alertGroup === 'GROUP' && alert.alertType === 'ANNOUNCEMENT_ALERT'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (announcement alert)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="announcementAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #announcementAvatar>
   <nz-avatar
      nzIcon="notification"
      class="announcement"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- ********************************* Edit Alert ***************************** -->

<nz-card
   *ngIf="
      (alert.alertGroup === 'STORY' &&
         (alert.alertType === 'EDIT_STORY' ||
            alert.alertType === 'STORY_UPDATE' ||
            alert.alertType === 'STORY_STATE_CHANGE')) ||
      (alert.alertGroup === 'POST' && alert.alertType === 'EDIT_POST')
   "
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (edit alert)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="postAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #postAvatar>
   <nz-avatar
      nzIcon="edit"
      class="addDetails"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- *********************************Activity Alert************************** -->

<nz-card
   *ngIf="alert.alertGroup === 'ALERTS' && alert.alertType === 'ACTIVITY_ICON'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (activity alert)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="activityAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #activityAvatar>
   <nz-avatar
      nzIcon="project"
      class="storyDetails"
      [nzSize]="48"></nz-avatar>
</ng-template>

<!-- *********************************Email Alert********************* -->

<nz-card
   *ngIf="alert.alertGroup === 'GENERAL' && alert.alertType === 'EMAIL_ALERT'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (email alert)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="emialAvatar"
         nzTitle="{{ alert.subject }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
<ng-template #emialAvatar>
   <nz-avatar
      nzIcon="mail"
      class="storyDetails"
      [nzSize]="48"></nz-avatar>
</ng-template>

<nz-card
   *ngIf="alert.alertGroup === 'GENERAL' && alert.alertType === 'SYSTEM_ANNOUNCEMENT'"
   [attr.data-component]="'ncx/alerts/render-alerts.component.html (system announcement)'">
   <div class="viewProfileLeft">
      <nz-card-meta
         [nzAvatar]="announcementAvatar"
         nzTitle="{{ alert.subject ? alert.subject : 'SYSTEM ANNOUNCEMENT' }}"
         [nzDescription]="alertDate"></nz-card-meta>
   </div>
   <div
      class="alerts"
      *ngIf="buttons">
      <button
         nz-button
         class="marginright_4"
         (click)="dismissAlert(alert)"
         *ngIf="alert.isActive">
         Dismiss
      </button>
      <button
         nz-button
         nzType="primary"
         nzGhost
         (click)="updateAlert(alert, 'VIEW')">
         View
      </button>
   </div>
</nz-card>
