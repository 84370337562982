import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
import { Stories } from '@models/ncx/story';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { BreakpointService } from '@services/breakpoint.service';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.scss']
})
export class FollowingComponent implements OnInit {

  isLoaded = false;

  storyId;

  storyFollowers;

  storyDetails = null as Stories;

  getApiURL;

  postFlag = false;

  isSticky = false;

  followerApi;

  isFilter = false;

  sortOrderSelected = 'A - Z';

  searchFollowerText: any;

  isStoryLanding = false;

  isStoriesLandingVisible = false;

  isinfocenterVisible = false;

  followerFilter: { order: string; value: any; };

  searchInput = '';

  storyTitle = '';

  constructor(
    private router: ActivatedRoute,
    private cService: CommonService,
    private breakpointService: BreakpointService,
    private toastService: ToastService,
    private reRoute: Router,
    private storiesSiderStatus: TransferInformationComponent
  ) {

    this.storiesSiderStatus.currentstoriesSiderStatus.subscribe(isCollapsed => {

      this.isStoriesLandingVisible = !isCollapsed;

    });

  }

  ngOnInit() {

    this.getApiURL = environment.getStoriesAPI;
    this.followerApi = environment.storyFollower;
    this.isStoryLanding = true;
    this.router.params.subscribe((params: Params) => {

      this.setStoryId(params);

    });

    this.isStoriesLandingVisible = !this.breakpointService.isMobile.value;

    // Close the right-hand drawer if the browser is minimized to handset and the drawer is open
    this.breakpointService.isMobile.subscribe((isMobile: boolean) => {

      if (isMobile && this.isStoriesLandingVisible) {

        this.isStoriesLandingVisible = false;

      }

    });

  }

  setStoryId(params) {

    if (params && params.storyId) {

      this.storyId = (params.isFollow ? params.storyId : params.storyId.substr(1));
      if (this.storyId != null || this.storyId !== '') {

        this.getStoryDetails(this.storyId);

      }

    }

  }

  toggleStoryDetail(): void {

    this.isStoriesLandingVisible = !this.isStoriesLandingVisible;
    this.isFilter = false;

  }

  viewStory(content) {

    this.reRoute.navigate(['ncx/view-story/:' + content.storyId]);

  }

  closeDrawer() {

    this.isStoriesLandingVisible = false;

  }

  openInfoCenter() {

    this.isinfocenterVisible = true;

  }

  closeInfoCenter() {

    this.isinfocenterVisible = false;

  }

  changeSortOrder(order) {

    if (order === 'asc') {

      this.sortOrderSelected = 'A - Z';

    } else if (order === 'desc') {

      this.sortOrderSelected = 'Z - A';

    }

    this.searchFollowerText = (this.searchFollowerText === null || this.searchFollowerText === undefined) ? '' : this.searchFollowerText;

    this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: this.searchFollowerText };

  }

  filterFollowers(event, value, type) {

    if (type === 'click') {

      this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: this.searchInput };
      return;

    }
    if (event.keyCode === 13) {

      this.searchFollowerText = event.target.value;
      this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: this.searchFollowerText };

    }
    setTimeout(() => {

      if (this.searchInput === '') {

        this.searchFollowerText = '';
        this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: '' };

      }

    }, 200);

  }

  updateFollowers(status: FollowStoryUpdateEvent) {

    this.storyFollowers = status.users;
    this.storyDetails.storyFollowerCount = status.data.storyFollowerCount;
    this.storyDetails.isUserFollowing = status.type;

  }

  getStoryDetails(storyId) {

    this.isLoaded = false;
    const queryStr = `/${storyId}` + '?isPosts=' + this.postFlag;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.storyDetails = JSON.parse(JSON.stringify(res));
      this.storyFollowers = res.storyFollower;
      this.storyTitle = res.storyTitle;

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');
      this.isStoriesLandingVisible = false;

    }).add(() => {

      this.isLoaded = true;

    });

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  /**
   * Append jwttokens to any files embedded in the story content
   *
   */
  get storyContent(): string {

    const token = localStorage.getItem('ncxjwttoken');

    return (this.storyDetails.storyContent || '').replace(/ncx-gateway\/api\/v1([^"]+)/i, (str, match) => {

      return !match.includes('ncxjwttoken=') ? `${str}?ncxjwttoken=${token}` : str;

    });

  }

  get isSortingAscending(): boolean {

    return this.sortOrderSelected === 'A - Z';

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
