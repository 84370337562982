<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isMovePost"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/move-discussion'">
         <p class="header">Move Discussion</p>

         <div class="body">
            <section>
               <p class="form-label">Move this discussion to a different story</p>

               <div class="form-element">
                  <nz-input-group
                     nzSearch
                     [nzAddOnAfter]="suffixIconButton">
                     <input
                        #storyInput="ngModel"
                        placeholder="Enter Name"
                        nz-input
                        [(ngModel)]="value"
                        (keydown)="onChange(storyInput.value, $event)"
                        (ngModelChange)="titleMatch($event)"
                        [nzAutocomplete]="auto" />
                  </nz-input-group>

                  <nz-autocomplete #auto>
                     <ng-container *ngFor="let user of groupsListNames">
                        <nz-auto-option [nzValue]="user.groupId">{{ user.groupName }}</nz-auto-option>
                     </ng-container>
                  </nz-autocomplete>

                  <ng-template #suffixIconButton>
                     <button
                        nz-button
                        nzSearch>
                        <i
                           nz-icon
                           nzType="search"
                           nzTheme="outline"></i>
                     </button>
                  </ng-template>
               </div>
            </section>

            <section *ngIf="groupsListNames.length">
               <ul class="list-selector">
                  <li *ngFor="let index of groupsNameList">
                     {{ index.groupName }}
                     <button
                        class="delete"
                        (click)="deleteGroupFromList(index, index.groupId)">
                        <i
                           nz-icon
                           nzType="delete"></i>
                     </button>
                  </li>
               </ul>
            </section>
         </div>
      </div>
   </ng-template>
   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="handleOk()">
         Move
      </button>
   </ng-template>
</nz-modal>
