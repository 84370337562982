<nz-page-header
   class="standard-header"
   [nzBackIcon]="showBackButton ? '' : null"
   (nzBack)="onClickBack()">
   <nz-page-header-title class="title">
      <span>{{ title }}</span>
   </nz-page-header-title>

   <!-- RIGHT SIDE CONTENT -->
   <nz-page-header-extra class="right-side">
      <div *ngIf="rightSide && rightSide.templateRef">
         <ng-container [ngTemplateOutlet]="rightSide.templateRef"></ng-container>
      </div>
   </nz-page-header-extra>
</nz-page-header>
