// Navigation configuration for drawer/sider.
// The 'roles' is an arry of strings that indicate who can see this particular link.
// 'Any' is a local parameter only meant to include the link regardless of the roles
// (e.g. everyone can see the 'reports' page)
// Note -- the 'Admin' role sees EVERYTHING -- so no need to specify unless you're
// explicitly only allowing the 'Admin' to see a certain page.

import { NavItem } from '@models/nav-item';

export const NAV_DISPLAY: NavItem[] = [
  {
    title: 'Search',
    icon: 'search',
    routerLink: '/ncx/search-results',
  },
  {
    title: 'Stories',
    icon: 'project',
    routerLink: '/ncx/stories-dashboard',
  },
  {
    title: 'Activity',
    icon: 'block',
    routerLink: '/Activity',
    submenu: [
      {
        title: 'All',
        routerLink: '/ncx/activity/:All',
      },
      {
        title: 'Hot',
        routerLink: '/ncx/activity/:Hot',
      },
      {
        title: 'Standards',
        routerLink: '/ncx/activity/:Standards',
      },
    ],
  },
  {
    title: 'Drafts',
    icon: 'file',
    routerLink: '/ncx/drafts',
  },
  {
    title: 'Groups',
    icon: 'team',
    routerLink: '/ncx/group-landing',
  },
  {
    title: 'Following',
    icon: 'star',
    submenu: [
      {
        title: 'Stories',
        routerLink: '/ncx/followed-stories',
      },
      {
        title: 'Connections',
        routerLink: '/ncx/connections',
      },
      {
        title: 'Topics',
        routerLink: '/ncx/topics',
      },
    ],
  },
  {
    title: 'Other Tools',
    icon: 'tool',
    routerLink: '/ncx/other-tools',
  },
  {
    title: 'Support',
    icon: 'question-circle',
    routerLink: '/ncx/support',
  },
  {
    title: 'Admin',
    icon: 'lock',
    routerLink: '/ncx-admin/landing',
    roles: ['Admin'],
  },
  {
    title: 'Settings',
    icon: 'setting',
    routerLink: '/ncx/settings',
  },
  /*
   {
   title: 'Training',
   icon: 'Training',
   routerLink: 'Training',
   submenu: [
   {
   title: 'News Group Workflows & Tools Training Guide',
   target: '_blank',
   href: 'https://nbcuacademyaccess.nbcuni.com/lesson/391153-nbcu-news-group-workflows-amp-tools-training-guide/',
   },
   {
   title: 'NewsConnect Guide',
   target: '_blank',
   href: 'https://nbcuacademyaccess.nbcuni.com/lesson/38319-get-connected-getting-started-with-newsconnect',
   },
   {
   title: 'Training Signup',
   target: '_blank',
   href: 'https://forms.office.com/pages/responsepage.aspx?id=-SY1T9aXLUGTOk4wpzEQ9G3smpXJB2dAgDe6ksKYDYhUQ0Y2NTZWVk1OMEZZNEw3V0cxRVVBUDBJOS4u',
   },
   {
   title: 'DEI Words Guide',
   target: '_blank',
   href: 'https://nbcuacademyaccess.nbcuni.com/lesson/472784-nbcu-news-group-diversity-equity-amp-inclusion',
   },
   ],
   },
   */
];
