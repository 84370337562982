import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-drafts-filter',
  templateUrl: './drafts-filter.component.html',
  styleUrls: ['./drafts-filter.component.scss']
})
export class DraftsFilterComponent {

  @Input() draftType;

  @Input() orderedBy;

  @Input() isDrawer: false;

  @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output() emitDraftTypeFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output() emitOrderFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();

  radioPostTypeValue = 'Posts';

  radioDraftOrderValue = 'Newest First';

  close() {

    this.closeFilter.emit(true);

  }

  addFilters(filterValue) {

    this.emitDraftTypeFilter.emit(filterValue);

  }

  orderFilter(filterValue) {

    this.emitOrderFilter.emit(filterValue);

  }

  clear() {

    this.radioPostTypeValue = 'Posts';
    this.radioDraftOrderValue = 'Newest First';
    this.clearFilter.emit(true);
    this.closeFilter.emit(true);

  }

}
