<!-- ADD NEW COMMENT -->
<div
   class="add-comment"
   data-component="src/shared/components/comments"
   *ngIf="functionAbility.fa_create_reply">
   <app-loader-component
      loaderType="SECTION_LOADER"
      [isLoaded]="isLoaded"></app-loader-component>

   <!-- PLAIN TEXT EDITOR -->
   <textarea
      nz-input
      placeholder="Enter new comment here"
      rows="1"
      class="text-input"
      [(ngModel)]="newComment"
      [nzAutosize]="{ minRows: 3, maxRows: 12 }"></textarea>

   <!-- ADD COMMENT BUTTON -->
   <div class="options">
      <button
         id="rteCustomLink"
         nz-button
         nzType="primary"
         [disabled]="!hasNewComment"
         (click)="handleSubmit()">
         Add Comment
      </button>
   </div>
</div>

<!-- VIEW ALL COMMENTS -->
<div
   class="comments"
   *ngIf="postVersionGroupId && comments.length > 0">
   <div
      *ngFor="let comment of comments"
      class="container">
      <button
         (click)="goToProfile(comment.userId)"
         nz-popover
         [nzPopoverContent]="profileCard"
         nzPopoverPlacement="topLeft">
         <nz-avatar
            nzIcon="user"
            class="avatar"
            nzSrc="{{ Common.formS3Link(comment.userId?.profilePictureURL) }}"></nz-avatar>
      </button>

      <div class="content">
         <ng-container *ngIf="!isEditing(comment)">
            <div class="top-row">
               <button
                  (click)="goToProfile(comment.userId)"
                  class="name"
                  nz-popover
                  [nzPopoverContent]="profileCard"
                  nzPopoverPlacement="topLeft">
                  {{ commentName(comment.userId) }}
               </button>

               <div class="options">
                  <button
                     *ngIf="canDelete(comment.userId)"
                     nz-popconfirm
                     nz-button
                     nzType="link"
                     nzDanger="true"
                     nzPopconfirmPlacement="left"
                     [nzPopconfirmTitle]="deleteTemplate"
                     [nzOkText]="'Yes'"
                     [nzCancelText]="'No'"
                     (nzOnConfirm)="deleteComment(comment.replyId)">
                     <i
                        nz-icon
                        class="delete-icon"
                        nzType="delete"
                        nzTheme="fill"></i>

                     <ng-template #deleteTemplate>
                        <div class="confirm-delete">
                           <i
                              nz-icon
                              nzTheme="fill"
                              nzType="exclamation-circle"></i>
                           <span>Are you sure to want to delete this comment?</span>
                        </div>
                     </ng-template>
                  </button>

                  <button *ngIf="canEdit(comment.userId)">
                     <i
                        nz-icon
                        nzType="edit"
                        nzTheme="fill"
                        (click)="editComment(comment)"
                        class="edit"></i>
                  </button>
               </div>
            </div>

            <div class="bottom-row">
               <div class="comment">
                  <pre>{{ comment.replyContent }}</pre>
               </div>
            </div>
         </ng-container>

         <ng-container *ngIf="isEditing(comment)">
            <div class="top-row">
               <textarea
                  #editTextarea
                  nz-input
                  placeholder="Enter updated comment here"
                  [rows]="3"
                  [(ngModel)]="editing.replyContent"
                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"></textarea>
            </div>

            <div class="bottom-row">
               <div class="options">
                  <button
                     nz-button
                     nzDanger="true"
                     (click)="clearEditor()">
                     Discard Changes
                  </button>

                  <button
                     nz-button
                     nzType="primary"
                     [disabled]="!hasUpdatedComment"
                     (click)="handleSubmit()">
                     Save Update
                  </button>
               </div>
            </div>
         </ng-container>
      </div>

      <ng-template #profileCard>
         <app-profile-overlay [profileData]="comment.userId"></app-profile-overlay>
      </ng-template>
   </div>
</div>
