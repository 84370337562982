<div
   class="element-post-detail"
   data-component="shared/cards/element">
   <!-- THUMBNAIL VIEWER -->
   <app-view-thumbnails
      [viewAttachments]="post.postAttachments"
      [postDetails]="postDetails"
      [isStoryLanding]="isStoryLanding"
      [postIndex]="postIndex"
      [storyId]="post.storyId"></app-view-thumbnails>

   <div class="details">
      <a
         target="_blank"
         class="title"
         href="/#/ncx/view-post/:{{ post.postId }}">
         {{ post.postTitle }}
      </a>

      <div class="tags">
         <!-- TAGS -->
         <app-post-labels
            [maxLabelCount]="maxLabelCount"
            [editorial]="post.editorialStatus"
            [legal]="post.postLegal"></app-post-labels>
      </div>

      <div class="footer">
         <!-- POST CREATED -->
         <div class="date-time">
            <ng-container *ngIf="post.updateDateTime > post.createDateTime">
               Modified : {{ post.updateDateTime | utcToLocal: 'DATE' }} at
               {{ post.updateDateTime | utcToLocal: 'TIME' }}
            </ng-container>
            <ng-container *ngIf="!(post.updateDateTime > post.createDateTime)">
               Created : {{ post.createDateTime | utcToLocal: 'DATE' }} at
               {{ post.createDateTime | utcToLocal: 'TIME' }}
            </ng-container>
         </div>

         <button
            nz-dropdown
            nz-button
            class="options"
            nzType="default"
            [nzDropdownMenu]="menu">
            <i
               nz-icon
               nzType="ellipsis"
               nzTheme="outline"></i>

            <nz-dropdown-menu #menu="nzDropdownMenu">
               <ul
                  nz-menu
                  nzSelectable>
                  <li
                     nz-menu-item
                     (click)="onShare()">
                     Share
                  </li>
                  <li
                     *ngIf="isAddToAngle && canAddToAngle"
                     nz-menu-item
                     (click)="addToAngle(post)">
                     Add to Angle
                  </li>
               </ul>
            </nz-dropdown-menu>
         </button>
      </div>
   </div>
</div>

<!-- SHARE POST MODAL -->
<app-share-post
   [isVisible]="showShareModal"
   [postId]="post.postId"
   [message]="shareMessage"
   (closedSharedContent)="closeShareModal()"></app-share-post>

<ng-template #closeIconTpl>
   <span
      nz-icon
      class="close-icon"
      nzType="close"
      nzTheme="outline"></span>
</ng-template>
