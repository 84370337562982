import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth/auth.service';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TransferInformationComponent } from '../shared/components/TransferInformation/TransferInformation.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnDestroy {

  private readonly baseUrl: string = '';

  options;

  public pageName = 'Column';

  categoryDetails = new BehaviorSubject('');

  clearAddColumnData = new BehaviorSubject('');

  stringSubject = new Subject();

  UpdateDashboard = new Subject();

  showBanner = new BehaviorSubject('');

  columnName = '';

  columnNumber = 0;

  customHomePage = false;

  private subs = new SubSink();

  // Tracks position of left-side navigation drawer
  public readonly isNavDrawerClosed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private router: Router,
    private userInfo: TransferInformationComponent
  ) {

    this.baseUrl = this.getAPIEndpoint();

    console.log('Common Service (baseUrl)', this.baseUrl);

  }

  passValue(data) {

    this.stringSubject.next(data);

  }

  getAPIEndpoint(): string {

    const domain = window.location.origin;

    // Developing Locally, we can pick and choose what environment to use for the API calls

    if (domain === AppConstants.domain.local_url) {

    //  return AppConstants.domain.capx_dev_api_url;

       return AppConstants.domain.capx_qa_api_url;

      // return AppConstants.domain.capx_stg_api_url;

    } else {

      return environment.baseAPIPath;

    }

  }

  getWebSocketEndpoint(): string {

    const domain = window.location.origin;

    // Developing Locally, we can pick and choose what environment to use for the WS connection
    if (domain === AppConstants.domain.local_url) {

    //  return AppConstants.domain.capx_dev_ws_url;

       return AppConstants.domain.capx_qa_ws_url;

      // return AppConstants.domain.capx_stg_ws_url;

    } else {

      // Developing in all other environments (prod, stage, etc),
      // return the base path that's registered in the environment file
      return environment.baseWSPath;

    }

  }

  get(path: string, headers?: HttpHeaders): Observable<any> {

    const apiUrl = this.baseUrl + path;

    return this.http.get<Blob>(apiUrl, { headers, responseType: 'blob' as 'json' });

  }

  post(path: string, body?: any, headers?: HttpHeaders, _formData?: FormData): Observable<any> {

    const apiUrl = this.baseUrl + path;

    return this.http.post(apiUrl, body, { headers });

  }

  public serviceRequestCommon(method = 'get', path = '', queryString = '', payLoad: any = {}) {

    let apiUrl = '';

    if (path) {

      // files loaded from the bundled app
      if (path.match(/assets\/jsons/)) {

        apiUrl += path;

      } else {

        // files loaded from the api
        apiUrl = `${this.baseUrl}/ncx-gateway` + path + queryString;

      }

    }
    const messageId = `${this.commonFunctionsHelper.generateUUID()}`;

    const userId = localStorage.getItem('userId');

    const Authorization = localStorage.getItem('id_token') !== null ? localStorage.getItem('id_token') : '';

    if (method === 'get') {

      this.options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization,
          messageId
        })
      };

    } else if (method === 'post' || method === 'put' || method === 'delete' || method === 'patch') {

      this.options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization,
          messageId,
          userId
        })
      };

    }

    switch (method) {

      case 'get':
        return this.doGetService(apiUrl, this.options);
      case 'post':
        return this.doPostService(apiUrl, payLoad, this.options);
      case 'put':
        return this.doPutService(apiUrl, payLoad, this.options);
      case 'delete':
        return this.doDeleteService(apiUrl, this.options);
      case 'patch':
        return this.doPatchService(apiUrl, payLoad, this.options);

    }

  }

  private doPutService(apiUrl, payLoad, options) {

    return this.http.put(apiUrl, payLoad, options).pipe(
      map(res => res),
      catchError((error: Response) => {

        const errMessage = this.handleError(error);

        return throwError(errMessage);

      })
    );

  }

  private doPatchService(apiUrl, payLoad, options) {

    return this.http.patch(apiUrl, payLoad, options).pipe(
      map(res => res),
      catchError((error: Response) => {

        const errMessage = this.handleError(error);

        return throwError(errMessage);

      })
    );

  }

  private doPostService(action, payLoad = {}, options) {

    return this.http.post(action, payLoad, options).pipe(
      map(res => res),
      catchError((error: Response) => {

        const errMessage = this.handleError(error);

        return throwError(errMessage);

      })
    );

  }

  private doDeleteService(apiUrl, options) {

    return this.http.delete(apiUrl, options).pipe(
      map(res => res),
      catchError((error: Response) => {

        const errMessage = this.handleError(error);

        return throwError(errMessage);

      })
    );

  }

  public doGetService(apiUrl, options) {

    return this.http.get(apiUrl, options).pipe(
      map(res => res),
      catchError((error: Response) => {

        const errMessage = this.handleError(error);

        return throwError(errMessage);

      })
    );

  }

  handleError(err: any) {

    console.log('handleError : ', err, this.options);

    // tslint:disable-next-line:max-line-length
    const errCode = (err && err.error && err.error.status) ? err.error.status : (err && err.error && err.error.errorCode)
      ? err.error.errorCode
      : 500;

    // For Duplicate Validation for Story and Group
    if (errCode === 'STORY-001') {

      return 'A story with this title already exists.';

    }
    // NRT-14353 starts
    if (errCode === 'STORY-008') {

      return 'STORY-008';

    }
    // NRT-14353 ends

    if (errCode === 'TOPIC-001') {

      return 'A topic with this title code already exists.';

    }
    if (errCode === 'GROUP-001') {

      return 'A group with this title already exists.';

    }
    if (errCode === 'PROFIL-004') {

      return 'SSO ID already exists and active';

    }
    if (errCode === 'PROFIL-005') {

      return 'SSO ID already exists, please activate the account';

    }
    if (errCode === 'STORY-003') {

      return 'STORY-003';

    }
    if (errCode === 'POST-003') {

      return 'POST-003';

    }
    if (errCode === 'POST-001') {

      return 'POST-001';

    }
    if (errCode === 'GROUP-003') {

      return 'GROUP-003';

    }
    if (errCode === 'ANGLE-006') {

      return 'You Do Not Have Access to the Angle.';

    }
    if (errCode === 'DISCUSSION-003') {

      return 'DISCUSSION-003';

    }

    // tslint:disable-next-line:radix
    switch (parseInt(errCode)) {

      case 500:
        return (err.error && err.error.errorMessage) ? err.error.errorMessage : 'Error in loading data please try again.';
      case 401:
        this.userInfo.getLogingErrorStatus(true, 401, 'Error in loading data Not a ncx user.');
        this.userInfo.getLogingStatus(true);
        this.router.navigate(['error']);
        return 'You are not authorised to access the application.';
      case 403:
        return (err.error && err.error.errorMessage) ? err.error.errorMessage : 'Error in loading data please try again.';
      case 10001:
        this.userInfo.getLogingErrorStatus(true, 10001, 'Error in loading data Not a valid token.');
        this.userInfo.getLogingStatus(true);
        this.router.navigate(['error']);
        return 'Error in loading data Not a valid token.';
      case 10002:
        if (localStorage.getItem('ncxjwttoken') && localStorage.getItem('ncxjwttoken') !== undefined) {

          localStorage.removeItem('id_token');
          localStorage.removeItem('ncx_token_expires_in');
          localStorage.removeItem('ncxjwttoken');

        } else {

          const isCollapsedValue = localStorage.getItem('isCollapsed');

          localStorage.clear();
          localStorage.setItem('isCollapsed', isCollapsedValue);

        }
        this.userInfo.getLogingErrorStatus(true, 10002, 'Your session has expired.');

        // this.userInfo.getLogingStatus(true);
        // this.router.navigate(['error']);
        this.authService.getOpenIdToken();
        return 'Your session has expired';
      case 10003: {

        const isCollapsedValue = localStorage.getItem('isCollapsed');

        localStorage.clear();
        localStorage.setItem('isCollapsed', isCollapsedValue);
        this.userInfo.getLogingErrorStatus(true, 10003, 'Error in loading data Not a ncx user.');
        this.userInfo.getLogingStatus(true);
        this.router.navigate(['/disabled-user']);
        return 'Error in loading data Not a ncx user.';

      }
      case 10004:
        this.userInfo.getLogingErrorStatus(true, 10004, 'Error in loading data Tempered Token.');
        this.userInfo.getLogingStatus(true);
        this.router.navigate(['/disabled-user']);
        return 'Error in loading data Tempered Token.';
      case 10005:
        if (localStorage.getItem('ncxjwttoken') && localStorage.getItem('ncxjwttoken') !== undefined) {

          localStorage.removeItem('id_token');
          localStorage.removeItem('ncx_token_expires_in');
          localStorage.removeItem('ncxjwttoken');

        } else {

          const isCollapsedValue = localStorage.getItem('isCollapsed');

          localStorage.clear();
          localStorage.setItem('isCollapsed', isCollapsedValue);

        }
        this.userInfo.getLogingErrorStatus(true, 10005, 'Token is missing');

        // this.userInfo.getLogingStatus(true);
        // this.router.navigate(['error']);
        this.router.navigate(['/disabled-user']);

        //          this.authService.getOpenIdToken();
        return 'Token is missing';
      default:
        return 'Error in loading data please try again.';

    }

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

}
