<div class="reportable">
   <div>
      <div class="reportableTitle">
         <span>Reportable-Approver</span>
      </div>
   </div>
   <div class="reportableApprover">
      <div class="reportableLabel">
         Is it from an official named source? If not, which senior approved as reportable?
      </div>
      <div class="approverName">
         <nz-input-group
            nzCompact
            class="approverOption">
            <!-- <div class="basis"> -->
            <nz-select
               (ngModelChange)="onChange($event, 'isOfficial')"
               [(ngModel)]="postReportableContent.isOfficial"
               class="reportableOptions">
               <nz-option
                  [nzLabel]="'Yes'"
                  [nzValue]="'Yes'"></nz-option>
               <nz-option
                  [nzLabel]="'No'"
                  [nzValue]="'No'"></nz-option>
            </nz-select>
            <!-- </div> -->
            <!-- <div class="grow-shrink"> -->
            <input
               type="text"
               nz-input
               (ngModelChange)="onChange($event, 'seniorApprovalName')"
               class="approver"
               placeholder="Sr.Approver's name"
               [(ngModel)]="postReportableContent.seniorApprovalName" />
            <!-- </div> -->
         </nz-input-group>
      </div>
      <div class="reportableTextArea">
         <textarea
            rows="4"
            nz-input
            placeholder="Additional notes go here"
            [(ngModel)]="postReportableContent.additionalNotes"
            (ngModelChange)="onChange($event, 'additionalNotes')"></textarea>
      </div>
   </div>
</div>
