<div
   class="story-landing-tab-container posts-tab"
   [attr.data-component]="'ncx/stories/landing/tabs/posts'">
   <div class="filter-post-lg mb-16 margin-screens">
      <ng-container *ngTemplateOutlet="postBtnGroup"></ng-container>
      <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
      <ng-container *ngTemplateOutlet="filterBtn"></ng-container>
   </div>

   <div class="filter-post-xs mb-16 margin-screens">
      <ng-container *ngTemplateOutlet="postBtnGroup"></ng-container>
      <div class="search-filter">
         <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
         <ng-container *ngTemplateOutlet="filterBtn"></ng-container>
      </div>
   </div>

   <app-scroll-container
      [footerHeight]="footerHeight"
      [additionalPadding]="10">
      <ng-template bodyTemplate>
         <div
            #bodyElement
            class="scroll-container-content content-body"
            id="storyContainer"
            data-simplebar
            data-scrollable-container
            infiniteScroll
            [scrollWindow]="false"
            (scrolled)="onLoadMore()"
            (scroll)="scrollContainer()"
            [infiniteScrollDisabled]="!canScrollMore"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="500">
            <!-- POSTS -->
            <div
               *ngIf="filters.controls['type']?.value !== 'Element'"
               class="margin-screens">
               <ncx-post
                  *ngFor="let post of data"
                  [post]="post"
                  [storyId]="storyId"
                  [searchText]="filters.controls['search']?.value"
                  (addToAngle)="showAddToAngleModal($event)"></ncx-post>
            </div>

            <!-- ELEMENTS -->
            <div
               class="element-section margin-screens"
               data-scrollable-container
               *ngIf="filters?.controls['type']?.value === 'Element'">
               <ncx-element
                  *ngFor="let post of data; let postIndex = index"
                  [post]="post"
                  [postIndex]="postIndex"
                  [postDetails]="storyDetails"
                  [isStoryLanding]="true"></ncx-element>
            </div>

            <div
               class="not-found"
               *ngIf="data?.length === 0 && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span *ngIf="filters.controls['type']?.value === 'All'">No Posts Found</span>
                     <span *ngIf="filters.controls['type']?.value === 'Element'">No Elements Found</span>
                     <span *ngIf="filters.controls['type']?.value === 'Standards' || segmentSelectedIndex == 2">
                        There is no Standards Guidance on this story at this time
                     </span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </ng-template>
   </app-scroll-container>

   <app-loader-component
      [isLoaded]="isLoaded"
      loaderType="SECTION_LOADER"></app-loader-component>
</div>

<!-- POSTS, ELEMENTS, STANDARDS BUTTONS -->
<ng-template #postBtnGroup>
   <nz-segmented
      [nzOptions]="options"
      [nzBlock]="isMobile ? true : false"
      [(ngModel)]="segmentSelectedIndex"
      (nzValueChange)="selectPostType($event)"></nz-segmented>
   <!-- <button
         nz-button
         [ngClass]="{ secondary: true, active: filters.controls['type']?.value === 'All' }"
         [nzSize]="isMobile ? 'large' : 'default'"
         (click)="selectPostType('All')">
         <i
            nz-icon
            nzType="unordered-list"
            nzTheme="outline"></i>
         All
      </button>
      <button
         nz-button
         [ngClass]="{ secondary: true, active: filters.controls['type']?.value === 'Element' }"
         [nzSize]="isMobile ? 'large' : 'default'"
         (click)="selectPostType('Element')">
         <i
            nz-icon
            nzType="file-image"
            nzTheme="outline"></i>
         Elements
      </button>
      <button
         nz-button
         [ngClass]="{ secondary: true, active: filters.controls['type']?.value === 'Standards' }"
         [nzSize]="isMobile ? 'large' : 'default'"
         (click)="selectPostType('Standards')">
         <i
            nz-icon
            nzType="audit"
            nzTheme="outline"></i>
         Standards
      </button>
   </nz-button-group> -->
</ng-template>

<!-- SEARCH BAR -->
<ng-template #searchFilter>
   <nz-input-group
      [nzSuffix]="suffixIconSearch"
      [formGroup]="filters"
      class="search-post">
      <input
         type="text"
         nz-input
         placeholder="Search Posts"
         formControlName="search"
         [value]="filters.controls['search']?.value"
         (keyup.enter)="onSearch()" />
   </nz-input-group>
   <ng-template #suffixIconSearch>
      <button (click)="isSearching ? clearSearch() : onSearch()">
         <i
            nz-icon
            [ngClass]="{ 'search-btn': true, 'clear': isSearching }"
            [nzType]="isSearching ? 'close-circle' : 'search'"></i>
      </button>
   </ng-template>
</ng-template>

<!-- FILTERS BTN -->
<ng-template #filterBtn>
   <button
      nz-button
      [ngClass]="['filters', filterDrawerOpen ? 'active' : '', isFiltering ? 'filtering' : '']"
      (click)="onToggleFilterDrawer()">
      <i
         nz-icon
         nzType="filter"
         [nzTheme]="filterDrawerOpen && !isFiltering ? 'outline' : isFiltering ? 'fill' : 'outline'"></i>
   </button>
</ng-template>

<ng-template #closeIconTpl>
   <span
      nz-icon
      class="close-icon"
      nzType="close"
      nzTheme="outline"></span>
</ng-template>
