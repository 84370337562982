<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout [attr.data-component]="'ncx/profile-section/profile/profile.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <!-- HISTORY AND PROFILE TABS -->
      <nz-page-header-title>
         <nz-button-group>
            <button
               nz-button
               nzType="primary"
               (click)="switchTab('Profile')">
               Profile
            </button>
            <button
               nz-button
               (click)="switchTab('History')">
               History
            </button>
         </nz-button-group>
      </nz-page-header-title>
   </nz-page-header>

   <nz-content class="profile-container">
      <div nz-row>
         <div
            nz-col
            [nzXs]="24"
            [nzSm]="24"
            [nzMd]="24"
            [nzLg]="6">
            <div class="image">
               <div *ngIf="!editProfile">
                  <nz-avatar
                     [nzSize]="80"
                     [nzSrc]="avatarImgUrl"
                     nzIcon="user"></nz-avatar>
               </div>

               <!-- EDIT MODE (UPLOAD PROFILE IMAGE) -->
               <div *ngIf="editProfile">
                  <nz-upload
                     class="avatar-uploader"
                     nzName="avatar"
                     nzListType="picture-card"
                     [nzFileList]="fileList"
                     [nzShowUploadList]="showUploadList"
                     [nzBeforeUpload]="beforeUpload"
                     [nzCustomRequest]="uploadImage"
                     [nzPreview]="handlePreview"
                     [nzHeaders]="setMediaUploadHeaders">
                     <div class="photo">
                        <button
                           class="btn"
                           nz-button
                           nzType="primary"
                           nzShape="circle">
                           <i
                              nz-icon
                              nzType="edit"></i>
                        </button>
                     </div>
                  </nz-upload>
               </div>

               <!-- PROFILE NAME (WHEN NOT IN EDIT MODE) -->
               <p
                  class="name"
                  *ngIf="!editProfile">
                  {{ userName }}
               </p>

               <!-- EDIT MODE (UPLOAD IMAGE) -->
               <div *ngIf="!isViewProfile">
                  <p *ngIf="editProfile">Max image file size is 1MB</p>

                  <button
                     *ngIf="!editProfile"
                     nz-button
                     nzType="primary"
                     nzGhost
                     (click)="enterEditMode()">
                     Edit Profile
                  </button>

                  <button
                     *ngIf="editProfile"
                     nz-button
                     nzType="primary"
                     (click)="documentEditForm.ngSubmit.emit()"
                     [disabled]="!profileForm.valid">
                     Update Profile
                  </button>
               </div>

               <!-- VIEW ONLY MODE (PROFILE CAN BE FOLLOWED/UNFOLLOWED) -->
               <div *ngIf="isViewProfile">
                  <button
                     *ngIf="!isUserFollowing"
                     (click)="followUser(userId, 'post')"
                     nzBlock="true"
                     nz-button
                     nzType="primary">
                     Follow
                  </button>

                  <button
                     *ngIf="isUserFollowing"
                     (click)="followUser(userId, 'delete')"
                     nzBlock="true"
                     nz-button
                     nzType="default">
                     Unfollow
                  </button>
               </div>
            </div>
         </div>

         <div
            nz-col
            [nzXs]="24"
            [nzSm]="24"
            [nzMd]="24"
            [nzLg]="16">
            <div class="informationSection">
               <div nz-row>
                  <div
                     class="leftSection"
                     nz-col
                     [nzXs]="24"
                     [nzSm]="24"
                     [nzMd]="24"
                     [nzLg]="24"
                     [nzXl]="24"
                     [nzXXl]="12">
                     <!-- NOT EDITING -->
                     <ng-container *ngIf="!editProfile">
                        <section class="profile-section">
                           <p>Your Info</p>
                           <ul>
                              <li *ngFor="let yourInfo of profileListYourInfo.name">
                                 <span class="label">{{ yourInfo.label }}:</span>
                                 <span class="value">{{ yourInfo.value }}</span>
                              </li>
                           </ul>
                        </section>

                        <section class="profile-section">
                           <p>Phone</p>
                           <ul>
                              <ng-container *ngFor="let phone of profileListYourInfo.phone">
                                 <li *ngIf="shouldShowContactDetails(phone.label)">
                                    <span class="label">{{ phone.label }}:</span>
                                    <span class="value">{{ formatPhoneNumber(phone.value) }}</span>
                                 </li>
                              </ng-container>
                           </ul>
                        </section>

                        <section class="profile-section">
                           <p>Email</p>
                           <ul>
                              <li *ngFor="let email of profileListYourInfo.email">
                                 <span class="label">{{ email.label }}:</span>
                                 <span class="value">{{ email.value }}</span>
                              </li>
                           </ul>
                        </section>

                        <section class="profile-section">
                           <p>Work Info</p>
                           <ul>
                              <ng-container *ngFor="let workInfo of profileListYourInfo.workinfo">
                                 <li *ngIf="shouldShowWorkInfo(workInfo.label) && workInfo.label !== 'statetext'">
                                    <span class="label">{{ workInfo.label }}:</span>
                                    <span class="value">{{ workInfo.value }}</span>
                                 </li>
                              </ng-container>
                           </ul>
                        </section>
                     </ng-container>

                     <!-- EDITING -->
                     <ng-container *ngIf="editProfile">
                        <form
                           nz-form
                           [formGroup]="profileForm"
                           class="profile-form"
                           [nzLayout]="formLayout"
                           #documentEditForm="ngForm"
                           (ngSubmit)="submitForm()">
                           <div formGroupName="name">
                              <p class="descriptions-title">Your Info</p>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">First</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid first name">
                                    <input
                                       nz-input
                                       formControlName="firstName"
                                       minlength="1"
                                       maxlength="40"
                                       [disabled]="true"
                                       [readonly]="true"
                                       placeholder="First Name......" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Middle</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid middle name">
                                    <input
                                       nz-input
                                       formControlName="middleName"
                                       minlength="1"
                                       maxlength="40"
                                       [disabled]="true"
                                       [readonly]="true"
                                       placeholder="Middle Name......" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Last</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid last name">
                                    <input
                                       nz-input
                                       formControlName="lastName"
                                       placeholder="Last Name......"
                                       [disabled]="true"
                                       [readonly]="true"
                                       minlength="1"
                                       maxlength="40" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Display Name</nz-form-label>
                                 <nz-form-control>
                                    <input
                                       nz-input
                                       formControlName="preferredName"
                                       placeholder=" Display Name......"
                                       [disabled]="true"
                                       [readonly]="true"
                                       minlength="1"
                                       maxlength="40" />
                                 </nz-form-control>
                              </nz-form-item>
                           </div>
                           <div
                              style="margin-top: 40px"
                              formGroupName="phone">
                              <p class="descriptions-title">Phone</p>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Work</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid work phone">
                                    <input
                                       nz-input
                                       formControlName="workPhone"
                                       placeholder="(xxx) xxx-xxxx"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Mobile</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid personal phone">
                                    <input
                                       nz-input
                                       formControlName="personalPhone"
                                       placeholder="(xxx) xxx-xxxx"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Alternate</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid alternate phone">
                                    <input
                                       nz-input
                                       formControlName="alternatePhone"
                                       placeholder="(xxx) xxx-xxxx" />
                                 </nz-form-control>
                              </nz-form-item>
                           </div>
                           <div
                              style="margin-top: 40px"
                              formGroupName="email">
                              <p class="descriptions-title">Email</p>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Work</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please enter a valid NBCU email address">
                                    <input
                                       nz-input
                                       formControlName="workEmail"
                                       placeholder="username@nbcuni.com"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Alternate</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid alternate email">
                                    <input
                                       nz-input
                                       formControlName="alternateEmail"
                                       placeholder="username@nbcuni.com" />
                                 </nz-form-control>
                              </nz-form-item>
                           </div>
                        </form>
                     </ng-container>
                  </div>
                  <div
                     class="rightSection"
                     nz-col
                     [nzXs]="24"
                     [nzSm]="24"
                     [nzMd]="24"
                     [nzLg]="24"
                     [nzXl]="24"
                     [nzXXl]="12">
                     <!-- EDITING -->
                     <ng-container *ngIf="editProfile">
                        <form
                           nz-form
                           class="profile-form"
                           #documentEditForm="ngForm"
                           [formGroup]="profileForm"
                           [nzLayout]="formLayout"
                           (ngSubmit)="submitForm()">
                           <div formGroupName="workInfo">
                              <p class="descriptions-title">Work Info</p>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Job Title</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid job title">
                                    <input
                                       nz-input
                                       formControlName="jobTitle"
                                       placeholder="Job Title...."
                                       [disabled]="true"
                                       [readonly]="true"
                                       maxlength="40" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Company</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please enter a company">
                                    <input
                                       nz-input
                                       formControlName="company"
                                       [disabled]="true"
                                       [readonly]="true"
                                       placeholder="Please enter a company" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Division</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please enter division">
                                    <input
                                       nz-input
                                       formControlName="division"
                                       [disabled]="true"
                                       [readonly]="true"
                                       placeholder="Please enter division" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Group</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please enter group">
                                    <input
                                       nz-input
                                       formControlName="group"
                                       [disabled]="true"
                                       [readonly]="true"
                                       placeholder="Please enter group" />
                                 </nz-form-control>
                              </nz-form-item>
                              <!-- <label class="horizontalLine"></label> -->
                              <nz-divider></nz-divider>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Address</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please input address">
                                    <input
                                       nz-input
                                       formControlName="address"
                                       placeholder="Address...."
                                       [disabled]="true"
                                       [readonly]="true"
                                       maxlength="80" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Building</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please select a building">
                                    <input
                                       nz-input
                                       formControlName="building"
                                       placeholder="Please enter building"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Office</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please input office">
                                    <input
                                       nz-input
                                       formControlName="office"
                                       placeholder="please enter office"
                                       [disabled]="true"
                                       [readonly]="true"
                                       maxlength="80" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">City</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide city">
                                    <input
                                       nz-input
                                       formControlName="city"
                                       placeholder="Please select city"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">State</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please select state">
                                    <input
                                       nz-input
                                       formControlName="state"
                                       placeholder="Please enter state"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Zip</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please provide valid zip code">
                                    <input
                                       nz-input
                                       formControlName="zip"
                                       placeholder="Zip Code...."
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                              <nz-form-item>
                                 <nz-form-label [nzSpan]="5">Country</nz-form-label>
                                 <nz-form-control
                                    nzValidatingTip="Validating..."
                                    nzErrorTip="Please select country">
                                    <input
                                       nz-input
                                       formControlName="country"
                                       placeholder="Please enter country"
                                       [disabled]="true"
                                       [readonly]="true" />
                                 </nz-form-control>
                              </nz-form-item>
                           </div>
                        </form>
                     </ng-container>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </nz-content>
</nz-layout>

<!-- VIEW PREVIEW IMAGE -->
<nz-modal
   [nzVisible]="previewVisible"
   (nzOnCancel)="hidePreview()"
   [nzFooter]="null">
   <ng-container *nzModalContent>
      <img
         [src]="previewImage"
         [alt]="previewImage"
         [ngStyle]="{ width: '100%' }" />
   </ng-container>
</nz-modal>
