<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/reportable'">
         <p class="header">Reportable</p>

         <div class="body">
            <section>
               <p>Is it from an official named source? If not which senior approved as reportable?</p>
               <div class="approverName">
                  <nz-input-group nzCompact>
                     <!-- [(ngModel)]="postReportableContent.isOfficial"  -->
                     <nz-select
                        (ngModelChange)="onChange($event, 'isOfficial')"
                        [(ngModel)]="postReportableContent.isOfficial"
                        style="width: 20%">
                        <nz-option
                           [nzLabel]="'Yes'"
                           [nzValue]="'Yes'"></nz-option>
                        <nz-option
                           [nzLabel]="'No'"
                           [nzValue]="'No'"></nz-option>
                     </nz-select>
                     <input
                        style="width: 80%"
                        type="text"
                        nz-input
                        (ngModelChange)="onChange($event, 'seniorApprovalName')"
                        placeholder="Senior Approval's Name"
                        [(ngModel)]="postReportableContent.seniorApprovalName" />
                  </nz-input-group>
               </div>
            </section>

            <section>
               <p>Additional Notes</p>
               <textarea
                  rows="6"
                  nz-input
                  placeholder="(Optional)Additional Notes goes here"
                  [(ngModel)]="postReportableContent.additionalNotes"
                  (ngModelChange)="onChange($event, 'additionalNotes')"></textarea>
            </section>
         </div>
      </div>
   </ng-template>
   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         [disabled]="!isLoaded"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="handleOk()">
         Submit
      </button>
   </ng-template>
</nz-modal>
