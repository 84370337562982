<!-- ALL VISIBLE TAGS -->
<ng-container *ngFor="let label of labels">
   <nz-tag [ngClass]="['ncx-label', labelsCSSClass[label]]">
      {{ label }}
   </nz-tag>
</ng-container>

<!-- BUTTON FOR + LABELS -->
<button
   *ngIf="extraLabels.length"
   nz-button
   nz-popover
   nzSize="small"
   class="post-extra-btn"
   [nzPopoverContent]="contentTemplate"
   [nzPopoverTrigger]="'click'"
   [(nzPopoverVisible)]="visible"
   nzPopoverPlacement="bottom">
   {{ '+' + extraLabels.length }}

   <!-- OVERFLOW LABELS -->
   <ng-template #contentTemplate>
      <div class="tags-container">
         <ng-container *ngFor="let label of extraLabels">
            <nz-tag [ngClass]="['ncx-label', labelsCSSClass[label]]">
               {{ label }}
            </nz-tag>
         </ng-container>
         <a  *ngIf="isMobile" (click)="closeExtraTags()">Close</a>
      </div>
</ng-template>
</button>

