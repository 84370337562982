<div class="storyPostDescription">
   <video
      id="my-video"
      muted
      preload="auto"
      controls
      width="250px"
      height="250px">
      <!-- <source src='https://www.w3schools.com/html/mov_bbb.mp4'/> -->
      <source
         [src]="item"
         type="video/mp4" />
      <!-- <source [src]='item' type="video/mov"> -->
      <!-- <p class="vjs-no-js">
				To view this video please enable JavaScript, and consider upgrading to a web browser that
				<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
		</p> -->
   </video>
</div>
