import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@services/toastService/toastMessage.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {

  loading : boolean = false;

  constructor(
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {

    this.loading = true;
    if (!this.router.url.match('access_token%')) {

      this.toastService.createMessage('error', 'Requested resource not found.');      
    
    }
    
    timer(5000).subscribe(() => {

      this.loading = false;
      this.router.navigate(['']);
    
    });

  }

}
