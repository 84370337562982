import { PersonalizedColumn } from '@models/ncx/dashboard';

export interface Permissions {
  [key: string]: boolean;
}

export interface IFunctionAbility {
  fa_access_admin_console: boolean;
  fa_access_private_group: boolean;
  fa_access_private_story: boolean;
  fa_access_public_group: boolean;
  fa_add_editor_on_duty: boolean;
  fa_add_post_label: boolean;
  fa_add_self_editor_on_duty: boolean;
  fa_branch_reply: boolean;
  fa_change_story_state: boolean;
  fa_create_draft: boolean;
  fa_create_group: boolean;
  fa_create_post: boolean;
  fa_create_private_story: boolean;
  fa_create_public_story: boolean;
  fa_create_reply: boolean;
  fa_create_sys_announcement: boolean;
  fa_delete_post: boolean;
  fa_delete_reply: boolean;
  fa_delete_story: boolean;
  fa_edit_group: boolean;
  fa_edit_post: boolean;
  fa_edit_reply: boolean;
  fa_edit_story: boolean;
  fa_hot_alert_post: boolean;
  fa_imp_flagged_post: boolean;
  fa_invite_group_members: boolean;
  fa_manage_group_members: boolean;
  fa_merge_story: boolean;
  fa_move_post: boolean;
  fa_pin_post: boolean;
  fa_standard_post: boolean;
  fa_view_post: boolean;
  fa_view_story: boolean;
  fa_post_versions: boolean;
  fa_add_post_via_email: boolean;
  fa_create_private_post: boolean;
  fa_create_public_post: boolean;
  fa_unlock_post: boolean;
  fa_create_public_angle: boolean;
  fa_view_public_angle: boolean;
  fa_edit_public_angle: boolean;
  fa_delete_public_angle: boolean;
  fa_create_private_angle: boolean;
  fa_view_private_angle: boolean;
  fa_edit_private_angle: boolean;
  fa_delete_private_angle: boolean;
}

export interface DeviceDetails {
  deviceId: any;
  deviceToken: any;
  active: boolean;
  deviceName: any;
  deviceLocation: any;
  addedDate: any;
  notificationFlag: boolean;
}

export interface User {
  userId: number;
  ssoId: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName?: string;
  displayName: string;
  profilePictureURL: string;
  jobTitle: string;
  role: string;
  workingEmail: string;
  email?: string;
}

export interface Profile {
  userId: number;
  ssoId: number;
  role: string;
  onDuty: boolean;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
    preferredName: string;
  };
  phone: {
    workPhone: string;
    personalPhone: string;
    alternatePhone: string;
  };
  email: {
    workEmail: string;
    personalEmail: string;
    alternateEmail: string;
  };
  workInfo: {
    jobTitle: string;
    company: string;
    division: string;
    group: string;
    address: string;
    building: string;
    office: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  userSettings: UserSettings;
  profilePictureURL: string;
  profilePicture: string;
  userAccess: string[];
  deviceDetails: any;
  lastLogin: string;
  createdOn: string;
  updatedOn: string;
  createdBy: string;
  updatedBy: string;
  dateTrained: string;
  suphierarchy: string;
  ldapActive: boolean;
  personalizedColumns: PersonalizedColumn[];
  organization: string;
  parentOrganizationId: string;
  active: boolean;
}

export interface UserSettings {
  enableDarkMode: boolean;
  notifyConnectedSummaries: boolean;
  notifyConnections: boolean;
  notifyFlaggedUpdates: boolean;
  notifyFollowing: boolean;
  notifyHotAlerts: boolean;
  notifyImportantUpdates: boolean;
  notifyReadyUpdates: boolean;
  notifyStandardUpdates: boolean;
  searchRelevancy: boolean;
  showDivision: boolean;
  showJobTitle: boolean;
  showMobilePhone: boolean;
  showWorkLocation: boolean;
  showWorkPhone: boolean;
}

export interface UserPermissions extends User {
  allowAdd: boolean;
}

export enum PERMISSIONS {

  // Stories
  CREATE_PUBLIC_STORY = 'fa_create_public_story',
  CREATE_PRIVATE_STORY = 'fa_create_private_story',
  ACCESS_PRIVATE_STORY = 'fa_access_private_story',
  CHANGE_STORY_STATE = 'fa_change_story_state',
  VIEW_STORY = 'fa_view_story',
  EDIT_STORY = 'fa_edit_story',
  DELETE_STORY = 'fa_delete_story',
  MERGE_STORY = 'fa_merge_story',

  // Posts
  CREATE_PRIVATE_POST = 'fa_create_private_post',
  CREATE_PUBLIC_POST = 'fa_create_public_post',
  CREATE_POST = 'fa_create_post',
  VIEW_POST = 'fa_view_post',
  EDIT_POST = 'fa_edit_post',
  MOVE_POST = 'fa_move_post',
  DELETE_POST = 'fa_delete_post',
  ADD_POST_LABEL = 'fa_add_post_label',
  POST_VERSIONS = 'fa_post_versions',
  PIN_POST = 'fa_pin_post',
  HOT_ALERT_POST = 'fa_hot_alert_post',
  STANDARD_POST = 'fa_standard_post',
  IMP_FLAGGED_POST = 'fa_imp_flagged_post',
  ADD_POST_VIA_EMAIL = 'fa_add_post_via_email',
  CREATE_DRAFT = 'fa_create_draft',
  UNLOCK_POST = 'fa_unlock_post',

  // Angles
  CREATE_PRIVATE_ANGLE = 'fa_create_private_angle',
  VIEW_PRIVATE_ANGLE = 'fa_view_private_angle',
  EDIT_PRIVATE_ANGLE = 'fa_edit_private_angle',
  DELETE_PRIVATE_ANGLE = 'fa_delete_private_angle',
  CREATE_PUBLIC_ANGLE = 'fa_create_public_angle',
  VIEW_PUBLIC_ANGLE = 'fa_view_public_angle',
  EDIT_PUBLIC_ANGLE = 'fa_edit_public_angle',
  DELETE_PUBLIC_ANGLE = 'fa_delete_public_angle',

  // Comments/Replies
  CREATE_COMMENT = 'fa_create_reply',
  EDIT_COMMENT = 'fa_edit_reply',
  DELETE_COMMENT = 'fa_delete_reply',
  BRANCH_COMMENT = 'fa_branch_reply',

  // Admin
  CREATE_ANNOUNCEMENT = 'fa_create_sys_announcement',
  ACCESS_ADMIN_CONSOLE = 'fa_access_admin_console',
  ADD_EDITOR_ON_DUTY = 'fa_add_editor_on_duty',
  EDIT_MARK_AS = 'fa_edit_mark_as',
  EDIT_STATUS_VALUES = 'fa_edit_status_values',

  // Groups
  CREATE_GROUP = 'fa_create_group',
  ACCESS_PUBLIC_GROUP = 'fa_access_public_group',
  ACCESS_PRIVATE_GROUP = 'fa_access_private_group',
  MANAGE_GROUP_MEMBERS = 'fa_manage_group_members',
  INVITE_GROUP_MEMBERS = 'fa_invite_group_members',
  EDIT_GROUP = 'fa_edit_group'

}
