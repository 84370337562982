import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[header-left]'
})
class HeaderLeftTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) {}

}

@Directive({
  selector: '[header-right]'
})
class HeaderRightTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) {}

}

export {
  HeaderLeftTemplateDirective,
  HeaderRightTemplateDirective
};

