<div
   class="StoryFollowing"
   [attr.data-component]="'ncx/stories/following'">
   <div
      nz-row
      [nzGutter]="24">
      <div
         nz-col
         nzXs="24"
         nzSm="24"
         nzMd="24"
         nzLg="24"
         nzXl="24"
         nzXXl="12"
         *ngFor="let following of storyFollowingDetails; index as i">
         <nz-card class="cardSize">
            <div
               class="viewProfileLeft"
               *ngIf="following.displayName && following.displayName.split(',').length > 1">
               <nz-card-meta
                  [nzAvatar]="avatarTemplate"
                  nzTitle="{{ following.displayName.split(',')[1].trim() }} {{ following.displayName.split(',')[0] }}"
                  nzDescription="{{ following.jobTitle }}"></nz-card-meta>
            </div>
            <div
               class="viewProfileLeft"
               *ngIf="following.displayName && following.displayName.split(',').length === 1">
               <nz-card-meta
                  [nzAvatar]="avatarTemplate"
                  nzTitle="{{ following.displayName.trim() }}"
                  nzDescription="{{ following.jobTitle }}"></nz-card-meta>
            </div>
            <div *ngIf="screen === 'MANAGE-MEMBERS'">
               <a
                  class="deleteProfile"
                  (click)="deleteProfile(following)">
                  Delete
               </a>
            </div>
            <div *ngIf="screen !== 'MANAGE-MEMBERS'">
               <a
                  class="viewProfile"
                  (click)="toggleSider(following.userId, i)">
                  View Profile
               </a>
            </div>
         </nz-card>
         <ng-template #avatarTemplate>
            <nz-avatar
               *ngIf="following.profilePictureURL !== '' && following.profilePictureURL !== null"
               [nzSize]="48"
               [nzSrc]="Common.formS3Link(following.profilePictureURL)"></nz-avatar>
            <nz-avatar
               *ngIf="following.profilePictureURL === '' || following.profilePictureURL === null || undefined"
               [nzSize]="48"
               nzIcon="user"></nz-avatar>
         </ng-template>
      </div>
   </div>
</div>
<nz-drawer
   [nzTitle]=""
   [nzClosable]="false"
   [nzVisible]="isFollowingingCollapsed"
   [nzWrapClassName]="'no-padding'"
   nzPlacement="right"
   [nzWidth]="320"
   (nzOnClose)="onClose()">
   <ng-container *nzDrawerContent>
      <app-toggle-section
         [isStoryFollowing]="isStoryFollowing"
         [isDrawer]="true"
         (isFollowingingCollapsed)="onClose()"
         [storyFollowingData]="storyFollowingData"
         [viewProfile]="isViewProfile"
         (isStoriesLandingCollapsed)="closeDrawer()"></app-toggle-section>
   </ng-container>
</nz-drawer>
