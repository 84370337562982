import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ISearch, StorySearchResult } from '@models/ncx/global-search';
import { CommonService } from '@services/common-service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

type StoryParams = {
  contentType: 'Story',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string
};

type StoryPayload = {
  story: {
    filter: {
      following: string,
      date?: {
        fieldName: string,
        startTime: string,
        endTime: string
      }
    }
  }
};

@Component({
  selector: 'app-stories-results',
  templateUrl: './stories-results.component.html',
  styleUrls: ['../search-results-common.scss']
})
export class StoriesResultsComponent implements OnChanges, OnDestroy {

  @Input() isVisible: boolean = false;

  // Search Results from API
  searchResults: StorySearchResult[] = [];

  // Current page of search results
  page: number = 1;

  // Number of results per page
  limit: number = 10;

  // Total number of results
  total: number = 0;

  //scroll distance
  scrollDistance: number = 2;

  // Loader
  isLoaded: boolean = true;

  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();

  private subs = new SubSink();

  constructor(
    private router: Router,
    private commonService: CommonService,
    private gaService: GoogleAnalyticsEventService,
    private toastService: ToastService,
    public searchService: GlobalSearchService
  ) {

    // When search term changes, load results if component is visible
    this.subs.sink = this.searchService.searchTerm.subscribe(() => {


      // When search term changes, reset results regardless of whether the component is visible or not
      // When the user returns to this tab, a new list of search results will load
      this.resetResults();
      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.loadSearchResults();

      }

    });

    // When filters changes, load results if component is visible
    this.subs.sink = this.searchService.filterChanged.subscribe(() => {

      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.resetResults();
        this.loadSearchResults();

      }

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    // When visibility changes, load search results if visible and if the results we're already loaded
    if ('isVisible' in changes) {

      if (changes.isVisible.currentValue && !this.searchResults.length) {

        this.loadSearchResults();

      }
      if (!changes.isVisible.currentValue) {

        this.cancelRequest.next(true);

      }

    }

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Load search results
   *
   */
  loadSearchResults() {

    this.isLoaded = false;

    const params = this.buildParams();

    const payload = this.buildPayload();

    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&');

    this.searchService.loadSearchResults(queryString, payload).pipe(takeUntil(this.cancelRequest.pipe(filter(value => value === true)))).subscribe({
      next: (res: any) => {

        const results: ISearch = res as ISearch;

        const searchResults = results.storySearchResult || [];

        this.searchResults = this.page === 1 ? searchResults : [...this.searchResults, ...searchResults];

        // this.searchResults?.forEach((story) => {

        //   story.autoGeneratedStoryId = 'HardCoded - SA47FL0001';

        // });

        this.total = results.storyCount;

        console.log('loadSearchResults', { searchResults: this.searchResults, total: this.total });

        this.searchService.setSuggestedSearch(results.contentSuggestedPhrase);

        this.sendGATracking(params, payload);

      },
      error: (error: any) => {

        this.toastService.createMessage('error', 'Unable to load search results');

        console.error('loadSearchResults', error);

      }
    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Build the query string portion of the API call
   *
   */
  buildParams(): StoryParams {

    // API Params
    const params: StoryParams = {
      contentType: 'Story',
      startIndex: 0,
      endIndex: this.limit,
      sortField: 'modificationdate',
      sortOrder: 'descending'
    };

    try {

      // Page
      const { start: startIndex, end: endIndex } = this.startEndIndex;

      params.startIndex = startIndex;
      params.endIndex = endIndex;

      // Sort
      let sortField = 'title';

      let sortOrder = 'ascending';

      const { sort } = this.searchService.filters.STORIES;

      // Sort
      switch (sort) {

        case 'newestFirst':
        case 'oldestFirst':
          sortField = 'modificationDate';
          sortOrder = sort === 'newestFirst' ? 'descending' : 'ascending';
          break;

        case 'mostFollowers':
        case 'leastFollowers':
          sortField = 'followerCount';
          sortOrder = sort === 'mostFollowers' ? 'descending' : 'ascending';
          break;

        case 'mostPost':
        case 'leastPost':
          sortField = 'postCount';
          sortOrder = sort === 'mostPost' ? 'descending' : 'ascending';
          break;

        case 'A-Z':
        case 'Z-A':
          sortField = 'title';
          sortOrder = sort === 'Z-A' ? 'descending' : 'ascending';
          break;

      }

      params.sortField = sortField;
      params.sortOrder = sortOrder;

      // Start/End Index
      const { start, end } = this.startEndIndex;

      params.startIndex = start;
      params.endIndex = end;

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as StoryParams;

    }

  }

  /**
   * Build the Payload/Body portion of the API call
   *
   */
  buildPayload(): StoryPayload {

    // API Payload
    const payload: StoryPayload = {
      story: {
        filter: {
          following: 'All',
          date: {
            fieldName: 'modificationdate',
            startTime: '',
            endTime: ''
          }
        }
      }
    };

    try {

      const { following, modificationDate } = this.searchService.filters.STORIES;

      // Following
      payload.story.filter.following = following;

      // Date
      if (Array.isArray(modificationDate) && modificationDate.length) {

        const [start, end] = modificationDate;

        const startDateTime = Time.convertLocalToLocal(start);

        const endDateTime = Time.convertLocalToLocal(end);

        const startDate = Time.convertUtcToUtcDate(startDateTime) + ' 00:00:00';

        const endDate = Time.convertUtcToUtcDate(endDateTime) + ' 23:59:59';

        payload.story.filter.date.startTime = Time.convertUTCToTimeStamp(startDate);
        payload.story.filter.date.endTime = Time.convertUTCToTimeStamp(endDate);

      } else {

        // If there are no dates, the date filter needs to be removed from the payload
        delete payload.story.filter.date;

      }

      console.log('buildPayload', payload);

      return payload;

    } catch (error) {

      console.error('buildPayload', error);

      return {} as StoryPayload;

    }

  }

  /**
   * Send Google Analytics tracking data
   *
   */
  sendGATracking(params: StoryParams, payload: StoryPayload) {

    const searchTerm = this.searchService.searchTerm.value;

    console.log('Google Analytics Tracking (Stories)', { searchTerm, params, payload });

    this.gaService.trackContentResultsCount(searchTerm, this.total, 'Story Results Count', 'Story Content');

    // Track Sort
    if (params.sortOrder && params.sortField) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Sort - ${params.sortField} - ${params.sortOrder}`, this.total,
        'Story Content');

    }

    // Track Date Start/End
    if (payload.story.filter?.date?.fieldName) {

      this.gaService.trackSelectedContentFilter(searchTerm, 'DateFilter - modificationdate', this.total, 'Story Selected Filter');

    }

    // Track Following
    if (payload.story.filter?.following) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Following - ${payload.story.filter.following}`, this.total, 'Story Selected Filter');

    }

  }

  /**
   * View Story event
   *
   */
  viewStory(story: StorySearchResult, index: number) {

    const searchTerm = this.searchService.searchTerm.value;

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, searchTerm, 'Selected Story Index', 'Story Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(this.page, searchTerm, 'Selected Story Page Number', 'Story Content');

  }

  /**
   * On page scroll, load next page
   *
   */
  onScrollDown() {

    const { end } = this.startEndIndex;

    console.log('onScrollDown (Stories)');

    // If the end index is >= number of results
    if (end >= this.total) {

      console.log('No More Results');
      return;

    }

    this.page += 1;

    this.scrollDistance = 0;
    setTimeout(() => this.scrollDistance = 2, 100);

    this.cancelRequest.next(false);

    this.loadSearchResults();

  }

  /**
   * Reset all results
   *
   */
  resetResults() {

    this.total = 0;
    this.page = 1;
    this.searchResults = [];

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  get resultCount(): string {

    return `${Common.formatNumber(this.total)} Result${this.total !== 1 ? 's' : ''}`;

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }

}
