import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })

export class TruncateTextPipe implements PipeTransform {

  /**
   * Truncates a string
   *
   *
   */
  transform(text: string, length: number = 5): string {

    try {

      const words = text.split(' ');

      // console.log(words, length);

      return words.length > length ? words.splice(0, length).join(' ') + '...' : words.join(' ');

    } catch (error) {

      console.error('TruncateTextPipe ', error);

      return text;

    }

  }

}
