import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { Post } from '@models/ncx/post';
import { CommonService } from '@services/common-service';
import { Time } from '@utilities/time';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-angle-material',
  templateUrl: './angle-material.component.html',
  styleUrls: ['./angle-material.component.scss']
})
export class AngleMaterialComponent implements OnChanges, OnInit, OnDestroy {

  // Fetching Angle Log by Angle Id
  @Input() angleId!: string;

  // Emits when Scroll down the bottom of page
  @Input() scrollDownEvent: Observable<boolean>;

  // Angle Material Active tab name
  @Input() materialSelectedTab: string = 'all';

  // Angle Material Post search form group
  @Input() postSearchForm!: UntypedFormGroup;

  // Angle Material Post filter form group
  @Input() postFilterForm!: UntypedFormGroup;

  // To prevent Switching the Material tab
  @Output() isAPILoadedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  // API Loader
  isLoaded = true;

  // Get for Search URL
  inPageSearchApiUrl = environment.getInpageSearchURL;

  // Angle Material Post API response
  postDetails = [];

  // Angle Material Post Search variable
  searchPostInput = '';

  // storyId = '1696534761021';

  // Angle Material Post Share toggle varible
  isVisible = false;

  // Angle Material Post Share message
  shareMessage = '';

  // Angle Material Post Share post id
  postID: number;

  // Start page variable and Per page contains 10 records
  contentPage: number = 1;

  // Scroll Event unsubscribe in ngondestroy from infinite Observables
  private eventsSubscription: Subscription;

  // Take the subscription util ngOnDestroy
  private destroy$ = new Subject();

  /**
   * Default method of the class that is executed when the class is instantiated
   * Setup Dependency Injection
   */
  constructor(
    private cService: CommonService
  ) { }

  /**
   * ngOnChanges Angular Life cycle
   * Called when any data-bound property of a directive changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.materialSelectedTab) {

      this.createQueryStringParams(false);

    }

  }

  /**
   * ngOnInit Angular Life cycle
   * Angular has initialized all data-bound properties of a directive
   */
  ngOnInit(): void {

    this.eventsSubscription = this.scrollDownEvent.subscribe(value => {

      value && this.createQueryStringParams(true);

    });
    this.formValueChanges();

  }

  /**
   * Reactive Form value changes (postSearchForm, postFilterForm) captured in this function
   */
  formValueChanges(): void {

    this.postSearchForm.get('searchPost')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {

      this.searchPostInput = value;
      this.createQueryStringParams(false);

    });

    this.postFilterForm.valueChanges.pipe(debounceTime(750), takeUntil(this.destroy$)).subscribe(_ => {

      this.createQueryStringParams(false);

    });

  }

  /**
   * Create Query string to pass that in URL based on several condition
   *
   */
  createQueryStringParams(isScrolled: boolean) {

    this.isLoaded = false;
    this.isAPILoadedEvent.emit(this.isLoaded);
    if (isScrolled) {

      this.contentPage += 1;

    } else {

      this.contentPage = 1;
      this.postDetails = [];

    }

    // Sort By Filter
    let queryStr = `?pageType=AngleDetails&contentPage=${this.contentPage}&isPublished=true`;

    const sortBy = this.postFilterForm.get('sortBy')?.value;

    if (sortBy === 'descending') {

      queryStr += '&postSortField=modificationdate&postSortOrder=descending';

    } else if (sortBy === 'ascending') {

      queryStr += '&postSortField=modificationdate&postSortOrder=ascending';

    }

    // Date Filter
    const dates = this.postFilterForm.get('date')?.value;

    if (dates && Array.isArray(dates) && dates[0] && dates[1]) {

      const startDateTime = Time.convertLocalToLocal(dates[0]);

      const endDateTime = Time.convertLocalToLocal(dates[1]);

      const startDate = Time.convertUtcToUtcDate(startDateTime) + ' 00:00:00';

      const endDate = Time.convertUtcToUtcDate(endDateTime) + ' 23:59:59';

      const startDateTimeStamp = Time.convertUTCToTimeStamp(startDate);

      const endDateTimeStamp = Time.convertUTCToTimeStamp(endDate);

      queryStr += `&startDate=${startDateTimeStamp}&endDate=${endDateTimeStamp}`;

    }

    // Search keyword
    const searchPostInput = this.postSearchForm.get('searchPost')?.value;

    if (searchPostInput && searchPostInput.trim() !== '') {

      queryStr += `&searchString=${encodeURIComponent(searchPostInput)}`;

    } else {

      queryStr += '&searchString=';

    }
    this.getPostDetails(queryStr);

  }

  /**
   * Create Payload based on filter section labels
   *
   */
  createPayload(angleId: string) {

    const postFilterFormValues = JSON.parse(JSON.stringify(this.postFilterForm.getRawValue()));

    delete postFilterFormValues['sortBy'];
    delete postFilterFormValues['date'];
    const labelsStatus = [];

    for (const val of Object.keys(postFilterFormValues)) {

      if (postFilterFormValues[val]) {

        let label = this.camelCaseToWords(val).toUpperCase();

        if (label === 'PUBLISHED AIRED') {

          label = 'PUBLISHED/AIRED';

        }
        labelsStatus.push(label);

      }

    }
    return {
      markAs: labelsStatus,
      contentType: [],
      postType: this.materialSelectedTab === 'elements' ? 'Element' : this.materialSelectedTab === 'standards' ? '' : 'All',
      angleId: Number(angleId)
    };

  }

  /**
   * Fetch the Angle Material details based on Angle Id, queryStr, payload
   *
   */
  getPostDetails(queryStr: string) {

    console.log('queryStr--' + queryStr);
    const payLoad = this.createPayload(this.angleId);

    this.cService.serviceRequestCommon('post', this.inPageSearchApiUrl, queryStr, payLoad).subscribe((res: any) => {

      if (res && res.posts && res.posts.length) {

        this.postDetails = [...this.postDetails, ...res.posts];

      }
      this.isLoaded = true;
      this.isAPILoadedEvent.emit(this.isLoaded);

    }, () => {

      this.isLoaded = true;
      this.isAPILoadedEvent.emit(this.isLoaded);

    });

  }

  /**
   * Modal will open to Share the Post details
   *
   */
  showShareModal(post: Post): void {

    this.isVisible = true;
    this.shareMessage = post.postTitle;
    this.postID = post.postId;

  }

  /**
   * Close Share Post details modal
   */
  handleCancel(): void {

    this.isVisible = false;

  }

  /**
   * Converts Camel Case word to seperate words
   *
   */
  camelCaseToWords(str: string): string {

    return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, '$1');

  }

  /**
   * ngOnDestroy Angular Life cycle
   * Use for any custom cleanup that needs to occur when the instance is destroyed
   */
  ngOnDestroy(): void {

    //this.destroy$.next();
    this.destroy$.complete();
    this.eventsSubscription.unsubscribe();

  }

}
