import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { Stories } from '@models/ncx/story';
import { environment } from 'src/environments/environment';
import { Sort } from '@utilities/sort';

@Component({
  selector: 'app-following-stories',
  templateUrl: './following-stories.component.html',
  styleUrls: ['./following-stories.component.scss']
})
export class FollowingStoriesComponent implements OnInit {

  isLoaded;

  stories: Stories[] = [];

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {

    this.getFollowedStories();

  }

  /**
   * Get list of all stories followed by this user
   *
   */
  getFollowedStories() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', environment.getStoriesAPI, '?isFollowing=true&pageLength=1000').subscribe({

      next: (res: any) => {

        if (res && Array.isArray(res.stories)) {

          // Sort alphabetically
          this.stories = (res.stories as Stories[]).sort((a: Stories, b: Stories) => Sort.string(a.storyTitle, b.storyTitle));

        }

      },

      error: (error: any) => {

        console.error('getFollowedStories', error);

        this.toastService.createMessage('error', 'Error while loading. Please try again');

      }

    }).add(() => {

      this.isLoaded = true;
    
    });
  
  }

  /**
   * Unfollow a story
   *
   *
   */
  unfollowStory(story: Stories) {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('delete', environment.storyFollower, `/${story.storyId}`).subscribe(data => {

      console.log('Unfollow Response', data);

      this.toastService.createMessage('success', `You have unfollowed the story ${story.storyTitle}`);

      this.getFollowedStories();

    }, (error: any) => {

      console.error({ error });

      this.toastService.createMessage('error', 'Error updating data. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * View story
   *
   */
  viewStory(storyId: number) {

    this.router.navigate([`/ncx/landing-story/:${storyId}`]);

  }

}
