import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';

@Injectable()
export class AuthGuardService {

  constructor(
    private router: Router,
    private userInfo: TransferInformationComponent
  ) {}

  canActivate(): boolean {

    console.log('App.auth.gard.ts (canActivate): ', this.userInfo.linkLoggedInErrSource.value);

    return !!this.userInfo.linkLoggedInErrSource.value.errorStatus;

  }

}
