<app-loader-component [isLoaded]="isInitLoaded"></app-loader-component>

<!-- STANDARD PAGE LAYOUT -->
<div
   *ngIf="customHomePage !== true"
   [attr.data-component]="'src/app/ncx/stories/dashboard (Standard)'"
   class="stories-dashboard">
   <ng-container *ngTemplateOutlet="commonHeader"></ng-container>

   <ng-container *ngIf="isInitLoaded">
      <nz-carousel
         [nzEffect]="'scrollx'"
         [ngClass]="{ 'carousel-container': true, 'custom-page': customHomePage }">
         <section nz-carousel-content>
            <app-generic-template-section
               [dataArray]="readyArray"
               [totalCount]="readyCount"
               (goToSlide)="goToSlide($event)"
               [dataHeading]="ready"></app-generic-template-section>
         </section>

         <section nz-carousel-content>
            <app-generic-template-section
               [dataArray]="workingArray"
               [totalCount]="workingCount"
               (goToSlide)="goToSlide($event)"
               [dataHeading]="working"></app-generic-template-section>
         </section>
      </nz-carousel>
   </ng-container>
</div>

<!-- CUSTOM PAGE LAYOUT -->
<div
   *ngIf="customHomePage === true"
   id="stories-dashboard"
   class="stories-dashboard"
   [attr.data-component]="'src/app/ncx/stories/dashboard (Custom)'">
   <ng-container *ngTemplateOutlet="commonHeader"></ng-container>

   <!-- only show dots on mobile devices in the standard layout -->
   <ng-container>
      <nz-carousel
         [nzEffect]="'scrollx'"
         [nzDots]="isMobile && !customHomePage"
         cdkDropList
         [cdkDropListData]="CustomArray"
         (cdkDropListDropped)="drop($event)"
         cdkDropListOrientation="horizontal"
         [attr.data-draggable-content]="true"
         [attr.data-carousel]="true"
         [ngClass]="{ 'carousel-container': true, 'custom-page': customHomePage }">
         <section
            *ngFor="let item of CustomArray"
            nz-carousel-content
            cdkDrag
            [cdkDragDisabled]="isMobile"
            [attr.data-draggable]="true"
            (cdkDragDropped)="dragDropped($event, item)">
            <app-generic-template-section
               *ngIf="item.categoryName === 'Story' || item.categoryName === 'Person'"
               [dataArray]="item.stories"
               [totalCount]="item.totalCount"
               [dataHeading]="item.columnName"
               [categoryName]="item.categoryName"
               [hideColumn]="item.hideColumn"
               [columnNumber]="item.columnNumber"
               (goToSlide)="goToSlide($event)"
               (openCustomColumnDrawer)="openCustomColumnEditDrawer($event)"
               (valueChange)="getValueChange($event)"></app-generic-template-section>

            <app-generic-template-section
               *ngIf="item.categoryName === 'Activity'"
               [dataArray]="item.stories"
               [totalCount]="item.totalCount"
               [dataHeading]="item.columnName"
               [categoryName]="item.categoryName"
               [columnNumber]="item.columnNumber"
               (goToSlide)="goToSlide($event)"
               (openCustomColumnDrawer)="openCustomColumnEditDrawer($event)"
               (valueChange)="getValueChange($event)"></app-generic-template-section>

            <app-generic-template-section
               *ngIf="item.categoryName === 'Group'"
               [dataArray]="item.discussions"
               [totalCount]="item.totalCount"
               [dataHeading]="item.columnName"
               [categoryName]="item.categoryName"
               [columnNumber]="item.columnNumber"
               (goToSlide)="goToSlide($event)"
               (openCustomColumnDrawer)="openCustomColumnEditDrawer($event)"
               (valueChange)="getValueChange($event)"></app-generic-template-section>
         </section>
      </nz-carousel>
      <nz-footer
         *ngIf="customHomePage"
         [ngClass]="{
            'page-footer': true,
            'desktop': !this.isMobile,
            'open': !this.isNavDrawerClosed,
         }">
         <div class="left-side">
            <button
               (click)="openCustomColumnDrawer()"
               nz-button
               nzType="default">
               <i
                  nz-icon
                  nzType="plus"
                  nzTheme="outline"></i>
               <span>Add Column</span>
            </button>
         </div>

         <div class="right-side">
            <button
               nz-button
               nzType="default"
               (click)="goToSlide('prev')">
               <i
                  nz-icon
                  nzType="left"
                  nzTheme="outline"></i>
            </button>
            <button
               nz-button
               nzType="default"
               (click)="goToSlide('next')">
               <i
                  nz-icon
                  nzType="right"
                  nzTheme="outline"></i>
            </button>
         </div>
      </nz-footer>
   </ng-container>
</div>

<!-- COMMON HEADER FOR ALL LAYOUTS -->
<ng-template #commonHeader>
   <nz-page-header class="site-page-header editors">
      <nz-page-header-title
         class="headerBtn"
         *ngIf="functionAbility.fa_add_self_editor_on_duty || functionAbility.fa_add_editor_on_duty">
         <button
            nz-button
            nzGhost="true"
            nzType="primary"
            (click)="showEditorsModal()">
            <i
               nz-icon
               nzType="user"
               nzTheme="outline"></i>
            <span>Change Who's On</span>
         </button>
      </nz-page-header-title>

      <nz-page-header-title class="editorsName">
         NBCNEWS.COM EDITOR ON DUTY:&nbsp;
         <ng-container *ngFor="let editor of editorsOnDuty; last as isLast">
            <button
               nz-popover
               nzPopoverPlacement="bottom"
               [nzPopoverTrigger]="'hover' || 'click'"
               (click)="showUserProfile(editor.userId)"
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'bottom'">
               {{ editorName(editor, isLast) }}
            </button>
            <ng-template #profileCardTemplate>
               <app-profile-overlay [profileData]="editor"></app-profile-overlay>
            </ng-template>
         </ng-container>
      </nz-page-header-title>
   </nz-page-header>
</ng-template>

<!-- ASSIGN EDITORS MODAL -->
<nz-modal
   class="modal"
   [(nzVisible)]="isVisible"
   [nzTitle]="modalTitle"
   [nzNoAnimation]="true"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()">
   <ng-template #modalTitle>Assign Editors</ng-template>
   <ng-template #modalContent>
      <p>Editors currently assigned (3 Editors Max):</p>
      <span *ngFor="let user of editorsOnDuty">
         <ng-container *ngIf="user && user.firstName">
            <nz-tag
               [ngClass]="{
                  'remove-hide':
                     !functionAbility.fa_add_editor_on_duty &&
                     functionAbility.fa_add_self_editor_on_duty &&
                     user.userId != userInfoDetails.userId,
               }"
               nzMode="closeable"
               (nzOnClose)="onClose(user.userId)">
               {{ getUserName(user) }}
            </nz-tag>
         </ng-container>
      </span>
      <div class="searchEditors">
         <div class="inputField">
            <nz-input-group [nzSuffix]="suffixIconSearch">
               <input
                  type="text"
                  [(ngModel)]="editorText"
                  nz-input
                  (keydown)="filterItems($event)"
                  placeholder="Which editors do you want to assign?" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
               <i
                  nz-icon
                  nzType="search"></i>
            </ng-template>
         </div>
         <div class="scrollable">
            <nz-list
               nzSize="small"
               *ngFor="let item of filteredItems">
               <nz-list-item
                  *ngIf="item.displayName.split(',').length > 1"
                  (click)="item.allowAdd ? addEditors(item) : false"
                  [ngClass]="{ 'add-user-style': item.allowAdd }">
                  {{ item.displayName.trim().split(',')[1] }}, {{ item.displayName.split(',')[0] }}
               </nz-list-item>
               <nz-list-item
                  *ngIf="item.displayName.split(',').length === 1"
                  (click)="item.allowAdd ? addEditors(item) : false"
                  [ngClass]="{ 'add-user-style': item.allowAdd }">
                  {{ item.displayName.trim() }}
               </nz-list-item>
            </nz-list>
         </div>
      </div>
   </ng-template>
   <ng-template #modalFooter>
      <button
         nz-button
         nzType="primary"
         (click)="handleOk()">
         Save
      </button>
   </ng-template>
</nz-modal>

<!-- SELECT ** NEW ** CUSTOM COLUMNS DRAWER -->
<nz-drawer
   nzPlacement="right"
   [nzWidth]="320"
   [nzWrapClassName]="'no-padding'"
   [nzMaskClosable]="true"
   [nzClosable]="false"
   (nzOnClose)="closeCustomColumnDrawer()"
   [nzVisible]="customColumnDrawerOpen">
   <ng-container *nzDrawerContent>
      <app-custom-column (close)="closeCustomColumnDrawer()"></app-custom-column>
   </ng-container>
</nz-drawer>

<!-- EDIT ** EXISTING ** CUSTOM COLUMN DRAWER -->
<nz-drawer
   nzPlacement="right"
   [nzWidth]="320"
   [nzWrapClassName]="'no-padding'"
   [nzMaskClosable]="true"
   [nzClosable]="false"
   (nzOnClose)="closeCustomColumnEditDrawer()"
   [nzVisible]="customColumnEditDrawerOpen">
   <ng-container *nzDrawerContent>
      <app-ready-working-filter
         *ngIf="columnEditing > 0"
         [columnNumber]="columnEditing"
         (close)="closeCustomColumnEditDrawer()"></app-ready-working-filter>
   </ng-container>
</nz-drawer>
