<div
   class="ncx-post-body"
   data-component="shared/cards/post">
   <!-- POST TITLE -->
   <a
      target="_blank"
      class="title"
      href="/#/ncx/view-post/:{{ post.postId }}"
      [innerHTML]="post.snippet?.title?.trim() || post.postTitle | highlight: searchText | safeHtml"></a>

   <!-- WHO CREATED/UPDATED THE POST -->
   <div class="created-updated">
      <ng-container *ngIf="isPostUpdated">
         Modified by
         <button
            nz-popover
            [nzPopoverContent]="profileUpdatedCard"
            nzPopoverPlacement="topLeft">
            {{ Common.formatName(post.updateUser, true) }}
         </button>
         on {{ post.updateDateTime | utcToLocal: 'DATE' }} at
         {{ post.updateDateTime | utcToLocal: 'TIME' }}
      </ng-container>

      <ng-container *ngIf="!isPostUpdated">
         <button
            nz-popover
            [nzPopoverContent]="profileCreatedCard"
            nzPopoverPlacement="topLeft">
            {{ Common.formatName(post.createUser, true) }}
         </button>
         on {{ post.createDateTime | utcToLocal: 'DATE' }} at
         {{ post.createDateTime | utcToLocal: 'TIME' }}
      </ng-container>

      <ng-template #profileCreatedCard>
         <app-profile-overlay [profileData]="post.createUser"></app-profile-overlay>
      </ng-template>

      <ng-template #profileUpdatedCard>
         <app-profile-overlay [profileData]="post.updateUser"></app-profile-overlay>
      </ng-template>
   </div>

   <!-- DESCRIPTION -->
   <div
      class="description"
      *ngIf="!!post.snippet?.description?.trim() && !!searchText.trim()"
      [innerHTML]="post.snippet.description | safeHtml"></div>
   <div
      class="description"
      *ngIf="!post.snippet || !post.snippet.description.trim() || !searchText.trim()">
      {{ post.postPlainContent }}
   </div>

   <!-- ATTACHMENTS -->
   <app-view-attachments
      *ngIf="post.postAttachments && post.postAttachments.length"
      class="attachments"
      [storyId]="storyId"
      [postAttachments]="post.postAttachments"
      [isStoryLanding]="true"></app-view-attachments>

   <!-- EDITORIAL TAGS -->
   <app-post-labels
      class="labels"
      [editorial]="post.editorialStatus"
      [legal]="post.postLegal"></app-post-labels>
</div>

<div class="ncx-post-footer">
   <div class="left-side">
      <!-- PINNED -->
      <app-info-pinned
         class="info"
         *ngIf="post.isPinnedOnTop"></app-info-pinned>

      <!-- NUMBER OF COMMENTS -->
      <!-- <app-info-comments
      class="info"
      [total]="post.repliesCount"></app-info-comments> -->

      <!-- NUMBER OF ATTACHMENTS -->
      <app-info-attachments
         class="info"
         [total]="post.postAttachments?.length"></app-info-attachments>

      <!-- SHARE BUTTON -->
      <app-info-share
         class="info"
         (click)="onShare()"></app-info-share>
   </div>

   <div
      class="right-side"
      *ngIf="isAddToAngle && canAddToAngle">
      <button
         nz-button
         class="angle-btn"
         [nzBlock]="isMobile"
         (click)="showAddToAngle(post)">
         <i
            nz-icon
            nzType="link"
            nzTheme="outline"></i>
         Add to Angle
      </button>
   </div>
</div>

<!-- SHARE POST MODAL -->
<app-share-post
   [isVisible]="showShareModal"
   [postId]="post.postId"
   [message]="shareMessage"
   (closedSharedContent)="closeShareModal()"></app-share-post>
