import { Component, Input } from '@angular/core';
import { Post } from '@models/ncx/post';

@Component({
  selector: 'app-license-limitations-view',
  templateUrl: './license-limitations-view.component.html',
  styleUrls: ['./license-limitations-view.component.scss']
})
export class LicenseLimitationsViewComponent {

  @Input() postDetails = null as Post;

  constructor() { }

}
