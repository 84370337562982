import { Component, OnInit, Input } from '@angular/core';
import { Post } from '@models/ncx/post';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-reportable-approver-view',
  templateUrl: './reportable-approver-view.component.html',
  styleUrls: ['./reportable-approver-view.component.scss']
})
export class ReportableApproverViewComponent implements OnInit {

  @Input() postDetails = {} as Post;

  ngOnInit(): void {

    console.log(JSON.stringify(this.postDetails));
  
  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);
    
    } else {

      return Time.convertingUtcToLocalTimeOfhhmmss(apiDate);
    
    }
  
  }

}
