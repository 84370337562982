<nz-card
   nzTitle="Privacy"
   class="privacy">
   <div nz-row>
      <div
         nz-col
         nzSpan="24">
         <nz-radio-group
            [(ngModel)]="groupPrivacy"
            (ngModelChange)="toggleGroup()">
            <label
               nz-radio
               nzValue="PUBLIC"
               style="margin-right: 24px">
               Public
            </label>
            <label
               nz-radio
               nzValue="PRIVATE">
               Private
            </label>
         </nz-radio-group>
      </div>
   </div>
</nz-card>
