<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="invitePeople"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/add-members'">
         <p class="header">Invite People to Join</p>

         <div class="body">
            <section>
               <p class="form-label">Search for people you want to invite below</p>

               <div class="form-element">
                  <nz-input-group
                     nzSearch
                     [nzAddOnAfter]="suffixIconButton">
                     <input
                        #nameInput
                        placeholder="Enter Name"
                        nz-input
                        [(ngModel)]="value"
                        (keydown)="onChange(nameInput.value, $event)"
                        (ngModelChange)="titleMatch($event)"
                        [nzAutocomplete]="auto" />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                     <button
                        nz-button
                        nzSearch>
                        <i
                           nz-icon
                           nzType="search"
                           nzTheme="outline"></i>
                     </button>
                  </ng-template>
                  <nz-autocomplete #auto>
                     <ng-container *ngFor="let user of userListNames">
                        <nz-auto-option [nzValue]="user.userId">{{ user.fullName }}</nz-auto-option>
                     </ng-container>
                  </nz-autocomplete>

                  <div class="members-list">
                     <span *ngFor="let user of userEmailList">
                        <nz-tag
                           nzMode="closeable"
                           (nzOnClose)="onClose(user.userId)">
                           {{ user.fullName }}
                        </nz-tag>
                     </span>
                  </div>
               </div>
            </section>

            <section>
               <p class="form-label">Include a Personal Note:</p>

               <textarea
                  rows="4"
                  nz-input
                  placeholder="You've been invited to join 'GROUP NAME'.
     Go to your NewsConnect Alerts page to accept this invitation."
                  [(ngModel)]="showGroupMessage"></textarea>

               <p class="form-hint">Max Character Limit 765</p>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="handleOk()">
         Send
      </button>
   </ng-template>
</nz-modal>
