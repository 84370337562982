import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-pinned',
  templateUrl: 'info-pinned.component.html',
  styleUrls: ['./info-pinned.component.scss']
})
export class InfoPinnedComponent {

  @Input() clickable: boolean = false;

  @Output() click: EventEmitter<void> = new EventEmitter();

  onClick() {

    this.click.emit();

  }

}
