<!----------------------------- STANDARD PAGE LAYOUT ------------------------>
<div
   *ngIf="!customHomePage"
   class="section">
   <app-loader-component
      loaderType="SECTION_LOADER"
      [isLoaded]="isLoaded"></app-loader-component>

   <!-- SECTION TITLE, OPTIONS -->
   <div class="section-header">
      <div class="header">
         <div class="title">
            <span class="icon">
               <i
                  nz-icon
                  nzType="file"
                  nzTheme="outline"></i>
            </span>

            <span class="text">{{ dataHeading }}</span>
         </div>

         <div class="options">
            <!-- SEARCH (only applicable to columns that have content) -->
            <button
               nz-button
               [nzType]="searchField ? 'primary' : 'default'"
               [disabled]="hideColumn"
               (click)="toggleSearch()">
               <i
                  nz-icon
                  nzType="search"
                  nzTheme="outline"></i>
            </button>

            <!-- COLUMN FILTERS (only applicable to personalized columns that have content) -->
            <button
               *ngIf="categoryName !== 'Story' && dataHeading !== 'Personalized Content'"
               nz-button
               [nzType]="filterField ? 'primary' : 'default'"
               (click)="!filterField ? filter(columnNumber, categoryName) : unfliter(columnNumber, categoryName)">
               <i
                  nz-icon
                  nzType="filter"
                  nzTheme="outline"></i>
            </button>

            <button
               nz-button
               nzType="default"
               class="navigate-slide"
               (click)="navigateSlide('next')">
               <i
                  nz-icon
                  nzType="right"
                  nzTheme="outline"></i>
            </button>
         </div>
      </div>
   </div>

   <!-- SEARCH & FILTERS -->
   <div
      class="section-sub-header"
      *ngIf="searchField || filterField">
      <!-- SEARCH BAR -->
      <div
         *ngIf="searchField"
         class="search-bar section-row">
         <nz-input-group
            *ngIf="hideSearchText"
            [nzSuffix]="suffixIconSearch">
            <input
               type="text"
               nz-input
               [(ngModel)]="searchInput"
               (keydown)="filterItems($event, searchInput)"
               [placeholder]="searchPlaceholder"
               #searchInputField />
            <ng-template #suffixIconSearch>
               <i
                  nz-icon
                  nzType="search"
                  (click)="searchStory(searchInput)"
                  class="close-search"></i>
            </ng-template>
         </nz-input-group>

         <button
            *ngIf="!isSearched"
            nz-button
            nzBlock="true"
            nzType="primary"
            (click)="searchEvent('submit')">
            Submit
         </button>
         <button
            *ngIf="isSearched"
            nz-button
            nzBlock="true"
            nzType="primary"
            nzDanger="true"
            (click)="searchEvent('clear')">
            Clear
         </button>
      </div>

      <div
         *ngIf="filterField"
         class="filters section-row">
         <nz-input-group nzCompact>
            <nz-select
               [ngModel]="sortType"
               (ngModelChange)="addFilters($event)">
               <nz-option
                  [nzLabel]="'Newest First'"
                  [nzValue]="'NEWEST'"></nz-option>
               <nz-option
                  [nzLabel]="'Oldest First'"
                  [nzValue]="'OLDEST'"></nz-option>
               <nz-option
                  [nzLabel]="'Trending'"
                  [nzValue]="'TRENDING'"></nz-option>
            </nz-select>
         </nz-input-group>
         <nz-radio-group
            nzButtonStyle="solid"
            nzSize="small"
            [(ngModel)]="radioValue"
            (ngModelChange)="toggleFollowing($event)">
            <label
               class="filterButtons"
               nz-radio-button
               nzValue="ALL">
               All
            </label>
            <label
               class="filterButtons"
               nz-radio-button
               nzValue="FOLLOWING">
               Following
            </label>
         </nz-radio-group>
      </div>
   </div>

   <!-- EMPTY CONTAINER FOR HIDDEN CONTENT -->
   <ng-container *ngIf="hideColumn">
      <div class="empty"></div>
   </ng-container>

   <ng-container
      *ngIf="!hideColumn && filteredItems && filteredItems.length"
      [ngTemplateOutlet]="story"
      [ngTemplateOutletContext]="{ type: 'Story', filteredItems: filteredItems }"></ng-container>

   <ng-container
      *ngIf="!hideColumn && filteredItems && !filteredItems.length && isLoaded"
      [ngTemplateOutlet]="noResults"></ng-container>
</div>

<!----------------------------- CUSTOM PAGE LAYOUT ------------------------>

<div
   *ngIf="customHomePage"
   class="section"
   [attr.data-custom-layout]="true"
   [attr.data-component]="'ncx/stories-dashboard/generic-dashboard/generic-template-section.component.html (Custom)'">
   <app-loader-component
      loaderType="SECTION_LOADER"
      [isLoaded]="isLoaded"></app-loader-component>

   <div class="section-header">
      <!-- TITLE, DRAG ICON, OPTIONS -->
      <div class="header">
         <div class="title">
            <!-- empty divs that are just placeholders (title === Personalized Content) are not draggable -->
            <button
               class="drag"
               *ngIf="dataHeading !== 'Personalized Content'">
               <i></i>
               <i></i>
               <i></i>
            </button>

            <span class="icon">
               <i
                  nz-icon
                  nzType="file"
                  nzTheme="outline"
                  *ngIf="categoryName === 'Story'"></i>
               <i
                  nz-icon
                  nzType="file-text"
                  nzTheme="outline"
                  *ngIf="categoryName === 'Activity'"></i>
               <i
                  nz-icon
                  nzType="user"
                  nzTheme="outline"
                  *ngIf="categoryName === 'Person'"></i>
               <i
                  nz-icon
                  nzType="team"
                  nzTheme="outline"
                  *ngIf="categoryName === 'Group'"></i>
            </span>

            <span class="text">{{ dataHeading }}</span>
         </div>

         <div class="options">
            <!-- HIDE CONTENT (only applicable to Ready and Working columns) -->
            <button
               nz-button
               nzType="default"
               *ngIf="dataHeading === 'Ready' || dataHeading === 'Working'"
               (click)="hideColumn ? showColumn(dataHeading) : hideReadyWorkingColumn(dataHeading)">
               <i
                  nz-icon
                  [nzType]="hideColumn ? 'eye-invisible' : 'eye'"
                  nzTheme="outline"></i>
            </button>

            <!-- SEARCH (only applicable to columns that have content) -->
            <button
               nz-button
               nzType="default"
               [disabled]="hideColumn || dataHeading === 'Personalized Content'"
               (click)="toggleSearch()">
               <i
                  nz-icon
                  nzType="search"
                  nzTheme="outline"></i>
            </button>

            <!-- COLUMN FILTERS (only applicable to personalized columns that have content) -->
            <button
               *ngIf="categoryName !== 'Story' && dataHeading !== 'Personalized Content'"
               nz-button
               nzType="default"
               (click)="!filterField ? filter(columnNumber, categoryName) : unfliter(columnNumber, categoryName)">
               <i
                  nz-icon
                  nzType="filter"
                  nzTheme="outline"></i>
            </button>
         </div>
      </div>
   </div>

   <!-- SEARCH BAR -->
   <div
      class="section-sub-header"
      *ngIf="searchField">
      <nz-input-group
         [nzSuffix]="suffixIconSearch"
         *ngIf="hideSearchText">
         <input
            type="text"
            [(ngModel)]="searchInput"
            (keydown)="filterItems($event, searchInput)"
            nz-input
            [placeholder]="searchPlaceholder"
            #searchInputField />
         <ng-template #suffixIconSearch>
            <i
               nz-icon
               nzType="search"
               (click)="searchStory(searchInput)"
               class="close-search"></i>
         </ng-template>
      </nz-input-group>
   </div>

   <!-- EMPTY CONTAINER FOR HIDDEN CONTENT -->
   <ng-container *ngIf="hideColumn">
      <div class="empty"></div>
   </ng-container>

   <!-- POSTS: WORKING, READY, ACTIVITY, GROUP, ETC... -->
   <ng-container *ngIf="!hideColumn">
      <ng-container
         [ngTemplateOutlet]="story"
         [ngTemplateOutletContext]="{ type: categoryName, filteredItems: filteredItems }"></ng-container>
   </ng-container>

   <!-- NO RESULTS -->
   <ng-container
      *ngIf="!hideColumn && filteredItems && !filteredItems.length && isLoaded"
      [ngTemplateOutlet]="noResults"></ng-container>
</div>

<!----------------------------- COMMON TEMPLATE FOR ALL COLUMNS ------------------------>

<ng-template
   #story
   let-type="type"
   let-filteredItems="filteredItems">
   <app-scroll-container>
      <ng-template bodyTemplate>
         <div
            class="dashboard-column"
            infiniteScroll
            [infiniteScrollDistance]="2"
            (scrolled)="onScrollDown()"
            [scrollWindow]="false">
            <div
               class="card"
               *ngFor="let content of filteredItems">
               <ng-container *ngIf="type !== 'Group'">
                  <ncx-story
                     [story]="content"
                     [showPostTimeAgo]="true"
                     [showPostAttachments]="false"></ncx-story>
               </ng-container>

               <ng-container *ngIf="type === 'Group'">
                  <ncx-group-discussion
                     location="dashboard"
                     [discussion]="content"></ncx-group-discussion>
               </ng-container>
            </div>
         </div>
      </ng-template>
   </app-scroll-container>
</ng-template>

<!-- NO RESULTS -->
<ng-template #noResults>
   <div class="notFoundCard">
      <nz-empty [nzNotFoundContent]="contentTpl">
         <ng-template #contentTpl>
            <span>No Results Found</span>
         </ng-template>
      </nz-empty>
   </div>
</ng-template>
