<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<app-standard-header
   title="Back"
   [defaultBackAction]="true"></app-standard-header>

<div
   class="topic-layout"
   [attr.data-component]="'/ncx/following/topics'">
   <div class="heading">{{ name }}:</div>

   <div
      class="topic-container"
      *ngIf="users && users.length">
      <nz-card
         *ngFor="let index of users"
         class="card">
         <div class="title">
            {{ index.topicName }}
         </div>

         <div class="followers">
            <button
               *ngIf="checkFollowing(index.topicFollowers)"
               (click)="followUser(index, index.topicId, 'unfollow')"
               nz-button
               nzType="default">
               Unfollow
            </button>
            <button
               *ngIf="!checkFollowing(index.topicFollowers)"
               (click)="followUser(index, index.topicId, 'follow')"
               nz-button
               nzType="primary">
               Follow
            </button>
         </div>
      </nz-card>
   </div>
</div>
