<div
   *ngIf="show"
   class="banner">
   <div class="info">
      <i
         nz-icon
         class="icon round"
         nzType="info"
         nzTheme="outline"></i>
   </div>

   <div>
      <div class="notice">Notice: Topic update</div>

      <div
         class="text"
         [innerHtml]="message | safeHtml"></div>
   </div>

   <div
      (click)="close()"
      class="close">
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"></i>
   </div>
</div>
