<div class="license-limitations">
   <nz-collapse
      nzExpandIconPosition="right"
      nzBordered="false">
      <nz-collapse-panel
         nzHeader="License Limitations"
         nzActive="true">
         <div class="elementDetails">
            <span class="elementDetailsText">{{ postDetails?.limitedLicenseApprover?.additionalNotes }}</span>
         </div>
      </nz-collapse-panel>
   </nz-collapse>
</div>
