import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-share',
  templateUrl: './info-share.component.html',
  styleUrls: ['../common.scss']
})
export class InfoShareComponent {

  @Input() showShareText: boolean = true;

  @Output() click: EventEmitter<void> = new EventEmitter();

  onClick() {

    this.click.emit();

  }

}
