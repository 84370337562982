<div class="angle-details-modal">
   <div class="angle-details-split">
      <div
         class="angle-details-content"
         (click)="isSelect ? selectAngle() : ''">
         <div class="angle-details-id">
            <button (click)="isSelect ? undefined : showAngle()">
               <span>#{{ angleDetail.angleId }}</span>
            </button>
            <span
               *ngIf="isSelect || isDelete"
               class="angle-action"
               [class.disabled]="isDisabled">
               <i
                  nz-icon
                  [class.select]="isSelect"
                  [class.delete]="isDelete"
                  [nzType]="isSelect ? 'select' : 'delete'"
                  (click)="isSelect ? selectAngle() : deselectAngle()"
                  nzTheme="outline"></i>
            </span>
         </div>
         <div class="angle-details-title angle-icon-title-flex">
            <div class="angle-icon-title-split">
               <span
                  class="eye-invisible-icon"
                  *ngIf="angleDetail.privacy || (angleDetail.angleAccess | lowercase) === 'private'">
                  <span
                     nz-icon
                     nzType="eye-invisible"
                     nzTheme="outline"></span>
               </span>
            </div>
            <div class="angle-icon-title-split">
               <button (click)="isSelect ? undefined : showAngle()">
                  <span
                     [class.font-14]="isSelect || isDelete"
                     target="_blank"
                     class="text-ellipsis"
                     [innerHTML]="
                        (angleDetail.angleTitleSnippet || angleDetail.angleTitle || '').trim() | safeHtml
                     "></span>
               </button>
            </div>
         </div>
         <div class="angle-created-user">
            <span>Created By:</span>
            <span
               *ngIf="angleDetail.createdUser"
               class="user-name"
               nz-popover
               [nzPopoverContent]="profileCardTemplate1"
               [nzPopoverPlacement]="'topLeft'">
               {{ angleDetail.createdUser.displayName }}
            </span>
            <ng-template #profileCardTemplate1>
               <app-profile-overlay
                  [profileData]="angleDetail.createdUser"
                  (destroyParentModal)="destroyParentModal()"></app-profile-overlay>
            </ng-template>
            <span *ngIf="!(isSelect || isDelete)">
               on {{ angleDetail.createdDateTime | utcToLocal: 'DATE' }} at
               {{ angleDetail.createdDateTime | utcToLocal: 'TIME' }}
            </span>
         </div>
      </div>
   </div>
</div>
