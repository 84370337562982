import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-component',
  templateUrl: './loader-component.component.html',
  styleUrls: ['./loader-component.component.scss']
})
export class LoaderComponentComponent {

  /**
   * APP LOADER WILL OBSCURE THE ENTIRE PAG
   * PAGE LOADER WILL SHOW THE PAGE BUT THE LOADER WILL BE VISIBLE IN THE CENTER
   * SECTION LOADER WILL SHOW THE PAGE BUT THE LOADER WILL BE VISIBLE IN THE RELATIVELY-POSITIONE SECTION
   * COMPONENT LOADER WILL SHOW THE LOADER IN THE CENTER OF THE FORM CONTROLS LIKE INPUT, SELECT, CHECKBOX, etc
   */

  @Input() loaderType: 'APP_LOADER' | 'SECTION_LOADER' | 'COMPONENT' | 'PAGE_LOADER' = 'PAGE_LOADER';

  @Input() size: 'large' | 'small' | 'default' = 'large';

  @Input() isLoaded: boolean = true;

}
