<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isMovePostVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/move-post'">
         <p class="header">Move Post</p>

         <div class="body">
            <section>
               <p class="form-label">Add this post to multiple stories or move to a different story</p>

               <div class="form-element new-story-input">
                  <nz-input-group [nzSuffix]="suffixIconSearch">
                     <input
                        placeholder="Search for the Story Here..."
                        nz-input
                        [(ngModel)]="value"
                        (ngModelChange)="titleMatch($event)"
                        (keydown)="onChange(storyInput.value, $event)"
                        [nzAutocomplete]="auto"
                        #storyInput />
                  </nz-input-group>
                  <nz-autocomplete
                     [nzDataSource]="filteredOptions"
                     #auto></nz-autocomplete>
                  <ng-template #suffixIconSearch>
                     <i
                        nz-icon
                        nzType="search"></i>
                  </ng-template>
               </div>
            </section>

            <section>
               <p class="form-label">Current stories</p>

               <ng-container *ngIf="currentStories">
                  <div
                     *ngFor="let index of currentStories"
                     class="move-post-container">
                     <div class="primary">
                        <button
                           (click)="postAsPrimaryStory(index.storyId)"
                           *ngIf="index.isPrimary === false"
                           [nzType]="'default'"
                           nz-button>
                           <i
                              nz-icon
                              nzType="crown"
                              nzTheme="outline"></i>
                        </button>

                        <button
                           *ngIf="index.isPrimary === true"
                           class="selected"
                           [nzType]="'primary'"
                           nz-button>
                           <i
                              nz-icon
                              nzType="crown"
                              nzTheme="outline"></i>
                        </button>
                     </div>

                     <div class="name">
                        <span *ngIf="index.autoGeneratedStoryId">
                           {{ index.autoGeneratedStoryId }}
                        </span>
                        <nz-divider
                           *ngIf="index.autoGeneratedStoryId"
                           class="vertical-line"
                           nzType="vertical"></nz-divider>
                        <p>{{ index.storyTitle }}</p>
                     </div>

                     <div class="options">
                        <button
                           *ngIf="functionAbility.fa_pin_post"
                           (click)="pinStory(index.storyId, isPinnedOnTop)">
                           <i
                              nz-icon
                              nzType="pushpin"
                              [ngClass]="{ pinned: index.isPinnedOnTop }"
                              [nzTheme]="index.isPinnedOnTop ? 'fill' : 'outline'"></i>
                        </button>

                        <button (click)="deleteRelatedStories(index, index.storyId)">
                           <i
                              class="delete"
                              nz-icon
                              nzType="delete"></i>
                        </button>
                     </div>
                  </div>
               </ng-container>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <div class="footer">
         <p>* 4 Stories Max</p>
         <div class="options">
            <button
               nz-button
               nzType="default"
               (click)="handleCancel()">
               Cancel
            </button>
            <button
               nz-button
               nzType="primary"
               [nzLoading]="!isLoaded"
               (click)="handleOk()">
               Move
            </button>
         </div>
      </div>
   </ng-template>
</nz-modal>
