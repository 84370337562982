<nz-card nzTitle="Admin">
   <div nz-row>
      <div class="inputGroup">
         <nz-input-group [nzSuffix]="suffixIconSearch">
            <input
               type="text"
               nz-input
               placeholder="Add users here"
               [(ngModel)]="groupUserInput"
               (keydown)="onchange($event)"
               (keyup)="onchange($event)"
               [nzAutocomplete]="auto"
               (ngModelChange)="titleMatch($event)" />
         </nz-input-group>
         <ng-template #suffixIconSearch>
            <i
               nz-icon
               nzType="search"></i>
         </ng-template>
         <nz-autocomplete #auto>
            <ng-container *ngFor="let user of userListNames">
               <nz-auto-option [nzValue]="user.memberUserId">{{ user.fullName }}</nz-auto-option>
            </ng-container>
         </nz-autocomplete>
      </div>
   </div>
   <ng-container *ngFor="let user of userEmailList">
      <div
         class="destinationStory"
         nz-row
         *ngIf="!user.deleted">
         <div
            class="destinationField"
            nz-col
            nzXs="13"
            nzSm="16"
            nzMd="18"
            nzLg="19"
            nzXl="20"
            nzXXl="21">
            <div class="destinationStoryName">{{ user.fullName }}</div>
         </div>
         <div
            class="select"
            nz-col
            nzXs="8"
            nzSm="6"
            nzMd="4"
            nzLg="3"
            nzXl="3"
            nzXXl="2">
            <span class="hovermeDropdown">
               <button
                  nz-dropdown
                  [nzDropdownMenu]="menu">
                  {{ user.membershipType | titlecase }}
                  <i
                     class="downIcon"
                     nz-icon
                     nzType="down"></i>
               </button>
            </span>
            <nz-dropdown-menu #menu="nzDropdownMenu">
               <ul nz-menu>
                  <li
                     nz-menu-item
                     (click)="toggleDropdown('ADMIN', user.memberUserId)">
                     Admin
                  </li>
                  <li
                     nz-menu-item
                     (click)="toggleDropdown('OWNER', user.memberUserId)">
                     Owner
                  </li>
                  <li
                     nz-menu-item
                     (click)="toggleDropdown('MEMBER', user.memberUserId)">
                     Member
                  </li>
               </ul>
            </nz-dropdown-menu>
         </div>
         <div
            class="select"
            nz-col
            nzXs="3"
            nzSm="2"
            nzMd="2"
            nzLg="2"
            nzXl="1"
            nzXXl="1">
            <button (click)="deleteUser(user.memberUserId, user.membershipType)">
               <i
                  class="delIcon"
                  nz-icon
                  nzType="delete"></i>
            </button>
         </div>
      </div>
   </ng-container>
</nz-card>
