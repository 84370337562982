import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { WebSocketService } from '@services/websocket.service';
import { UtilityService } from '@services/utility.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
import { IFunctionAbility, User } from '@models/users';
import { CSSThemeService } from '@services/css-theme.service';
import { Common } from '@utilities/common';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-activity',
  templateUrl: './activity-page.html',
  styleUrls: ['./activity-page.scss']
})
export class ActivityPage implements OnInit, OnDestroy {

  getFilterListURL: string;

  filterList: any = [];

  filterType: any = [];

  labels: any = [];

  creationDate: any = [];

  labelStatus = [];

  postTypeSelected: any = 'All';

  creationDateSelected: any = 'Modified Date';

  isCollapsed = true;

  isVisible = false;

  shareMessage = '';

  postID;

  isToggle;

  labelsStatus: any;

  dateStatus: any;

  activityTypeStatus: any;

  searchStatus: any;

  refreshStatus: boolean;

  storyId: any;

  isLoaded: boolean;

  activityDetails: any = [];

  filteredItems: any = [];

  pageStartCount: any = 0;

  pageLength: any = 10;

  activityApiUrl: string;

  searchPostInput: string;

  activityText = '';

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  @ViewChild('searchText') searchText: ElementRef;

  activityType: any;

  activityPage = '';

  labelsStatusSelected = '';

  labelsStatusSelectedSort: any = {};

  isCleared = false;

  public functionAbility = {} as IFunctionAbility;

  contentType = [];

  pagecode = 'STORY';

  activitySearchApiUrl: string;

  payLoad = {};

  orderStatus = 'descending';

  pageSearchStartCount = 1;

  pageActivityStartCount = 1;

  private subscriptionsWs: Subscription = new Subscription();

  totalRecords: number;

  hiddenScrollFlag: boolean;

  isFilterChanged = false;

  searchValue: string;

  constructor(
    private router: ActivatedRoute,
    private http: HttpClient,
    private cService: CommonService,
    private reRoute: Router,
    private toastService: ToastService,
    private wS: WebSocketService,
    private uS: UtilityService,
    private themeService: CSSThemeService,
    private tI: TransferInformationComponent
  ) { }

  ngOnInit() {

    this.getFilterListURL = environment.getPostFiltersURL;
    this.activityApiUrl = environment.getActivityURL;
    this.activitySearchApiUrl = environment.getActivitySearchURL;
    this.pageLength = 10;
    this.pageStartCount = 1;
    this.totalRecords = 1000; // for testing pupose only
    this.hiddenScrollFlag = false;
    this.isFilterChanged = false;
    this.getFilterList();
    this.router.params.subscribe(
      (params: Params) => {

        this.setActivityType(params);

      });
    this.subscriptionsWs = this.wS.doSocketData$.subscribe(data => {

      this.updateActivity(data);

    });
    this.wS.connect(this.pagecode);
    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  setActivityType(params) {

    if (params && params.activityType) {

      const id = params.activityType;

      this.activityType = id.substr(1);
      this.refresh();

    }

  }

  createQueryStr(isSCrolled) {

    this.labelStatus = [];
    this.activityDetails = (isSCrolled) ? this.activityDetails : [];
    this.activityPage = '';
    let queryStr = `?pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}&isPublished=true`;

    switch (this.activityType) {

    case 'All':
      this.isFilterChanged = false;
      this.setLabels('HOT', false, false);
      this.setLabels('IMPORTANT', false, false);
      this.setLabels('STANDARDS', false, false);
      this.labelsStatusSelectedSort = {};
      break;
    case 'Hot': {

      queryStr += '&filterType=HOT,IMPORTANT';
      this.activityPage = 'HOT';
      this.isFilterChanged = false;
      this.setLabels('HOT', true, true);
      this.setLabels('IMPORTANT', true, true);
      this.setLabels('STANDARDS', false);
      this.setLabelStatus({ key: 'IMPORTANT', value: 'IMPORTANT' });
      this.setLabelStatus({ key: 'HOT', value: 'HOT' });
      this.labelsStatusSelected = 'HOT,IMPORTANT';
      const payLoad = {
        markAs: ['HOT', 'IMPORTANT'],
        contentType: this.contentType
      };

      this.labelsStatusSelectedSort = payLoad;
    
    }
      break;
    case 'Standards': {

      queryStr += '&filterType=STANDARDS';
      this.activityPage = 'STANDARDS';
      this.isFilterChanged = false;
      this.setLabels('HOT', false);
      this.setLabels('IMPORTANT', false);
      this.setLabels('STANDARDS', true, true);
      this.labelsStatusSelected = 'STANDARDS';
      this.setLabelStatus({ key: 'STANDARDS', value: 'STANDARDS' });
      const payLoad1 = {
        markAs: ['STANDARDS'],
        contentType: this.contentType
      };

      this.labelsStatusSelectedSort = payLoad1;
    
    }
      break;
    case 'default':
      break;

    }
    return queryStr;

  }

  goToProfile(id) {

    this.reRoute.navigate(['ncx/profile/:' + id]);

  }

  setLabels(value, type, isDisabled = false) {

    const labelType = this.labels.filter((obj) => obj.value === value)[0];

    if (!(labelType === null || labelType === undefined)) {

      labelType.checked = type;
      labelType.disabled = isDisabled;

    }

  }

  setLabelStatus(data) {

    const obj = {
      order: data
    };

    this.labelStatus.push(obj);

  }

  changeActivityStatus(data, type) {

    this.isFilterChanged = true;
    this.resetFilters();
    this.searchPostInput = '';
    if (type === 'type') {

      this.postTypeSelected = (data && data.value) ? data.value : null;
      this.openPost(this.postTypeSelected, type);

    } else {

      const val = (data && data.value) ? data.value : null;

      this.openPost(val, type);

    }

  }

  changeLabelStatus(event, data, type) {

    this.isFilterChanged = true;
    this.resetFilters();
    this.searchPostInput = '';
    const obj = {
      order: data
    };

    if (event === true) {

      // Pushing the object into array
      const labelObj = this.labelStatus.filter((label) => label.order.value === data.value)[0];

      if (labelObj === null || labelObj === undefined) {

        this.labelStatus.push(obj);

      }

    } else {

      const removeIndex = this.labelStatus.findIndex(itm => itm.order === data);

      if (removeIndex !== -1) {

        this.labelStatus.splice(removeIndex, 1);

      }

    }
    this.openPost(this.labelStatus, type, true);

  }

  onScrollDown() {

    // console.log('StoryLandinf Page scroll ');
    if ((this.activityTypeStatus === 'Following') || ((this.isFilterChanged === false) && (!this.searchStatus))) {

      this.pageStartCount += this.pageLength;
      this.pageActivityStartCount = this.pageActivityStartCount + 1;
      if (this.searchStatus) {

        this.pageSearchStartCount = this.pageSearchStartCount + 1;

      }

    } else {

      this.pageActivityStartCount = this.pageActivityStartCount + 1;
      if (this.searchStatus) {

        this.pageSearchStartCount = this.pageSearchStartCount + 1;

      }

    }
    this.getPostByFilters(true);

  }

  changeDate(data, type) {

    this.isFilterChanged = true;
    this.resetFilters();
    this.creationDateSelected = data.label;
    this.openPost(data.value, type);

  }

  setStoryTitle(relatedStory) {

    let primaryStory;

    relatedStory.filter((post) => {

      if (post.isPrimary === true) {

        primaryStory = post;
        return;

      }

    });

    // console.log('setStoryTitle : ', primaryStory);
    return (primaryStory && primaryStory.storyTitle) ? primaryStory.storyTitle : '';

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  resetFilters() {

    this.pageStartCount = 1;
    this.pageSearchStartCount = 1;
    this.pageActivityStartCount = 1;
    this.hiddenScrollFlag = false;
    this.activityDetails = [];
    this.filteredItems = [];

  }

  openPost(data, type, status = false) {

    // console.log('openPost : ', data, type);
    this.refreshStatus = true;
    switch (type) {

    case 'type':
      this.activityTypeStatus = null;
      if (data !== 'All') {

        this.activityTypeStatus = data;

      }
      break;
    case 'labels':
      if (!(data && data.length)) {

        this.labelsStatus = null;
        this.labelsStatusSelected = '';
        this.labelsStatusSelectedSort = {};

      } else {

        const temp = data.map(a => a.order.value);

        let labels = '';

        temp.forEach((element, index) => {

          labels += (index !== (temp.length - 1)) ? element + ',' : element;

        });
        this.labelsStatusSelected = labels;
        const payLoad = {
          markAs: temp,
          contentType: this.contentType
        };

        this.labelsStatusSelectedSort = payLoad;

      }
      break;
    case 'date':
      this.dateStatus = data;
      this.isToggle = !this.isToggle;
      break;
    case 'search':
      this.searchStatus = data;
      break;

    case 'order':
      this.orderStatus = data;

    }

    // this.storyId = '1596792004565';
    if (data.length > 0 || status) {

      this.getPostByFilters(false);

    }

  }

  getPostByFilters(isSCrolled) {

    this.isLoaded = false;

    this.activityDetails = (isSCrolled) ? this.activityDetails : [];

    // replace sortType filterType

    if (this.activityTypeStatus === 'Following') {

      let queryStr = `?pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}&isPublished=true`;

      queryStr += `&secondFilterType=${this.activityTypeStatus}`;

      if (this.dateStatus) {

        queryStr += `&sortType=${this.dateStatus}`;

      }
      if (this.labelsStatusSelected !== '') {

        queryStr += `&filterType=${this.labelsStatusSelected}`;

      }
      if (this.searchStatus) {

        queryStr += `&searchText=${encodeURIComponent(this.searchStatus)}`;

      }

      this.getActivityDetails(queryStr, isSCrolled);

    } else {

      let queryStr = '';

      if (this.searchStatus) {

        this.searchValue = encodeURIComponent(this.searchStatus);
        queryStr = `?searchString=${this.searchValue}&contentPage=${this.pageSearchStartCount}&pageType=AllActivity`;

      } else {

        queryStr = `?searchString=${''}&contentPage=${this.pageActivityStartCount}&pageType=AllActivity`;

      }

      if (this.activityTypeStatus === 'Authored') {

        const ssoId = localStorage.getItem('ssoId');

        queryStr += `&authorSSO=${ssoId}`;

      }

      if (!Object.keys(this.labelsStatusSelectedSort)) {

        this.labelsStatusSelectedSort = {
          markAs: '',
          contentType: this.contentType
        };

      }

      if (this.activityTypeStatus === 'Participated') {

        const userId = Number(localStorage.getItem('userId'));

        this.labelsStatusSelectedSort.userId = userId;

      } else {

        this.labelsStatusSelectedSort.userId = 0;

      }

      if (this.dateStatus) {

        if (this.dateStatus === 'updateDate') {

          queryStr += '&postSortField=modificationdate';

        } else if (this.dateStatus === 'createDate') {

          queryStr += '&postSortField=creationdate';

        } else if (this.dateStatus === 'mostReplies') {

          queryStr += '&postSortField=reply';

        }

      } else {

        queryStr += '&postSortField=modificationdate';

      }

      if (this.orderStatus) {

        queryStr += this.orderStatus === 'descending' ? '&postSortOrder=descending' : '&postSortOrder=ascending';

      }

      this.getActivitySearchDetails(queryStr, isSCrolled);

    }

  }

  filterItems(event, _val) {

    if (event.keyCode === 13) {

      this.resetFilters();
      this.searchPostInput = event.target.value;
      if (this.searchPostInput) {

        this.openPost(this.searchPostInput, 'search');

      } else if (this.searchPostInput === '') {

        this.refresh();

      }

    }

  }

  searchPost() {

    if (this.searchPostInput !== '') {

      this.openPost(this.searchPostInput, 'search');

    }

  }

  refresh() {

    this.resetFilters();
    this.isFilterChanged = false;
    this.postTypeSelected = 'All';
    this.creationDateSelected = 'Modified Date';
    this.labelsStatus = null;
    this.labelsStatusSelected = '';
    this.dateStatus = null;
    this.activityTypeStatus = null;
    this.searchStatus = null;
    this.labelStatus = [];
    this.activityText = '';
    const updatedLabels = [];

    this.labels.map((obj) => {

      updatedLabels.push({ label: obj.label, value: obj.value, checked: false, disabled: false });

    });
    this.labels = [...updatedLabels];
    this.refreshStatus = false;
    this.isCleared = false;
    const queryStr = this.createQueryStr(false);

    this.getActivityDetails(queryStr, false);

  }

  shareStory(postObj) {

    if (postObj && postObj.postId) {

      this.isVisible = true;
      this.shareMessage = postObj.postTitle;
      this.postID = postObj.postId;

    }

  }

  applyFilters(filtersObj) {

    if ((filtersObj !== undefined || filtersObj !== null)) {

      this.labelsStatusSelectedSort = {};

      if (filtersObj.type === 'Remove') {

        this.activityText = '';
        this.searchStatus = null;
        this.isCleared = true;
        this.activityTypeStatus = filtersObj.postType;
        this.labelsStatusSelected = filtersObj.labels;
        this.dateStatus = filtersObj.date;

      } else {

        // Only update these fields if a value was passed

        if (filtersObj.filter === 'type') {

          this.activityTypeStatus = filtersObj.postType;

        }
        if (filtersObj.filter === 'labels') {

          this.labelsStatusSelected = filtersObj.labels;

        }
        if (filtersObj.filter === 'date') {

          this.dateStatus = filtersObj.date;

        }

      }

      const payLoad = {
        markAs: this.labelsStatusSelected ? this.labelsStatusSelected.split(',') : [],
        contentType: this.contentType
      };

      this.labelsStatusSelectedSort = payLoad;

    }

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  handleCancel() {

    this.isVisible = false;

  }

  toggleSider(): void {

    this.isCollapsed = !this.isCollapsed;

  }

  closeSlider() {

    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed && !this.isCleared) {

      this.getPostByFilters(false);

    } else {

      this.refresh();

    }

  }

  /*API for All Activity Operations */
  getFilterList() {

    this.cService.serviceRequestCommon('get', this.getFilterListURL).subscribe((res: any) => {

      this.filterList = res;
      this.labels = this.filterList.activityLabel;
      const updatedLabels = [];

      this.labels.map((obj) => {

        updatedLabels.push({ label: obj.label, value: obj.value, checked: false, disabled: false });

      });
      this.labels = [...updatedLabels];

      // this.setLabels(this.activityPage, true);
      if (this.activityPage === 'HOT') {

        this.setLabels('HOT', true, true);
        this.setLabels('IMPORTANT', true, true);
        this.setLabels('STANDARDS', false);

      } else if (this.activityPage === 'STANDARDS') {

        this.setLabels('HOT', false);
        this.setLabels('IMPORTANT', false);
        this.setLabels('STANDARDS', true, true);

      }
      this.filterType = this.filterList.activityFilter;
      this.creationDate = this.filterList.activityOrder;

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getActivitySearchDetails(queryStr, _isScrolled) {

    this.isLoaded = false; // show loader only when actuall scroll happen
    this.cService.serviceRequestCommon('post', this.activitySearchApiUrl, queryStr, this.labelsStatusSelectedSort).subscribe((res: any) => {

      if (res && res.totalCount) {

        this.totalRecords = res.totalCount;

      }
      if (res && res.stories && (res.stories.length < 5 && res.stories.length > 0)) {

        // hit next iteration if more records are present
        // this.hiddenScrollFlag = true;
        this.activityDetails = [...this.activityDetails, ...res.stories];
        this.filteredItems = [...this.filteredItems, ...res.stories];

      } else if (res && res.stories && res.stories.length) {

        this.activityDetails = [...this.activityDetails, ...res.stories];
        this.filteredItems = [...this.filteredItems, ...res.stories];

      }

      this.isLoaded = true;

      //  this.hiddenScrollFlag = false;

    }, () => {

      this.isLoaded = true;

    });

  }

  getActivityDetails(queryStr, _isScrolled) {

    this.isLoaded = this.hiddenScrollFlag; // show loader only when actuall scroll happen
    this.cService.serviceRequestCommon('get', this.activityApiUrl, queryStr).subscribe((res: any) => {

      if (res && res.totalCount) {

        this.totalRecords = res.totalCount;

      }
      if (res && res.stories && (res.stories.length < 5 && res.stories.length > 0)) {

        // hit next iteration if more records are present
        //  this.hiddenScrollFlag = true;
        this.activityDetails = [...this.activityDetails, ...res.stories];
        this.filteredItems = [...this.filteredItems, ...res.stories];

        //  if (!this.isPSCReachedLastIndex()) {
        //   this.onScrollDown();
        //  } else {
        //   this.hiddenScrollFlag = false;
        //   this.pageStartCount -= this.pageLength;
        //  }
        // return;

      } else if (res && res.stories && res.stories.length) {

        this.activityDetails = [...this.activityDetails, ...res.stories];
        this.filteredItems = [...this.filteredItems, ...res.stories];

        // } else {
        //   // page count raches last then stop else continue
        //   if (!this.isPSCReachedLastIndex()) {
        //     this.hiddenScrollFlag = false;
        //     this.onScrollDown();
        //   } else if (isSCrolled) {
        //     this.pageStartCount -= this.pageLength;
        //   }

      }
      this.isLoaded = true;
      this.hiddenScrollFlag = false;

    }, () => {

      this.isLoaded = true;

    });

  }

  updateFollower(userObj: FollowStoryUpdateEvent) {

    const type = userObj.type;

    const storyId = userObj.storyId;

    const obj = userObj.data;

    const updateObj = this.filteredItems.filter((story) => story.storyId === storyId)[0];

    if (updateObj && obj) {

      updateObj.storyFollowerCount = obj.storyFollowerCount;
      updateObj.isUserFollowing = type;

    } else {

      updateObj.storyFollowerCount -= 1;
      updateObj.isUserFollowing = type;

    }

  }

  /*API for All Activity Operations */

  private updateActivity(data) {

    if (data.story) {

      let privateStoryWdAccess = true;

      // tslint:disable-next-line:max-line-length
      if ((data.story && data.story.storyAccess === 'private') &&
        !(((data.story.createUser && data.story.createUser.userId === Number(localStorage.getItem('userId')))
          || (this.functionAbility.fa_access_private_story)))) {

        privateStoryWdAccess = false;

      }
      if (data.story.isDeleted) {

        this.removeStory(data.story.storyId);

      } else {

        if (this.uS.checkActivity(data.story, this.activityTypeStatus) && this.uS.checkLabelStatus(data.story, this.labelStatus)) {

          if (this.dateStatus === 'mostReplies') {

            const cloneActivity = this.uS.deepClone(this.activityDetails);

            this.activityDetails = [];
            this.activityDetails = this.uS.checkMostReplies(data.story, cloneActivity);

          } else {

            let index = this.getStoryIndexBasedOnPost(data.story);

            let checkToAdd = false;

            if (index === -1) {

              checkToAdd = false;

            } else if (index !== -1 && this.activityDetails[index].storyPosts.length === 0) {

              checkToAdd = true;

            } else if (index !== -1 && this.activityDetails[index].storyPosts.length !== 0 && data.story.storyPosts[0].isDeleted) {

              this.activityDetails.splice(index, 1);
              index = -1;
              checkToAdd = true;

            } else {

              checkToAdd = this.checkPostDeleted(index, data.story.storyPosts);

            }
            if (index !== -1 && checkToAdd) {

              this.activityDetails.splice(index, 1);
              checkToAdd = false;

            }
            if (!checkToAdd && privateStoryWdAccess) {

              this.activityDetails.unshift(data.story);

            }

          }
          this.toastService.createMessage('success', 'New Story Update/Added');

        }

      }

    }

  }

  private checkPostDeleted(index, post) {

    //  && post[0].isDeleted
    if (post.length > 0) {

      const postIndex = this.activityDetails[index].storyPosts.findIndex(postS => postS.postVersionGroupId === post[0].postVersionGroupId);

      if (postIndex !== -1) {

        return true;

      }
      return false;

    }
    return false;

  }

  private getStoryIndexBasedOnPost(storyData) {

    const storyList = this.activityDetails.filter(story => story.storyId === storyData.storyId);

    if (storyList.length === 0) {

      return -1;

    } else if (storyList.length === 1) {

      return this.activityDetails.indexOf(storyList[0]);

    } else {

      let exactStoryToUpdate = {};

      storyList.filter(story => {

        for (const post of story.storyPosts) {

          const index = storyData.storyPosts.findIndex(postS => postS.postVersionGroupId === post.postVersionGroupId);

          if (index !== -1) {

            exactStoryToUpdate = story;
            break;

          }

        }

      });
      return this.activityDetails.indexOf(exactStoryToUpdate);

    }

  }

  private removeStory(storyId) {

    const index = this.activityDetails.findIndex(story => story.storyId === storyId);

    if (index !== -1) {

      this.activityDetails.splice(index, 1);

    }

  }

  private isPSCReachedLastIndex() {

    return (this.pageStartCount * this.pageLength >= this.totalRecords) ? true : false;

  }

  getUserName(user: User): string {

    return Common.formatName(user);

  }

}
