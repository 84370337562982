import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DeviceDetails } from '@models/users';
import { CommonService } from './common-service';
import { ToastService } from './toastService/toastMessage.service';

@Injectable({
  providedIn: 'root'
})

export class IOSShellAppService {

  constructor(private cS: CommonService, private toastService: ToastService) { }

  isDeviceExist(deviceObj, devicesRegistered) {

    if (devicesRegistered && devicesRegistered.length) {

      const devicedetail = devicesRegistered.filter(device => device.deviceId === deviceObj.deviceId)[0];

      return !!devicedetail;

    } else {

      return false;

    }

  }

  createPayload(deviceObj, userObj) {

    const device = {} as DeviceDetails;

    device.deviceId = (deviceObj && deviceObj.deviceId) ? deviceObj.deviceId : null;
    device.deviceToken = (deviceObj && deviceObj.deviceToken) ? deviceObj.deviceToken : null;
    device.deviceName = (deviceObj && deviceObj.deviceModel) ? deviceObj.deviceModel : null;
    device.deviceLocation = (deviceObj && deviceObj.location) ? deviceObj.location : null;
    device.active = true;
    device.notificationFlag = true;
    device.addedDate = null;
    if (userObj && userObj.deviceDetails && userObj.deviceDetails.length) {

      userObj.deviceDetails = [...userObj.deviceDetails, device];

    } else {

      userObj.deviceDetails = [device];

    }
    return userObj;

  }

  registerIOSDevice(deviceObj, userObj) {

    // deviceObj = JSON.parse(deviceObj);
    const iosRegDeviceURL = environment.getProfileAPIURL;

    const queryStr = `/${userObj.userId}`;

    console.log('Regestering IOS Device to backend : ', deviceObj, userObj);
    const ifExist = this.isDeviceExist(deviceObj, userObj.deviceDetails);

    if (!ifExist) {

      const payload = this.createPayload(deviceObj, userObj);

      console.log('Regestering IOS Device to backend payload: ', payload);
      this.cS.serviceRequestCommon('put', iosRegDeviceURL, queryStr, payload).subscribe(() => {

        this.toastService.createMessage('success', 'The Device Has Been Succesfully registered');

      }, () => {

        this.toastService.createMessage('error', 'Error in Regestering Device.');

      });

    }

  }

}
