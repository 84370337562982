import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  /**
     *  
     *  To redirect the old /other UI env to new UI env
     * 
     */
  copyToClipboard(text: string): void {

    if (window.navigator.clipboard) {

      window.navigator.clipboard.writeText(text).then(
        () => {

          console.log('Text copied to clipboard');
        
        },
        (err) => {

          console.error('Could not copy text: ', err);
        
        }
      );
    
    } else {

      console.error('Clipboard API not supported');
    
    }
  
  }

}


