import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-story-status',
  templateUrl: './story-status.component.html',
  styleUrls: ['./story-status.component.scss']
})
export class StoryStatusComponent {

    @Input() status = '';

    @Input() showStatusText = true;

    @Input() style: { [key: string]: string } = {};

}
