import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePipe } from '@angular/common';

const keepOrder = (a, _b) => a;

@Pipe({
  name: 'defaultOrderKeyvalue'
})
export class DefaultOrderKeyvaluePipe extends KeyValuePipe implements PipeTransform {

  transform(value: any, ..._args: any[]): any {

    return super.transform(value, keepOrder);

  }

}
