import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  public language: any;

  constructor(public translate: TranslateService) { }

  setDefaultLanguage() {

    this.translate.addLangs(['en', 'fr']);
    this.language = navigator.language.slice(0, 2);

    // console.log(browserLang);
    // if (browserLang) {
    //   console.log('browserLang', browserLang);
    //   this.language = browserLang.match(/en|fr/) ? browserLang : 'en';
    // }
    // Commented above because all the time either en or fn will come in browserLang
    return this.language.replace(/(^\/?)|(\/?$)/, '/');
  
  }

}
