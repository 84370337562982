<nz-spin
   nzSimple
   class="loader"
   *ngIf="isLoading"></nz-spin>

<nz-card class="hoverCard"
         [attr.data-component]="'shared/components/profile-overlay/profile-overlay.component.html'"
         *ngIf="profileData && isVisible">

	<nz-avatar *ngIf="profileData.profilePictureURL !== '' && profileData.profilePictureURL !== null "
	           [nzSize]="64"
	           [nzSrc]="getUrl(profileData.profilePictureURL)"></nz-avatar>
	<nz-avatar *ngIf="profileData.profilePictureURL === '' || profileData.profilePictureURL === null || undefined"
	           [nzSize]="64"
	           nzIcon="user"></nz-avatar>

   <p *ngIf="profileData.displayName && profileData.displayName.split(',').length > 1">
      {{ profileData.displayName.split(',')[1] }} {{ profileData.displayName.split(',')[0] }}
   </p>

   <p *ngIf="profileData.displayName && profileData.displayName.split(',').length === 1">
      {{ profileData.displayName.trim() }}
   </p>

   <p *ngIf="!profileData.displayName">{{ profileData.firstName }} {{ profileData.lastName }}</p>

   <p>{{ profileData.jobTitle }}</p>

   <div
      class="followButton"
      *ngIf="!isLoggedInUser">
      <button
         nz-button
         nzType="primary"
         *ngIf="!isFollowing"
         (click)="followUser(profileData.userId, 'post')">
         Follow
      </button>
      <button
         nz-button
         nzType="default"
         *ngIf="isFollowing"
         (click)="followUser(profileData.userId, 'delete')">
         Unfollow
      </button>
   </div>
   <div class="viewProfileButton">
      <button
         nz-button
         nzType="primary"
         (click)="viewProfile(profileData.userId)">
         View Profile
      </button>
   </div>
</nz-card>

<nz-card
   class="hoverCard"
   *ngIf="!profileData">
   <nz-empty [nzNotFoundContent]="contentTpl">
      <ng-template #contentTpl>
         <span>No Results Found</span>
      </ng-template>
   </nz-empty>
</nz-card>
