<div class="license">
   <div nz-row>
      <div
         nz-col
         nzXs="24"
         class="licenseTitle">
         <span>License Limitations</span>
      </div>
   </div>
   <div class="licenseContent">
      <div class="licenseTextArea">
         <textarea
            rows="4"
            nz-input
            placeholder="List all license limitations here"
            [(ngModel)]="limitLicenseContent.additionalNotes"
            (ngModelChange)="onChange($event, 'additionalNotes')"></textarea>
      </div>
   </div>
</div>
