<ul nz-menu
    class='app-bento-box'>
  <li nz-menu-item
      [ngClass]="disabled.includes('ncx') ? 'disabled' : ''"
      *ngIf="links.includes('ncx')">
    <button (click)="openNBCApp('ncx')">
      <icon-news-connect
        class='icon'
        [useDefaultColors]='false'></icon-news-connect>
      NewsConnect
    </button>
  </li>
  <li nz-menu-item
      [ngClass]="disabled.includes('producer-dashboard') ? 'disabled' : ''"
      *ngIf="links.includes('producer-dashboard')">
    <button (click)="openNBCApp('producer-dashboard')">
      <icon-nbc class='icon'></icon-nbc>
      Producer Dashboard
    </button>
  </li>
  <li nz-menu-item
      [ngClass]="disabled.includes('guest-tracker') ? 'disabled' : ''"
      *ngIf="links.includes('guest-tracker')">
    <button (click)="openNBCApp('guest-tracker')">
      <icon-guest-tracker class='icon guest-tracker'></icon-guest-tracker>
      Guest Tracker
    </button>
  </li>
  <li nz-menu-item
      [ngClass]="disabled.includes('news-search') ? 'disabled' : ''"
      *ngIf="links.includes('news-search')">
    <button (click)="openNBCApp('news-search')">
      <icon-nbc class='icon'></icon-nbc>
      Search
    </button>
  </li>
</ul>
