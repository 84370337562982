import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { AngleMode } from '@models/ncx/angle';
import { IFunctionAbility, PERMISSIONS, User } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { PermissionsService } from '@services/profile/permissions.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-linked-story-team',
  templateUrl: './linked-story-team.component.html',
  styleUrls: ['./linked-story-team.component.scss']
})
export class LinkedStoryTeamComponent implements OnInit, OnChanges {

  @Input() team: User[] = [];

  @Input() createdUser: User = {} as User;

  @Input() disableAccess: boolean = false;

  @Input() access: string = AngleMode.Public;

  @Input() angleHasPrivateStory: boolean = false;

  @Output() updateInTeam = new EventEmitter();

  @Output() updateInAccess = new EventEmitter();

  availableUsers: User[] = [];

  subject: Subject<string> = new Subject();

  previousSearchInput: string = '';

  searchInput: string = '';

  searchedUserNames: any = [];

  usersFromSearchText: any = [];

  emptyValue = ['No Collaborators Found'];

  privacy: boolean = false;

  functionAbility = {} as IFunctionAbility;

  isMobile: boolean = false;

  showToolTip: boolean = false;

  tooltipTrigger = 'manual';

  accessToEditCollaborators: boolean = false;

  constructor(
    private cService: CommonService, private tI: TransferInformationComponent, private breakpointService: BreakpointService,
    private permissions: PermissionsService
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      if (searchTextValue.trim() != '') {

        const searchUser = this.usersFromSearchText.filter(user => user.name.preferredName === searchTextValue)[0];

        if (searchUser == null && this.previousSearchInput !== searchTextValue) {

          this.fetchUsers(searchTextValue);

        }
        this.previousSearchInput = searchTextValue;

      } else {

        this.searchedUserNames = [];

      }

    });

  }

  ngOnInit() {

    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });
    this.privacy = this.access === AngleMode.Private ? true : false;

    this.editAccessToCollaborators();

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    if ('team' in changes || 'new' in changes) {

      this.setTeamMembers();
      this.editAccessToCollaborators();

    }

    if ('access' in changes) {

      this.privacy = this.access === AngleMode.Private ? true : false;
      this.editAccessToCollaborators();

    }

    if ('angleHasPrivateStory' in changes) {

      this.editAccessToCollaborators();

    }


  }

  setTeamMembers() {

    this.availableUsers = [];
    if (this.team?.length > 0) {

      this.availableUsers = this.team;

    }

  }

  onchange(event) {

    this.subject.next(event.target.value);

  }

  selectedUser(value) {

    const selectedUser = this.usersFromSearchText.filter(user => user.name.preferredName === value)[0];

    if (selectedUser != null) {

      let user: User = {} as User;

      user = {
        firstName: selectedUser.name.firstName.trim(),
        lastName: selectedUser.name.lastName.trim(),
        jobTitle: selectedUser.workInfo.jobTitle,
        ssoId: selectedUser.ssoId,
        userId: selectedUser.userId
      } as User;
      this.availableUsers.push(user);

      //to get distinct records of userId, incase the same user is selected twice
      this.availableUsers = [
        ...new Map(this.availableUsers.map((item) => [item.userId, item])).values()
      ];

      this.updateInTeam.emit(this.availableUsers);
      this.resetValues();

    }

  }

  resetValues() {

    setTimeout(() => {

      this.searchInput = '';
      this.previousSearchInput = '';
      this.searchedUserNames = [];

    }, 100);

  }

  /* API to fetch the user details based upon search string */
  fetchUsers(searchTextValue) {

    this.usersFromSearchText = [];
    const queryStr = `?name=${searchTextValue}`;

    this.cService.serviceRequestCommon('get', environment.getUsersOnDutyURL, queryStr).subscribe((res: any) => {

      this.usersFromSearchText = [...res];
      this.searchedUserNames = res.length ? this.usersFromSearchText.map((user) => user.name.preferredName) : this.emptyValue;

    }, (err) => {

      console.log('Error in fetching users by search text :', err);

    });

  }

  unlinkCollaborators(userId: number) {

    this.availableUsers.forEach((element, itemindex) => {

      if (userId === element.userId) {

        this.availableUsers.splice(itemindex, 1);

      }

    });
    this.updateInTeam.emit(this.availableUsers);

  }

  /**
 * Event when clicking the button to change Access Value (public/private)
 *
 */
  toggleAccess() {


    // if (this.functionAbility.fa_create_private_angle) {
    //   
    // }
    this.access = this.access === AngleMode.Private ? AngleMode.Public : AngleMode.Private;
    this.updateAccess();

  }

  /**
   * Event when clicking the button to change Access Value (public/private)
   *
   */
  updateAccess() {

    this.privacy = this.access === AngleMode.Private ? true : false;
    this.updateInAccess.emit(this.access);

  }

  toggleTooltip() {

    if (this.isMobile) {

      this.showToolTip = !this.showToolTip;

    }

  }

  showTooltipEvent() {

    if (!this.isMobile && !this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = true;

      }, 500);

    }

  }

  hideTooltip() {

    if (!this.isMobile && this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = false;

      }, 500);

    }

  }

  /**
   * This event is similar to the event editAccessToAngle in Angle Landing component
   * User who have edit access to angle will have edit access to collaborators in simple point   * 
   * As of now Admins, Standards and Senior Editors have edit access by default. Refer 'Pemission-Matrix' excel. Rights can change at any time 
   * Creator and Collaborators have edit access other than the users who have default rights to edit angle
   */
  editAccessToCollaborators() {

    if (this.angleHasPrivateStory) {

      this.accessToEditCollaborators = false;

    } else {

      const collaboratorsIds = this.team.map(x => x.userId);

      if (this.privacy) {

        this.accessToEditCollaborators = this.permissions.hasPermissionTo(PERMISSIONS.EDIT_PRIVATE_ANGLE,
          { createUser: this.createdUser ? this.createdUser : {} as User, collaborators: collaboratorsIds });

      } else {

        this.accessToEditCollaborators = this.permissions.hasPermissionTo(PERMISSIONS.EDIT_PUBLIC_ANGLE,
          { createUser: this.createdUser ? this.createdUser : {} as User, collaborators: collaboratorsIds });

      }
    
    }

  }

}
