<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout
   class="group-details"
   [attr.data-component]="'ncx/Group/group-details.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title>
         <button
            nz-button
            nzType="primary"
            (click)="createDiscussion()">
            <i
               nz-icon
               nzType="plus"></i>
         </button>

         <!-- HEADER AFTER SCROLLING UP -->
         <span *ngIf="didScroll">
            <nz-divider nzType="vertical"></nz-divider>
            <span class="title">{{ groupDetails?.groupName }}</span>
         </span>
      </nz-page-header-title>

      <!-- OPEN GROUP DETAILS -->
      <nz-page-header-extra class="extra-header">
         <button
            (click)="toggleSider()"
            class="slider-btn">
            <i
               nz-icon
               nzType="setting"
               nzTheme="outline"></i>
         </button>
      </nz-page-header-extra>
   </nz-page-header>

   <nz-layout>
      <nz-content class="content">
         <!-- DEFAULT HEADER BEFORE SCROLLING UP -->
         <div
            *ngIf="!didScroll"
            nz-row
            class="default-header">
            <div
               class="title"
               nz-col
               nzXs="16"
               nzSm="19"
               nzMd="19"
               nzLg="18"
               nzXl="15"
               nzXXl="17">
               <span *ngIf="groupDetails && groupDetails.groupName">
                  {{ groupDetails?.groupName }}
               </span>
            </div>
            <div
               nz-col
               nzXs="8"
               nzSm="5"
               nzMd="5"
               nzLg="6"
               nzXl="9"
               nzXXl="7">
               <span style="float: right">
                  <app-follow-group
                     *ngIf="groupDetails"
                     type="text"
                     [groupId]="groupDetails.groupId"
                     [isFollowing]="groupDetails.isUserFollowing"
                     [followers]="groupDetails.groupFollowerCount"
                     [showFollowCount]="true"
                     [showFollowStatus]="false"
                     (updatedFollower)="updateFollower($event)"></app-follow-group>
               </span>
            </div>
         </div>

         <div class="group-details-tabs">
            <nz-tabset>
               <!-- DISCUSSION TAB -->
               <nz-tab
                  nzTitle="Discussion"
                  (nzClick)="navTo('discussion')">
                  <nz-input-group
                     nzSearch
                     class="search-bar"
                     [nzAddOnAfter]="suffixIconButton">
                     <input
                        nz-input
                        placeholder="Search here..."
                        [(ngModel)]="searchText"
                        (keyup)="onChange($event.target.value, $event, false)" />
                     <ng-template #suffixIconButton>
                        <button
                           nz-button
                           nzType="primary"
                           nzSearch>
                           <i
                              nz-icon
                              nzType="search"
                              nzTheme="outline"></i>
                        </button>
                     </ng-template>
                  </nz-input-group>

                  <ng-container
                     *ngIf="(!groupDiscussionDetails || !groupDiscussionDetails.length) && isLoaded"
                     [ngTemplateOutlet]="noResults"></ng-container>

                  <div
                     *ngIf="groupDiscussionDetails"
                     [attr.data-scrollable]="true"
                     class="discussion-details"
                     infiniteScroll
                     [ngStyle]="checkScreen()"
                     [infiniteScrollDistance]="2"
                     (scrolled)="onScrollDown()"
                     [scrollWindow]="false"
                     (scroll)="scrollDiv()">
                     <ncx-group-discussion
                        *ngFor="let discussion of groupDiscussionDetails"
                        [discussion]="discussion"></ncx-group-discussion>
                  </div>
               </nz-tab>

               <!-- MEMBERS TAB -->
               <nz-tab
                  nzTitle="Members({{ groupFollowers && groupFollowers.length ? groupFollowers.length : 0 }})"
                  (nzClick)="navTo('members')">
                  <nz-input-group
                     nzSearch
                     class="search-bar"
                     [nzAddOnAfter]="suffixIconButton">
                     <input
                        nz-input
                        placeholder="Search here..."
                        [(ngModel)]="value"
                        (keyup)="onChange($event.target.value, $event, true)" />
                     <ng-template #suffixIconButton>
                        <button
                           nz-button
                           nzType="primary"
                           nzSearch>
                           <i
                              nz-icon
                              nzType="search"
                              nzTheme="outline"></i>
                        </button>
                     </ng-template>
                  </nz-input-group>

                  <ng-container
                     *ngIf="(!groupFollowers || !groupFollowers.length) && isLoaded"
                     [ngTemplateOutlet]="noResults"></ng-container>

                  <div class="member-details">
                     <app-stories-following [storyFollowingDetails]="groupFollowers"></app-stories-following>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>
      </nz-content>

      <nz-sider
         *ngIf="!isMobile"
         nzCollapsible
         nzCollapsedWidth="0"
         [nzWidth]="295"
         [(nzCollapsed)]="isGroupDetailsCollapsed"
         [nzTrigger]="null">
         <app-toggle-section
            [gdCollapsed]="gdCollapsed"
            [groupDetails]="groupDetails"
            [isAdminOrOwner]="isAdminOrOwner"
            (gdCloseSider)="toggleSider()"
            (gotoManageMembers)="goToManageMembers($event)"
            (isEdit)="redirectToEditGroup($event)"
            (exportMembersCSV)="exportMembersCSV($event)"></app-toggle-section>
      </nz-sider>
   </nz-layout>
</nz-layout>

<nz-drawer
   *ngIf="isMobile"
   nzPlacement="right"
   [nzClosable]="false"
   [nzVisible]="!isGroupDetailsCollapsed"
   [nzMaskClosable]="true"
   [nzWrapClassName]="'no-padding'"
   [nzWidth]="320"
   (nzOnClose)="closeSlider()">
   <ng-container *nzDrawerContent>
      <app-toggle-section
         [isDrawer]="true"
         [gdCollapsed]="gdCollapsed"
         [groupDetails]="groupDetails"
         [isAdminOrOwner]="isAdminOrOwner"
         (gdCloseSider)="toggleSider()"
         (exportMembersCSV)="exportMembersCSV($event)"
         (isEdit)="redirectToEditGroup($event)"
         (gotoManageMembers)="goToManageMembers($event)"></app-toggle-section>
   </ng-container>
</nz-drawer>

<app-share-discussion
   [isVisible]="isVisible"
   [message]="shareMessage"
   [discussionId]="discussionId"
   (closedSharedContent)="handleCancel()"></app-share-discussion>

<!-- NO RESULTS -->
<ng-template #noResults>
   <div class="notFoundCard">
      <nz-empty [nzNotFoundContent]="contentTpl">
         <ng-template #contentTpl>
            <span>No Results Found</span>
         </ng-template>
      </nz-empty>
   </div>
</ng-template>
