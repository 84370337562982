import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-archive-banner',
  templateUrl: './archive-banner.component.html',
  styleUrls: [
    '../posts/common.scss',
    './archive-banner.component.scss'
  ]
})
export class ArchiveBannerComponent {

  @Input() archiveUrl: string = '';

  show: boolean = true;

  close() {

    this.show = false;

  }

}
