import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileIcon' })
export class FileIcon implements PipeTransform {

  imgExtn = ['jpeg', 'jfif', 'png', 'jpg', 'bmp', 'heic', 'jfif', 'avif'];

  docsExtn = ['docx', 'doc'];

  zipExtn = ['zip', 'rar'];

  audioExtn = ['mp3', 'wav', 'm4a', 'ogg', 'aiff', 'au'];

  pptExtn = ['ppt', 'pptx'];

  excelExtn = ['xls', 'xlsx'];

  txtExtn = ['txt', 'rtf'];

  videoExtn = ['mov', 'mp4', 'mpg', 'wmv', 'webm', 'flv', 'avi', 'mkv', 'm4v', '3gp', 'mpeg', 'wmv', 'asf'];

  pdfExtn = ['pdf'];

  transform(filename: string): string {

    const extn = String(filename).split('.').pop().toLowerCase();

    if (this.docsExtn.includes(extn)) {

      return 'file-word';

    } else if (this.pdfExtn.includes(extn)) {

      return 'file-pdf';

    } else if (this.zipExtn.includes(extn)) {

      return 'file-zip';

    } else if (this.audioExtn.includes(extn)) {

      return 'audio';

    } else if (this.pptExtn.includes(extn)) {

      return 'file-ppt';

    } else if (this.excelExtn.includes(extn)) {

      return 'file-excel';

    } else if (this.videoExtn.includes(extn)) {

      return 'video-camera';

    } else if (this.imgExtn.includes(extn)) {

      return 'file-image';
    
    } else if (this.txtExtn.includes(extn)) {

      return 'file-text';
    
    } else {

      return 'file-unknown';

    }

  }

}
