<!-- BUTTON STYLE -->
<button
   nz-button
   *ngIf="type === 'button'"
   [attr.data-component]="'shared/followers/follow-group (button)'"
   [nzLoading]="!isLoaded"
   (click)="changeFollowStatus()">
   <i
      nz-icon
      nzType="star"
      [nzTheme]="isFollowing ? 'fill' : 'outline'"></i>

   <span
      class="count"
      *ngIf="showFollowCount">
      {{ followers || 0 }}
   </span>

   <span
      class="status"
      *ngIf="showFollowStatus">
      {{ isFollowing ? 'Following' : 'Follow' }}
   </span>
</button>

<!-- TEXT LINK STYLE -->
<span
   *ngIf="type === 'text'"
   class="text"
   [attr.data-component]="'shared/followers/follow-group (link)'">
   <button (click)="changeFollowStatus()">
      <i
         nz-icon
         [nzType]="isLoaded ? 'star' : 'loading'"
         [nzTheme]="!isLoaded ? 'outline' : isFollowing ? 'fill' : 'outline'"></i>
   </button>

   <button
      class="count"
      *ngIf="showFollowCount"
      (click)="followers > 0 ? viewFollowers() : undefined">
      {{ followers || 0 }}
   </button>

   <span
      class="status"
      *ngIf="showFollowStatus">
      {{ isFollowing ? 'Following' : 'Follow' }}
   </span>
</span>
