import { Component } from '@angular/core';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-disabled-user',
  templateUrl: './disabled-user.component.html',
  styleUrls: ['./disabled-user.component.scss']
})
export class DisabledUserComponent {

  errorDetails;

  constructor(
    private authService: AuthService,
    private userInfo: TransferInformationComponent
  ) {


    /**
     * Session should be validated only if the user has successfully logged in to the application.
     * If the user is disabled in the Admin screen, then the 'Disabled-user' screen should be shown and the user should not be redirected to the log off screen.
     */

    this.authService.checkSession.next(false);

    this.errorDetails = this.userInfo.linkLoggedInErrSource.value;

  }

}
