import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BreakpointService } from '@services/breakpoint.service';
import { CSSThemeService } from '@services/css-theme.service';

@Component({
  selector: 'app-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.scss']
})
export class BetaComponent implements OnInit, OnChanges {

  isMobile = false;

  isXXLarge = false;

  isMedium = false;

  isSmallOrXSmall = false;

  isLarge = false;

  isXLarge = false;

  betaText = 'BETA';

  width = 49;

  margn: any = {
    top: -2,
    right: 0,
    bottom: 0,
    left: 0
  };

  color: string = '';

  bgColor: string = '';

  border: any = {
    width: 0,
    color: ''
  };

  @Input() showBInMobile: boolean = false;

  @Input() isRadioSelected: boolean = false;

  @Input() isTabSelected: boolean = false;

  @Input() search: boolean = false;

  @Input() isTab: boolean = false;

  @Input() angleLanding: boolean = false;

  @Input() margin: any = this.margn;

  constructor(
    private breakpointService: BreakpointService,
    private themeService: CSSThemeService
  ) {
  }

  ngOnInit() {

    // this.breakpointService.isMobile$.subscribe(res => {
    //   this.isMobile = res;
    // });

    this.breakpointService.breakpoints$.subscribe((res: any) => {

      console.log(res);
      this.isXXLarge = res.xxlarge ? true : false;
      this.isMobile = (res.xsmall || res.small || res.medium) ? true : false;
      this.isMedium = (res.medium) ? true : false;
      this.isSmallOrXSmall = (res.xsmall || res.small) ? true : false;
      this.isLarge = (res.large) ? true : false;
      this.isXLarge = (res.xlarge) ? true : false;
      this.setHeight();

    });

    this.themeService.theme.subscribe(() => {

      this.setColor();
      this.setHeight();

    });

  }

  ngOnChanges() {

    this.setColor();
    this.setHeight();

  }

  setHeight() {

    if (this.isMobile && this.showBInMobile) {

      this.betaText = 'B';
      this.width = 16;
      this.margin.right = this.angleLanding ? 8 : 0;
      this.margin.left = 0;

      //document.documentElement.style.setProperty('--width', '16px');
      //document.documentElement.style.setProperty('--right-padding', '0px');
      //document.documentElement.style.setProperty('--left-padding', '0px');
      if (this.isTab) {

        document.documentElement.style.setProperty('--line-height', this.themeService.currentTheme == 'dark' ? '12.75px' : '14.63px');

      } else {

        document.documentElement.style.setProperty('--line-height',
          this.themeService.currentTheme == 'dark' && this.isMedium ? '13.5px' : this.themeService.currentTheme == 'dark' ? '12.75px' : '14.63px');

      }

    } else {

      this.betaText = 'BETA';
      this.width = 49;
      this.margin.right = this.angleLanding ? 8 : 0;
      this.margin.left = this.angleLanding ? 0 : 8;

      //document.documentElement.style.setProperty('--width', '49px');
      // document.documentElement.style.setProperty('--right-padding', this.angleLanding ? '8px' : '0px');
      // document.documentElement.style.setProperty('--left-padding', this.angleLanding ? '0px' : '8px');

      if (this.themeService.currentTheme == 'dark') {

        if (this.isTab) {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '13.3px' : this.isXXLarge ? '13.7px' : this.isXLarge ? '13.3px' : this.isLarge ? '13.3px' : '13.3px');

        } else if (this.angleLanding) {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '12.75px' : this.isXXLarge ? '14.3px' : this.isXLarge ? '14.3px' : this.isLarge ? '13.2px' : '14.63px');

        } else if (this.search) {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '12.75px' : this.isXXLarge ? '14.3px' : this.isXLarge ? '14.63px' : this.isLarge ? '13.5px' : '14.63px');

        } else {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '12.75px' : this.isXXLarge ? '14.63px' : this.isXLarge ? '14px' : this.isLarge ? '14.3px' : '14.63px');

        }

      } else {

        if (this.isTab) {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '15px' : this.isXXLarge ? '15.5px' : this.isXLarge ? '15.4px' : this.isLarge ? '15.3px' : '15px');

        } else if (this.angleLanding) {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '15px' : this.isXXLarge ? '15.2px' : this.isXLarge ? '15.5px' : this.isLarge ? '15px' : '14.63px');

        } else if (this.search) {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '12.75px' : this.isXXLarge ? '15.3px' : this.isXLarge ? '14.8px' : this.isLarge ? '14.7px' : '14.63px');

        } else {

          document.documentElement.style.setProperty('--line-height',
            (this.isSmallOrXSmall) ? '12.75px' : this.isXXLarge ? '16px' : this.isXLarge ? '15px' : this.isLarge ? '15px' : '14.63px');

        }

      }

    }

  }

  setColor() {

    if (this.isRadioSelected && this.themeService.currentTheme == 'dark') {

      this.color = '#1890FF';

    } else if (this.isTabSelected && this.themeService.currentTheme == 'dark') {

      this.color = '#000000';

    } else if (this.themeService.currentTheme == 'dark') {

      this.color = '#141414';

    } else {

      this.color = '#FFFFFF';

    }

    //document.documentElement.style.setProperty('--beta-color', color);

    if (this.isTabSelected && this.themeService.currentTheme == 'dark') {

      this.bgColor = '#1890FF';

    } else if (this.themeService.currentTheme == 'dark') {

      this.bgColor = '#FFFFFFA6';

    } else {

      this.bgColor = '#0050B3';

    }

    //document.documentElement.style.setProperty('--beta-bg-color', bgColor);

    if (this.isTabSelected && this.themeService.currentTheme == 'dark') {

      this.border.width = 1;
      this.border.color = '#1890FF33';

    } else if (this.themeService.currentTheme == 'dark') {

      this.border.width = 1;
      this.border.color = '#FFFFFF4D';

    } else {

      this.border.width = 0;
      this.border.color = '';

    }

    // document.documentElement.style.setProperty('--border-width', borderWidth);
    // document.documentElement.style.setProperty('--border-color', borderColor);

  }

}


