<button
   [ngClass]="{ text: !clickable }"
   (click)="clickable ? onClick() : undefined"
   *ngIf="total || showZero">
   <i
      nz-icon
      nzType="paper-clip"
      nzTheme="outline"></i>
   <span class="count">{{ total || 0 }}</span>
</button>
