import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-topic-banner',
  templateUrl: './topic-banner.component.html',
  styleUrls: [
    '../posts/common.scss',
    './topic-banner.component.scss'
  ]
})
export class TopicBannerComponent {

  @Input() message: string = '';

  show: boolean = true;

  close() {

    this.show = false;

  }

}
