<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<nz-layout [attr.data-component]="'ncx/Groups/manage-members.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title>
         <button
            (click)="addMembers()"
            nz-button
            *ngIf="isAdminOrOwner">
            <i
               nz-icon
               nzType="usergroup-add"
               nzTheme="outline"></i>
         </button>
         <nz-divider
            nzType="vertical"
            *ngIf="isAdminOrOwner"></nz-divider>
         <span style="font-size: 14px">{{ groupDetails?.groupName }}</span>
      </nz-page-header-title>
   </nz-page-header>
</nz-layout>

<div
   class="manageFollowers"
   *ngIf="isAdminOrOwner">
   <nz-tabset
      (nzSelectChange)="onTabChnage(selectedIndex)"
      [(nzSelectedIndex)]="selectedIndex">
      <ng-container>
         <nz-tab nzTitle="Members({{ groupFollowers && groupFollowers.length ? groupFollowers.length : 0 }})">
            <div class="searchMembers">
               <div class="searchField">
                  <nz-input-group [nzPrefix]="prefixTemplateUser">
                     <input
                        type="text"
                        [(ngModel)]="searchInput"
                        nz-input
                        placeholder="Search Members......"
                        (keyup)="onChange($event.target.value, $event)"
                        #inputText />
                     <ng-template #prefixTemplateUser>
                        <i
                           nz-icon
                           nzType="search"></i>
                     </ng-template>
                  </nz-input-group>
               </div>
            </div>
            <div class="membersList">
               <app-stories-following
                  [storyFollowingDetails]="groupFollowers"
                  [screen]="'MANAGE-MEMBERS'"
                  (deleteUser)="deleteUser($event)"></app-stories-following>
            </div>
         </nz-tab>

         <nz-tab nzTitle="Invitation({{ invitationList && invitationList.length ? invitationList.length : 0 }})">
            <div class="searchMembers">
               <div class="searchField">
                  <nz-input-group [nzPrefix]="prefixTemplateUser">
                     <input
                        type="text"
                        [(ngModel)]="searchInput"
                        nz-input
                        placeholder="Search Members......"
                        (keyup)="onChange($event.target.value, $event)"
                        #inputText />
                     <ng-template #prefixTemplateUser>
                        <i
                           nz-icon
                           nzType="search"></i>
                     </ng-template>
                  </nz-input-group>
               </div>
            </div>
            <div nz-row>
               <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzXXl="12"
                  *ngFor="let member of invitationList">
                  <nz-card
                     class="cardSize"
                     class="requestList">
                     <div class="viewProfileLeft">
                        <nz-card-meta
                           [nzAvatar]="avatarTemplate"
                           [nzTitle]="titleReference"
                           [nzDescription]="descReference"></nz-card-meta>
                     </div>
                     <div>
                        <a
                           class="resend"
                           (click)="sendRequest(member.userId, 'INVITATION')">
                           Resend
                        </a>
                        <a
                           class="remove"
                           (click)="deleteRequest(member, 'INVITATION')">
                           Remove
                        </a>
                     </div>
                  </nz-card>
                  <ng-template #avatarTemplate>
                     <nz-avatar
                        (click)="goToProfile(member.userId)"
                        [nzPopoverContent]="profileCardTemplate1"
                        nz-popover
                        [nzPopoverPlacement]="'topLeft'"
                        nzSrc="{{ Common.formS3Link(member.profilePictureURL) }}"
                        [nzSize]="48"></nz-avatar>
                     <ng-template #profileCardTemplate1>
                        <app-profile-overlay [profileData]="member.userId"></app-profile-overlay>
                     </ng-template>
                  </ng-template>
                  <ng-template #titleReference>
                     <p *ngIf="member.displayName.split(',').length > 1">
                        {{ member.displayName.split(',')[1] }} {{ member.displayName.split(',')[0] }}
                     </p>
                     <p *ngIf="member.displayName.split(',').length === 1">{{ member.displayName.trim() }}</p>
                  </ng-template>
                  <ng-template #descReference>
                     {{ member.jobTitle }}
                  </ng-template>
               </div>
            </div>
         </nz-tab>

         <nz-tab nzTitle="Requests({{ requestList && requestList.length ? requestList.length : 0 }})">
            <div class="searchMembers">
               <div class="searchField">
                  <nz-input-group [nzPrefix]="prefixTemplateUser">
                     <input
                        type="text"
                        [(ngModel)]="searchInput"
                        nz-input
                        placeholder="Search Members......"
                        (keyup)="onChange($event.target.value, $event)"
                        #inputText />
                     <ng-template #prefixTemplateUser>
                        <i
                           nz-icon
                           nzType="search"></i>
                     </ng-template>
                  </nz-input-group>
               </div>
            </div>
            <div nz-row>
               <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="24"
                  nzXl="24"
                  nzXXl="12"
                  *ngFor="let member of requestList">
                  <nz-card
                     class="cardSize"
                     class="requestList">
                     <div class="viewProfileLeft">
                        <nz-card-meta
                           [nzAvatar]="avatarTemplate"
                           [nzTitle]="titleReference"
                           [nzDescription]="descReference"></nz-card-meta>
                     </div>
                     <div>
                        <a
                           class="resend"
                           (click)="sendRequest(member.userId, 'REQUEST')">
                           Accept
                        </a>
                        <a
                           class="remove"
                           (click)="deleteRequest(member, 'REQUEST')">
                           Decline
                        </a>
                     </div>
                  </nz-card>
                  <ng-template #avatarTemplate>
                     <nz-avatar
                        (click)="goToProfile(member.userId)"
                        [nzPopoverContent]="profileCardTemplate1"
                        nz-popover
                        [nzPopoverPlacement]="'topLeft'"
                        nzSrc="{{ Common.formS3Link(member.profilePictureURL) }}"
                        [nzSize]="48"></nz-avatar>
                     <ng-template #profileCardTemplate1>
                        <app-profile-overlay [profileData]="member.userId"></app-profile-overlay>
                     </ng-template>
                  </ng-template>
                  <ng-template #titleReference>
                     <p *ngIf="member.displayName.split(',').length > 1">
                        {{ member.displayName.split(',')[1] }} {{ member.displayName.split(',')[0] }}
                     </p>
                     <p *ngIf="member.displayName.split(',').length === 1">{{ member.displayName.trim() }}</p>
                  </ng-template>
                  <ng-template #descReference>
                     {{ member.jobTitle }}
                  </ng-template>
               </div>
            </div>
         </nz-tab>
      </ng-container>
   </nz-tabset>
</div>

<div *ngIf="!isAdminOrOwner && isLoaded">
   <div class="searchMembers">
      <div class="searchField">
         <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input
               type="text"
               [(ngModel)]="searchInput"
               nz-input
               placeholder="Search Members......"
               (keyup)="onChange($event.target.value, $event)"
               #inputText />
            <ng-template #prefixTemplateUser>
               <i
                  nz-icon
                  nzType="search"></i>
            </ng-template>
         </nz-input-group>
      </div>
   </div>
   <div class="membersList">
      <app-stories-following [storyFollowingDetails]="groupFollowers"></app-stories-following>
   </div>
</div>

<div *ngIf="groupDetails && groupDetails.groupName">
   <app-add-members
      [invitePeople]="invitePeople"
      [groupMembers]="groupFollowersInit"
      [groupMessage]="groupDetails.groupName"
      [groupId]="groupId"
      (closeInvite)="closeMembers()"
      (refreshMemberList)="refreshMemberList($event)"></app-add-members>
</div>
