<div
   [ngClass]="{ layout: true, drawer: isDrawer }"
   [attr.data-component]="'ncx/activity/activity-filter/activity-filter.component.html'">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Filter</span>
      <button
         class="close"
         (click)="close()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>

   <nz-card>
      <div class="section">
         <p>
            <i
               nz-icon
               nzType="file"
               nzTheme="outline"></i>
            Activity
         </p>
         <section>
            <nz-radio-group [(ngModel)]="radioPostTypeValue">
               <label
                  (click)="addFilters($event, item, 'type')"
                  nz-radio
                  class="radio-labels"
                  [nzValue]="item.value"
                  *ngFor="let item of radioFilterOptions">
                  {{ item.label }}
               </label>
            </nz-radio-group>
         </section>
      </div>
   </nz-card>
   <nz-card>
      <div class="section story-labels">
         <p>
            <i
               nz-icon
               nzType="flag"
               nzTheme="outline"></i>
            Labels
         </p>
         <section>
            <ul nz-menu>
               <li
                  nz-menu-item
                  *ngFor="let item of checkOptionsLabel">
                  <label
                     nz-checkbox
                     nzValue="item.label"
                     [ngModel]="item.checked"
                     [nzDisabled]="item.disabled"
                     (ngModelChange)="addFilters($event, item, 'labels')">
                     {{ item.label }}
                  </label>
               </li>
            </ul>
         </section>
      </div>
   </nz-card>
   <nz-card>
      <div class="section">
         <p>
            <i
               nz-icon
               nzType="sort-ascending"
               nzTheme="outline"
               style="font-size: 16px; margin-right: 11px"></i>
            Order
         </p>
         <section>
            <nz-radio-group [(ngModel)]="radioCreationDateValue">
               <label
                  nz-radio
                  class="radio-labels"
                  (click)="addFilters($event, item, 'date')"
                  [nzValue]="item.value"
                  *ngFor="let item of radioCreationDateOptions">
                  {{ item.label }}
               </label>
            </nz-radio-group>
         </section>
      </div>
   </nz-card>

   <div class="options">
      <button
         (click)="clear()"
         nzBlock="true"
         nz-button>
         Clear
      </button>
   </div>
</div>
