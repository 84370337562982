import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { WebSocketService } from '@services/websocket.service';
import { UtilityService } from '@services/utility.service';
import { IFunctionAbility } from '@models/users';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { BreakpointService } from '@services/breakpoint.service';

@Component({
  selector: 'app-feeds',
  templateUrl: './feeds.component.html',
  styleUrls: ['./feeds.component.scss']
})
export class FeedsComponent implements OnInit, OnDestroy {

  getFilterListAPI;

  getFilters;

  feedOrder;

  feedTypeName = 'All';

  feedType;

  orderedBy = 'Modified Date';

  isCollapsed = true;

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  getFeedsAPI: string;

  storyState: any = 'ALL';

  sortType: any = 'modifiedDate';

  pageStartCount: any;

  pageLength: any;

  feedList: any = [];

  isLoaded: boolean;

  public functionAbility = {} as IFunctionAbility;

  private subscriptionsWs: Subscription = new Subscription();

  totalRecords: number;

  hiddenScrollFlag: boolean;

  constructor(
    private cService: CommonService,
    private uS: UtilityService,
    private wS: WebSocketService,
    private toastService: ToastService,
    private breakpointService: BreakpointService,
    private tI: TransferInformationComponent
  ) { }

  ngOnInit() {

    this.getFilterListAPI = environment.getPostFiltersURL;
    this.getFilters = environment.getFilterListURL;
    this.getFeedsAPI = environment.getStoriesAPI;
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.totalRecords = 100; // for testing pupose only
    this.hiddenScrollFlag = false;
    const queryStr = `/feeds?sortType=${this.sortType}&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    this.getFeedFilters();
    this.getFeeds(queryStr);
    this.subscriptionsWs = this.wS.doSocketData$.subscribe(data => {

      this.updateFeeds(data);

    });
    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  toggleSider(): void {

    this.isCollapsed = !this.isCollapsed;

  }

  closeSlider() {

    this.isCollapsed = !this.isCollapsed;

  }

  changeFeedType(obj) {

    this.feedList = [];
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.storyState = obj.value;
    this.getNextFeeds();
    this.feedTypeName = obj.label;

  }

  changeFeedOrder(obj) {

    this.feedList = [];
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.sortType = obj.value;
    this.orderedBy = obj.label;
    this.getNextFeeds();

  }

  onScrollDown() {

    this.pageStartCount += this.pageLength;

    // this.assignType();
    this.getNextFeeds();

  }

  getNextFeeds() {

    let queryStr = `/feeds?sortType=${this.sortType}&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    if (this.storyState !== 'ALL') {

      queryStr += `&state=${this.storyState}`;

    }
    this.getFeeds(queryStr);

  }

  clearFilter(_event) {

    const queryStr = `/feeds?sortType=${this.sortType}&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    this.getFeedFilters();
    this.getFeeds(queryStr);

  }

  updateFollower(userObj: FollowStoryUpdateEvent) {

    const type = userObj.type;

    const storyId = userObj.storyId;

    const data = userObj.data;

    const updateObj = this.feedList.filter((story) => story.storyId === storyId);

    if (!updateObj?.length) {

      return;

    }
    if (data) {

      updateObj[0].storyFollowerCount = data.storyFollowerCount;
      updateObj[0].isUserFollowing = type;

    } else {

      updateObj[0].storyFollowerCount -= 1;
      updateObj[0].isUserFollowing = type;

    }

  }

  resetFilters() {

    this.pageStartCount = 1;
    this.hiddenScrollFlag = false;
    this.feedList = [];

  }

  /*API for CRUD Operation Feeds Start*/
  getFeeds(queryStr) {

    this.isLoaded = (this.hiddenScrollFlag) ? true : false; // show loader only when actuall scroll happen
    this.cService.serviceRequestCommon('get', this.getFeedsAPI, queryStr).subscribe((res: any) => {

      if (res && res.totalCount) {

        this.totalRecords = res.totalCount;

      }
      if (res && res.stories && (res.stories.length < 5 && res.stories.length > 0)) {


        // hit next iteration if more records are present
        this.hiddenScrollFlag = true;
        this.feedList = [...this.feedList, ...res.stories];
        if (!this.isPSCReachedLastIndex()) {

          this.onScrollDown();

        } else {

          this.hiddenScrollFlag = false;
          this.pageStartCount -= this.pageLength;

        }
        return;

      } else if (res && res.stories && res.stories.length) {

        this.feedList = [...this.feedList, ...res.stories];

      } else if ((this.pageStartCount + 1) > this.pageLength) {

        if (!this.isPSCReachedLastIndex()) {

          this.hiddenScrollFlag = false;
          this.onScrollDown();

        } else {

          this.pageStartCount -= this.pageLength;

        }

      }

      // this.infiniteScroll.ngOnDestroy();
      // this.infiniteScroll.setup();
      this.hiddenScrollFlag = false;
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getFilterList() {

    this.cService.serviceRequestCommon('get', this.getFilterListAPI).subscribe((res: any) => {

      if (res) {

        this.feedType = res.draftType;
        this.feedOrder = res.orderDate;

      }

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getFeedFilters() {

    this.cService.serviceRequestCommon('get', this.getFilters).subscribe((res: any) => {

      this.feedType = res.data.feedType;
      this.feedOrder = res.data.orderDate;

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  /*API for CRUD Operation Feeds End*/

  private updateFeeds(data) {

    if (data.story) {

      let privateStoryWdAccess = true;


      // tslint:disable-next-line:max-line-length
      if ((data.story && data.story.storyAccess === 'private') &&
        !(((data.story.createUser && data.story.createUser.userId === Number(localStorage.getItem('userId')))
          || (this.functionAbility.fa_access_private_story)))) {

        privateStoryWdAccess = false;

      }
      if (data.story.isDeleted) {

        this.removeStory(data.story.storyId);

      } else {

        if (this.uS.checkStatus(data.story, this.storyState)) {

          if (this.sortType === 'mostReplies') {

            const CloneFeedList = this.uS.deepClone(this.feedList);

            this.feedList = [];
            this.feedList = this.uS.checkMostReplies(data.story, CloneFeedList);

          } else {

            const index = this.feedList.findIndex(story => story.storyId === data.story.storyId);

            if (index !== -1) {

              this.feedList.splice(index, 1);

            }
            if (privateStoryWdAccess) {

              this.feedList.unshift(data.story);

            }

          }
          this.toastService.createMessage('success', 'New Story Update/Added');

        }

      }

    }

  }

  private removeStory(storyId) {

    const index = this.feedList.findIndex(story => story.storyId === storyId);

    if (index !== -1) {

      this.feedList.splice(index, 1);

    }

  }

  private isPSCReachedLastIndex() {

    return (this.pageStartCount * this.pageLength >= this.totalRecords) ? true : false;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
