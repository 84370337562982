import { Injectable } from '@angular/core';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { PERMISSIONS, Profile, User } from '@models/users';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  userId: number = 0;

  role: string = 'READ_ONLY';

  permissions: string[] = [];

  isAdminRole = false;

  isStandardsRole = false;

  isSeniorEditorRole = false;

  isEditorRole = false;

  isJournalistRole = false;

  isReadOnlyRole = false;

  constructor(
    private tIService: TransferInformationComponent
  ) {

    // User's Profile is loaded
    this.tIService.userProfile.subscribe((profile: Profile) => {

      this.setProfile(profile)

    });

  }

  setProfile(profile: Profile) {

    const { role, userId, userAccess } = profile;

    // Roles can't be empty, must be a string
    this.role = role || 'READ_ONLY';

    if (userAccess && Array.isArray(userAccess)) {

      this.permissions = userAccess;
    
    }

    if (userId) {

      this.userId = userId;
    
    }

    this.setUserRole();
  
  }

  /**
   * Returns true or false for a given Permission and optional createUser or updateUser record
   *
   * Example:
   * this.permissionsService.hasPermissionTo(ACCESS.ACCESS_ADMIN_CONSOLE, { createUser: { ssoId: 0... } }));
   *
   */
  hasPermissionTo(permission: PERMISSIONS, users?: { createUser?: User, updateUser?: User, collaborators?: number[] }): boolean {

    // If permission is explicitly set.. return true
    if (this.permissions.includes(permission)) {

      return true;
    
    }

    // ... exceptions when the permission is not explicitly set
    switch (permission) {

    case PERMISSIONS.ACCESS_PRIVATE_STORY:
      return this.userId === users.createUser.userId;

    case PERMISSIONS.EDIT_STORY:
      return this.isJournalistRole && this.userId === users.createUser.userId;

    case PERMISSIONS.EDIT_POST:
      return this.isJournalistRole && this.userId === users.createUser.userId;

    case PERMISSIONS.VIEW_PRIVATE_ANGLE:
      return !this.isReadOnlyRole && (this.userId === users.createUser.userId || users.collaborators.includes(this.userId));

    case PERMISSIONS.EDIT_PRIVATE_ANGLE:
      return !this.isReadOnlyRole && (this.userId === users.createUser.userId || users.collaborators.includes(this.userId));

    case PERMISSIONS.DELETE_PRIVATE_ANGLE:
      return !this.isReadOnlyRole && (this.userId === users.createUser.userId || users.collaborators.includes(this.userId));

    case PERMISSIONS.EDIT_PUBLIC_ANGLE:
      return (this.isJournalistRole || this.isEditorRole) && (this.userId === users.createUser.userId || users.collaborators.includes(this.userId));

    case PERMISSIONS.DELETE_PUBLIC_ANGLE:
      return (this.isJournalistRole || this.isEditorRole) && (this.userId === users.createUser.userId || users.collaborators.includes(this.userId));

    case PERMISSIONS.EDIT_COMMENT:
      return this.isJournalistRole && this.userId === users.createUser.userId;

    case PERMISSIONS.ACCESS_PRIVATE_GROUP:
      return ((this.isJournalistRole || this.isEditorRole) && this.userId === users.createUser.userId);

    case PERMISSIONS.STANDARD_POST:
      return this.isAdminRole || this.isStandardsRole;

    case PERMISSIONS.EDIT_GROUP:
      return this.isJournalistRole && this.userId === users.createUser.userId;

    case PERMISSIONS.MANAGE_GROUP_MEMBERS:
      return this.isJournalistRole && this.userId === users.createUser.userId;

    default:
      return this.permissions.includes(permission);

    }

  }

  /**
   * Can add a specific POST label
   *
   */
  canAddPostLabel(label: string): boolean {

    // If the user can't even add/change label
    if (!this.hasPermissionTo(PERMISSIONS.ADD_POST_LABEL)) {

      return false;
    
    }

    // Admins can do anything
    if (this.isAdminRole) {

      return true;
    
    }

    // These labels carry special permission rules
    switch (label) {

    case 'IMPORTANT':
    case 'FLAGGED':
      return this.isStandardsRole ||
          this.isSeniorEditorRole ||
          this.isEditorRole;

    case 'HOT':
      return this.isStandardsRole ||
          this.isSeniorEditorRole;

    case 'STANDARDS':
      return this.isStandardsRole;

      // ... all other labels are OK for everyone
    default:
      return true;

    }

  }

  setUserRole() {

    this.isAdminRole = this.role === 'ADMIN';
    this.isStandardsRole = this.role === 'STANDARDS';
    this.isSeniorEditorRole = this.role === 'SR_EDITOR';
    this.isEditorRole = this.role === 'EDITOR';
    this.isJournalistRole = this.role === 'JOURNALIST';
    this.isReadOnlyRole = !this.role || this.role === 'READ_ONLY';
  
  }

}
