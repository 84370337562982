import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-draft-banner',
  templateUrl: './draft-banner.component.html',
  styleUrls: [
    '../posts/common.scss',
    './draft-banner.component.scss'
  ]
})
export class DraftBannerComponent {

  @Input() message : string = 'This document is a draft and will not be visible to others until you post it to a story or group.';

  show: boolean = true;

  close() {

    this.show = false;

  }

}
