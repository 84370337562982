import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

/**
 * This service shows large Notification messages
 * https://ng.ant.design/version/12.1.x/components/notification/en
 *
 */

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  // All available types
  private types = ['success', 'info', 'warning', 'error', 'loading'];

  // All active messages
  notifications: string[] = [];

  constructor(public notify: NzNotificationService) {}

  showMessage(
    type: string = 'info',
    title: string = '',
    message: string = '',
    options: object = {
      nzDuration: 4000,
      nzPauseHover: true
    }
  ): void {

    if (!message || !this.types.includes(type)) {

      return;
    
    }

    const messageRef = this.notify.create(type, title, message, options);

    // Store the messageId
    this.notifications.push(messageRef.messageId);

    // Remove messageId from the list of active messages
    messageRef.onClose.subscribe(() => {

      const index = this.notifications.indexOf(messageRef.messageId);

      if (index !== -1) {

        this.notifications.splice(index, 1);
      
      }
    
    });

  }

  /**
   * Remove message by message Id
   *
   */
  removeMessage(messageId: string) {

    this.notify.remove(messageId);

  }

  /**
   * Remove all messages
   *
   */
  removeAllMessages() {

    this.notifications.forEach(messageId => {

      this.removeMessage(messageId);
    
    });

  }

}
