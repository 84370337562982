import { Component, Input } from '@angular/core';
import { Post } from '@models/ncx/post';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-standard-guidance-view',
  templateUrl: './standard-guidance-view.component.html',
  styleUrls: ['./standard-guidance-view.component.scss']
})
export class StandardGuidanceViewComponent {

  @Input() postDetails: Post = {} as Post;

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);
    
    } else {

      return Time.convertingUtcToLocalTime(apiDate);
    
    }
  
  }

}
