import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlight' })

export class HighlightTextPipe implements PipeTransform {

  /**
   * Highlights text in a string
   *
   *
   */
  transform(input: string, wordToHighlight: string): string {

    try {

      if (wordToHighlight.length < 2) {

        return input;
      
      }

      const regex = new RegExp(`\\b${wordToHighlight}\\b`, 'igm');

      // 'snippet' is a global style
      return input.replace(regex, '<span class="snippet">$&</span>');

    } catch (error) {

      console.error('HighlightTextPipe ', error);

      return input;

    }

  }

}
