import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@models/users';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-discussion',
  templateUrl: './share-discussion.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class ShareDiscussionComponent implements OnInit {

  @Input() discussionId: number;

  @Input() isVisible: boolean = false;

  @Input() message: string = '';

  @Output() closedSharedContent = new EventEmitter();

  @Output() contentShared = new EventEmitter();

  isLoaded: boolean = true;

  searchValue: string;

  userList: string[];

  userEmailList: User[] = [];

  selectedUserList = [];

  userListNames: any = [];

  getUsers: string;

  notificationURL: string;

  subject: Subject<string> = new Subject();

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private commonFunctionsHelper: CommonFunctionsHelper
  ) {}

  ngOnInit() {

    this.getUsers = environment.getUsersOnDutyURL;

    this.notificationURL = environment.getNotificationURL;

    this.subject.pipe(debounceTime(500)).subscribe(searchTextValue => {

      this.fetchUserList(searchTextValue);
    
    });

  }

  onSearchEmails(value, event) {

    this.subject.next(event.target.value);

  }

  titleMatch(value: string) {

    try {

      const obj = this.userListNames.filter(user => user.userId === value)[0];

      const obj1 = this.selectedUserList.filter(user => user.userId === value)[0];

      if ((!(obj === null || obj === undefined) && (obj1 === null || obj1 === undefined))) {

        this.userEmailList.push(obj);

        setTimeout(() => {

          this.searchValue = '';
          this.userListNames = [];
          this.selectedUserList = [];
        
        }, 100);

      }

    } catch (error: any) {

      console.error('Title Match', error);

    }

  }

  /**
   * Remove user from selected list
   *
   */
  removeUser(userId: number) {

    this.userEmailList = this.userEmailList.filter((user: User) => user.userId !== userId);

  }

  /**
   * Ready to send e-mail
   *
   */
  onFinish(): void {

    if (!this.discussionId) {

      this.toastService.createMessage('warning', 'No Discussions to share');
      return;
    
    }

    if (!this.message) {

      this.toastService.createMessage('warning', 'Please enter a message');
      return;
    
    }

    if (!this.userEmailList.length) {

      this.toastService.createMessage('warning', 'Please select users to share with');
      return;
    
    }

    const payload = {
      messageId: this.commonFunctionsHelper.generateUUID(),
      notificationTypes: ['ShareDiscussion'],
      notificationCategory: 'UPDATE',
      entityId: this.discussionId,
      notificationTargetUsers: this.userEmailList.map((user: User) => user.userId),
      textDescription: this.message
    };

    this.isLoaded = false;

    this.cService.serviceRequestCommon('post', this.notificationURL, '/sharePost', payload).subscribe(() => {

      this.resetForm();
      this.contentShared.emit();
      this.closedSharedContent.emit();
      this.toastService.createMessage('success', 'Email successfully sent');
    
    }, (error: any) => {

      console.error('Send Share Email', error);
      this.toastService.createMessage('error', 'Error sending email. Please try again.');
    
    }).add(() => {

      this.isLoaded = true;
    
    });

  }

  fetchUserList(searchTextValue) {

    this.userList = [];
    const queryStr = `?name=${searchTextValue}`;

    this.cService.serviceRequestCommon('get', this.getUsers, queryStr).subscribe((res: any) => {

      this.userList = [...res];
      this.bindDropdownVal();
    
    }, (error: any) => {

      console.error('Fetch User List', error);
    
    });

  }

  bindDropdownVal() {

    const users = [];

    this.userList.forEach((user: any) => {

      users.push({
        fullName: ((user.name.firstName === undefined || user.name.firstName === null) ? '' : user.name.firstName) + ' ' +
          ((user.name.middleName === undefined || user.name.middleName === null) ? '' : user.name.middleName) + ' ' +
          ((user.name.lastName === undefined || user.name.lastName === null) ? '' : user.name.lastName)
        , userId: (user.userId === undefined || user.userId === null) ? '' : user.userId
      });
    
    });

    this.userListNames = users;

  }

  handleCancel() {

    this.resetForm();
    this.closedSharedContent.emit(true);

  }

  resetForm() {

    this.userEmailList = [];
    this.userListNames = [];
    this.searchValue = '';

  }

}
