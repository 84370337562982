import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-comments',
  templateUrl: './info-comments.component.html',
  styleUrls: ['../common.scss']
})
export class InfoCommentsComponent {

  @Input() total: number = 0;

  @Input() clickable: boolean = false;

  @Input() showZero: boolean = true;

  @Output() click: EventEmitter<void> = new EventEmitter();

  onClick() {

    this.click.emit();

  }

}
