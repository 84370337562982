import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private data = new BehaviorSubject<any>('');

  data$ = this.data.asObservable();

  setData(val: any) {

    this.data.next(val);
  
  }

  getData(val: any) {

    this.data.getValue();
  
  }

}

