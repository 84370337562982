import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-infocenter-research',
  templateUrl: './infocenter-research.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class InfoCenterResearchComponent implements OnInit, OnChanges {

  @Input() isinfocenterVisible;

  @Input() storyTitle;

  @Input() Id;

  @Output() closeinfoCenter: EventEmitter<any> = new EventEmitter<any>();

  isLoaded = true;

  inputValue = '';

  notificationURL: any;

  constructor(
    private cService: CommonService,
    private toastService: ToastService
  ) {}

  ngOnInit() {

    this.notificationURL = environment.getNotificationURL;
  
  }

  ngOnChanges() {

    if (this.isinfocenterVisible) {

      this.openDialog();
    
    }
  
  }

  createPayload() {

    const obj = {
      messageId: 'Testing',
      notificationTypes: ['RequestResearch'],
      entityId: this.Id,
      textDetails: this.inputValue,
      userId: localStorage.getItem('userId')
    };

    return obj;
  
  }

  handleOk() {

    this.shareContent();
  
  }

  handleCancel() {

    this.inputValue = '';
    this.closeinfoCenter.emit(true);
  
  }

  openDialog() {
  }

  shareContent() {

    const queryStr = '/requestResearch';

    const payload = this.createPayload();

    this.isLoaded = false;
    this.cService.serviceRequestCommon('post', this.notificationURL, queryStr, payload).subscribe((res: any) => {

      console.log('shareContent success: ', res, payload);
      this.toastService.createMessage('success', 'Email Successfully sent.');
      this.inputValue = '';
      this.closeinfoCenter.emit(true);
    
    }, (err) => {

      console.log('shareContent err : ', err, payload);
      this.inputValue = '';
      this.toastService.createMessage('error', 'Error in Sending Email. Please try again.');
      this.closeinfoCenter.emit(false);
    
    }).add(() => {

      this.isLoaded = true;
    
    });
  
  }

}
