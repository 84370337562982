<div
   class="custom-drawer-layout"
   id="target"
   [attr.data-component]="'ncx/customization/custom-columns.component.html'">
   <app-loader-component
      [isLoaded]="isLoaded"
      loaderType="SECTION_LOADER"></app-loader-component>

   <section class="header">
      <i
         nz-icon
         class="icon"
         nzType="filter"
         nzTheme="outline"></i>
      <span class="title">Add Personalized Column</span>
      <button
         (click)="closeDrawer()"
         class="close">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </section>

   <nz-divider style="margin: 0"></nz-divider>

   <div class="scrollable-content">
      <!-------------------- COLUMN NAME -------------->

      <section class="content column-name">
         <p>Pick a Type & Name Your Column:</p>
         <input
            #searchText
            type="text"
            nz-input
            placeholder="Column Name..."
            [(ngModel)]="newColumnName"
            maxlength="20" />
         <p class="text-length">{{ newColumnName?.length }}/20</p>
      </section>

      <!-------------------- TYPE OF COLUMN -------------->

      <section class="content pick-type">
         <button
            *ngFor="let category of categories; let i = index"
            id="{{ category.categoryName }}"
            [ngClass]="{ selected: this.categoryName === category.categoryName }"
            (click)="selectbox(category.categoryName)">
            <i
               nz-icon
               [nzType]="category.iconType"
               nzTheme="outline"></i>
            {{ category.categoryName }}
         </button>
      </section>

      <!-------------------- POST TYPE -------------->

      <section
         class="content"
         *ngIf="categoryName === 'Activity'">
         <nz-divider style="margin: 0 0 24px 0"></nz-divider>

         <p>Post Type:</p>

         <div class="button-group">
            <button
               nz-button
               nzType="default"
               [disabled]="!isLoaded"
               [nzType]="this.PostType === 'All' ? 'primary' : 'default'"
               (click)="selectPostType(true, 'AllPostType')">
               All
            </button>
            <button
               nz-button
               [disabled]="!isLoaded"
               [nzType]="this.PostType === 'Posts' ? 'primary' : 'default'"
               (click)="selectPostType(false, 'PostsPostType')">
               Posts
            </button>
            <button
               nz-button
               [disabled]="!isLoaded"
               [nzType]="this.PostType === 'Elements' ? 'primary' : 'default'"
               (click)="selectPostType(true, 'ElementsPostType')">
               Elements
            </button>
            <button
               nz-button
               [disabled]="!isLoaded"
               [nzType]="this.PostType === 'Summaries' ? 'primary' : 'default'"
               (click)="selectPostType(false, 'SummariesPostType')">
               Summaries
            </button>
         </div>
      </section>

      <!-------------------- ADD GROUP OR PERSON -------------->

      <section
         class="content"
         *ngIf="categoryName === 'Group' || categoryName === 'Person'">
         <nz-divider style="margin: 0 0 24px 0"></nz-divider>

         <p>{{ searchTextLabelHeader }}</p>

         <div>
            <!-- SEARCH PEOPLE OR GROUPS -->

            <nz-input-group
               nzSearch
               [nzAddOnAfter]="suffixIconSearch">
               <input
                  type="text"
                  nz-input
                  id="groupPersonValue"
                  placeholder="{{ searchTextLabel }}"
                  [(ngModel)]="searchInput"
                  (input)="globalSearch($event, categoryName)"
                  [nzAutocomplete]="autoPerson"
                  (ngModelChange)="onChange($event, 'Person')" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
               <button
                  nz-button
                  nzType="default">
                  <i
                     nz-icon
                     nzType="search"></i>
               </button>
            </ng-template>
            <nz-autocomplete #autoPerson>
               <nz-auto-option
                  *ngFor="let item of editLabels"
                  [nzLabel]="categoryName === 'Person' ? item.fullName : item.groupName"
                  [nzValue]="item">
                  <ng-container *ngIf="categoryName === 'Person'">
                     {{ item.fullName }}
                  </ng-container>
                  <ng-container *ngIf="categoryName === 'Group'">
                     {{ item.groupName }}
                  </ng-container>
               </nz-auto-option>
            </nz-autocomplete>
         </div>

         <div class="tabs">
            <nz-tabset
               nzType="card"
               [nzSelectedIndex]="selectedGroupPersonIndex">
               <nz-tab
                  *ngFor="let tab of tabs; let i = index"
                  [nzTitle]="tab.itemName">
                  <div class="checkbox-list">
                     <!-- LIST OF SELECTED PEOPLE -->
                     <div *ngIf="categoryName === 'Person'">
                        <div *ngFor="let item of tab.users">
                           <label
                              #labelCheckbox
                              nz-checkbox
                              nzValue="item.userId"
                              [ngModel]="item.checked"
                              (ngModelChange)="SelectCheckbox(tab.itemName, item, item.checked)">
                              {{ item.fullName }}
                           </label>
                        </div>
                     </div>

                     <!-- LIST OF SELECTED GROUPS -->
                     <div *ngIf="categoryName === 'Group'">
                        <div *ngIf="i === 0">
                           <div *ngFor="let item of tab.users">
                              <label
                                 #labelCheckbox
                                 nz-checkbox
                                 nzValue="item.groupId"
                                 [ngModel]="item.checked"
                                 (ngModelChange)="SelectCheckbox(tab.itemName, item, item.checked)">
                                 {{ item.groupName }}
                              </label>
                           </div>
                        </div>
                        <div *ngIf="i > 0">
                           <div *ngFor="let item of tab.groups">
                              <label
                                 #labelCheckbox
                                 nz-checkbox
                                 nzValue="item.groupId"
                                 [ngModel]="item.checked"
                                 (ngModelChange)="SelectCheckbox(tab.itemName, item, item.checked)">
                                 {{ item.groupName }}
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div>
                     <button
                        nzBlock
                        nz-button
                        nzType="default"
                        [disabled]="disableClearAll"
                        (click)="SelectAllOptions(tab.itemName)">
                        Select All
                     </button>
                  </div>
                  <div class="list-content-footer">
                     <label
                        class="footer-label"
                        *ngIf="categoryName === 'Group'">
                        Group Count: {{ tab.count }}
                     </label>
                     <label
                        class="footer-label"
                        *ngIf="categoryName === 'Person' && tab.itemName === 'List'">
                        User Count: {{ tab.count }}
                     </label>
                     <label
                        class="footer-label"
                        *ngIf="categoryName === 'Person' && tab.itemName !== 'List'">
                        User Following Count: {{ tab.count }}
                     </label>
                     <button
                        [ngClass]="tab.itemName === 'List' ? 'clear-button' : 'Add-to-List-button'"
                        nz-button
                        nzType="default"
                        [disabled]="disableClear"
                        (click)="
                           ClearSelectAllOptions(
                              tab.itemName === 'List' ? 'clear-button' : 'Add-to-List-button',
                              tab.itemName
                           )
                        ">
                        {{ tab.itemName === 'List' ? 'Clear' : 'Add to List' }}
                     </button>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>
      </section>

      <!-------------------- ADD STORY -------------->
      <section
         class="content"
         *ngIf="categoryName === 'Activity'">
         <div>
            <p>Add a Story:</p>
            <div>
               <nz-input-group
                  nzSearch
                  [nzAddOnAfter]="suffixIconSearch">
                  <input
                     type="text"
                     nz-input
                     placeholder="Search for a Story..."
                     [(ngModel)]="searchInputStory"
                     (input)="globalSearch($event, 'Story')"
                     [nzAutocomplete]="autoStory"
                     (ngModelChange)="onChange($event, 'Story')"
                     id="storyValue" />
               </nz-input-group>
               <ng-template #suffixIconSearch>
                  <button
                     nz-button
                     nzType="default">
                     <i
                        nz-icon
                        nzType="search"></i>
                  </button>
               </ng-template>
               <nz-autocomplete #autoStory>
                  <nz-auto-option
                     *ngFor="let item of storiesList"
                     [nzLabel]="item.storyTitle"
                     [nzValue]="item">
                     {{ item.storyTitle }}
                  </nz-auto-option>
               </nz-autocomplete>
            </div>
         </div>

         <div class="tabs">
            <nz-tabset
               nzType="card"
               nzCentered="true"
               [nzSelectedIndex]="0">
               <nz-tab
                  *ngFor="let tab of Stories; let i = index"
                  [nzTitle]="tab.itemName"
                  [nzDisabled]="!(tab.stories.length > 0)">
                  <div class="checkbox-list">
                     <div *ngFor="let item of tab.stories">
                        <label
                           #labelCheckbox
                           nz-checkbox
                           nzValue="item.storyId"
                           [ngModel]="item.checked"
                           (ngModelChange)="SelectStoriesCheckbox(tab.itemName, item, item.checked)">
                           {{ item.storyTitle }}
                        </label>
                     </div>
                  </div>
                  <div>
                     <button
                        nzBlock
                        nz-button
                        [disabled]="disableClearAll"
                        (click)="SelectAllStoriesOptions(tab.itemName)">
                        Select All
                     </button>
                  </div>
                  <div class="list-content-footer">
                     <label class="footer-label">Story Count: {{ tab.count }}</label>
                     <button
                        [ngClass]="tab.itemName === 'List' ? 'clear-button' : 'Add-to-List-button'"
                        nz-button
                        nzType="default"
                        [disabled]="disableClear"
                        (click)="
                           ClearSelectAllStoriesOptions(
                              tab.itemName === 'List' ? 'clear-button' : 'Add-to-List-button',
                              tab.itemName
                           )
                        ">
                        {{ tab.itemName === 'List' ? 'Clear' : 'Add to List' }}
                     </button>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>

         <!-------------------- ADD TOPIC -------------->
         <div>
            <nz-divider></nz-divider>
            <p>Add a Topic:</p>
            <div>
               <nz-input-group
                  nzSearch
                  [nzAddOnAfter]="suffixIconSearch">
                  <input
                     type="text"
                     nz-input
                     id="topicValue"
                     placeholder="Search for a Topic..."
                     [(ngModel)]="searchInputTopic"
                     (input)="globalSearch($event, 'Topic')"
                     [nzAutocomplete]="autoTopic"
                     (ngModelChange)="onChange($event, 'Topic')" />
               </nz-input-group>
               <ng-template #suffixIconSearch>
                  <button
                     nz-button
                     nzType="default">
                     <i
                        nz-icon
                        nzType="search"></i>
                  </button>
               </ng-template>
               <nz-autocomplete #autoTopic>
                  <nz-auto-option
                     *ngFor="let item of TopicsList"
                     [nzLabel]="item.topicName"
                     [nzValue]="item">
                     {{ item.topicName }}
                  </nz-auto-option>
               </nz-autocomplete>
            </div>
         </div>

         <div class="tabs">
            <nz-tabset
               nzType="card"
               [nzSelectedIndex]="0">
               <nz-tab
                  *ngFor="let tab of Topics; let i = index"
                  [nzTitle]="tab.itemName"
                  [nzDisabled]="!(tab.users.length > 0)">
                  <div class="checkbox-list">
                     <div *ngFor="let item of tab.users">
                        <label
                           #labelCheckbox
                           nz-checkbox
                           nzValue="item.userId"
                           [ngModel]="item.checked"
                           (ngModelChange)="SelectTopicsCheckbox(tab.itemName, item, item.checked)">
                           {{ item.topicName }}
                        </label>
                     </div>
                  </div>
                  <div>
                     <button
                        nz-button
                        nzBlock
                        nzType="default"
                        [disabled]="disableClearAllTopic"
                        (click)="SelectAllTopicsOptions(tab.itemName)">
                        Select All
                     </button>
                  </div>
                  <div class="list-content-footer">
                     <label class="footer-label">Topic Count: {{ tab.count }}</label>
                     <button
                        [ngClass]="tab.itemName === 'Added Topics' ? 'clear-button' : 'Add-to-List-button'"
                        nz-button
                        nzType="default"
                        [disabled]="disableClearTopic"
                        (click)="
                           ClearSelectAllTopicsOptions(
                              tab.itemName === 'Added Topics' ? 'clear-button' : 'Add-to-List-button',
                              tab.itemName
                           )
                        ">
                        {{ tab.itemName === 'Added Topics' ? 'Clear' : 'Add to List' }}
                     </button>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>

         <!-------------------- ADD LABELS -------------->
         <div>
            <nz-divider></nz-divider>
            <p>Add Labels:</p>
         </div>
         <div class="tabs">
            <nz-tabset
               nzType="card"
               [nzSelectedIndex]="1">
               <nz-tab
                  *ngFor="let tab of Labels; let i = index"
                  [nzTitle]="tab.itemName"
                  [nzDisabled]="!(tab.labels.length > 0)">
                  <div class="checkbox-list">
                     <div *ngFor="let item of tab.labels">
                        <label
                           #labelCheckbox
                           nz-checkbox
                           nzValue="item.value"
                           [ngModel]="item.checked"
                           (ngModelChange)="SelectLabelsCheckbox(tab.itemName, item, item.checked)">
                           {{ item.label }}
                        </label>
                     </div>
                  </div>
                  <div>
                     <button
                        nzBlock
                        nz-button
                        nzType="default"
                        (click)="SelectAllLabelsOptions(tab.itemName)">
                        Select All
                     </button>
                  </div>
                  <div class="list-content-footer">
                     <label class="footer-label">Label Count: {{ tab.count }}</label>
                     <button
                        [ngClass]="tab.itemName === 'Added Labels' ? 'clear-button' : 'Add-to-List-button'"
                        nz-button
                        nzType="default"
                        (click)="
                           ClearSelectAllLabelsOptions(
                              tab.itemName === 'Added Labels' ? 'clear-button' : 'Add-to-List-button',
                              tab.itemName
                           )
                        ">
                        {{ tab.itemName === 'Added Labels' ? 'Clear' : 'Add to List' }}
                     </button>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>

         <!-------------------- ADD TAGS -------------->

         <div>
            <nz-divider></nz-divider>
            <p>Add Tags:</p>
            <div>
               <nz-input-group
                  nzSearch
                  [nzAddOnAfter]="suffixIconAddTags">
                  <input
                     type="text"
                     nz-input
                     #searchInputField
                     [(ngModel)]="Tag"
                     placeholder="Add your Tags here..."
                     (keypress)="AddTags($event)" />
                  <ng-template #suffixIconAddTags>
                     <button
                        nz-button
                        nzType="default"
                        (click)="AddTagsonClick()">
                        <i
                           nz-icon
                           nzType="plus"></i>
                     </button>
                  </ng-template>
               </nz-input-group>
            </div>
            <div class="tags-list">
               <nz-tag
                  *ngFor="let tag of Tags"
                  nzMode="closeable"
                  (nzOnClose)="removeTag(tag)">
                  {{ tag }}
               </nz-tag>
            </div>
         </div>
      </section>

      <!-------------------- NOTIFICATION/CLEAR BUTTONS -------------->

      <section
         class="content bottom-btns"
         *ngIf="categoryName">
         <button
            [ngClass]="{ 'enable-notification': notification === 'Notifications On' }"
            nz-button
            nzBlock="true"
            nzType="default"
            (click)="enableNotification($event)">
            <i
               nz-icon
               nzType="mail"
               nzTheme="outline"></i>
            {{ notification }}
         </button>

         <nz-divider></nz-divider>

         <button
            nz-button
            nzBlock="true"
            nzDanger="true"
            nzType="primary"
            [disabled]="isDisable"
            (click)="clearFilters()">
            <i
               nz-icon
               nzType="clear"
               nzTheme="outline"></i>
            Clear Filters
         </button>
      </section>
   </div>

   <section class="footer-section">
      <button
         nz-button
         nzType="default"
         [disabled]="isDisable"
         (click)="closeDrawer()">
         Cancel
      </button>

      <button
         nz-button
         nzType="primary"
         [disabled]="isDisable || !isLoaded"
         (click)="AddColumn()">
         Add Column
      </button>
   </section>
</div>
