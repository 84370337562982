import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],
})
export class CollapsableComponent implements AfterViewInit {

  // Id of collapsable component
  @Input() id = '';

  // Title of header
  @Input() title = '';

  // Optional Alt text
  @Input() altText = '';

  // Optional icon
  @Input() icon = '';

  // Optional icon theme
  @Input() iconTheme: 'outline' | 'fill' = 'outline';

  // Whether the collapsable content is open
  @Input() active = false;

  // Whether to show the toggle up/down arrow
  @Input('show-arrow') showArrow = true;

  // Whether the title of the header is bold
  @Input('bold-title') boldTitle = true;

  // Does the header title truncate or wrap (set to 'false' to wrap text)
  @Input('truncate') truncate = false;

  // Whether the component is collapsable at all
  @Input() collapsable = true;

  // Whether is collapsable header is disabled
  @Input() disabled = false;

  // Additional classes on parent component
  // Options:  'not-bold'
  @Input() classes: string[] = [];

  // border around collapse panel
  @Input() border: boolean = true;

  // Callback when collapsable content is toggled
  @Output() onClick: EventEmitter<{ isOpen: boolean, id: string }> = new EventEmitter<{
    isOpen: boolean,
    id: string
  }>();

  ngAfterViewInit() {

    if (!this.id) {

      this.id = (Math.random() + 1).toString(36).substring(7);

    }

  }

  onClickHeader(isOpen: boolean) {

    this.active = isOpen;

    this.onClick.emit({ isOpen, id: this.id });

  }

  get allClasses() {

    const classes: { [key: string]: boolean } = {
      'custom-collapsable': true,
    };

    if (this.classes) {

      this.classes.forEach(cls => {

        classes[cls] = true;

      });

    }

    return classes;

  }

}
