import { Injectable } from '@angular/core';
import { UrlRedirectService } from '@services/url-redirect.service';

@Injectable({
  providedIn: 'root'
})

export class Common {


  private static urlRedirectService = new UrlRedirectService();

  /**
   * Split array into columns
   *
   */
  public static toColumns(array: any[], cols = 1): any[] {

    if (cols === 1) {

      return [array];

    }

    const size = Math.ceil(array.length / cols);

    return [array.slice(0, size)].concat(this.toColumns(array.slice(size), cols - 1));

  }

  public static formatSpanValues(spanVal: any, width: number): Array<number> {

    let nzSpan = [];

    if (Array.isArray(spanVal)) {

      nzSpan = spanVal;

    } else if (typeof spanVal === 'object') {

      const spanKey = Object.keys(spanVal);

      if (width >= 1600 && spanKey.includes('xxl')) {

        nzSpan = this.getLabelInputValue(spanVal['xxl']);

      } else if (width >= 1200 && spanKey.includes('xl')) {

        nzSpan = this.getLabelInputValue(spanVal['xl']);

      } else if (width >= 992 && spanKey.includes('lg')) {

        nzSpan = this.getLabelInputValue(spanVal['lg']);

      } else if (width >= 768 && spanKey.includes('md')) {

        nzSpan = this.getLabelInputValue(spanVal['md']);

      } else if (width >= 576 && spanKey.includes('sm')) {

        nzSpan = this.getLabelInputValue(spanVal['sm']);

      } else if (spanKey.includes('xs')) {

        nzSpan = this.getLabelInputValue(spanVal['xs']);

      }

    } else {

      nzSpan = [spanVal, spanVal];

    }
    return nzSpan;

  }

  public static getLabelInputValue(val: any): Array<number> {

    if (Array.isArray(val)) {

      return val;

    }

    return [val, val];

  }

  /**
   * Strip all HTML tags from a string
   *
   */
  public static stripHTML(str: string): string {

    try {

      return String(str).replace(/(<([^>]+)>)/ig, '');

    } catch (error) {

      console.error('Error in Common.stripHTML event : ', error);

      return str;

    }

  }

  /**
   * Format a user's name to look like
   * First Last or F. Last
   *
   */
  public static formatName(user: { displayName: string, firstName: string, lastName: string }, abbreviated: boolean = false): string {

    try {

      const { displayName, firstName, lastName } = user || {};

      if (displayName) {

        const [last, first] = displayName.split(',').map((name: string) => name.trim());

        if (last && first) {

          return abbreviated ? `${first.substring(0, 1)}. ${last}` : `${first} ${last}`;

        } else {

          return displayName.trim();

        }

      }

      if (firstName && lastName) {

        return abbreviated ? `${firstName.substring(0, 1)}. ${lastName}` : `${firstName} ${lastName}`;

      }

      return '';

    } catch (error) {

      console.error('Error in Common.formatName event : ', error);

      return user?.displayName || `${user?.firstName} ${user?.lastName}` || '';

    }

  }

  /**
   * Create link to S3 resource using jwttoken
   *
   */
  public static formS3Link(url: string): string {

    try {

      const token = localStorage.getItem('ncxjwttoken');

      if (url && !url.includes('?ncxjwttoken=')) {

        return this.urlRedirectService.getRedirectAPIURL(`${url}?ncxjwttoken=${token}`);

      } else {

        return url;

      }

    } catch (error) {

      console.error('Error in Common.formS3Link event : ', error);

      return url;

    }

  }

  /**
   * Function to validate whether the input has valid text or not
   *
   */
  public static isInputHasValidTextContent(html: string): boolean {

    try {

      console.log('Text input is ' + html);

      if (!html) {

        return false;

      }

      // Create a new DOMParser
      const parser = new DOMParser();


      // Parse the HTML string into a document
      const doc = parser.parseFromString(html, 'text/html');

      // Check if any text content exists in the document
      const textExists = this.checkTextContent(doc);

      // Log the result
      if (textExists) {

        console.log('Text content exists in the document.');

      } else {

        console.log('No text content found in the document.');

      }
      return textExists;

    } catch (error) {

      console.error('Error in Common.isEmptyHTML event : ', error);
      return false;

    }

  }

  /**
   * Function to recursively traverse the DOM tree and check for text content
   * Below code is to log character codes of each character in the text content
   * let val =node.textContent?.trim();
   * for (let i = 0; i < val.length; i++) {
   *    console.log(`Character "${val[i]}" (code ${val.charCodeAt(i)})`);
   *  }
   *
   * &nbsp; is a non visible white space character. It should not be considered as valid text.
   * when the input has &nbsp; then the node textContent returns the value as ' ' but this not an empty string.
   * Applying trim() is not matching the value as empty string and considering it as a text value.
   *
   * This has to be removed from the input node textContent to check empty value.  *
   * Unicode character of &nbsp; is \u200B so it is specified in condition to remove that code
   * Don't remove this code by assuming this as an invalid check
   */
  static checkTextContent(node: Node): boolean {

    if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim().replace(/\u200B/g, '')) {


      // If the node is a text node and it has non-empty text content
      console.log('Text in the input is ' + node.textContent?.trim());
      return true;

    }

    // Recursively check child nodes
    for (const childNode of Array.from(node.childNodes)) {

      if (this.checkTextContent(childNode)) {


        // If any child node contains text content, return true
        return true;

      }

    }
    return false;

  }

  /**
   * Open default e-mail client with optional subject and body
   *
   */
  public static sendEmail({ to = [], cc = [], subject = '', body = '' }: { to: string[], cc?: string[], subject?: string, body?: string }) {

    try {

      const mail = [`mailto:${to.join(';')}`];

      if (subject) {

        mail.push(`Subject=${encodeURIComponent(subject)}`);

      }

      if (body) {

        mail.push(`Body=${encodeURIComponent(body)}`);

      }

      if (cc) {

        mail.push(`cc=${cc.join(';')}`);

      }

      const url = `${mail[0]}?${mail.splice(1).join('&')}`;

      window.location.href = url;

    } catch (error) {

      console.error('Error in Common.sendEmail event : ', error);

    }

  }

  /**
   * Check if the mime type is type of video
   *
   */
  public static isVideoMimeType(mime: string): boolean {

    switch (mime) {

    case 'video/quicktime':
    case 'video/mp4':
    case 'video/mpeg':
      return true;

    default:
      return false;

    }

  }

  /**
   * Browser identification
   *
   */
  public static getBrowser(): { name: string, isMobile: boolean } {

    const data = {
      name: 'other',
      isMobile: false
    };

    try {

      let agent = '';

      // Legacy
      //if ('userAgent' in window.navigator) {

      // Legacy User Agent string
      agent = window.navigator.userAgent;

      const isIphone = !!navigator.appVersion.match(/iPhone/);

      const isIPad = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0;

      data.isMobile = isIPad || isIphone;

      //}

      // Future State
      // if ('userAgentData' in window.navigator) {
      //
      //   const { brands, mobile, platform } = window.navigator['userAgentData'];
      //
      //   // The User Agent is a combination of all brands
      //   agent = brands.map(o => o.brand).join(' ');
      //
      //   data.isMobile = mobile;
      //
      //
      // }

      // Parse the user agent, whether it comes from userAgentData or the legacy userAgent
      switch (true) {

      case !!agent.match(/edge/i):
        data.name = 'edge';
        break;
      case !!agent.match(/chrome/i) && !!(window as any).chrome:
        data.name = 'chrome';
        break;
      case !!agent.match(/firefox/i):
        data.name = 'firefox';
        break;
      case !!agent.match(/safari/i):
        data.name = 'safari';
        break;
      default:
        data.name = 'other';

      }

      return data;

    } catch (error) {

      console.error('Common.getBrowser', error);

      return data;

    }

  }

  /**
   * Convert a number into a formatted string with commas
   *
   */
  public static formatNumber(num: number) {

    return new Intl.NumberFormat().format(num);

  }

  /*
   *  Function to calculate the popover placement based on cursor position
   *  first character of top and bottom should be lowercase
   *  first character of left and rights should be uppercase
   */
  public static placementPosition(event: MouseEvent, defaultPositionTopOrBottom: string, defaultPositionLeftOrRight: string): string {

    let placement: string = '';

    const cursorX = event.pageX; // Get the horizontal position of the cursor

    const cursorY = event.pageY; // Get the vertical position of the cursor

    // Determine placement based on cursor position
    if (defaultPositionTopOrBottom != '') {

      placement = defaultPositionTopOrBottom;

    } else {

      if (cursorY <= window.innerHeight / 2) {

        placement = 'bottom';

      } else {

        placement = 'top';

      }

    }

    if (defaultPositionLeftOrRight != '') {

      placement += defaultPositionLeftOrRight;

    } else {

      if (cursorX <= window.innerWidth / 2) {

        placement += 'Left';

      } else {

        placement += 'Right';

      }

    }

    return placement;

  }

}
