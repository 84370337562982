import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-guest-tracker',
  template: `<i nz-icon
                [ngClass]='classes'
                [ngStyle]='style'>
    <svg viewBox='0 0 32 32'
         xmlns='http://www.w3.org/2000/svg'>
      <path opacity='1.0'
            d='M32 18.8522L17.1458 15.5826C16.9525 15.0261 16.4418 14.6226 15.8343 14.6226C15.0613 14.6226 14.4262 15.2626 14.4262 16.0417C14.4262 16.8209 15.0613 17.4609 15.8343 17.4609C16.2347 17.4609 16.5936 17.28 16.8559 17.0017L20.0449 17.6974V20.8696C18.8714 21.8991 17.3805 22.4696 15.8343 22.4696C12.3003 22.4696 9.42882 19.5757 9.42882 16C9.42882 12.4243 12.3003 9.54435 15.8343 9.54435H16.3589V7.52696H15.8343C11.1959 7.52696 7.42709 11.3252 7.42709 16C7.42709 20.6748 11.1959 24.473 15.8343 24.473C18.126 24.473 20.2657 23.5548 21.8671 21.8991L22.019 21.7461V18.1287L23.5375 18.4626V22.6643C21.6048 24.9322 18.8024 26.24 15.8481 26.24C10.2571 26.24 5.70147 21.6487 5.70147 16.0139C5.70147 10.3791 10.2571 5.78783 15.8481 5.78783H16.3727V3.77043H15.8481C9.15272 3.77043 3.69974 9.26609 3.69974 16.0139C3.69974 22.7617 9.15272 28.2574 15.8481 28.2574C19.5893 28.2574 23.082 26.5461 25.4012 23.5687L25.5116 23.4296V18.9217L27.044 19.2557V24.4313C24.4211 27.9235 20.2657 30.0104 15.8758 30.0104C8.22778 30.0104 2.00173 23.7357 2.00173 16.0278C2.00173 8.32 8.22778 2.01739 15.8758 2.01739H16.3865V0H15.8758C7.12338 0 0 7.17913 0 16C0 24.8209 7.12338 32 15.8758 32C21.0526 32 25.9258 29.4817 28.9215 25.2383L29.0181 25.0991V19.6591L31.6963 20.2435L32 18.8383V18.8522Z' />
    </svg>
  </i>`,
})
export class GuestTrackerIcon {

  @Input() classes: { [key: string]: boolean; } = {};

  @Input() styles: { [key: string]: number | string } = {};

  get style() {

    return { ...this.styles };

  }

}
