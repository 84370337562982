<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<app-standard-header
   title="Back"
   [defaultBackAction]="true"></app-standard-header>

<div
   class="alertsHeading"
   [attr.data-component]="'ncx/alerts/alerts.component.html'">
   <nz-button-group>
      <button
         class="btn"
         nz-button
         nz-dropdown
         [nzDropdownMenu]="menu1">
         <i
            nz-icon
            nzType="down"></i>
      </button>
      <nz-dropdown-menu #menu1="nzDropdownMenu">
         <ul nz-menu>
            <li
               *ngFor="let index of alertsType"
               (click)="changeAlertType(index)"
               nz-menu-item>
               {{index.label}}
            </li>
         </ul>
      </nz-dropdown-menu>
   </nz-button-group>
   <span>{{groupName}}</span>
   <span class="alerts">
      <nz-radio-group
         [(ngModel)]="radioValue"
         [nzButtonStyle]="'solid'"
         (ngModelChange)="fetchAlerts($event)">
         <label
            nz-radio-button
            nzValue="active">
            Active
         </label>
         <label
            nz-radio-button
            nzValue="archived">
            Archived
         </label>
      </nz-radio-group>
   </span>
</div>
<div class="alerts-section">
   <div
      class="alertsCard alerts-template"
      infiniteScroll
      [infiniteScrollDistance]="2"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
      #panel
      [ngStyle]="checkScreen()">
      <div
         *ngFor="let content of alertList"
         class="alert">
         <app-render-alerts
            [alert]="content"
            (updateAlertId)="updateAlertList($event)"
            (updateAlertCount)="updateAlertCount($event)"
            [buttons]="showButtons"></app-render-alerts>
      </div>

      <div
         class="notFoundCard"
         *ngIf="isAlertsEmpty">
         <nz-empty [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
               <span>No Results Found</span>
            </ng-template>
         </nz-empty>
      </div>
   </div>
</div>
