import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Discussion } from '@models/ncx/groups-discussions';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-create-discussion',
  templateUrl: './create-discussion.component.html',
  styleUrls: ['./create-discussion.component.scss']
})
export class CreateDiscussionComponent implements OnInit {

  viewPreview = false;

  isVisibleGoogleDocs = false;

  isVisibleEmbedly = false;

  isVisibleShare = false;

  hideElements = false;

  discussionText;

  filteredOptions = [];

  discussionDetails = {} as Discussion;

  livediscussionDetails: any;

  livediscussionDetailsTime: string = '';

  draftdiscussionDetailsTime: string = '';

  discussionContent: any;

  discussionId: any;

  isLoaded: boolean;

  isPinnedToTop = false;

  isSavedAsDraft: boolean;

  isNewDiscussion: boolean;

  isAttachmentDisabled: boolean;

  discussionApiURL: string;

  rteData: any;

  groupId: any;

  attachments: any = [];

  //discussionAttachments: [];
  subject: Subject<string> = new Subject();

  isAttachmentDeleted: boolean;

  groupName: any;

  groupApiURL: any;

  isAutoSaveDraft = false;

  editableText = false;

  isEmailUpdate = false;

  googleDocs: string;

  embedlyLink: string;

  tooltipMsg;

  isEmitted: boolean;

  showPreviewFlag: boolean;

  isPublishDisable = false;

  constructor(
    private router: ActivatedRoute,
    private authService: AuthService,
    private cService: CommonService,
    private modalService: NzModalService,
    private toastService: ToastService,
    private reRoute: Router
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.autoSaveAsDraft(searchTextValue, this.rteData);

    });

  }

  ngOnInit() {

    this.isAttachmentDeleted = false;
    this.discussionApiURL = environment.getDiscussionURL;
    this.groupApiURL = environment.getGroupsURL;
    this.hideElements = true;
    this.isAttachmentDisabled = true;
    this.discussionDetails = {} as Discussion;
    this.tooltipMsg = 'Please select the title to save as auto draft';
    this.router.queryParams.subscribe(
      (params: Params) => {

        this.setDiscussionId(params);

      });

  }

  setDiscussionId(params) {

    if (params && params.discussionId && params.groupId) {

      this.isAutoSaveDraft = true;
      this.isSavedAsDraft = true;
      this.isLoaded = false;
      this.isAttachmentDisabled = false;
      this.isNewDiscussion = false;

      // this.editableText = true;
      this.isEmitted = false;
      this.discussionId = params.discussionId;
      this.groupId = params.groupId;
      this.getGroupDetails(this.groupId);
      this.getDiscussionDetails(this.discussionId);

    } else if (params.groupId === null && params.discussionId === undefined) {

      this.isAttachmentDisabled = true;
      this.isSavedAsDraft = false;
      this.editableText = false;
      this.toastService.createMessage('warning', 'Discussion is not associated with any group Id. Please try again.');

    } else {

      this.isAttachmentDisabled = true;
      this.isSavedAsDraft = false;
      this.isLoaded = true;
      this.editableText = false;
      this.isEmitted = true;
      this.groupId = params.groupId;
      this.getGroupDetails(this.groupId);
      this.createDiscussionObject(null);

    }

  }

  createDiscussionObject(discussionObj) {

    console.log('createDiscussionObject:', discussionObj);
    this.discussionDetails.discussionId = (discussionObj && discussionObj.discussionId) ? discussionObj.discussionId : null;
    this.discussionId = (discussionObj && discussionObj.discussionId) ? discussionObj.discussionId : null;
    this.discussionDetails.liveDiscussionId = (discussionObj && discussionObj.liveDiscussionId) ? discussionObj.liveDiscussionId : null;
    this.discussionDetails.groupId = (discussionObj && discussionObj.groupId) ? discussionObj.groupId : null;
    this.discussionDetails.discussionSubject = (discussionObj && discussionObj.discussionSubject) ? discussionObj.discussionSubject : null;
    this.discussionText = (discussionObj && discussionObj.discussionSubject) ? discussionObj.discussionSubject : null;
    this.discussionDetails.discussionContent = (discussionObj && discussionObj.discussionContent) ? this.authService.addJWTTokenToLink(
      discussionObj.discussionContent, 'img') : null;

    //JWT Token has to be added to the img S3 link to download the file from S3
    this.discussionContent = (discussionObj && discussionObj.discussionContent) ? discussionObj.discussionContent : '';

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionAttachments = (discussionObj && discussionObj.discussionAttachments)
      ? discussionObj.discussionAttachments
      : [];

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionGroupId = (discussionObj && discussionObj.discussionVersionGroupId)
      ? discussionObj.discussionVersionGroupId
      : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionState = (discussionObj && discussionObj.discussionVersionState)
      ? discussionObj.discussionVersionState
      : 'DRAFT';

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.isDiscussionPublished = (discussionObj && discussionObj.isDiscussionPublished)
      ? discussionObj.isDiscussionPublished
      : 'PUBLIC';
    this.discussionDetails.isPinnedOnTop = (discussionObj && discussionObj.isPinnedOnTop) ? discussionObj.isPinnedOnTop : false;
    this.isPinnedToTop = (this.discussionDetails && this.discussionDetails.isPinnedOnTop) ? true : false;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionAttachments = (discussionObj && discussionObj.discussionAttachments)
      ? discussionObj.discussionAttachments
      : [];
    this.attachments = this.discussionDetails.discussionAttachments;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionGroupId = (discussionObj && discussionObj.discussionVersionGroupId)
      ? discussionObj.discussionVersionGroupId
      : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionNumber = (discussionObj && discussionObj.discussionVersionNumber >= 0)
      ? discussionObj.discussionVersionNumber
      : null;
    this.draftdiscussionDetailsTime = (discussionObj && discussionObj.updateDateTime) ? discussionObj.updateDateTime : '';
    if (discussionObj && discussionObj.discussionVersionNumber === 1 && discussionObj.discussionVersionState !== 'Draft') {

      this.editableText = true;

    } else if (discussionObj && discussionObj.discussionVersionNumber === 1 && discussionObj.discussionVersionState === 'Draft') {

      this.editableText = false;

    } else if (discussionObj && discussionObj.discussionVersionNumber >= 1) {

      this.editableText = true;

    } else {

      this.editableText = false;

    }
    this.isEmailUpdate = (this.editableText) ? true : false;

  }

  titleMatch(event) {

    console.log('titleMatch : ', event, this.discussionText);
    this.subject.next(this.discussionText);
  
  }

  onChange(_value, _event) {

    // this.subject.next(event.target.value);
  }

  toggleEmailCheckbox() {

    this.discussionDetails.doNotSendEmail = this.isEmailUpdate;

  }

  /* For Deleting and adding attachment  */
  createAttachmentArray() {

    if (this.isAttachmentDeleted && this.attachments && this.attachments.length > 0) {

      this.discussionDetails.discussionAttachments = this.attachments;

    } else if (this.isAttachmentDeleted && this.attachments && this.attachments.length === 0) {

      this.discussionDetails.discussionAttachments = [];

    }

  }

  getRTEData(data) {

    this.rteData = data;
    this.discussionDetails.discussionContent = data;
    this.googleDocs = '';
    this.embedlyLink = '';

  }

  getAttachmentsData(event) {

    this.attachments = event.attachments;

    // this.attachments = [...event.attachments];
    if (event.isDeleted === true) {

      this.isAttachmentDeleted = true;

    }

  }

  autoSaveAsDraft(searchText, _rteData) {

    if (searchText && !this.isSavedAsDraft && this.isLoaded && !this.discussionId) {

      this.saveAs('DRAFT');

    }

  }

  saveAs(type) {

    // Enabling Google Doc and Embedly Link in RTE
    if (this.isVisibleGoogleDocs || this.isVisibleEmbedly) {

      return;

    }

    if(type === 'DRAFT' && this.discussionText === null || this.discussionText === undefined || this.discussionText === '') {

      this.showPreviewFlag = false;
      this.toastService.createMessage('warning', 'Give your Post a title to publish');
      return;
    
    }
     

    let operationType = 'put';

    if (type === 'DRAFT' && !this.isSavedAsDraft) {

      // this.isAutoSaveDraft = true;
      this.discussionDetails.discussionId = null;
      this.discussionDetails.discussionVersionState = 'Draft';
      this.discussionDetails.isDiscussionPublished = false;
      this.discussionDetails.discussionAttachments = this.attachments;
      operationType = 'post';

      // tslint:disable-next-line:max-line-length

    } else if (type === 'DRAFT' && this.isSavedAsDraft && this.discussionDetails && this.discussionDetails.discussionVersionState === 'Live') {

      // this.isAutoSaveDraft = true;
      this.discussionDetails.discussionId = this.discussionId;
      this.discussionDetails.discussionVersionState = 'Draft';
      this.discussionDetails.isDiscussionPublished = false;
      this.createAttachmentArray();
      operationType = 'post';

    } else if (type === 'DRAFT' && this.isSavedAsDraft) {

      this.discussionDetails.discussionId = this.discussionId;
      this.discussionDetails.discussionVersionState = 'Draft';
      this.discussionDetails.isDiscussionPublished = false;
      this.createAttachmentArray();
      operationType = 'put';

    } else {

      this.discussionDetails.discussionVersionState = 'Live';
      this.discussionDetails.isDiscussionPublished = true;
      this.createAttachmentArray();
      operationType = 'put';

    }

    // this.discussionDetails.discussionVersionState
    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionGroupId = (this.discussionDetails && this.discussionDetails.discussionVersionGroupId)
      ? this.discussionDetails.discussionVersionGroupId
      : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionNumber = (this.discussionDetails && this.discussionDetails.discussionVersionNumber >= 0)
      ? this.discussionDetails.discussionVersionNumber
      : null;
    this.discussionDetails.groupId = this.groupId;
    this.discussionDetails.isPinnedOnTop = this.isPinnedToTop;
    this.discussionDetails.discussionContent = this.rteData;
    if (operationType === 'put') {

      // const rteDiv = document.createElement('div');
      // rteDiv.innerHTML = this.discussionDetails.discussionContent;
      // const imgSrc = rteDiv.querySelectorAll('img');

      // imgSrc.forEach((imgEle: HTMLImageElement) => {
      //   // console.log('imgEle-->' + imgEle);
      //   imgEle.src = this.removeS3Link(imgEle.src);
      //   // console.log('imgEle SRC-->' + imgEle.src);
      // });

      // console.log("Modified RTE" + rteDiv.innerHTML);
      this.discussionDetails.discussionContent = this.authService.removeJWTTokenFromLink(this.discussionDetails.discussionContent, 'img');

    }
    this.discussionDetails.discussionSubject = this.discussionText;
    this.discussionDetails.doNotSendEmail = (this.isEmailUpdate) ? this.isEmailUpdate : false;
    if (!(this.discussionDetails.liveDiscussionId === undefined || null === this.discussionDetails.liveDiscussionId) && type !== 'DRAFT') {

      this.getliveDiscussionPost(operationType);

    } else {

      this.saveDiscussionDetails(operationType);

    }

    // if (!this.isLoaded) {
    // } else {
    //   console.log('Cannot excecute as previous dicsussion request is in progress ');
    // }

  }

  getliveDiscussionPost(operationType) {

    const queryStr = `/${this.discussionDetails.discussionVersionGroupId}/version`;

    this.cService.serviceRequestCommon('get', this.discussionApiURL, queryStr).subscribe((response: any) => {

      this.livediscussionDetails = response;

      for (let i = 0; i < response.discussions.length; i++) {

        if (response.discussions[i].discussionVersionState === 'Live') {

          this.livediscussionDetailsTime = response.discussions[i].updateDateTime;
          break;

        }

      }

      const livePostTime = moment.utc(this.livediscussionDetailsTime).local().format('YYYY-MM-DD HH:mm:ss');

      const draftPostTime = moment.utc(this.draftdiscussionDetailsTime).local().format('YYYY-MM-DD HH:mm:ss');

      const livePostDate = new Date(livePostTime);

      const draftPostDate = new Date(draftPostTime);

      if (livePostDate > draftPostDate) {

        this.showPopUp(operationType);

      } else {

        this.saveDiscussionDetails(operationType);

      }
      this.isLoaded = true;

    },
    (err) => {

      this.isLoaded = true;
      this.toastService.createMessage('error', err);

    }
    );

  }

  showPopUp(operationType) {

    this.modalService.confirm({

      // tslint:disable-next-line:max-line-length
      nzTitle: 'A newer version of post is already available and this could override the content.<br>Do you want to proceed?',
      nzOkText: 'Ok',

      // nzOkType: 'danger',
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {

        console.log('ok clicked');
        this.saveDiscussionDetails(operationType);

      },
      nzOnCancel: () => {

        console.log('cancel clicked');

      }
    });

  }

  removeS3Link(url) {

    return url.split('?ncxjwttoken')[0];

  }

  reDirectTo() {

    // this.reRoute.navigate(['/group-details/:' + this.groupId]);
    console.log('reDirectTo : ', this.discussionDetails);
    this.viewPreview = false;
    this.showPreviewFlag = false;
    if (this.discussionDetails.discussionVersionNumber === 0 && this.discussionDetails.discussionVersionState === 'Draft') {

      this.deleteDraftDiscussion(this.discussionDetails.discussionVersionGroupId);

    } else {

      // tslint:disable-next-line:max-line-length
      const queryStr = this.discussionDetails.discussionVersionGroupId;

      this.cService.serviceRequestCommon('post', this.discussionApiURL, '/' + queryStr + '/lock/delete').subscribe((res: any) => {

        console.log('Post Response', res, this.discussionId);

      });
      this.reRoute.navigate(['/group-details/:' + this.groupId]);

      // , { queryParams: { groupId: this.groupId, discussionId: this.discussionDetails.discussionId }});

    }

  }

  showPreview() {

    window.scroll(0, 0);
    this.showPreviewFlag = true;
    this.saveAs('DRAFT');

    // this.viewPreview = true;

  }

  shareContent() {

    this.isVisibleShare = true;

  }

  handleCancel() {

    this.isVisibleShare = false;

  }

  returnToEdit() {

    console.log('returnToEdit : ', this.discussionDetails);
    this.viewPreview = false;
    this.showPreviewFlag = false;
    if (this.discussionDetails && this.discussionDetails.discussionId) {

      // tslint:disable-next-line:max-line-length
      this.reRoute.navigate(['/create-discussion'],
        { queryParams: { groupId: this.groupId, discussionId: this.discussionDetails.discussionId } });

    } else {

      this.reRoute.navigate(['/create-discussion'], { queryParams: { groupId: this.groupId } });

    }

  }

  /* Enabling Google Doc and Embedly Links Start*/
  checkGoogleFlag(event) {

    if (event === 'true') {

      this.isVisibleGoogleDocs = true;
      document.getElementById('rteCustomLink').click();
      this.googleDocs = '';

    } else {

      this.isVisibleGoogleDocs = false;
      this.googleDocs = '';

    }

  }

  checkEmbedlyFlag(event) {

    if (event === 'true') {

      this.embedlyLink = '';
      this.isVisibleEmbedly = true;
      document.getElementById('rteCustomLink').click();

    } else {

      this.isVisibleEmbedly = false;
      this.embedlyLink = '';

    }

  }

  getDocLink(value) {

    if (value !== 'close') {

      this.googleDocs = value;
      this.updateRTELinkContent('googleDoc');

    }
    this.isVisibleGoogleDocs = false;

  }

  getEmbedlyData(value) {

    if (value !== 'close') {

      this.embedlyLink = value;
      this.updateRTELinkContent('embedlyLink');

    }
    this.isVisibleEmbedly = false;

  }

  updateRTELinkContent(type) {

    if (this.discussionContent === '<p><br></p>') {

      this.discussionContent = (type === 'googleDoc') ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0"
      src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>` : `<p>${this.embedlyLink}<p>`;

    } else {

      this.discussionContent += (type === 'googleDoc') ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0"
      src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>` : `<p>${this.embedlyLink}<p>`;

    }

  }

  /* Enabling Google Doc and Embedly Links End*/

  /* API CRUD Operations Discussions Start */
  getGroupDetails(groupId) {

    const queryStr = `/${groupId}`;

    this.cService.serviceRequestCommon('get', this.groupApiURL, queryStr).subscribe((res: any) => {

      this.isLoaded = true;
      if (res.isDeleted === true) {

        this.toastService.createMessage('warning', 'Group does not Exist.');
        this.reRoute.navigate(['/group-landing']);

      } else {

        this.isLoaded = true;
        this.groupName = res.groupName;

      }

    },
    (err) => {

      this.isLoaded = true;
      if (err !== 'GROUP-003') {

        this.toastService.createMessage('error', err);

      }

    }
    );

  }

  getDiscussionDetails(discussionId) {

    const queryStr = `/${discussionId}`;

    this.cService.serviceRequestCommon('get', this.discussionApiURL, queryStr).subscribe((res: any) => {

      this.isLoaded = true;
      if (res && res.groupId && res.discussionVersionState === 'Archived') {

        this.toastService.createMessage('warning', 'You cannot edit a Archived Discussion');
        this.reRoute.navigate(['/ncx/group-details/:' + res.groupId]);

      } else if (res.isDeleted === true) {

        this.toastService.createMessage('warning', 'Discussion doesnot Exist.');
        this.reRoute.navigate(['/create-group']); // need to change redirection link upon confirmation

      } else {

        this.isLoaded = true;
        this.createDiscussionObject(res);

      }

    },
    (err) => {

      this.isLoaded = true;
      if (err === 'DISCUSSION-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Discussion');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/group-landing']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }

    }
    );

  }

  saveDiscussionDetails(type) {

    this.isLoaded = false;
    this.isPublishDisable = true;
    const discussionDetailsObj = JSON.parse(JSON.stringify(this.discussionDetails));

    // const type = (this.discussionId && (this.discussionDetails.discussionVersionState !== 'Draft')) ? 'put' : 'post';
    const queryStr = (this.discussionId && type === 'put') ? `/${this.discussionId}` : '';

    this.cService.serviceRequestCommon(type, this.discussionApiURL, queryStr, discussionDetailsObj).subscribe((res: any) => {

      if (this.showPreviewFlag) {

        this.viewPreview = true;

      }
      if (type === 'post' && !this.showPreviewFlag) {

        this.isPublishDisable = false;
        this.toastService.createMessage('success', 'The Discussion has Been Successfully saved as Draft');

      } else if (type === 'put' && res.discussionVersionState === 'Draft' && !this.showPreviewFlag) {

        this.isPublishDisable = false;
        this.toastService.createMessage('success', 'The Discussion Draft has Been Successfully Updated');

      } else if (res.discussionVersionState !== 'Draft') {

        // this.editableText = true;
        this.toastService.createMessage('success', 'The Discussion has Been Successfully Updated');
        this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: this.groupId, discussionId: res.discussionId } });

      }
      this.isLoaded = true;
      this.isAutoSaveDraft = true;
      this.isSavedAsDraft = true;
      setTimeout(() => {

        this.isAttachmentDisabled = false;

      }, 100);
      this.createDiscussionObject(res);

    },
    (err) => {

      this.isLoaded = true;
      this.isPublishDisable = false;
      this.toastService.createMessage('error', err);

    }
    );

  }

  deleteDiscussionById(groupId) {

    this.isLoaded = false;
    const queryStr = `/${groupId}`;

    this.cService.serviceRequestCommon('delete', this.discussionApiURL, queryStr).subscribe(() => {

      this.isLoaded = true;
      this.toastService.createMessage('success', 'The Discussion has Been Successfully deleted');
      this.isLoaded = true;
      this.reRoute.navigate(['/group-landing']);

    },
    (err) => {

      this.isLoaded = true;
      this.toastService.createMessage('error', err);

    }
    );

  }

  deleteDraftDiscussion(discussionVersionGroupId) {

    // To delete Auto Draft version
    const queryStr = `/${discussionVersionGroupId}`;

    this.cService.serviceRequestCommon('delete', this.discussionApiURL, queryStr).subscribe(() => {

      this.isLoaded = true;
      this.toastService.createMessage('success', 'Discussion has been successfully deleted.');
      this.reRoute.navigate(['ncx/group-details/:' + this.groupId]);

    },
    (err) => {

      this.isLoaded = true;
      this.toastService.createMessage('error', err);

    }
    );

  }

  /* API CRUD Operations Discussions End */

}
