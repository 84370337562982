import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { WebSocketService } from 'src/app/services/websocket.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts-page.html',
  styleUrls: ['./alerts-page.scss']
})
export class AlertsPage implements OnInit, OnDestroy {

  getAlertListURL: string;

  alertList: any = [];

  radioValue = 'active';

  getAlerts: string;

  isActive = true;

  pageStartCount: any;

  pageLength: any;

  getFilterListAPI: string;

  alertsType: any;

  isLoaded;

  alertType: any = 'ALL';

  showButtons = true;

  isAlertsEmpty: boolean;

  private subscriptionsWs: Subscription = new Subscription();

  constructor(
    private router: ActivatedRoute,
    private http: HttpClient,
    private cService: CommonService,
    private reRoute: Router,
    private wS: WebSocketService,
    private toastService: ToastService,
    private tS: TransferInformationComponent
  ) { }

  ngOnInit() {

    this.getAlertListURL = environment.mockAlertAPIURL;
    this.getAlerts = environment.getProfileAPIURL;
    this.getFilterListAPI = environment.getFilterListURL; // environment.getPostFiltersURL;
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.isActive = true;
    this.isLoaded = false;
    this.showButtons = true;
    this.isAlertsEmpty = false;
    this.getFilterList();
    this.getAlertList();

    // this.getAlertCount();
    this.subscriptionsWs = this.wS.doSocketData$.subscribe(data => {

      this.updateDashBoard(data);

    });

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  changeAlertType(alertType) {

    this.resetFilters();

    this.alertType = alertType.value;

    this.getAlertList();

    console.log(this.alertType);

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  onScrollDown() {

    this.pageStartCount += this.pageLength;

    // console.log('onScrollDown', this.pageStartCount);
    this.getAlertList();

  }

  updateAlertList(alertId) {

    console.log('updateAlertList : ', alertId);
    const updatedList = [];

    this.alertList.forEach(alert => {

      if (parseFloat(alert.alertId) !== parseFloat(alertId)) {

        updatedList.push(alert);

      }

    });
    if (updatedList && updatedList.length > 0) {

      this.alertList = [...updatedList];

    }
    if (this.alertList.length <= 5) {

      this.resetFilters();

      // this.alertList = this.alertList.splice(-this.alertList.length );
      this.getAlertList();

    }

  }

  updateAlertCount(count) {

    if (count === -1) {

      this.getAlertCount();

    } else {

      this.tS.setAlertCount(count);

    }

  }

  fetchAlerts(event) {

    this.isActive = event === 'active';

    this.resetFilters();

    this.getAlertList();

  }

  resetFilters() {

    this.pageStartCount = 1;
    this.pageLength = 10;
    this.alertList = [];

  }

  createQueryStr() {

    let queryStr = `/alerts?isActive=${this.isActive}&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    if (this.alertType !== 'ALL') {

      queryStr += `&alertGroup=${this.alertType}`;

    }
    return queryStr;

  }

  getAlertList() {

    this.isLoaded = false;
    const queryStr = this.createQueryStr();

    this.cService.serviceRequestCommon('get', this.getAlerts, queryStr).subscribe((res: any) => {

      // console.log(`SUCCESS: ${this.getAlerts}`);
      if (res && res.totalCount) {

        this.tS.setAlertCount(res.totalCount);

      }
      if (res && res.alerts && res.alerts.length > 0) {

        this.alertList = [...this.alertList, ...res.alerts];
        console.log('getAlertList : ', this.alertList);

      } else if ((this.pageStartCount + 1) > this.pageLength) {

        this.pageStartCount -= this.pageLength;

      }
      if (this.alertList && this.alertList.length === 0) {

        this.isAlertsEmpty = true;

      } else {

        this.isAlertsEmpty = false;

      }
      this.isLoaded = true;

    }, err => {

      console.log(`ERR: ${this.getAlerts}`, err);
      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getFilterList() {

    this.cService.serviceRequestCommon('get', this.getFilterListAPI).subscribe((res: any) => {

      console.log('Drafts', res);
      if (res && res.data) {

        this.alertsType = res.data.AlertsType;

      }

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getAlertCount() {

    const url = '/api/v1/gateway/alert/unread/count';

    this.cService.serviceRequestCommon('get', url, '', null).subscribe((res: any) => {

      console.log(`SUCCESS getAlertCount: ${res}`);
      if (res) {

        this.tS.setAlertCount(res);

      }

    }, () => {

      console.log(`ERR: ${url}`);

    });

  }

  ifExist(alertContentId) {

    return this.alertList.findIndex((a) => a.alertContentId === alertContentId);

  }

  private updateDashBoard(data) {

    let isAdded = false;

    const userId = localStorage.getItem('userId');

    // tslint:disable-next-line:max-line-length
    if (data && data.alert && data.alert.alertId && this.isActive && data.notificationTarget && Number(data.notificationTarget) ===
      Number(userId)) {

      const alertIndex = this.ifExist(data.alert.alertContentId);

      if (alertIndex >= 0) {

        // this.alertList.splice(alertIndex, 1);
        // return;
      }
      switch (this.alertType) {

      case 'ALL' :
        this.alertList = (this.alertList && this.alertList.length) ? [data.alert, ...this.alertList] : data.alert;
        isAdded = true;
        break;
      case 'GENERAL' :
        if (data.alert.alertGroup === 'GENERAL') {

          this.alertList = (this.alertList && this.alertList.length) ? [data.alert, ...this.alertList] : data.alert;
          isAdded = true;

        }
        break;
      case 'GROUP' :
        if (data.alert.alertGroup === 'GROUP') {

          this.alertList = (this.alertList && this.alertList.length) ? [data.alert, ...this.alertList] : data.alert;
          isAdded = true;

        }
        break;
      case 'STORY_POST' :
        if (data.alert.alertGroup === 'STORY' || data.alert.alertGroup === 'POST') {

          this.alertList = (this.alertList && this.alertList.length) ? [data.alert, ...this.alertList] : data.alert;
          isAdded = true;

        }
        break;
      default:
        break;

      }
      if (isAdded) {

        this.toastService.createMessage('success', 'New Alert Added');

      }

    }

  }

  get groupName(): string {

    switch (this.alertType) {

    case 'GENERAL':
      return 'Alerts';
    case 'GROUP':
      return 'Groups';
    case 'STORY_POST':
      return 'Story/Posts';
    default:
      return 'All Alerts';

    }

  }

}
