import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '@services/toastService/toastMessage.service';
import { StandardsGuidance } from '@models/ncx/standards-guidance';

@Component({
  selector: 'app-standards-guidance-modal',
  templateUrl: './standards-guidance.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class StandardsGuidanceModalComponent implements OnInit {

  @Input() isVisible: boolean = false;

  @Input() isLoaded: boolean = true;

  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Output() updated: EventEmitter<StandardsGuidance> = new EventEmitter<StandardsGuidance>();

  standardGuidenceContent: StandardsGuidance = {} as StandardsGuidance;

  constructor(private toastService: ToastService) { }

  ngOnInit() {

    this.standardGuidenceContent = {
      notes: ''
    };
  
  }

  handleCancel() {

    this.cancelled.emit();
  
  }

  handleOk() {

    if (this.standardGuidenceContent.notes !== '') {

      this.updated.emit(this.standardGuidenceContent);
    
    } else {

      this.toastService.createMessage('warning', 'Please fill standards Guidance here');
    
    }
  
  }

  onChange(value, type) {

    if (type === 'notes' && value === '') {

      this.toastService.createMessage('warning', 'Please fill standards Guidance here');
    
    }
  
  }

}
