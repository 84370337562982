import { User } from '../users';
import { Post } from './post';

export interface Angle {
  angleContent: string;
  angleId: number;
  anglePlainContent: string;
  anglePosts: Post[];
  anglePublished: boolean;
  angleTitle: string;
  createDateTime: number;
  createDateTimeString: string;
  createdUser: User;
  deleted: boolean;
  relatedStories: RelatedStory[];
  collaborators?: number[];
  collaboratorsDetails: User[];
  updateDateTime: number;
  updateDateTimeString: string;
  updateUser: User;
  angleIdTitle?: string;
  angleAccess?: string;
  privacy?: boolean;
}

export interface RelatedStory {
  createUserId: string;
  isPrimary: boolean;
  storyAccess: string;
  storyId: number;
  storyTitle: string;
  autoGeneratedStoryId: string;

}

export interface AngleLog {
  actionDescription: string;
  angleId: number;
  auditId: number;
  changedFields: string[];
  contentId: number;
  contentTitle: string;
  contentType: string;
  createdUser: User;
  createdUserId: number;
  createdDateAndTime: number;
  createdDateTime: string;
  time?: string;
  changedField?: string;
  isAccessOnlyChanged: boolean;
}

export interface AngleTitle {
  angleId: number;
  anglePublished: boolean;
  angleTitle: string;
  createDateTimeString: string;
  createdUser: User;
  createdUserId: number;
  deleted: boolean;
  angleURL?: string;
  createdDateTime: number;
  angleTitleSnippet?: string;
  angleAccess?: string;
  privacy?: boolean;
}


export enum AngleMode { Private = 'private', Public = 'public' }
