<!-- HEADER FOR THE DASHBOARD -->
<ng-container *ngIf="location === 'dashboard'">
   <div
      class="discussion-header"
      data-component="shared/cards/group/discussion (Dashboard)">
      <a
         target="_blank"
         href="/#/ncx/group-details/:{{ discussion.groupId }}"
         nz-tooltip
         class="title"
         nzTooltipPlacement="bottomLeft"
         [nzTooltipMouseEnterDelay]="0.5"
         [nzTooltipTitle]="discussion.groupName">
         {{ discussion.groupName }}
      </a>

      <div class="time-ago">
         {{ calculateDiff(discussion.updateDateTime) }}
      </div>
   </div>
</ng-container>

<!-- HEADER FOR THE "GROUP DETAILS" PAGE (ONLY IF THE DISCUSSION WAS RECENTLY UPDATED) -->
<ng-container *ngIf="location !== 'dashboard'">
   <div
      class="discussion-header"
      data-component="shared/cards/group/discussion (Non-Dashboard)"
      *ngIf="isDiscussionUpdated">
      <div
         nz-popover
         class="avatar-container"
         [nzPopoverContent]="profileTemplate"
         nzPopoverPlacement="topLeft"
         *ngIf="discussion.updateUser">
         <nz-avatar
            class="avatar"
            [nzSize]="30"
            [nzSrc]="Common.formS3Link(discussion.updateUser.profilePictureURL)"
            nzIcon="user"></nz-avatar>

         UPDATED

         <ng-template #profileTemplate>
            <app-profile-overlay [profileData]="discussion.updateUser"></app-profile-overlay>
         </ng-template>
      </div>

      <div class="time-ago">
         {{ calculateDiff(discussion.updateDateTime) }}
      </div>
   </div>
</ng-container>

<!-- DISCUSSION SUBJECT -->
<div class="subject">
   <a
      target="_blank"
      href="/#/ncx/discussion-details?groupId={{ discussion.groupId }}&discussionId={{ discussion.discussionId }}"
      nz-tooltip
      nzTooltipPlacement="bottomLeft"
      [nzTooltipMouseEnterDelay]="0.5"
      [nzTooltipTitle]="discussion.discussionSubject">
      {{ discussion.discussionSubject }}
   </a>
</div>

<div class="updated-created-by">
   <!-- DASHBOARD: SHOW WHO UPDATED THE RECORD -->
   <ng-container *ngIf="location === 'dashboard'">
      <button
         class="name"
         nz-popover
         [nzPopoverContent]="profileCardPerson"
         nzPopoverPlacement="topLeft">
         {{ Common.formatName(discussion.updateUser, true) }}
      </button>

      <span class="time">
         on {{ utcToLocal(discussion.updateDateTime, 'DATE') | date: 'M/d/yy' }} at
         {{ utcToLocal(discussion.updateDateTime, 'TIME') }}
      </span>

      <ng-template #profileCardPerson>
         <app-profile-overlay [profileData]="discussion.updateUser"></app-profile-overlay>
      </ng-template>
   </ng-container>

   <!-- NOT DASHBOARD: SHOW WHO CREATED THE RECORD -->
   <ng-container *ngIf="location !== 'dashboard'">
      <button
         class="name"
         nz-popover
         [nzPopoverContent]="profileCardPerson">
         {{ Common.formatName(discussion.createUser, true) }}
      </button>

      <span class="time">
         posted on {{ utcToLocal(discussion.createDateTime, 'DATE') | date: 'M/d/yy' }} at
         {{ utcToLocal(discussion.createDateTime, 'TIME') }}
      </span>

      <ng-template #profileCardPerson>
         <app-profile-overlay [profileData]="discussion.createUser"></app-profile-overlay>
      </ng-template>
   </ng-container>
</div>

<!-- DISCUSSION CONTENT -->
<div
   *ngIf="hasDiscussionContent"
   class="description">
   {{ discussion.discussionPlainContent.trim() }}
</div>

<!-- FOOTER -->
<div class="footer">
   <div class="left-side">
      <!-- ATTACHMENTS -->
      <app-info-attachments
         class="item"
         [total]="discussion.discussionAttachments?.length || 0"></app-info-attachments>

      <!-- COMMENTS -->
      <app-info-comments
         class="item"
         [total]="discussion.discussionCommentCount || 0"></app-info-comments>
   </div>

   <div class="right-side">
      <!-- SHARE -->
      <app-info-share
         class="item"
         [showShareText]="false"
         (click)="onShare()"></app-info-share>
   </div>
</div>

<app-share-discussion
   [isVisible]="showShareModal"
   [message]="shareMessage"
   [discussionId]="discussion.discussionId"
   (contentShared)="contentShared()"
   (closedSharedContent)="closeShareModal()"></app-share-discussion>
