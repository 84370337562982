<nz-modal
   [(nzVisible)]="isEmbedlyVisible"
   [nzTitle]="modalTitle"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()">
   <ng-template #modalTitle>Embedly Link</ng-template>

   <ng-template #modalContent>
      <p>URL:</p>
      <form nz-form></form>
      <nz-input-group [nzPrefix]="prefixTemplateUser">
         <input
            nz-input
            placeholder=""
            [(ngModel)]="embedlyLink"
            required />
      </nz-input-group>
      <ng-template #prefixTemplateUser>
         <i
            nz-icon
            nzType="link"
            nzTheme="outline"></i>
      </ng-template>
   </ng-template>
   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         (click)="handleOk('insert')">
         Insert
      </button>
   </ng-template>
</nz-modal>
