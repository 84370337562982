import { Component, Input, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-activity-filter',
  templateUrl: './activity-filter.component.html',
  styleUrls: ['./activity-filter.component.scss']
})
export class ActivityFilterComponent implements OnInit, OnChanges {

  @Input() radioFilterOptions;

  @Input() checkOptionsLabel;

  @Input() radioCreationDateOptions;

  @Input() activityText;

  @Input() labelStatus;

  @Input() isDrawer = true;

  @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output() filterApplied: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentStatus: any = { activity: '', labels: '', date: '' };

  postTypeSelected: any;

  dateTypeSelected: any;

  labelSelected: any;

  radioPostTypeValue = 'All';

  radioCreationDateValue = 'updateDate';

  statusType: any;

  radioPostOptions: any[];

  radioLabelsValue: string;

  filterList: any;

  getFilterListURL: string;

  checkOptionsLabelInit: any = [];

  constructor(
    private cService: CommonService,
    private toastService: ToastService
  ) { }

  ngOnInit() {

    // Initialize pre-selected values
    this.radioPostTypeValue = this.currentStatus.activity || 'All';
    this.radioCreationDateValue = this.currentStatus.date || 'updateDate';
    this.checkOptionsLabel.forEach((opt: any) => {

      const labels = this.currentStatus.labels ? this.currentStatus.labels.split(',') : [];

      opt.checked = !!labels.includes(opt.label);

    });

  }

  ngOnChanges() {

    if (this.checkOptionsLabel && this.checkOptionsLabel.length && this.checkOptionsLabelInit && !this.checkOptionsLabelInit.length) {

      this.checkOptionsLabelInit = JSON.parse(JSON.stringify(this.checkOptionsLabel));

    }

  }

  close() {

    this.closeFilter.emit(true);

  }

  getFilter() {

    // need to change
    this.getFilterListURL = environment.getPostFiltersURL;
    this.cService.serviceRequestCommon('get', this.getFilterListURL).subscribe((res: any) => {

      this.filterList = res;
      this.radioPostOptions = this.filterList.activityFilter;
      this.checkOptionsLabel = this.filterList.activityLabel;
      this.radioCreationDateOptions = this.filterList.activityOrder;
      this.checkOptionsLabel.forEach(element => {

        if (element.checked) {

          this.changeLabelStatus(true, element, 'labels');

        }

      });

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  clear() {

    this.radioPostOptions = [];
    this.checkOptionsLabel = [];
    this.radioPostTypeValue = 'All';
    this.radioLabelsValue = 'Important';
    this.radioCreationDateValue = 'updateDate';
    this.labelStatus = [];
    this.postTypeSelected = null;
    this.labelSelected = null;
    this.checkOptionsLabel = JSON.parse(JSON.stringify(this.checkOptionsLabelInit));
    this.checkOptionsLabel.forEach((opt: any) => {

      opt.checked = false;

    });

    // this.getFilter();
    const filterObj = {
      labels: '',
      postType: '',
      date: '',
      type: 'Remove'
    };

    this.filterApplied.emit(filterObj);

    // console.log('clear : ', this.checkOptionsLabel, this.checkOptionsLabelInit);

  }

  addFilters(event, data, type) {

    switch (type) {

    case 'type':
      this.postTypeSelected = (data && data.value) ? data.value : null;
      break;
    case 'labels':
      this.changeLabelStatus(event, data, type);
      break;
    case 'date':
      this.dateTypeSelected = (data && data.value) ? data.value : null;
      break;

    }

    const filterObj = {
      labels: this.labelSelected,
      postType: this.postTypeSelected,
      date: this.dateTypeSelected,
      filter: type,
      type: 'Add'
    };

    this.filterApplied.emit(filterObj);

  }

  changeLabelStatus(event, data?, type?) {

    const obj = {
      order: data
    };

    if (event === true) {

      // Pushing the object into array
      this.labelStatus.push(obj);

    } else {

      const removeIndex = this.labelStatus.findIndex(itm => itm.order.value === data.value);

      if (removeIndex !== -1) {

        this.labelStatus.splice(removeIndex, 1);

      }

    }

    this.statusType = type;
    if (!this.labelStatus || !this.labelStatus.length) {

      this.labelSelected = null;

    } else {

      const temp = this.labelStatus.map(a => a.order.value);

      let labels = '';

      temp.forEach((element, index) => {

        labels += (index !== (temp.length - 1)) ? element + ',' : element;

      });
      this.labelSelected = labels;

    }

  }

}
