import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { TransferInformationComponent } from '../TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-select-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnChanges {

  @Input() groupMembersList;

  @Output() updatedGroupAdmin = new EventEmitter();


  // selectedValue = 'lucy';
  selectedValue = null;

  accessValue: any = 'public';

  subject: Subject<string> = new Subject();

  userListNames: any = [];

  selectedUserList: any = [];

  userEmailList: any = [];

  groupUserInput: string;

  userList: any = [];

  getUsers: string;

  dropdownText: any = 'ADMIN';

  groupMembers;

  constructor(
    private cService: CommonService,
    private userInfo: TransferInformationComponent,
    private toastService: ToastService
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.fetchUserList(searchTextValue);
    
    });
  
  }

  ngOnInit() {

    this.getUsers = environment.getUsersOnDutyURL;
    this.userEmailList.push({
      fullName: this.userInfo.linkSelectedDetailsSource.value,
      memberUserId: localStorage.getItem('userId'),
      membershipType: 'OWNER',
      membershipCategory: 'MEMBER',
      deleted: false
    });
    this.updatedGroupAdmin.emit(this.userEmailList);
  
  }

  ngOnChanges() {

    console.log('ngOnChanges this.groupMembersList ', this.groupMembersList);
    if (this.groupMembersList) {

      this.userEmailList = this.groupMembersList.map((user) => {

        return {
          fullName: (user && user.fullName) ? user.fullName : user.userId.displayName.split(',').length > 1 ? user.userId.displayName.split(',')[1] +
            ' ' + user.userId.displayName.split(',')[0] : user.userId.displayName.trim(),
          memberUserId: (user && user.userId && user.userId.userId) ? user.userId.userId : user.memberUserId,
          membershipType: (user && user.membershipType) ? user.membershipType : user.membershipType,
          membershipCategory: 'MEMBER',
          deleted: (user && user.deleted) ? user.deleted : false
        };
      
      });
    
    }
  
  }

  onchange(event) {

    this.subject.next(event.target.value);
  
  }

  toggleDropdown(dropdownValue, memberUserId) {

    const userObj = this.userEmailList.filter(user => user.memberUserId === memberUserId)[0];

    if (userObj === undefined || userObj === null) {

      return;
    
    } else {

      const groupOwner = this.userEmailList.filter((member) => member.membershipType === 'OWNER')[0];

      if (!(groupOwner === null || groupOwner === undefined) && dropdownValue === 'OWNER') {

        this.toastService.createMessage('warning', 'Multiple Owner for group is not allowed.');
        return false;
      
      }
      userObj.membershipType = dropdownValue;
      this.updatedGroupAdmin.emit(this.userEmailList);
    
    }
  
  }

  titleMatch(value) {

    const obj = this.userListNames.filter(user => user.memberUserId === value)[0];

    const obj1 = this.userEmailList.filter(user => user.memberUserId === value)[0];

    if ((!(obj === null || obj === undefined) && (obj1 === null || obj1 === undefined))) {

      this.userEmailList.push(obj);
      this.resetValues();
      this.updatedGroupAdmin.emit(this.userEmailList);
    
    } else if (obj1 !== null && obj1 !== undefined && !obj1.deleted) {

      this.resetValues();
      this.updatedGroupAdmin.emit(this.userEmailList);
    
    } else if (obj1 !== null && obj1 !== undefined && obj1.deleted) {

      obj1.deleted = false;
      this.resetValues();
      this.updatedGroupAdmin.emit(this.userEmailList);
    
    }
  
  }

  // createGroupMembers() {
  //   const temp =[];
  //   this.userEmailList.forEach(user => {
  //     temp.push({

  //     })
  //   });
  // }
  resetValues() {

    setTimeout(() => {

      this.groupUserInput = '';
      this.userListNames = [];
      this.selectedUserList = [];
    
    }, 100);
  
  }

  bindDropdownVal() {

    const users = [];

    this.userList.forEach((user: any) => {

      users.push({
        fullName: ((user.name.preferredName.split(',')[1] === undefined || user.name.preferredName.split(',')[1] === null)
          ? ''
          : user.name.preferredName.split(',')[1]) + ' ' +
          ((user.name.preferredName.split(',')[0] === undefined || user.name.preferredName.split(',')[0] === null)
            ? ''
            : user.name.preferredName.split(',')[0])
        ,
        memberUserId: (user.userId === undefined || user.userId === null) ? '' : user.userId,
        membershipType: 'MEMBER',
        membershipCategory: 'MEMBER',
        deleted: false
      });
    
    });
    this.userListNames = users;
  
  }

  deleteUser(memberUserId, membershipType) {

    if (membershipType === 'OWNER') {

      this.toastService.createMessage('warning', 'Group Owner cannot be Deleted');
    
    } else if (membershipType !== 'OWNER') {

      this.updateUserList(memberUserId);
      this.updatedGroupAdmin.emit(this.userEmailList);
    
    }
  
  }

  updateUserList(memberUserId) {

    const temp = [];

    this.userEmailList.forEach((user) => {

      if (user.memberUserId === memberUserId) {

        user.deleted = true;
      
      }
      temp.push(user);
    
    });
    this.userEmailList = [...temp];
  
  }

  /* API to fetch User Details based upon search string Start */
  fetchUserList(searchTextValue) {

    this.userList = [];
    const queryStr = `?name=${searchTextValue}`;

    this.cService.serviceRequestCommon('get', this.getUsers, queryStr).subscribe((res: any) => {

      this.userList = [...res];
      this.bindDropdownVal();
    
    }, (err) => {

      console.log('fetchUserList err : ', err);
    
    });
  
  }

  /* API to fetch User Details based upon search string End */

}
