import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppConstants } from '@constants/app.constants';

@Component({
  selector: 'app-google-docs',
  templateUrl: './google-docs.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class GoogleDocsComponent {

  googleDocLink = '';

  isValid = true;

  @Input() isVisible;

  @Output() docLinkUpdated: EventEmitter<any> = new EventEmitter<any>();

  handleOk(_type: string): void {

    const urlRegx = new RegExp(AppConstants.googlePattern);

    if (urlRegx.test(this.googleDocLink)) {

      this.docLinkUpdated.emit(this.googleDocLink);
      this.isVisible = false;
      this.isValid = true;
      this.googleDocLink = '';

    } else {

      this.isValid = false;

    }

  }

  handleCancel(): void {

    this.isVisible = false;
    this.docLinkUpdated.emit('close');

  }

}
