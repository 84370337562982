import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Post } from '@models/ncx/post';
import { Common } from '@utilities/common';

@Component({
  selector: 'app-post-unlock-banner',
  templateUrl: './unlock-banner.component.html',
  styleUrls: [
    '../common.scss',
    './unlock-banner.component.scss'
  ]
})
export class PostUnlockBannerComponent {

  @Input() postDetails: Post = {} as Post;

  @Input() unlock : boolean = false;

  @Output() unlockPost: EventEmitter<void> = new EventEmitter();

  show: boolean = true;

  unlockEvent() {

    this.unlockPost.emit();

  }

  get name(): string {

    const { lockedByUser } = this.postDetails;

    if (!lockedByUser) {

      return '';
    
    }

    return Common.formatName(lockedByUser);

  }

}
