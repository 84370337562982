<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<nz-page-header
   class="site-page-header pageHeader"
   nzBackIcon
   (nzBack)="back()">
   <nz-page-header-title class="headerLabel">Back</nz-page-header-title>
</nz-page-header>
<nz-content [attr.data-component]="'ncx/posts/versions'">
   <div class="postVersions">
      <nz-table
         #nzTable
         [nzData]="postVersions"
         [nzShowPagination]="false">
         <thead>
            <tr>
               <th
                  class="expandable"
                  nzShowExpand>
                  &nbsp;
               </th>
               <th>Version</th>
               <th>State</th>
               <th class="versionChangedByHeading">Changed By</th>
               <th class="versionTimestamp">Time Stamp</th>
               <th class="actionHeading">Action</th>
               <th class="versionMore">&nbsp;</th>
            </tr>
         </thead>
         <tbody>
            <ng-template
               ngFor
               let-data
               [ngForOf]="nzTable.data">
               <tr *ngIf="data.postVersionNumber">
                  <td
                     class="expandable"
                     nzShowExpand
                     [(nzExpand)]="mapOfExpandData[data.postVersionNumber]"
                     (nzExpandChange)="showMoreData(data.name, data.postVersionNumber)">
                     &nbsp;
                  </td>
                  <td>
                     <a
                        (click)="openPost(data.postId)"
                        class="versionIdChangedBy"
                        style="cursor: pointer">
                        {{ data.postVersionNumber }}
                     </a>
                  </td>
                  <td>{{ data.postVersionState }}</td>
                  <td class="versionChangedBy">
                     <ng-container *ngIf="data && data.updateUser">
                        <span
                           nz-popover
                           [nzPopoverContent]="profileCardTemplate"
                           [nzPopoverPlacement]="'bottomLeft'"
                           *ngIf="data.updateUser.displayName.split(',').length > 1">
                           {{ data.updateUser.displayName.split(',')[1] }}
                           {{ data.updateUser.displayName.split(',')[0] }}
                        </span>
                        <span
                           nz-popover
                           [nzPopoverContent]="profileCardTemplate"
                           [nzPopoverPlacement]="'bottomLeft'"
                           *ngIf="data.updateUser.displayName.split(',').length === 1">
                           {{ data.updateUser.displayName.trim() }}
                        </span>
                        <ng-template #profileCardTemplate>
                           <app-profile-overlay [profileData]="data.updateUser"></app-profile-overlay>
                        </ng-template>
                     </ng-container>
                  </td>
                  <td class="versionTimestamp">
                     {{ utcToLocal(data.updateDateTime, 'DATE') | date: 'M/d/yyyy - '
                     }}{{ utcToLocal(data.updateDateTime, 'TIME') }}
                  </td>
                  <td class="versionAction">
                     <a
                        class="versionAction"
                        (click)="deleteVersion(data.postId)"
                        *ngIf="data.postVersionState !== 'Live'">
                        Delete
                     </a>
                     <nz-divider
                        nzType="vertical"
                        *ngIf="data.postVersionState !== 'Live' && data.postVersionState !== 'Draft'"></nz-divider>
                     <a
                        class="versionAction"
                        (click)="createVersion(data)"
                        *ngIf="data.postVersionState !== 'Live' && data.postVersionState !== 'Draft'">
                        Restore
                     </a>
                  </td>
                  <td *ngIf="formatDataGrid()">
                     <div *ngIf="data.postVersionState !== 'Live'">
                        <i
                           nz-popover
                           [nzPopoverContent]="contentTemplate"
                           nzPopoverPlacement="left"
                           [nzPopoverTrigger]="'click'"
                           nz-icon
                           nzType="more"
                           nzTheme="outline"></i>
                     </div>
                     <div *ngIf="data.postVersionState === 'Live'">
                        <i style="padding: 16px"></i>
                     </div>
                  </td>
                  <ng-template #contentTemplate>
                     <div
                        class="popoverLinks"
                        (click)="deleteVersion(data.postId)"
                        *ngIf="data.postVersionState !== 'Live'">
                        Delete
                     </div>
                     <div
                        class="popoverLinks"
                        (click)="createVersion(data)"
                        *ngIf="data.postVersionState !== 'Live' && data.postVersionState !== 'Draft'">
                        Restore
                     </div>
                  </ng-template>
               </tr>
               <tr
                  [nzExpand]="mapOfExpandData[data.postVersionNumber]"
                  *ngFor="let item of itemName">
                  <td
                     colspan="24"
                     *ngIf="item === 'timestamp'">
                     Timestamp: {{ utcToLocal(data.updateDateTime, 'DATE') | date: 'M/d/yyyy - '
                     }}{{ utcToLocal(data.updateDateTime, 'TIME') }}
                  </td>
                  <td
                     colspan="24"
                     *ngIf="item === 'changedBy'">
                     <ng-container *ngIf="data && data.updateUser && data.updateUser.displayName.split(',').length > 1">
                        Changed By: {{ data.updateUser.displayName.split(',')[1] }}
                        {{ data.updateUser.displayName.split(',')[0] }}
                     </ng-container>
                     <ng-container
                        *ngIf="data && data.updateUser && data.updateUser.displayName.split(',').length === 1">
                        Changed By: {{ data.updateUser.displayName.trim() }}
                     </ng-container>
                  </td>
               </tr>
            </ng-template>
         </tbody>
      </nz-table>
   </div>
</nz-content>
