import { Component, ContentChild, Output, EventEmitter, Input } from '@angular/core';
import { Location } from '@angular/common';
import { HeaderRightTemplateDirective } from '../header-directives';

/**
 * Standard header with Back button, title and optional action button on the Right
 *
 */

@Component({
  selector: 'app-standard-header',
  templateUrl: './standard-header.component.html',
  styleUrls: ['./standard-header.component.scss']
})
export class StandardHeaderComponent {

  @ContentChild(HeaderRightTemplateDirective) rightSide!: HeaderRightTemplateDirective;

  // Header title
  @Input() title = '';

  // Back Button
  @Input() showBackButton = true;

  // Set to 'true' if you want to use the default goBack() function to return to the previous page
  @Input() defaultBackAction = false;

  // Function to call when setting the defaultBackAction to 'false'
  @Output() clickBack: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private location: Location
  ) { }

  onClickBack() {

    if (this.defaultBackAction) {

      this.location.back();
    
    } else {

      this.clickBack.emit();
    
    }
  
  }

}
