<!-- CONTAINER FOR A COLLAPSABLE BOX -->

<nz-collapse [nzExpandIconPosition]="'right'"
             [nzBordered]='border'
             [class]='allClasses'
             data-component='shared/components/collapsable'>

  <nz-collapse-panel
    [nzHeader]='header'
    [nzActive]='active'
    [nzShowArrow]='showArrow && collapsable'
    [nzDisabled]='disabled'
    (nzActiveChange)='onClickHeader($event)'>

    <ng-content></ng-content>

  </nz-collapse-panel>
</nz-collapse>

<ng-template #header>
  <div class='collapsable-header'>
        <p [ngClass]='{title:true,bold: boldTitle, truncate: truncate}'>
            <i nz-icon
               *ngIf='icon'
               [nzType]='icon'
               [nzTheme]='iconTheme'></i>
          {{title}}
        </p>
    <span *ngIf='altText'
          class='altText'>{{altText}}</span>
  </div>
</ng-template>
