import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngleTitle } from '@models/ncx/angle';

@Component({
  selector: 'app-angle-details',
  templateUrl: './angle-details.component.html',
  styleUrls: ['./angle-details.component.scss']
})

export class AngleDetailsComponent {

  @Input() angleDetail!: AngleTitle;

  @Input() isSelect = false;

  @Input() isDelete = false;

  @Input() isDisabled = false;

  @Output() selectAngleEvent: EventEmitter<AngleTitle> = new EventEmitter<AngleTitle>();

  @Output() deselectAngleEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() destroyAddToAngleModal = new EventEmitter();

  selectAngle() {

    this.selectAngleEvent.emit(this.angleDetail);

  }

  deselectAngle() {

    this.deselectAngleEvent.emit();

  }

  destroyParentModal() {

    this.destroyAddToAngleModal.emit();

  }

  showAngle() {

    window.open(this.angleDetail.angleURL ? this.angleDetail.angleURL : '/#/ncx/landing-angle/:' + this.angleDetail.angleId, '_blank');

  }

}
