import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit {

  radioValue = 'following';

  name = 'Following';

  followerApi;

  isFollowing = false;

  connectionsApi;

  followersUsers;

  followingUsers;

  connections;

  users;

  isLoaded;

  screenType;

  currentSelectedTab: any = 'following';

  followingUserIds: any = [];

  followerUserIds: any = [];

  placement: string = '';

  public readonly Common = Common;

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {

    this.screenType = 'connections';
    this.followerApi = environment.getProfileAPIURL;
    const userId = localStorage.getItem('userId');

    this.connectionsApi = environment.getProfileAPIURL + '/' + userId + '/userConnections';
    console.log('connectionsApi', this.connectionsApi);
    this.getConnectionsDetails();

  }

  getConnectionsDetails() {

    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', this.connectionsApi).subscribe((res: any) => {

      if (res && res.followingUsers && res.followingUsers.length) {

        this.followingUsers = [...res.followingUsers];

      } else {

        this.followingUsers = [];

      }
      if (res && res.followerUsers && res.followerUsers.length) {

        this.followersUsers = [...res.followerUsers];

      } else {

        this.followersUsers = [];

      }

      // this.followersUsers = [...res.followerUsers];
      this.radioValue = this.currentSelectedTab;
      if (this.radioValue === 'following') {

        this.users = this.followingUsers;

      } else {

        this.users = this.followersUsers;

      }
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  toggleConnections(value) {

    // console.log('Connections Users', value);
    this.currentSelectedTab = value;
    if (value === 'following') {

      this.name = 'Following';
      this.users = this.followingUsers;
      this.followingUserIds = this.followingUsers.map(user => {

        return user.userId;

      });

    } else if (value === 'followers') {

      this.name = 'Followers';
      this.users = this.followersUsers;
      this.followerUserIds = this.followersUsers.map(user => {

        return user.userId;

      });

    }

  }

  checkFollowing(userId) {

    let check = false;

    if (this.followingUsers && this.followingUsers.length) {

      this.followingUsers.forEach(element => {

        if (element.userId === userId) {

          check = true;

        }

      });

    }
    if (check) {

      return true;

    } else {

      return false;

    }

  }

  goToProfile(id) {

    this.router.navigate(['ncx/profile/:' + id]);

  }

  awaitFollow(id, value) {

    this.followUser(id, id, value);

  }

  updateFollowersList(followerObj, _action) {

    console.log('updateFollowersList : ', followerObj, this.users);
    this.getConnectionsDetails();

  }

  followUser(index, targetUserId, type) {

    const followerUserId = localStorage.getItem('userId');

    if (targetUserId.toString() === followerUserId.toString()) {

      this.toastService.createMessage('error', 'Please select Different User to Follow.');
      return;

    }
    const queryStr = `/${targetUserId}/userFollower/${followerUserId}`;

    console.log('followUser : ', targetUserId, followerUserId, type);
    this.cService.serviceRequestCommon(type, this.followerApi, queryStr).subscribe(data => {

      console.log('Follow Response', data);
      if (type === 'post') {

        this.toastService.createMessage('success', 'You are now following the User');
        this.updateFollowersList(data, 'FOLLOW');

      } else {

        this.updateFollowersList(data, 'UNFOLLOW');
        this.toastService.createMessage('success', 'You have Unfollowed the User');

      }

    }, () => {

      this.toastService.createMessage('error', 'Error saving data. Please try again');

    });

  }

  handleMouseOver(event: MouseEvent, defaultTopOrBottom: string, defaultLeftOrRight: string) {

    this.placement = Common.placementPosition(event, defaultTopOrBottom, defaultLeftOrRight);

  }

}
