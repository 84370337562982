<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<ng-container *ngIf="postDetails">
   <!-- *** Size : {{screenSize}} Width : {{windowWidth}}

  **** isMobile : {{isMobile}} -->
   <!-- STANDARD HEADER -->
   <div
      #pageHeader
      class="viewPost">
      <app-standard-header
         *ngIf="showPostHeader"
         title="Back"
         (clickBack)="goBack()">
         <ng-template header-right>
            <div class="header">
               <ng-container *ngIf="!isMobile">
                  <button
                     *ngIf="functionAbility.fa_post_versions"
                     [disabled]="
                        postDetails &&
                        postDetails.locked &&
                        postDetails.lockedByUser &&
                        loggedInUserId !== postDetails.lockedByUser.userId
                     "
                     nz-button
                     nzType="default"
                     class="extraHeaderBtn"
                     (click)="showPostVersions()">
                     <i
                        nz-icon
                        nzType="exception"
                        nzTheme="outline"></i>
                     Versions
                  </button>
                  <button
                     nz-button
                     nzType="default"
                     class="extraHeaderBtn"
                     (click)="viewAsPdf()">
                     <i
                        nz-icon
                        nzType="file-pdf"
                        nzTheme="outline"></i>
                     View as PDF
                  </button>
                  <button
                     nz-button
                     nzType="text"
                     nzShape="circle"
                     nzSize="large"
                     class="toggle-icon"
                     (click)="toggleSider()">
                     <i
                        nz-icon
                        nzType="setting"
                        nzTheme="outline"></i>
                  </button>
               </ng-container>

               <button
                  *ngIf="isMobile"
                  nz-button
                  nzType="text"
                  nzShape="circle"
                  nzSize="large"
                  class="toggle-icon"
                  nz-dropdown
                  [nzDropdownMenu]="headerMenus">
                  <i
                     nz-icon
                     nzType="ellipsis"
                     nzTheme="outline"></i>
               </button>

               <!-- <span *ngIf="!(isHandset$ | async)" nz-icon nzType="setting" (click)="toggleSider()" class="settingIcon"
          nzTheme=""></span> -->

               <!-- <span  *ngIf="isMobile" nz-icon nzType="setting" nz-dropdown [nzDropdownMenu]="headerMenus"
          class="settingIcon" nzTheme="twotone"></span> -->
            </div>
         </ng-template>
      </app-standard-header>
   </div>

   <nz-content>
      <div class="post-details-metadata-flex">
         <div class="post-split">
            <app-scroll-container [footerHeight]="footerHeight">
               <ng-template bodyTemplate>
                  <section
                     class="postDetailsBodySection"
                     #postDetailsBody>
                     <ng-container *ngTemplateOutlet="topBannerTemplate"></ng-container>
                     <ng-container *ngTemplateOutlet="postDetailsTemplate"></ng-container>
                  </section>
               </ng-template>
            </app-scroll-container>
         </div>
         <div
            class="post-split"
            *ngIf="!isMobile && !isMetadataCollapsed">
            <!-- METADATA FILTERS ON DESKTOP -->
            <ng-container *ngTemplateOutlet="metadataSiderTemplate"></ng-container>
         </div>
      </div>
   </nz-content>

   <!-- FOOTER -->
   <div
      class="footer-section"
      #footerElement>
      <nz-footer class="footer-flex">
         <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </nz-footer>
   </div>
</ng-container>

<!-- MOBILE: FILTER DRAWER/SIDER -->
<nz-drawer
   *ngIf="isMobile"
   nzPlacement="right"
   nzWrapClassName="no-padding"
   [nzWidth]="320"
   [nzMaskClosable]="true"
   [nzClosable]="false"
   (nzOnClose)="toggleSider()"
   [nzVisible]="!isMetadataCollapsed">
   <ng-container
      *nzDrawerContent
      [ngTemplateOutlet]="metadataSiderTemplate"></ng-container>
</nz-drawer>

<app-share-post
   [isVisible]="isShareStoryVisible"
   [message]="shareMessage"
   [postId]="sharePostId"
   (closedSharedContent)="cancelSharedContent()"></app-share-post>

<app-move-post-modal
   [isMovePostVisible]="isMovePostVisible"
   [sourcePostId]="postId"
   [StoryList]="relatedStoryTitles"
   [isPinnedOnTop]="isPinnedOnTop"
   (closeMovePost)="closeMovePost()"
   (updatedStories)="updateRelatedStory($event)"></app-move-post-modal>

<!-- LOCKED/ARCHIVE/DRAFT BANNERS -->
<ng-template #topBannerTemplate>
   <div *ngIf="postDetails">
      <app-archive-banner
         [archiveUrl]="archiveUrl"
         *ngIf="
            postDetails.postVersionState && postDetails.postVersionState === 'Archived' && urlCheckFlag && showBanner
         "></app-archive-banner>

      <app-draft-banner
         *ngIf="
            postDetails.postVersionState && postDetails.postVersionState === 'Draft' && showBanner
         "></app-draft-banner>

      <app-post-unlock-banner
         [postDetails]="postDetails"
         [unlock]="functionAbility.fa_unlock_post"
         (unlockPost)="unlockPost()"
         *ngIf="postDetails?.locked && loggedInUserId !== postDetails.lockedByUser?.userId"></app-post-unlock-banner>
   </div>
</ng-template>

<ng-template #profileCardTemplate>
   <app-profile-overlay [profileData]="postDetails.lockedByUser"></app-profile-overlay>
</ng-template>

<ng-template #postDetailsTemplate>
   <div class="post-view">
      <!-- Post Title Details -->
      <ng-container *ngTemplateOutlet="postTitleDetailsTemplate"></ng-container>

      <!-- Standard Guidance -->
      <div
         *ngIf="
            postDetails &&
            postDetails.editorialStatus &&
            postDetails.editorialStatus.includes('STANDARDS') &&
            postDetails.standardsGuidance
         "
         class="mt-16">
         <app-standard-guidance-view [postDetails]="postDetails"></app-standard-guidance-view>
      </div>

      <!-- Post Description. Profile view on mouse over. If the description has the format @username then the profile will be shown -->
      <span
         class="custom-popover"
         id="triggerClick"
         nzPopoverTrigger="click"
         nz-popover
         [nzPopoverContent]="profileCardTemplate"
         [nzPopoverPlacement]="'topLeft'"></span>
      <ng-template
         cdkConnectedOverlay
         #profileCardTemplate>
         <app-profile-overlay [profileData]="profileInDescription"></app-profile-overlay>
      </ng-template>

      <div
         class="mt-16 postDescription fr-element fr-view"
         #postcontent>
         <span [innerHtml]="postDetails?.postContent | safeHtml"></span>
      </div>

      <!-- Reportable Approver -->
      <div
         *ngIf="
            postDetails &&
            postDetails.editorialStatus &&
            postDetails.editorialStatus.includes('REPORTABLE') &&
            postDetails.reportableApprover
         "
         class="section mt-16">
         <app-reportable-approver-view [postDetails]="postDetails"></app-reportable-approver-view>
      </div>

      <!--License Limitations-->

      <div
         *ngIf="
            postDetails &&
            postDetails.postLegal &&
            postDetails.postLegal.includes('LIMITED LICENSE') &&
            postDetails.limitedLicenseApprover
         "
         class="section mt-8">
         <app-license-limitations-view [postDetails]="postDetails"></app-license-limitations-view>
      </div>

      <div class="divider"></div>

      <!-- Attachments -->
      <div
         *ngIf="postDetails && postDetails.postAttachments"
         class="postAttachments">
         <app-view-attachments
            [postAttachments]="postDetails.postAttachments"
            [showAllAttachments]="true"></app-view-attachments>
      </div>

      <!-- Element Details -->
      <div
         *ngIf="
            postDetails &&
            postDetails.attachmentDetails &&
            (postDetails.attachmentDetails.linkToSource !== '' ||
               postDetails.attachmentDetails.creditName !== '' ||
               (postDetails.attachmentDetails.captureDate?.toString() !== '' &&
                  postDetails.attachmentDetails.captureDate !== null) ||
               (postDetails.attachmentDetails.clearanceStatus != null &&
                  postDetails.attachmentDetails.clearanceStatus !== ''))
         "
         class="section mt-16">
         <app-element-details-view [postDetails]="postDetails"></app-element-details-view>
      </div>

      <!-- Linked Angles -->
      <div
         class="section mt-8"
         *ngIf="postDetails && postDetails.relatedAngles?.length > 0">
         <app-linked-angles-view [relatedAngles]="relatedAngles"></app-linked-angles-view>
      </div>

      <!-- Linked Stories -->
      <div
         class="section mt-8"
         *ngIf="postDetails && postDetails.relatedStories?.length > 0">
         <app-linked-stories-view [relatedStoryTitles]="relatedStoryTitles"></app-linked-stories-view>
      </div>

      <!-- Editorial and Post tags -->
      <div class="postLabels">
         <app-post-labels
            [editorial]="postDetails.editorialStatus"
            [legal]="postDetails.postLegal"></app-post-labels>
      </div>

      <div class="count-section">
         <div class="right">
            <app-info-attachments
               class="mr-24"
               [total]="postDetails?.postAttachments?.length || 0"></app-info-attachments>

            <app-info-comments
               class="mr-24"
               [total]="postDetails?.repliesCount || 0"></app-info-comments>

            <app-info-share
               [showShareText]="false"
               (click)="openSharePost(postDetails.postTitle)"></app-info-share>
         </div>
      </div>
   </div>

   <!-- Sent to WG01/06 -->
   <div
      *ngIf="postDetails && postDetails.postAttachments && isMediaCentralDetails"
      class="mt-16">
      <app-media-central-details-view
         [postDetails]="postDetails"
         (viewAttachment)="viewAttachment($event)"></app-media-central-details-view>
   </div>

   <div
      class="app-replies"
      *ngIf="postDetails && postDetails.postVersionGroupId">
      <app-text-comments [postVersionGroupId]="postDetails.postVersionGroupId"></app-text-comments>
   </div>
</ng-template>

<!-- METADATA CONTENT -->
<ng-template #metadataSiderTemplate>
   <div class="metadata-title-section">
      <span class="metadataTitle">Metadata</span>
      <button
         (click)="toggleSider()"
         class="metadataClose">
         <i
            *ngIf="!isMetadataCollapsed"
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>
   <app-scroll-container [footerHeight]="footerHeight">
      <ng-template bodyTemplate>
         <section
            class="metadataRightBodySection"
            #metadataBody>
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
         </section>
      </ng-template>
   </app-scroll-container>
</ng-template>

<!-- RIGHT-HAND SIDEBAR METADATA (TAGS,LABELS,TOPICS) -->
<ng-template #metadataTemplate>
   <app-posts-metadata
      [viewMode]="true"
      [filterList]="filterList"
      [postDetails]="postDetails"
      (onPostUpdated)="updatePostDetails($event)"></app-posts-metadata>
</ng-template>

<!-- FOOTER -->
<ng-template #footerTemplate>
   <div class="left-btn">
      <button
         nz-button
         class="delete-bg footer-btn"
         nzType="primary"
         nzDanger
         [nzShape]="!isMobile ? 'round' : 'circle'"
         [disabled]="postDetails?.locked && loggedInUserId !== postDetails.lockedByUser?.userId"
         id="delete"
         (click)="showConfirm()"
         *ngIf="functionAbility.fa_delete_post">
         <span
            nz-icon
            nzType="delete"
            nzTheme="outline"></span>
         <span *ngIf="!isMobile">Delete</span>
      </button>
   </div>

  <div class="right-btn">
    <span class="footer-btn">
      <button nz-button
              nzType="default"
              [nzShape]="!isMobile? 'round' :  'circle'"
              *ngIf="functionAbility.fa_move_post"
              [disabled]="postDetails?.locked && loggedInUserId !== postDetails.lockedByUser?.userId || postDetails.postVersionState === 'Draft'"
              (click)="openMovePost()">
        <span nz-icon
              nzType="swap"
              nzTheme="outline"></span>
        <span *ngIf="!isMobile">Move</span>
      </button>
    </span>
    <span class="footer-btn"
          *ngIf="doesPostHasVideo && isPostClearedByLegal && !permissions.isReadOnlyRole">
      <button nz-button
              nzType="default"
              [nzShape]="!isMobile? 'round' :  'circle'"
              (click)="sendPostToMediaCentral()" [disabled]="postDetails.postVersionState === 'Draft'">
        <span nz-icon
              nzType="export"
              nzTheme="outline"></span>
        <span *ngIf="!isMobile">Send to WG01/06</span>
      </button>
    </span>

    <span class="footer-btn">
      <button nz-button
              nzType="default"
              [nzShape]="!isMobile? 'round' :  'circle'"
              (click)="linkToAngle()"
              *ngIf="canAddAngleToPost"
              [disabled]="postDetails?.locked && loggedInUserId !== postDetails.lockedByUser?.userId || postDetails.postVersionState === 'Draft'">
        <span nz-icon
              nzType="link"
              nzTheme="outline"></span>
        <span *ngIf="!isMobile">Link To Angle</span>
      </button>
    </span>
    <span class="footer-btn edit-btn">
      <button nz-button
              nzType="primary"
              nzShape="round"
              (click)="editRedirect()"
              *ngIf="canEditPost"
              [disabled]="postDetails?.locked && loggedInUserId !== postDetails.lockedByUser?.userId">
        <span nz-icon
              nzType="edit"
              nzTheme="outline"></span>Edit
      </button>
    </span>
  </div>
</ng-template>

<ng-template #postTitleDetailsTemplate>
   <div class="post-title-flex"
       *ngIf="postDetails && postDetails.postTitle">
    <a href="/#/ncx/view-post/:{{postId}}"
       target="_blank"
       class="postTitleDetails"  [innerHTML]="(postDetails.postTitle).trim() | safeHtml"> </a>
  </div>

   <div class="created-updated">
      <div class="created-updated-details">
         <div class="created-updated-label">Created By:</div>
         <div class="created-updated-text">
            <button
               class="created-updated-profile"
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'bottomLeft'">
               {{ Common.formatName(postDetails.createUser) }}
            </button>
            <span class="pl-8">
               {{ utcToLocal(postDetails.createDateTime, 'DATE') }}
            </span>
            <span>at {{ utcToLocal(postDetails.createDateTime, 'TIME') }}</span>
         </div>
         <ng-template #profileCardTemplate>
            <app-profile-overlay [profileData]="postDetails.createUser"></app-profile-overlay>
         </ng-template>
      </div>

      <div
         class="created-updated-details p-l-32"
         *ngIf="postDetails?.updateUser && postDetails.createDateTime !== postDetails.updateDateTime">
         <div class="created-updated-label">Modified By:</div>
         <div class="created-updated-text">
            <button
               class="created-updated-profile"
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'bottomLeft'">
               {{ Common.formatName(postDetails.updateUser) }}
            </button>
            <span class="pd-l-8">
               {{ utcToLocal(postDetails.updateDateTime, 'DATE') }}
            </span>
            <span>at {{ utcToLocal(postDetails.updateDateTime, 'TIME') }}</span>
         </div>
         <ng-template #profileCardTemplate>
            <app-profile-overlay [profileData]="postDetails.updateUser"></app-profile-overlay>
         </ng-template>
      </div>
   </div>
</ng-template>

<nz-dropdown-menu #headerMenus="nzDropdownMenu">
   <div class="drop-menu">
      <div>
         <button
            *ngIf="functionAbility?.fa_post_versions"
            [disabled]="postDetails?.locked && loggedInUserId !== postDetails.lockedByUser?.userId"
            nz-button
            nzType="default"
            class="extraHeaderBtn"
            (click)="showPostVersions()">
            Versions
         </button>
      </div>

      <div>
         <button
            nz-button
            nzType="default"
            class="extraHeaderBtn"
            (click)="viewAsPdf()">
            View as PDF
         </button>
      </div>

      <div>
         <button
            nz-button
            nzType="default"
            class="extraHeaderBtn"
            (click)="toggleSider()">
            View Metadata
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<ng-template #closeIconTpl>
   <span
      nz-icon
      class="close-icon"
      nzType="close"
      nzTheme="outline"></span>
</ng-template>
