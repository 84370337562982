<!-- RESULT COUNT -->
<div
   *ngIf="isLoaded || (total > 0 && !isLoaded)"
   class="result-count">
   {{ resultCount }}
   <button
      *ngIf="searchService.suggestedSearch.show"
      (click)="searchService.performSuggestedSearch()"
      class="suggested-search">
      Did you mean {{ searchService.suggestedSearch.keyword }}?
   </button>
</div>

<app-scroll-container>
   <ng-template bodyTemplate>
      <div
         [ngClass]="{ 'search-result-container': true, 'no-results': !searchResults.length }"
         infiniteScroll
         [infiniteScrollDistance]="2"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         data-scrollable-container
         [attr.data-component]="'ncx/search/page/results/angles'">
         <div class="search-result-body">
            <!-- LOADER -->
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isLoaded"></app-loader-component>

            <!-- CARD TO DISPLAY SEARCH RESULTS FOR ANGLES -->
            <ng-container *ngFor="let angle of searchResults; let index = index">
               <ncx-angle-search
                  [angleDetail]="angle"
                  (viewAngle)="viewAngle($event, index)"></ncx-angle-search>
            </ng-container>

            <div
               class="not-found"
               *ngIf="!searchResults.length && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span>No Angles Found</span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </div>
   </ng-template>
</app-scroll-container>
