import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';
import { FollowGroupUpdateEvent, GroupFollowerResponse } from '@models/ncx/followers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-follow-group',
  templateUrl: './follow-group.component.html',
  styleUrls: ['./follow-group.component.scss']
})
export class FollowGroupComponent {

  isLoaded = true;

  userId: number = 0;

  @Input() groupId: number = 0;

  @Input() isFollowing: boolean = false;

  @Input() followers: number = 0;

  // How should the display look
  @Input() type: 'button' | 'text' = 'button';

  // Show the number of followers
  @Input() showFollowCount: boolean = true;

  // Show the status (Follow|Unfollow)
  @Input() showFollowStatus: boolean = false;

  @Output() updatedFollower: EventEmitter<FollowGroupUpdateEvent> = new EventEmitter();

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) {

    this.userId = Number(localStorage.getItem('userId'));

  }

  changeFollowStatus() {

    if (this.isFollowing) {

      this.unfollow();
    
    } else {

      this.follow();
    
    }

  }

  follow() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('post', environment.getGroupsURL, `/${this.groupId}/groupFollower/${this.userId}/post`).subscribe({

      next: async (data: any) => {

        const response = data as GroupFollowerResponse;

        console.log('Follow Response', response);

        this.isFollowing = true;

        this.updatedFollower.emit({ type: true, groupId: this.groupId, data: response });

        this.toastService.createMessage('success', 'You are now following this group');

      },
      error: (error: any) => {

        console.error('Follow Story', error);

        this.toastService.createMessage('error', 'Error following group. Please try again.');

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  unfollow() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('delete', environment.getGroupsURL, `/${this.groupId}/groupFollower/${this.userId}/delete`).subscribe({

      next: async (data: any) => {

        const response = data as GroupFollowerResponse;

        console.log('UnFollow Response', response);

        this.isFollowing = false;

        this.updatedFollower.emit({ type: false, groupId: this.groupId, data: response });

        this.toastService.createMessage('success', 'You have unfollowed this group');

      },
      error: (error: any) => {

        console.error('Unfollow', error);
        this.toastService.createMessage('error', 'Error unfollowing group. Please try again.');

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  viewFollowers() {

    this.router.navigate(['ncx/group-followers/:' + this.groupId]);

  }

}
