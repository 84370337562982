import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Stories } from '@models/ncx/story';
import { CommonService } from '@services/common-service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'app-generic-template-section',
  templateUrl: './generic-template-section.component.html',
  styleUrls: ['./generic-template-section.component.scss']
})
export class GenericDashboardComponent implements OnInit, OnChanges {

  @Input() dataArray: Stories;

  @Input() dataHeading: string;

  @Input() columnNumber: string;

  @Input() editorsList: string;

  @Input() categoryName: string;

  @Input() hideColumn: boolean;

  @Input() isLoaded: boolean;

  @Input() totalCount: number;

  @Output() goToSlide = new EventEmitter();

  @Output() valueChange = new EventEmitter();

  @Output() openCustomColumnDrawer: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('searchInputField') focusInput: ElementRef<any>;

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  @ViewChild('panel') public panel: ElementRef<any>;

  public readonly Common = Common;

  filteredItems;

  searchText = '';

  searchField = false;

  filterField = false;

  radioValue = 'ALL';

  isVisible = false;

  isSearched = false;

  hideSearchText = true;

  hideSearchClose = false;

  searchInput = '';

  isFollowing = false;

  storyId;

  sortType = 'NEWEST';

  sortByField = 'UPDATEDATE';

  sortOrder = 'DESC';

  getApiURL: string;

  updateColumnApiURL: string;

  pageStartCount: number;

  pageLength: number;

  type: string;

  followerApi: string;

  postID: any;

  totalRecords: number;

  hiddenScrollFlag: boolean;

  userInfoDetails: any;

  pageCode = 'STORY';

  customHomePage = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cService: CommonService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService
  ) { }

  ngOnInit() {

    this.isFollowing = false;
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.getApiURL = environment.getStoriesAPI;
    this.followerApi = environment.storyFollower;
    this.filteredItems = this.dataArray;
    this.totalRecords = 100; // for testing pupose only
    this.hiddenScrollFlag = false;

  }

  ngOnChanges() {

    this.isLoaded = false;

    this.customHomePage = this.cService.customHomePage;

    if (this.dataArray && this.dataArray['length'] > 0) {

      this.filteredItems = this.dataArray;

    }

    // this was disabled because it was causing columns with fewer than 5 records to show duplicate records. --mparillo
    // this.checkCollectionSize(this.dataArray);

    this.totalRecords = this.totalCount; // for testing pupose only
    this.hiddenScrollFlag = true;
    this.cService.stringSubject.subscribe((data: any) => {

      this.filterField = data;

    });

    this.cService.showBanner.subscribe((res) => {

      if (this.cService.customHomePage === true) {

        const nodeList = document.querySelectorAll('[data-custom-layout]');

        if (res === 'both') {

          for (let i = 0; i < nodeList.length; i++) {

            nodeList[i].classList.add('hot-announcement');

          }

        }
        if (res === 'Announcement') {

          for (let i = 0; i < nodeList.length; i++) {

            nodeList[i].classList.add('announcement');

          }

        }
        if (res === 'Hot') {

          for (let i = 0; i < nodeList.length; i++) {

            nodeList[i].classList.add('hot');

          }

        }

      }
      if (this.dataArray !== null) {

        this.isLoaded = true;

      }

    });

  }

  checkCollectionSize(res) {

    if (res && res.length >= 0) {

      // console.log('checkCollectionSize : ', res.length, this.dataHeading, this.totalRecords);
    }
    if (res && (res.length > 0 && res.length < 5)) {

      this.hiddenScrollFlag = true;
      this.onScrollDown();

    }

  }

  private isPSCReachedLastIndex() {

    return ((this.pageStartCount * this.pageLength) >= this.totalRecords);

  }

  formatHeaderIcons() {

    return (window.innerWidth < 1200);

  }

  searchStory(val) {

    if (this.searchInput !== '') {

      this.resetFilters();
      this.searchText = val;
      this.getNextStories(true);

    }

  }

  navigateSlide(slide) {

    this.goToSlide.emit(slide);

  }

  filterItems(event, _val) {

    setTimeout(() => {

      if (!(this.searchInput === '')) {

        this.isSearched = true;

      }

    }, 200);
    this.assignType();
    if (!event.target.value) {

      this.assignCopy();

    }
    if (event.keyCode === 13) {

      this.searchText = event.target.value;
      this.resetFilters();
      this.getNextStories(true);

    }

  }

  resetFilters() {

    this.pageStartCount = 1;
    this.filteredItems = [];

  }

  searchEvent(_val: any) {

    this.hideSearchClose = false;
    this.hideSearchText = true;
    this.toggleSearch();
    this.searchInput = '';
    this.searchText = '';
    this.isSearched = false;
    this.resetFilters();
    this.getNextStories(true);

  }

  assignType() {

    if (this.dataHeading === 'Submitted') {

      this.type = 'SUBMITTED';

    } else if (this.dataHeading === 'Working') {

      this.type = 'WORKING';

    } else {

      this.type = 'READY';

    }

  }

  assignCopy() {

    this.filteredItems = Object.assign([], this.dataArray);

  }

  onScrollDown() {

    this.pageStartCount += this.pageLength;
    this.assignType();
    this.getNextStories(false);

  }

  toggleSearch() {

    this.searchField = !this.searchField;
    setTimeout(() => {

      if (this.searchField) {

        this.focusInput.nativeElement.focus();

      }

    }, 200);

  }

  filter(colNumber, categoryName) {

    this.filterField = !this.filterField;
    this.cService.columnNumber = colNumber;
    this.cService.categoryDetails.next(categoryName);

    this.openCustomColumnDrawer.emit(colNumber);

  }

  unfliter(colNumber, categoryName) {

    this.filterField = !this.filterField;
    this.openCustomColumnDrawer.emit(colNumber);
    this.cService.columnNumber = colNumber;
    this.cService.categoryDetails.next(categoryName);

  }

  addFilters(type) {

    this.sortType = type;
    this.pageStartCount = 1;
    this.assignType();
    this.filteredItems = [];
    if (type === 'NEWEST') {

      this.sortOrder = 'DESC';
      this.pageCode = 'STORY';

    } else if (type === 'OLDEST') {

      this.pageCode = 'STORY';
      this.sortOrder = 'ASC';

    } else {

      this.sortOrder = 'TRENDING';
      this.pageCode = 'STORY_TRENDING';

    }

    // console.log ('Child value : ' + type);
    this.getNextStories(false);

  }

  toggleFollowing(type) {

    this.pageStartCount = 1;
    this.assignType();
    this.filteredItems = [];
    if (type === 'ALL') {

      this.isFollowing = false;

    } else {

      this.isFollowing = true;

    }

    // console.log ('Child value : ' + type);
    this.getNextStories(false);

  }

  hideReadyWorkingColumn(heading) {

    this.isLoaded = false;
    const userId = localStorage.getItem('userId');

    const queryStr = `/${userId}`;

    this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr).subscribe((res: any) => {

      const response = res;

      let sortedArray;

      let queryStr1;

      response.personalizedColumns.forEach((element, index) => {

        // check.push(element.hideColumn);
        if (element.columnName === heading) {

          sortedArray = response.personalizedColumns.filter(x => x.columnName === heading);
          if (!response.personalizedColumns[index].hideColumn) {

            queryStr1 = `/${userId}/userPersonalizedColumn?hideColumn=true&` +
              `leftPosition=${sortedArray[0].columnNumber}`;

          } else {

            queryStr1 = `/${userId}/userPersonalizedColumn?hideColumn=true&` +
              `leftPosition=${sortedArray[0].columnNumber}`;

          }
          response.personalizedColumns[index].hideColumn = !response.personalizedColumns[index].hideColumn;

        }

      });

      // sortedArray[0].hideColumn = true;
      this.cService.serviceRequestCommon('put', environment.getProfileAPIURL, queryStr, response).subscribe(() => {

        const body = {};

        // const queryStr1 = `/${userId}/userPersonalizedColumn?isColumnExchange=true&` +
        // `leftPosition=${sortedArray[0].columnNumber}&rightPosition=${res.personalizedColumns.length}`;
        this.cService.serviceRequestCommon('put', environment.getProfileAPIURL, queryStr1, body).subscribe(() => {

          setTimeout(() => {

            this.cService.clearAddColumnData.next('true');
            this.cService.UpdateDashboard.next('true');

          }, 0);

        });

      });

    });

  }

  showColumn(heading) {

    const userId = localStorage.getItem('userId');

    const queryStr = `/${userId}`;

    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr).subscribe((res: any) => {

      const response = res;

      const sortedArray = response.personalizedColumns.filter(x => x.columnName === heading);

      sortedArray[0].hideColumn = false;
      this.cService.serviceRequestCommon('put', environment.getProfileAPIURL, queryStr, response).subscribe(() => {

        this.cService.clearAddColumnData.next('true');
        this.cService.UpdateDashboard.next('true');

      });

    }).add(() => {

      this.isLoaded = true;

    });

  }

  calculateDiff(apiDate) {

    apiDate = Time.convertingUtcToLocal(apiDate);
    if (apiDate) {

      const seconds = Math.floor((+new Date() - +new Date(apiDate)) / 1000);

      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'

        return 'Just now';

      }
      const intervals = {
        yr: 31536000,
        mo: 2592000,
        wk: 604800,
        day: 86400,
        hr: 3600,
        min: 60,
        sec: 1
      };

      let counter;

      // tslint:disable-next-line: forin
      for (const i in intervals) {

        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {

          if (counter === 1) {

            return counter + ' ' + i; // singular (1 day ago)

          } else {

            return counter + ' ' + i + 's'; // plural (2 days ago)

          }

        }

      }

    }

  }

  createQueryStr(isSearched) {

    let queryStr;

    if (this.pageLength === undefined) {

      this.pageLength = 10;

    }

    // tslint:disable-next-line:use-isnan
    if (this.pageStartCount.toString() === 'NaN' || this.pageStartCount === undefined) {

      this.pageStartCount = 1;

    }
    queryStr = `?state=${(this.type).toUpperCase()}&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;
    if (isSearched) {

      this.pageStartCount = 1;
      this.pageLength = 10;
      this.filteredItems = [];

      // queryStr = `?state=${this.type}&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;

    }
    if (this.sortOrder === 'ASC') {

      queryStr += `&sortByField=${this.sortByField}&sortOrder=${this.sortOrder}`;

    }
    if (this.sortOrder === 'TRENDING') {

      queryStr += `&sortByField=rank&sortOrder=${this.sortOrder}`;
      this.gaService.trackTrendingAction('Trending_' + this.type);

    }
    if (this.radioValue === 'FOLLOWING') {

      queryStr += '&isFollowing=true';

    }
    if (this.searchText !== '') {

      queryStr += `&searchText=${encodeURIComponent(this.searchText)}`;

    }
    return queryStr;

  }

  getNextStories(isSearched) {

    this.isLoaded = false; // show loader only when actuall scroll happen

    const queryStr = this.createQueryStr(isSearched);

    if (this.categoryName === 'Activity') {

      const userId = localStorage.getItem('userId');

      const queryStr1 = `/${userId}`;

      this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr1).subscribe((res1: any) => {

        const response1 = res1;

        const sortedArray1 = response1.personalizedColumns.filter(x => x.columnName === this.dataHeading);

        let sotryIds = '';

        if (sortedArray1[0].storyIds !== null && sortedArray1[0].storyIds !== undefined) {

          sotryIds = sortedArray1[0].storyIds.toString();

        }
        let topics = '';

        if (sortedArray1[0].topics !== null && sortedArray1[0].topics !== undefined) {

          topics = sortedArray1[0].topics.toString();

        }
        const postType = sortedArray1[0].postType === null ? '' : sortedArray1[0].postType;

        const selectedLables = sortedArray1[0].labels === null ? '' : sortedArray1[0].labels;

        const tags = sortedArray1[0].tags === null ? '' : sortedArray1[0].tags;

        const queryStr2 = `?ids=${sotryIds}&tags=${tags}&labels=${selectedLables}&postType=${postType}&contentPage=${this.pageStartCount}&topics=${topics}&searchString=${this.searchText}`;

        this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((res: any) => {

          if (res && res.totalCount) {

            this.totalRecords = res.totalCount;

          }
          if (res && res.stories && (res.stories.length > 0 && res.stories.length < 5)) {

            res = res.stories;
            this.filteredItems = [...this.filteredItems, ...res];
            const uniqueArray = this.filteredItems.filter((value, index) => {

              const _value = value;

              return index === this.filteredItems.findIndex(obj => {

                return obj.storyId === _value.storyId && obj.storyPosts[0].postId.toString() ===
                  _value.storyPosts[0].postId.toString();

              });

            });

            this.filteredItems = uniqueArray;

            // this.checkCollectionSize(res);
            this.isLoaded = true;
            this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });
            return;

          }
          if (res && res.stories && res.stories.length > 0) {

            res = res.stories;
            this.filteredItems = [...this.filteredItems, ...res];

          } else {

            this.pageStartCount -= this.pageLength;

          }
          if (isSearched && res && res.stories && res.stories.length > 0) {

            this.filteredItems = [...res];

          } else if (isSearched && res && res.stories && res.stories.length === 0) {

            this.filteredItems = [];

          }
          this.isLoaded = true;
          this.hiddenScrollFlag = true;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

        }, () => {

          this.isLoaded = true;
          this.hiddenScrollFlag = false;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

        });

      });

    } else if (this.categoryName === 'Group') {

      const userId = localStorage.getItem('userId');

      const queryStr1 = `/${userId}`;

      this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr1).subscribe((res1: any) => {

        const response1 = res1;

        const sortedArray1 = response1.personalizedColumns.filter(x => x.columnName === this.dataHeading);

        const groupIds = sortedArray1[0].groupIds.toString();

        const queryStr2 = `?ids=${groupIds}&searchString=${this.searchText}&isStory=false&idType=group&contentPage=${this.pageStartCount}&isDiscussion=true`;

        this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((res: any) => {

          if (res && res.totalCount) {

            this.totalRecords = res.totalCount;

          }
          if (res && res.discussions && res.discussions.length > 0) {

            res = res.discussions;
            this.filteredItems = [...this.filteredItems, ...res]; // [...this.filteredItems, ...res];
            // this.checkCollectionSize(res);
            this.isLoaded = true;
            this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });
            return;

          }
          if (res && res.stories && res.stories.length > 0) {

            res = res.stories;
            this.filteredItems = [...this.filteredItems, ...res];

          } else {

            this.pageStartCount -= this.pageLength;

          }
          if (isSearched && res && res.stories && res.stories.length > 0) {

            this.filteredItems = [...res];

          } else if (isSearched && res && res.stories && res.stories.length === 0) {

            this.filteredItems = [];

          }
          this.isLoaded = true;
          this.hiddenScrollFlag = true;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

        }, () => {

          this.isLoaded = true;
          this.hiddenScrollFlag = false;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

        });

      });

    } else if (this.categoryName === 'Person') {

      const userId = localStorage.getItem('userId');

      const queryStr1 = `/${userId}`;

      this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr1).subscribe((res1: any) => {

        const response1 = res1;

        const sortedArray1 = response1.personalizedColumns.filter(x => x.columnName === this.dataHeading);

        const personIds = sortedArray1[0].personIds.toString();

        const queryStr2 = `?searchString=${this.searchText}&createdBySSOIds=${personIds}&isCreatedBy=true&contentPage=${this.pageStartCount}`;

        this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((res: any) => {

          if (res && res.totalCount) {

            this.totalRecords = res.totalCount;

          }
          if (res && res.stories && (res.stories.length > 0 && res.stories.length < 5)) {

            res = res.stories;
            this.filteredItems = [...this.filteredItems, ...res];

            const uniqueArray = this.filteredItems.filter((value, index) => {

              const _value = value;

              return index === this.filteredItems.findIndex(obj => {

                return obj.storyId === _value.storyId;

              });

            });

            this.filteredItems = uniqueArray;

            // this.checkCollectionSize(res);
            this.isLoaded = true;
            this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });
            return;

          }
          if (res && res.stories && res.stories.length > 0) {

            res = res.stories;
            this.filteredItems = [...this.filteredItems, ...res];

          } else {

            this.pageStartCount -= this.pageLength;

          }
          if (isSearched && res && res.stories && res.stories.length > 0) {

            this.filteredItems = [...res];

          } else if (isSearched && res && res.stories && res.stories.length === 0) {

            this.filteredItems = [];

          }
          this.isLoaded = true;
          this.hiddenScrollFlag = true;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

        }, () => {

          this.isLoaded = true;
          this.hiddenScrollFlag = false;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

        });

      });

    } else {

      this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

        console.log(res);

        // res = res.stories;
        if (res && res.totalCount) {

          this.totalRecords = res.totalCount;

        }
        if (res && res.stories && (res.stories.length > 0 && res.stories.length < 5)) {

          res = res.stories;
          this.filteredItems = [...this.filteredItems, ...res];

          // this.checkCollectionSize(res);
          this.isLoaded = true;
          this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });
          return;

        }
        if (res && res.stories && res.stories.length > 0) {

          res = res.stories;
          this.filteredItems = [...this.filteredItems, ...res];

        } else {

          this.pageStartCount -= this.pageLength;

        }
        if (isSearched && res && res.stories && res.stories.length > 0) {

          this.filteredItems = [...res];

        } else if (isSearched && res && res.stories && res.stories.length === 0) {

          this.filteredItems = [];

        }

        // this.infiniteScroll.ngOnDestroy();
        // this.infiniteScroll.setup();
        this.isLoaded = true;
        this.hiddenScrollFlag = true;
        this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

      }, () => {

        this.isLoaded = true;
        this.hiddenScrollFlag = false;
        this.valueChange.emit({ filters: this.pageCode + '|' + this.type + '|' + this.radioValue, resp: this.filteredItems });

      });

    }

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  get searchPlaceholder(): string {

    return `Search the "${this.dataHeading}" column`;

  }

}
