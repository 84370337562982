<span
   class="custom-popover"
   id="triggerClick1"
   nzPopoverTrigger="click"
   nz-popover
   [nzPopoverContent]="profileCardTemplateCustom"
   [nzPopoverPlacement]="'topLeft'"></span>
<ng-template
   cdkConnectedOverlay
   #profileCardTemplateCustom>
   <app-profile-overlay [profileData]="customPopOverUserId"></app-profile-overlay>
</ng-template>
<nz-card *ngIf="functionAbility.fa_create_reply">
   <div class="commentsSection">
      <nz-comment>
         <nz-avatar
            nz-comment-avatar
            nzIcon="user"
            nzSrc="{{ Common.formS3Link(avtarImgLink) }}"></nz-avatar>
         <nz-comment-content>
            <nz-form-item>
               <app-rteditor
                  [rteHeight]="100"
                  [isEmitted]="isEmitted"
                  [rteValue]="rteData"
                  (rteUpdated)="getRTEData($event)"
                  (gFlag)="checkGoogleFlag($event)"
                  [googleDocs]="googleDocs"
                  [isrepliesScreen]="true"
                  [operationType]="operationType"
                  [rteValue]="replyContent"></app-rteditor>
            </nz-form-item>
            <nz-form-item>
               <button
                  id="rteCustomLink"
                  style="float: right"
                  nz-button
                  nzType="primary"
                  (click)="handleSubmit()">
                  {{ buttonLabel }}
               </button>
            </nz-form-item>
         </nz-comment-content>
      </nz-comment>
   </div>
</nz-card>
<app-google-docs
   [isVisible]="isVisible"
   (docLinkUpdated)="getDocLink($event)"></app-google-docs>
<app-embedly
   [isEmbedlyVisible]="isEmbedlyVisible"
   (embedlyUpdated)="getEmbedlyData($event)"></app-embedly>
<div
   class="replies-list"
   *ngIf="postVersionGroupId"
   [ngStyle]="checkScreen()">
   <div *ngFor="let index of data">
      <nz-card>
         <nz-card-meta
            [nzAvatar]="avatarTemplate"
            [nzTitle]="titleReference"
            [nzDescription]="descriptionReference"></nz-card-meta>
      </nz-card>
      <ng-template #avatarTemplate>
         <nz-avatar
            (click)="goToProfile(index.userId)"
            [nzPopoverContent]="profileCardTemplate1"
            nz-popover
            [nzPopoverPlacement]="'right'"
            nzSrc="{{ Common.formS3Link(index.userId.profilePictureURL) }}"></nz-avatar>
         <ng-template #profileCardTemplate1>
            <app-profile-overlay [profileData]="index.userId"></app-profile-overlay>
         </ng-template>
      </ng-template>
      <ng-template #titleReference>
         <span
            (click)="goToProfile(index.userId)"
            *ngIf="index.userId.displayName.split(',').length > 1"
            [nzPopoverContent]="profileCardTemplate1"
            nz-popover
            [nzPopoverPlacement]="'right'"
            [innerHTML]="
               (index.userId
                  ? index.userId.displayName.split(',')[1].trim() + ' ' + index.userId.displayName.split(',')[0]
                  : ''
               ) | safeHtml
            "></span>
         <span
            (click)="goToProfile(index.userId)"
            *ngIf="index.userId.displayName.split(',').length === 1"
            [nzPopoverContent]="profileCardTemplate1"
            nz-popover
            [nzPopoverPlacement]="'right'"
            [innerHTML]="(index.userId ? index.userId.displayName.trim() : '') | safeHtml"></span>
         <ng-template #profileCardTemplate1>
            <app-profile-overlay [profileData]="index.userId"></app-profile-overlay>
         </ng-template>
         <span style="float: right">
            <i
               nz-icon
               nzType="delete"
               nzTheme="fill"
               (click)="deletePostReply(index.replyId)"
               class="deleteIcon"
               *ngIf="index.delete"></i>
            <nz-divider nzType="vertical"></nz-divider>
            <i
               nz-icon
               nzType="edit"
               nzTheme="fill"
               (click)="updateReply(index, index.replyId)"
               class="editIcon"
               *ngIf="index.edit"></i>
         </span>
      </ng-template>

      <ng-template #descriptionReference>
         <p [innerHTML]="index.replyContent | safeHtml"></p>
      </ng-template>
   </div>
</div>
<div
   class="replies-list"
   *ngIf="discussionVersionGroupId"
   [ngStyle]="checkScreen()">
   <div *ngFor="let index of data">
      <nz-card>
         <nz-card-meta
            [nzAvatar]="avatarTemplate"
            [nzTitle]="titleReference"
            [nzDescription]="descriptionReference"></nz-card-meta>
      </nz-card>
      <ng-template #avatarTemplate>
         <nz-avatar
            (click)="goToProfile(index.userId)"
            [nzPopoverContent]="profileCardTemplate1"
            nz-popover
            [nzPopoverPlacement]="'right'"
            nzSrc="{{ Common.formS3Link(index.userId.profilePictureURL) }}"></nz-avatar>
         <ng-template #profileCardTemplate1>
            <app-profile-overlay [profileData]="index.userId"></app-profile-overlay>
         </ng-template>
      </ng-template>
      <ng-template #titleReference>
         <span
            (click)="goToProfile(index.userId)"
            *ngIf="index.userId.displayName.split(',').length > 1"
            [nzPopoverContent]="profileCardTemplate1"
            nz-popover
            [nzPopoverPlacement]="'right'"
            [innerHTML]="
               (index.userId
                  ? index.userId.displayName.split(',')[1].trim() + ' ' + index.userId.displayName.split(',')[0]
                  : ''
               ) | safeHtml
            "></span>
         <span
            (click)="goToProfile(index.userId)"
            *ngIf="index.userId.displayName.split(',').length === 1"
            [nzPopoverContent]="profileCardTemplate1"
            nz-popover
            [nzPopoverPlacement]="'right'"
            [innerHTML]="(index.userId ? index.userId.displayName.trim() : '') | safeHtml"></span>
         <ng-template #profileCardTemplate1>
            <app-profile-overlay [profileData]="index.userId"></app-profile-overlay>
         </ng-template>
         <span style="float: right">
            <i
               nz-icon
               nzType="delete"
               nzTheme="fill"
               (click)="deletePostReply(index.commentId)"
               class="deleteIcon"
               *ngIf="isEditable(index.userId.userId)"></i>
            <nz-divider nzType="vertical"></nz-divider>
            <i
               nz-icon
               nzType="edit"
               nzTheme="fill"
               (click)="updateReply(index, index.commentId)"
               class="editIcon"
               *ngIf="isEditable(index.userId.userId)"></i>
         </span>
      </ng-template>

      <ng-template #descriptionReference>
         <p [innerHTML]="index.commentContent | safeHtml"></p>
      </ng-template>
   </div>
</div>
