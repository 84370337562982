import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-merge-stories',
  templateUrl: './merge-stories.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class MergeStoriesComponent implements OnInit, OnChanges {

  @Input() isMergeStoryVisible;

  @Input() sourceStoryId;

  @Output() closeMergeStory: EventEmitter<any> = new EventEmitter<any>();

  destinationStory = '';

  destinationObject;

  value = '';

  mergeStoryApi;

  getStoryTitlesAPI;

  listofStories = [];

  filteredOptions: string[] = [];

  filteredOptionsInit: any[];

  subject: Subject<string> = new Subject();

  isLoaded = true;

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.getStoryTitlesList(searchTextValue);

    });

  }

  ngOnInit() {

    this.mergeStoryApi = environment.getStoriesAPI;
    this.getStoryTitlesAPI = environment.getStoryTitles;

  }

  ngOnChanges() {

    console.log('Source Story Id ', this.sourceStoryId);

  }

  titleMatch(value) {

    const match = this.listofStories.filter(story => story.storyTitle === value);

    if (!match.length) {

      return;

    }

    const obj = match[0];

    if ((!(obj === null || obj === undefined) || (this.sourceStoryId === obj.storyId))) {

      this.destinationStory = obj.storyTitle;
      this.destinationObject = obj;
      setTimeout(() => {

        this.value = '';

      }, 100);

    }
    if (this.filteredOptionsInit && this.filteredOptionsInit.length) {

      this.filteredOptions = [...this.filteredOptionsInit];

    }

  }

  getStoryTitlesList(queryStr?) {

    let params;

    if (queryStr) {

      params = `?storyTitle=${queryStr}`;

    } else {

      this.filteredOptions = [];
      return;

    }
    this.cService.serviceRequestCommon('get', this.getStoryTitlesAPI, params).subscribe((data: any) => {

      const tempStoiesObj = data.map((story) => {

        return {
          storyId: story.storyId,
          storyTitle: story.storyTitle,
          isPrimary: null,
          isPinnedOnTop: null
        };

      });

      this.listofStories = JSON.parse(JSON.stringify(tempStoiesObj));
      this.filteredOptions = this.listofStories.map((story) => story.storyTitle); //
      this.filteredOptionsInit = this.listofStories.map((story) => story.storyTitle);

    });

  }

  handleCancel() {

    this.destinationStory = '';
    this.value = '';
    this.destinationObject = null;
    this.closeMergeStory.emit(true);

  }

  delete() {

    this.destinationStory = '';
    this.destinationObject = null;

  }

  handleOk() {

    if (!this.destinationObject) {

      this.toastService.createMessage('warning', 'Please select the destination story.');

    }
    if (Number(this.sourceStoryId) === this.destinationObject.storyId) {

      this.toastService.createMessage('warning', 'Destination story cannot be same as originating story.');
      return false;

    }
    this.isLoaded = false;
    const queryStr = `/${this.sourceStoryId}/` + 'merge/' + this.destinationObject.storyId;

    this.cService.serviceRequestCommon('patch', this.mergeStoryApi, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'The story has been successfully merged.');
      this.closeMergeStory.emit(true);
      this.destinationStory = '';
      this.router.navigate(['ncx/landing-story/:' + this.destinationObject.storyId]);

    }, () => {

      this.toastService.createMessage('error', 'Error in merging story. Please try again');
      this.closeMergeStory.emit(true);
      this.destinationStory = '';

    }).add(() => {

      this.isLoaded = true;

    });

  }

  onChange(value: string, event: any): void {

    setTimeout(() => {

      this.subject.next(event.target.value);

    }, 200);

  }

}
