<div class="feeds-filter-layout">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Filter</span>
      <button
         class="close"
         (click)="close()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>

   <nz-divider style="margin: 0"></nz-divider>

   <section>
      <p class="header">
         <i
            nz-icon
            nzType="file"
            nzTheme="outline"></i>
         Feed Type
      </p>
      <div class="radio-list">
         <nz-radio-group [(ngModel)]="radioFeedTypeValue">
            <label
               (click)="addFilters(item)"
               nz-radio
               [nzValue]="item.value"
               *ngFor="let item of feedType">
               {{ item.label }}
            </label>
         </nz-radio-group>
      </div>
   </section>

   <section>
      <p class="header">
         <i
            nz-icon
            nzType="flag"
            nzTheme="outline"></i>
         Order By
      </p>
      <div class="radio-list">
         <nz-radio-group [(ngModel)]="radioFeedOrderValue">
            <label
               (click)="orderFilter(item)"
               nz-radio
               [nzValue]="item.value"
               *ngFor="let item of orderedBy">
               {{ item.label }}
            </label>
         </nz-radio-group>
      </div>
   </section>

   <div class="footer">
      <button
         nzType="default"
         nz-button
         nzBlock
         (click)="clear()">
         Clear
      </button>
   </div>
</div>
