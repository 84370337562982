import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';
import { TransferInformationComponent } from '../../TransferInformation/TransferInformation.component';
import { IFunctionAbility, User } from '@models/users';
import { Comment } from '@models/ncx/comment';
import { AppConstants } from '@constants/app.constants';
import { Common } from '@utilities/common';
import { Sort } from '@utilities/sort';

@Component({
  selector: 'app-text-comments',
  templateUrl: './text-comments.component.html',
  styleUrls: ['./text-comments.component.scss']
})
export class TextCommentsComponent implements OnInit, OnChanges {

  // If viewing a post
  @Input() postVersionGroupId;

  // If viewing a dicussion group
  @Input() discussionVersionGroupId;

  // All comments for post/discussion
  comments: Comment[] = [];

  // New comment text
  newComment: string;

  // comment being edited
  editing: Comment = {} as Comment;

  // Loading indicator
  isLoaded: boolean;

  // API URLs depending on whether it's a comment for a Discussion or a Post
  getApiURL: string;

  getReplyURL: any;

  functionAbility = {} as IFunctionAbility;

  @ViewChild('editTextarea') editTextarea!: ElementRef;

  public readonly Common = Common;

  // public editDeleteReply = false;
  constructor(
    private cService: CommonService,
    private router: Router,
    private toastService: ToastService,
    private el: ElementRef,
    private userInfo: TransferInformationComponent
  ) {}

  ngOnInit() {

    this.getApiURL = environment.getPostApi;
    this.getReplyURL = environment.getRepliesURL;

    this.functionAbility = { ...this.userInfo.userFunctionAbility } as IFunctionAbility;

  }

  ngOnChanges() {

    this.comments = [];

    if (this.postVersionGroupId !== null && this.postVersionGroupId !== undefined) {

      this.getApiURL = environment.getPostApi;
      this.getReplyURL = environment.getRepliesURL;
      this.getComments();
      this.clearEditor();
    
    }

    if (this.discussionVersionGroupId !== null && this.discussionVersionGroupId !== undefined) {

      this.getApiURL = environment.getDiscussionURL;
      this.getReplyURL = environment.getDiscussionURL;
      this.getComments();
      this.clearEditor();
    
    }

  }

  /**
   * Validate both Update and New comments
   *
   */
  handleSubmit(): void {

    if (!this.hasNewComment && !this.hasUpdatedComment) {

      this.toastService.createMessage('warning', 'Please provide a valid comment');
      return;
    
    }

    this.saveOrUpdateComment();

  }

  /**
   * Logic determines how to call API call on a new
   * or existing comment
   *
   */
  saveOrUpdateComment() {

    if (this.editing.replyId) {

      if (this.postVersionGroupId) {

        this.updateComment({
          replyContent: this.editing.replyContent.trim(),
          postVersionGroupId: this.postVersionGroupId
        }, this.editing.replyId);
      
      }

      if (this.discussionVersionGroupId) {

        this.updateComment({
          commentContent: this.editing.replyContent.trim(),
          discussionGroupId: this.discussionVersionGroupId
        }, this.editing.replyId);
      
      }

    } else {

      if (this.postVersionGroupId) {

        this.saveComment({
          replyContent: this.newComment.trim(),
          postVersionGroupId: this.postVersionGroupId
        });
      
      }

      if (this.discussionVersionGroupId) {

        this.saveComment({
          commentContent: this.newComment.trim(),
          discussionGroupId: this.discussionVersionGroupId
        });

      }

    }

  }

  /**
   * Get list of all comments
   *
   */
  getComments() {

    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${this.postVersionGroupId}/reply`;
    
    } else if (this.discussionVersionGroupId) {

      queryStr = `/${this.discussionVersionGroupId}/comments`;
    
    }

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((comments: any) => {

      if (comments && comments.length) {


        // Filter out any deleted comments and make sure the list is sorted with the first comment at
        // the top, followed by later comments in order they were created
        this.comments = [...comments].filter((comment: Comment) => !comment.isDeleted).
          sort((a: Comment, b: Comment) => Sort.numeric(+a.createDateTime, +b.createDateTime));
      
      }

    }, (error: any) => {

      console.error('Get Post Comments', { error });
      this.toastService.createMessage('error', 'Error while retrieving comments.  Please try again.');
    
    }).add(() => {

      this.isLoaded = true;
    
    });

  }

  /**
   * Save NEW comment
   *
   */
  saveComment(payload) {

    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${this.postVersionGroupId}/reply`;
    
    } else if (this.discussionVersionGroupId) {

      queryStr = `/${this.discussionVersionGroupId}/comment`;
    
    }

    console.log('Save Comment', { payload });

    this.isLoaded = false;

    this.cService.serviceRequestCommon('post', this.getApiURL, queryStr, payload).subscribe(() => {

      this.clearEditor();
      this.getComments();
      this.toastService.createMessage('success', 'The comment was successfully added');
    
    }, (error: any) => {

      console.error('Save Comment', { error });
      this.toastService.createMessage('error', 'Error saving comment. Please try again.');
    
    }).add(() => {

      this.isLoaded = true;
    
    });

  }

  /**
   * Update EXISTING comment
   *
   */
  updateComment(payload, id) {

    this.isLoaded = false;

    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${id}`;
    
    } else if (this.discussionVersionGroupId) {

      queryStr = `/comment/${id}`;
    
    }

    console.log('Update Comment', { id, payload });

    this.cService.serviceRequestCommon('put', this.getReplyURL, queryStr, payload).subscribe(() => {

      this.clearEditor();
      this.getComments();
      this.toastService.createMessage('success', 'The comment was successfully updated');
    
    }, (error: any) => {

      console.error('Update Comment', { error });
      this.toastService.createMessage('error', 'Error updating comment.  Please try again.');
    
    }).add(() => {

      this.isLoaded = true;
    
    });

  }

  /**
   * Delete Comment
   *
   */
  deleteComment(id) {

    this.isLoaded = false;

    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${id}`;
    
    } else if (this.discussionVersionGroupId) {

      queryStr = `/comment/${id}`;
    
    }

    console.log('Delete Comment', { id });

    this.cService.serviceRequestCommon('delete', this.getReplyURL, queryStr).subscribe(() => {

      this.clearEditor();
      this.getComments();
      this.toastService.createMessage('success', 'The comment was successfully deleted');
    
    }, (error: any) => {

      console.error('Delete Comment', { error });
      this.toastService.createMessage('error', 'Error deleting comment. Please try again.');
    
    }).add(() => {

      this.isLoaded = true;
    
    });

  }

  /**
   * Place an existing comment into Edit mode
   *
   */
  editComment(comment: Comment) {

    this.editing = { ...comment };

    this.newComment = '';

    setTimeout(() => {

      if (this.editTextarea) {

        this.editTextarea.nativeElement.focus();
      
      }

    }, 500);

  }

  /**
   * Clear editing an existing comment
   *
   */
  clearEditor() {

    this.editing = {} as Comment;

    this.newComment = '';

  }

  goToProfile(user) {

    this.router.navigate(['ncx/profile/:' + user.userId]);

  }

  canEdit(user: User): boolean {

    let editReply = this.functionAbility.fa_edit_reply;

    if (this.userInfo.userInfoDetails.role.toLowerCase() === AppConstants.userRoleCheck) {

      editReply = +user.userId === +this.userInfo.userInfoDetails.userId;
    
    }

    return editReply;

  }

  canDelete(user: User): boolean {

    let deleteReply = this.functionAbility.fa_delete_reply;

    if (this.userInfo.userInfoDetails.role.toLowerCase() === AppConstants.userRoleCheck) {

      deleteReply = +user.userId === +this.userInfo.userInfoDetails.userId;
    
    }

    return deleteReply;

  }

  /**
   * Name of person who made the comment
   *
   */
  commentName(user: User): string {

    return Common.formatName(user);

  }

  isEditing(comment: Comment): boolean {

    return this.editing.replyId === comment.replyId;

  }

  get hasNewComment(): boolean {

    return !!this.newComment.match(/\w+/);

  }

  get hasUpdatedComment(): boolean {

    return this.editing.replyId && this.editing.replyContent && !!this.editing.replyContent.match(/\w+/);

  }

}


