<nz-layout class="invalidLayout"
           style="height: 100vh;"
           [attr.data-component]="'app/components/disabled-user'">
	<nz-empty [nzNotFoundContent]="contentTpl">
		<ng-template #contentTpl>
			<div style="padding-top: 24px;">
				Hi, <br>
				Your NewsConnect account must be enabled.
				Please email <a href="mailto:newsconnecthelp@nbcuni.com"
				                class="link">newsconnecthelp&#64;nbcuni.com </a><span nz-icon
				                                                                  nzType="mail"
				                                                                  nzTheme="outline"></span> to request access
				<br>
				Please include: Name, SSO, Email Address, and Access Level (Journalist, Editor, Sr. Editor, Standards)
			</div>
		</ng-template>
	</nz-empty>
</nz-layout>
