<div
   *ngIf="show"
   class="banner">
   <div class="info">
      <i
         nz-icon
         class="icon round"
         nzType="info"
         nzTheme="outline"></i>

      <p>
         This is an archived version of the document. The current version can be viewed
         <a href="#{{ archiveUrl }}">here.</a>
      </p>
   </div>

   <button
      (click)="close()"
      class="close">
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"></i>
   </button>
</div>
