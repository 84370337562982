import { Pipe, PipeTransform } from '@angular/core';
import { Time } from '@utilities/time';

@Pipe({ name: 'utcToLocal' })
export class UtcToLocalPipe implements PipeTransform {

  transform(value: any, type: string): string {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(value);
    
    } else {

      return Time.convertingUtcToLocalTime(value);
    
    }
  
  }

}
