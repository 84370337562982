import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppJsonDataService {

  private NCX_MENU_JSON_URL: string = '../assets/jsons/ncxJsonStore.json?v=' + Math.random();

  constructor(
    private http: HttpClient
  ) { }

  public getNcxMenuJson() {

    return this.http.get(this.NCX_MENU_JSON_URL);
  
  }
  
}
