import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
})
export class SupportPageComponent {

  tabIndex = 0;

  tabs = ['faq', 'training', 'versions'];

  constructor(
    private activeRoute: ActivatedRoute,
    private location: Location,
  ) {

    this.activeRoute.params.subscribe((params: { [key: string]: string }) => {

      if ((params['tab'] || '').match(/^(faq|training|versions)$/i)) {

        this.tabIndex = this.tabs.indexOf(params['tab']);

      }

    });

    this.location.replaceState(`/ncx/support/${this.tabs[this.tabIndex]}`);

  }

  /**
   * Update the URL when the tab changes
   *
   */
  onChangeTab(event: NzTabChangeEvent) {

    const index = event.index;

    this.location.replaceState(`/ncx/support/${this.tabs[index]}`);

  }

}
