import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common-service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-other-tools',
  templateUrl: './other-tools.component.html',
  styleUrls: ['./other-tools.component.scss']
})
export class OtherToolsComponent implements OnInit {

  otherToolsApi;

  productionDetails;

  requestDetails;

  monitoringDetails;

  infoDetails;

  integrationDetails;

  otherToolsLandingDetails;

  constructor(
    private cService: CommonService
  ) { }

  ngOnInit() {

    this.otherToolsApi = environment.otherToolsData;
    this.getOtherToolsData();

  }

  getOtherToolsData() {

    this.cService.serviceRequestCommon('get', this.otherToolsApi).subscribe(res => {

      console.log('Data of tools', res);
      this.otherToolsLandingDetails = JSON.parse(JSON.stringify(res));
      this.productionDetails = this.otherToolsLandingDetails.data.production;
      this.requestDetails = this.otherToolsLandingDetails.data.requests;
      this.monitoringDetails = this.otherToolsLandingDetails.data.monitoring;
      this.infoDetails = this.otherToolsLandingDetails.data.info;
      this.integrationDetails = this.otherToolsLandingDetails.data.integrations;
      console.log('Data of Productions', this.productionDetails);

    });

  }

}
