import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdditionalTopicNewComponent } from '@components/additional-topic-new/additional-topic-new.component';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { Stories } from '@models/ncx/story';
import { IFunctionAbility } from '@models/users';
import { AuthService } from '@services/auth/auth.service';
import { BannerSessionResizeObserverService } from '@services/banner-session-resize-observer.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Observable, Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


/**
 * Component for creating a new story.
 * This component handles the creation and editing of stories, including setting the story state, access, title, topics, subject, and description.
 * It also provides functionality for saving drafts, publishing stories, and adding Slack channel names.
 * @remarks
 * This component relies on various dependencies such as TransferInformationComponent, ActivatedRoute, CommonService, ToastService, AuthService, Router, Location, BreakpointService, BannerSessionResizeObserverService, ChangeDetectorRef, and FormBuilder.
 * @example
 * ```
 * <app-create-story></app-create-story>
 * ```
 */
@Component({
  selector: 'app-create-story',
  templateUrl: './create-story.component.html',
  styleUrls: ['./create-story.component.scss']
})
export class CreateStoryComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  functionAbility = {} as IFunctionAbility;

  viewPreview = false;

  storyId = 0;

  tagValues = [];

  rteValue: any;

  storyDetails = {} as Stories;

  isNewStory = false;

  isLoaded: boolean;

  savedStoryId = 0;

  googleDocs = '';

  getApiURL: any;

  isVisible = false;

  isEmitted: boolean;

  userInfoDetails: any;

  // standardGuidancePlainContent: any;

  // selectedTopicValues: any;

  selectedTopics = [];

  slackName = '';

  slackChannelNames: string[] = [];

  slackIntegration = true;

  showErrorMsg: boolean = false;

  showTitleErrorMsg: boolean = false;

  isMobile: boolean = false;

  isSmall: boolean = false;

  showToolTip: boolean = false;

  showTopicToolTip: boolean = false;

  showSubjectToolTip: boolean = false;

  tooltipTrigger = 'manual';

  subjectsForTheTitle: any[] = [];

  windowWidth = 992;

  footerHeight = 65;

  isSubjectLoaded: boolean = true;

  resizeObservable$!: Observable<Event>;

  private destroy$ = new Subject();

  storyTitleChange: Subject<string> = new Subject();

  @ViewChild('storyTitle') storyTitle: ElementRef;

  @ViewChild('bodyElement', { static: false }) bodyElement: ElementRef | any;

  @ViewChild('displayElement', { static: false }) displayElement: ElementRef | any;

  @ViewChild('sideMetaElement', { static: false }) sideMetaElement: ElementRef | any;

  @ViewChild(AdditionalTopicNewComponent) topicChildComponent: AdditionalTopicNewComponent;

  storyForm: FormGroup;

  selectedTab = 0;

  tagValue: string[] = [];

  enableScroll: boolean = true;
  storyParam: Params;


  constructor(
    private tI: TransferInformationComponent,
    private router: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private authService: AuthService,
    private reRoute: Router,
    private location: Location,
    private breakpointService: BreakpointService,
    private bannerSessionResizeObserverService: BannerSessionResizeObserverService,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder
  ) {

    this.storyForm = this.fb.group({
      state: [this.storyDetails.storyState || '', Validators.required],
      access: [this.storyDetails.storyAccess || '', Validators.required],
      title: [this.storyDetails.storyTitle || '', Validators.required],
      topicForm: this.fb.group({
        topic: [this.storyDetails.storyTopics, Validators.required]
      }),
      subject: [this.storyDetails.subject || '', Validators.required],
      description: [this.storyDetails.storyContent || '', Validators.required]
    });

  }

  /**
   * Callback when the user clicks the 'Create Story' button.
   * This method initializes the story object and sets the story state, access, title, topics, subject, and description.
   */
  createStoryObject() {

    this.storyDetails.storyState = 'WORKING';
    this.storyDetails.storyAccess = 'public';
    this.storyDetails.storyTitle = '';
    this.storyDetails.storyTopics = [];
    this.storyDetails.subject = null;
    this.storyDetails.storyContent = '';
    this.storyDetails.isStoryPublished = false;
    this.storyDetails.storyContentTags = [];
    this.storyDetails.slackChannelName = [];
    this.storyDetails.storyFollower = null;

    this.storyForm.patchValue({
      state: this.storyDetails.storyState,
      access: this.storyDetails.storyAccess
    });

    this.isNewStory = true;
    this.isLoaded = true;

  }


  /**
   * Getter for the form controls of the story form.
   *
   * @returns The controls of the story form.
   */
  get formControls() {

    return this.storyForm.controls;

  }

  /**
   * Initializes the component and sets up the necessary subscriptions and event listeners.
   * This method is called after the component has been created and initialized with input properties.
   */
  ngOnInit() {

    this.resizeObservable$ = fromEvent(window, 'resize');

    this.storyDetails.isGenerateTags = false;

    this.savedStoryId = 0;
    this.isLoaded = false;
    this.getApiURL = environment.getStoriesAPI;
    this.isVisible = false;
    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });
    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role
    };
    this.router.params.subscribe(
      (params: Params) => {

        this.setStoryId(params);

      });

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

    this.breakpointService.isSmall$.subscribe(res => {

      this.isSmall = res;

    });

    this.windowWidth = window.innerWidth;
    this.resizeObservable$.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(() => {

      this.windowWidth = window.innerWidth;

    });

    this.bannerSessionResizeObserverService.setBannerSessionHeight();

    this.storyTitleChange.pipe(debounceTime(500)).subscribe(() => {

      if (this.storyForm.controls.title.value.length > 2 && !this.storyDetails.isStoryPublished) {

        this.getSubjectsForTitle();

      }

    });

    // Update the 'access' control whenever accessValue changes
    this.storyForm.get('access')?.valueChanges.subscribe(value => {

      this.storyDetails.storyAccess = value;

    });

  }

  /**
   * Handles the window resize event.
   * Adjusts the height of various elements based on the window size.
   */
  windowResizeEvent() {

    const bodyElementTab = (this.bodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const bodyElementTabHeight = window.innerHeight - bodyElementTab?.top - this.footerHeight - 24;

    document.documentElement.style.setProperty('--body-tab-height', bodyElementTabHeight + 'px');


    const displayElementTab = (this.displayElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const displayElementTabHeight = window.innerHeight - displayElementTab?.top - this.footerHeight;

    document.documentElement.style.setProperty('--display-tab-height', displayElementTabHeight + 'px')


    const sideMetaElementTab = (this.sideMetaElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const sideMetaElementTabHeight = window.innerHeight - sideMetaElementTab?.top - this.footerHeight - (this.isSmall ? 24 : 0);

    document.documentElement.style.setProperty('--side-tab-height', sideMetaElementTabHeight + 'px');

  }

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   * This method sets the focus on the story title input element and triggers change detection.
   *
   * @memberof CreateStoryNewComponent
   */
  ngAfterViewInit() {

    this.storyTitle.nativeElement.focus();
    this.changeDetector.detectChanges();

  }

  /**
   * Lifecycle hook that is called after the component's view has been checked by the Angular change detection mechanism.
   * Callback when the view has been fully initialized.
   * This method triggers the window resize event handler.
   *
   */
  ngAfterViewChecked(): void {

    this.windowResizeEvent();

  }


  /**
   * Sets the story ID based on the provided parameters.
   * 
   * @param params - The parameters containing the story ID.
   * @param params.storyId - The ID of the story to be set.
   * 
   * If the `storyId` is present in the parameters, it extracts the ID,
   * sets the `storyId` property, marks the story as not new, and fetches
   * the story details. If the `storyId` is not present, it marks the story
   * as emitted and creates a new story object.
   */
  setStoryId(params) {

    if (params && params.storyId) {

      const id = params.storyId;

      this.storyId = id.substr(1);
      this.isNewStory = false;
      this.isEmitted = false;
      this.getStoryDetails(this.storyId);

    } else {

      this.isEmitted = true;
      this.createStoryObject();

    }

  }

  /**
   * Toggles the access value of the story between 'private' and 'public'.
   * Updates the form control with the new access value.
   *
   * @remarks
   * This method switches the `storyAccess` property of `storyDetails` 
   * between 'private' and 'public'. It then patches the `storyForm` 
   * with the updated access value.
   */
  toggleAccessValue() {

    this.storyDetails.storyAccess = this.storyDetails.storyAccess === 'private' ? 'public' : 'private';

    this.storyForm.patchValue({
      access: this.storyDetails.storyAccess
    });

  }


  /**
   * Handles the input event and updates the story title.
   *
   * @param value - The new value for the story title.
   */
  onInput(value: string): void {

    this.storyDetails.storyTitle = value;

    this.storyTitleChange.next(value);

  }


  /**
   * Navigates to a different route based on the provided story type.
   *
   * @param type - The type of story to navigate to. 
   *               If 'Post', navigates to 'ncx/post'.
   *               If 'Angle', navigates to 'ncx/angle'.
   *               Otherwise, navigates to 'ncx/create-story'.
   */
  toggleStory(type: any) {

    (type === 'Post') ? this.reRoute.navigate(['ncx/post']) : (type === 'Angle')
      ? this.reRoute.navigate(['ncx/angle'])
      : this.reRoute.navigate(['ncx/create-story']);

  }


  /**
   * Toggles the generate flag for story details.
   * Callback when clicking the button to change State Value (working/complete)
   * @param type - The new value to set for the generate tags flag.
   */
  toggleGenerateFlag(type) {

    this.storyDetails.isGenerateTags = type;

  }



  /**
   * Publishes the story if the input is valid.
   * 
   * This method sets the `showErrorMsg` flag to true and checks if the input is valid
   * by calling the `validateInput` method. If the input is valid, it sets the 
   * `isStoryPublished` property of `storyDetails` to true and calls the `saveStory` method.
   */
  publish() {

    this.showErrorMsg = true;
    if (this.validateInput()) {

      this.storyDetails.isStoryPublished = true;
      this.saveStory();

    }

  }

  /**
   * Handles the draft functionality for the story creation component.
   * 
   * This method performs the following actions:
   * - Resets error messages related to the story title.
   * - Marks the topic and description form controls as untouched.
   * - Validates the story name and marks it as touched if invalid.
   * - If the story title is valid, assigns story details, sets the story as not published, and saves the story.
   */
  draft() {
    this.showErrorMsg = false;
    this.showTitleErrorMsg = true;
    this.topicChildComponent.topicForm.controls['topic'].markAsUntouched();
    this.storyForm.controls.description.markAsUntouched();
    this.markStoryNameTouchedIfInvalid();
    if (this.storyForm.controls.title?.valid) {
      this.assignStoryDetails()
      this.storyDetails.isStoryPublished = false;
      this.saveStory();
    }
  }


  /**
   * Validates the input form for creating a new story.
   * 
   * If the form is valid, it assigns the story details, logs the form value,
   * sets the story as published, and returns true.
   * 
   * If the form is invalid, it marks all form fields as touched, updates the
   * form's validity, disables the preview view, scrolls to the first invalid
   * field after a delay, sets the loaded state to true, and returns false.
   * 
   * @returns {boolean} - Returns true if the form is valid, otherwise false.
   */
  validateInput() {

    if (this.storyForm.valid) {

      this.assignStoryDetails();
      console.log(this.storyForm.value);
      this.storyDetails.isStoryPublished = true;
      return true;

    } else {

      this.storyForm.markAllAsTouched();
      this.storyForm.updateValueAndValidity();
      this.viewPreview = false;
      setTimeout(() => this.scrollToField(), 500)

      this.isLoaded = true;
      return false;

    }

  }


  /**
   * Removes JWT tokens from S3 links in the story description.
   *
   * This method updates the `storyContent` property of `storyDetails` by
   * removing JWT tokens from any S3 links found within the content. It
   * specifically targets links associated with 'img' tags.
   *
   * @returns {void}
   */
  removeJWTTokenFromS3LinksInDescription() {

    this.storyDetails.storyContent = this.authService.removeJWTTokenFromLink(this.storyDetails.storyContent, 'img');

  }

  /**
   * Saves the current story. If the story is new, it initializes the story ID to 0 and saves it.
   * Otherwise, it saves the story with its existing ID.
   * 
   * This method also removes JWT tokens from S3 links in the story description before saving.
   * 
   * @returns {void}
   */
  saveStory() {

    this.removeJWTTokenFromS3LinksInDescription();

    if (this.isNewStory) {

      this.storyDetails.storyId = 0;
      this.saveStoryById(false);

    } else {

      this.saveStoryById(true);

    }

  }


  /**
   * Saves the current story as a draft or publishes it based on the provided type.
   * 
   * @param type - The type of save operation. Can be 'SaveDraft' or 'Publish'.
   * 
   * The function performs the following steps:
   * 1. Sets `isLoaded` to false and checks if the story form is visible.
   * 2. If the form is visible, it sets `isLoaded` to true and exits.
   * 3. If the type is 'SaveDraft', it marks the story as not published.
   * 4. Sets `storyFollower` to null.
   * 5. If the story form is valid, it assigns story details and logs the form value.
   * 6. If the type is 'Publish', it marks the story as published.
   * 7. If the form is invalid, it marks all fields as touched, updates validity, scrolls to the first invalid field, sets `isLoaded` to true, and exits.
   * 8. Removes JWT tokens from image links in the story content.
   * 9. If it's a new story, sets the story ID to 0 and saves the story.
   * 10. If it's not a new story, saves the story by its existing ID.
   */
  saveDraft(type: any) {

    setTimeout(() => {

      this.isLoaded = false;
      if (this.isVisible) {

        this.isVisible = true;
        this.isLoaded = true;
        return;

      }

      if (type === 'SaveDraft') {

        this.storyDetails.isStoryPublished = false;

      }

      this.storyDetails.storyFollower = null;


      if (this.storyForm.valid) {

        this.assignStoryDetails();

        console.log(this.storyForm.value);

        if (type === 'Publish') {

          this.storyDetails.isStoryPublished = true;

        }

      } else {

        this.storyForm.markAllAsTouched();
        this.storyForm.updateValueAndValidity();
        this.scrollToField();

        this.isLoaded = true;
        return;

      }

      //JWT Token has to be removed from the img S3 link in the content to be saved

      this.storyDetails.storyContent = this.authService.removeJWTTokenFromLink(this.storyDetails.storyContent, 'img');
      if (this.isNewStory) {

        this.storyDetails.storyId = 0;
        this.saveStoryById(false);

      } else {

        this.saveStoryById(true);

      }

    }, 500);

  }

  /**
   * Transforms the selectedTopics array into an object where each key is a topicId (as a string)
   * and each value is the corresponding topicName.
   *
   * @returns {Object} An object with topicId as keys and topicName as values.
   */
  modifyTheObjectArrayToDifferentFormat() {

    const item = {};

    this.selectedTopics.forEach((element) => {

      item[element.topicId.toString()] = element.topicName;

    });

    return item;

  }

  /**
   * Handles the 'Enter' key press event to add a Slack channel.
   * 
   * @param event - The keyboard event triggered by pressing a key.
   */
  AddSlackChannelOnEnterClick(event) {

    if (event.charCode === 13) {

      this.AddSlackChannel();

    }

  }

  /**
   * Handles the button click event to add a Slack channel.
   * This method triggers the `AddSlackChannel` method.
   */
  AddSlackChannelOnBtnClick() {

    this.AddSlackChannel();

  }

  /**
   * Adds a Slack channel to the list of Slack channel names.
   * 
   * This method performs the following actions:
   * 1. Initializes the `slackChannelNames` array if it is not already initialized.
   * 2. Trims the input Slack channel name and checks if it is non-empty.
   * 3. Removes any leading hash symbol from the Slack channel name.
   * 4. Checks if the trimmed Slack channel name is not already in the list.
   *    - If not, adds the trimmed Slack channel name to the list and resets the input field.
   *    - If it is, displays a warning message indicating that the Slack channel name is already added.
   * 5. If the input Slack channel name is empty but contains whitespace, resets the input field and displays a warning message.
   */
  AddSlackChannel() {

    if (!this.slackChannelNames) {

      this.slackChannelNames = [];

    }
    if (this.slackName.trim().length > 0) {

      this.removeHashInTheFront();
      if (!this.slackChannelNames.includes(this.slackName.trim())) {

        this.slackChannelNames.push(this.slackName.trim());
        this.slackName = '';
        this.slackNameLength();

      } else {

        this.toastService.createMessage('warning', 'Slack channel name already added to story.');

      }

    } else {

      if (this.slackName.length > 0 && this.slackName.trim().length === 0) {

        this.slackName = '';
        this.toastService.createMessage('warning', 'Enter Slack channel name.');

      }

    }

  }

  /**
   * Recursively removes the hash character ('#') from the front of the `slackName` string.
   * If the first character of `slackName` is a hash, it removes it and calls itself again
   * until the first character is no longer a hash.
   *
   * @remarks
   * This method modifies the `slackName` property of the class instance.
   */
  removeHashInTheFront() {

    const firstChar = this.slackName.substring(0, 1);

    if (firstChar == '#') {

      this.slackName = this.slackName.substring(1, this.slackName.length);
      this.removeHashInTheFront();

    }

  }

  /**
   * Determines the length of the Slack channel names and sets the slackIntegration flag accordingly.
   * 
   * If `slackChannelNames` is defined and its length is 0 or less, `slackIntegration` is set to `true`.
   * Otherwise, `slackIntegration` is set to `false`.
   */
  slackNameLength() {

    if (this.slackChannelNames && this.slackChannelNames.length <= 0) {

      this.slackIntegration = true;

    } else {

      this.slackIntegration = false;

    }

  }

  /**
   * Removes a specified tag from the `slackChannelNames` array.
   * If the tag is found, it is removed and the `slackNameLength` method is called.
   *
   * @param tag - The tag to be removed from the `slackChannelNames` array.
   */
  removeTag(tag) {

    const index = this.slackChannelNames.indexOf(tag);

    if (index > -1) {

      this.slackChannelNames.splice(index, 1);
      this.slackNameLength();

    }

  }

  /**
   * Removes the S3 link token from the given URL.
   *
   * @param url - The URL string from which the S3 link token needs to be removed.
   * @returns The URL string without the S3 link token.
   */
  removeS3Link(url) {

    return url.split('?ncxjwttoken')[0];

  }

  /**
   * Displays the preview of the story if it is not already visible.
   * 
   * This method sets the `viewPreview` flag to true, triggers a window resize event,
   * and assigns the story details. If there is a value in the rich text editor (`rteValue`),
   * it updates the `storyContent` of `storyDetails` after a short delay.
   * 
   * @returns {void}
   */
  showPreview() {

    if (this.isVisible) {

      return;

    }

    this.viewPreview = true;

    this.windowResizeEvent();

    this.assignStoryDetails();

    setTimeout(() => {

      if (this.rteValue) {

        this.storyDetails.storyContent = this.rteValue;

      }


    });

  }

  /**
   * Updates the component's tag values with the provided data.
   *
   * @param data - The new tag data to be set.
   */
  getTagsData(data: any) {

    this.tagValues = data;

  }


  /**
   * Updates the description of the story based on the provided data.
   * 
   * @param data - The new description data to be processed.
   * 
   * The method performs the following actions:
   * 1. Sets `this.rteValue` to the provided data.
   * 2. Checks if the data contains valid text content using `Common.isInputHasValidTextContent`.
   *    - If valid, updates `this.storyDetails.storyContent` with the data.
   *    - If not valid, sets `this.storyDetails.storyContent` to an empty string.
   * 3. Resets `this.googleDocs` to an empty string.
   * 4. Updates the form control `description` in `this.storyForm` with the new story content.
   */
  getDescription(data) {

    this.rteValue = data;
    if (Common.isInputHasValidTextContent(data)) {

      this.storyDetails.storyContent = data;

    } else {

      this.storyDetails.storyContent = '';

    }
    this.googleDocs = '';

    this.storyForm.patchValue({
      description: this.storyDetails.storyContent
    });

  }

  /**
   * Processes the provided RTE data by extracting the description and marking it as touched if invalid.
   *
   * @param data - The data to be processed.
   */
  getRTEData(data: any) {

    this.getDescription(data);

    this.markDescriptionTouchedIfInvalid();

  }

  /**
   * Handles the event when data is pasted into the Rich Text Editor (RTE).
   * 
   * @param data - The data that was pasted into the RTE.
   */
  getRTEPastedData(data: any) {

    this.getDescription(data);

    this.markDescriptionTouchedIfInvalid();


  }


  /**
   * Converts HTML content to plain text.
   *
   * This function creates a temporary DIV element, sets its innerHTML to the provided HTML string,
   * and then retrieves the text content from the DIV. It ensures that any HTML tags are stripped out,
   * leaving only the plain text.
   *
   * @param html - The HTML string to be converted to plain text.
   * @returns The plain text extracted from the provided HTML.
   */
  htmlToText(html) {

    const tmp = document.createElement('DIV');

    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';

  }

  /**
   * Handles the visibility and state of the Google Docs element based on the event flag.
   * 
   * @param event - A string that indicates the flag status. If the event is 'true', 
   *                it sets `googleDocs` to an empty string, makes the element visible, 
   *                and triggers a click on the element with the ID 'test'. 
   *                Otherwise, it hides the element and resets `googleDocs` to an empty string.
   */
  checkFlag(event) {

    if (event === 'true') {

      this.googleDocs = '';
      this.isVisible = true;
      document.getElementById('test').click();

    } else {

      this.isVisible = false;
      this.googleDocs = '';

    }

  }


  /**
   * Handles the document link based on the provided value.
   * If the value is not 'close', it updates the `googleDocs` property
   * and calls `updateRTELinkContent` with 'googleDoc'.
   * Finally, it sets `isVisible` to false.
   *
   * @param value - The value to determine the action to be taken.
   */
  getDocLink(value) {

    if (value !== 'close') {

      this.googleDocs = value;
      this.updateRTELinkContent('googleDoc');

    }
    this.isVisible = false;

  }


  /**
   * Updates the RTE (Rich Text Editor) content with a link and an embedded iframe based on the specified type.
   * 
   * @param type - The type of content to be embedded. Currently supports 'googleDoc'.
   * 
   * If the current RTE value is an empty paragraph (`<p><br></p>`), it replaces it with a span containing a link to the Google Doc
   * and an iframe embedding the Google Doc. If the current RTE value is not empty, it appends the same span to the existing content.
   * 
   * The iframe is configured with a height of 600 pixels, a width of 800 pixels, no border, and no scrolling.
   * 
   * @example
   * ```typescript
   * this.updateRTELinkContent('googleDoc');
   * ```
   */
  updateRTELinkContent(type) {

    if (this.rteValue === '<p><br></p>') {

      // tslint:disable-next-line:max-line-length
      this.rteValue = (type === 'googleDoc')
        ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0" src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>`
        : '';

    } else {

      // tslint:disable-next-line:max-line-length
      this.rteValue += (type === 'googleDoc')
        ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0" src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>`
        : '';

    }

  }

  /**
   * Handles the cancel action by setting the visibility to false and marking the component as loaded.
   * 
   * @remarks
   * This method is typically called when the user cancels an action or closes a modal/dialog.
   */
  handleCancel(): void {

    this.isVisible = false;
    this.isLoaded = true;


  }

  /**
   * Assigns values to the story details and updates the form with the provided response.
   * 
   * @param res - The response object containing story details.
   * 
   * The function performs the following tasks:
   * - Logs the response to the console.
   * - Checks if the story is private and if the user has access to it. If not, displays an error message and redirects to the stories dashboard.
   * - Assigns the response to `storyDetails` and deep clones it.
   * - Maps `storyContentTags` to `tagValues`.
   * - Adds a JWT token to the story content links for S3 access.
   * - Initializes `storyTopics` to an empty array.
   * - Sets `rteValue` to the story content.
   * - Initializes `subjectsForTheTitle` based on the story subject.
   * - Updates the form fields with the story details.
   */
  assignValues(res) {

    console.log('Assing values', res);
    if ((res && res.storyAccess === 'private') && !(((res.createUser && res.createUser.userId === Number(localStorage.getItem('userId')))
      || (this.functionAbility.fa_access_private_story)))) {

      this.toastService.createMessage('error', 'You Do Not Have Access to the Story');
      setTimeout(() => {

        this.reRoute.navigate(['ncx/stories-dashboard']);

      }, 500);
      return;

    }
    this.storyDetails = res;
    this.storyDetails = JSON.parse(JSON.stringify(res));
    if (res && res.storyContentTags) {

      this.tagValues = res.storyContentTags.map((obj) => {

        return obj;

      });

    }

    //JWT Token has to be added to the img S3 link to download the file from S3
    this.storyDetails.storyContent = this.authService.addJWTTokenToLink(this.storyDetails.storyContent, 'img');


    //hardcoded
    this.storyDetails.storyTopics = [];

    this.rteValue = this.storyDetails.storyContent;


    this.subjectsForTheTitle = [];

    if (this.storyDetails?.subject == null) {

      // let value = [{ "entity": "MISC", "entityType": "MISCELLANEOUS", "entityCode": "misc" }];

      // this.subjectsForTheTitle.push(value[0]);

      // this.storyDetails.subject = this.subjectsForTheTitle[0];

      setTimeout(() => {

        this.getSubjectsForTitle();

      }, 200);

    } else {

      this.subjectsForTheTitle.push(this.storyDetails.subject);

    }

    this.storyForm.patchValue({
      state: this.storyDetails.storyState,
      access: this.storyDetails.storyAccess,
      title: this.storyDetails.storyTitle,
      topicForm: this.storyForm.patchValue({ topic: this.storyDetails.topicDetails }),
      subject: this.subjectsForTheTitle,
      description: this.storyDetails.storyContent
    });

  }


  /**
   * Navigates back to the previous view or exits the preview mode.
   * 
   * If the component is currently in preview mode (`viewPreview` is `true`), 
   * this method will disable the preview mode by setting `viewPreview` to `false`.
   * Otherwise, it will navigate back to the previous location using the `location.back()` method.
   */
  goBackToCreate() {

    if (this.viewPreview) {

      this.viewPreview = false;

    } else {

      this.location.back();

    }

  }

  /**
   * Navigates the user to the previous location in the browser's history.
   * This method utilizes the `location.back()` function to achieve the redirection.
   */
  redirectTo() {

    this.location.back();

  }

  /**
   * Redirects the user to the edit view by setting the `viewPreview` property to `false`.
   * This method is typically called when the user needs to switch from preview mode to edit mode.
   */
  redirectToEdit() {

    this.viewPreview = false;

  }


  /**
   * Updates the follower status and follower count of the story.
   *
   * @param status - An object containing the type of follow status update and the new follower count.
   */
  updateFollowers(status: FollowStoryUpdateEvent) {

    this.storyDetails.isUserFollowing = status.type;
    this.storyDetails.storyFollowerCount = status.data.storyFollowerCount;

  }

  /**
   * Fetches the details of a story by its ID.
   * 
   * This method sends a GET request to retrieve the story details. If the story is deleted,
   * it shows an error message and redirects to the stories dashboard. If the user does not
   * have access to the story, it shows an access error message and redirects after a delay.
   * Otherwise, it assigns the retrieved values and marks the data as loaded.
   * 
   * @param storyId - The ID of the story to fetch details for.
   */
  getStoryDetails(storyId) {

    const queryStr = `/${storyId}`;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      /**
       * if the story was already deleted then show the msg and redirect to stories dashboard
       */
      if (res.isDeleted === true) {

        this.toastService.createMessage('error', 'Requested story does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else {

        console.log(`SUCCESS: ${this.getApiURL}`);
        this.slackChannelNames = res.slackChannelName;
        this.assignValues(res);
        this.slackNameLength();
        this.isLoaded = true;

      }

    }, err => {

      console.log(`ERROR: ${this.getApiURL}`, err);
      if (err === 'STORY-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Story');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }
      this.isLoaded = true;

    });

  }


  /**
   * Redirects the user to the stories dashboard after a delay of 500 milliseconds.
   * Utilizes Angular's Router to navigate to the 'ncx/stories-dashboard' route.
   *
   * @remarks
   * This method uses a `setTimeout` to introduce a delay before performing the navigation.
   */
  redirectToStoriesDashboard() {

    setTimeout(() => {

      this.reRoute.navigate(['ncx/stories-dashboard']);

    }, 500);

  }

  /**
   * Saves or updates a story by its ID.
   *
   * @param {boolean} isUpdate - Indicates whether the story is being updated or created.
   *
   * This method performs the following actions:
   * - Logs the story details and story ID to the console.
   * - Determines the appropriate API endpoint and HTTP method (POST or PUT) based on the `isUpdate` flag and `savedStoryId`.
   * - Sets the Slack channel name for the story.
   * - If `savedStoryId` is present, assigns it to the story details.
   * - Converts the `storyFollower` array to contain only user IDs.
   * - Removes `topics` and `storyTopics` fields from the story details before making the API call.
   * - Makes an API call to save or update the story.
   * - Handles the API response:
   *   - If the story is deleted, shows an error message and redirects to the stories dashboard.
   *   - If the story is successfully saved or updated, shows a success message and navigates to the view story page.
   * - Handles API errors:
   *   - If the error is 'STORY-008', prompts the user to select a new topic.
   *   - If a story with the same title already exists, shows an error message.
   *   - For other errors, shows a generic error message.
   */
  saveStoryById(isUpdate: boolean) {

    console.log('saving data to backend ', this.storyDetails, this.storyDetails.storyId);
    const queryStr = (isUpdate || this.savedStoryId) ? `/${this.storyId}` : '';

    const type = (isUpdate || this.savedStoryId) ? 'put' : 'post';

    this.storyDetails.slackChannelName = this.slackChannelNames;
    if (this.savedStoryId) {

      this.storyDetails.storyId = this.savedStoryId;

    }

    this.storyDetails.storyFollower = this.storyDetails.storyFollower?.map(follower => follower.userId)

    const storyToAPICall = this.storyDetails;

    //below fields should not be passed to the API call

    delete storyToAPICall.topics;

    delete storyToAPICall.storyTopics;

    this.cService.serviceRequestCommon(type, this.getApiURL + queryStr, '', this.storyDetails).subscribe((val: any) => {

      /**
       * if the story was already deleted then show the msg and redirect to stories dashboard
       */
      if (val.isDeleted === true) {

        this.toastService.createMessage('error', 'Requested story does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else {

        console.log(`SUCCESS: ${this.getApiURL}`, val);
        this.isLoaded = true;
        this.savedStoryId = val.storyId;
        if (val.isStoryPublished) {

          this.toastService.createMessage('success', 'The Story has been successfully updated');
          this.reRoute.navigate(['ncx/view-story/:' + this.savedStoryId]);

        } else {

          this.toastService.createMessage('success', 'The Draft has been successfully updated');

        }

      }

    },
      (err) => {
        if (err === 'STORY-008') {
          this.storyDetails.topicDetails = [];
          this.topicChildComponent.getTopicsAndPatchData();
          this.toastService.createMessage('error', 'Please select a new topic.');
        } else if (err === 'A story with this title already exists.') {
          this.storyDetails.isStoryPublished = false;
          this.toastService.createMessage('error', err);
        } else {
          this.toastService.createMessage('error', err);
        }
        this.isLoaded = true;
      }
    );

  }

  /**
   * Retrieves the user's role.
   *
   * @returns {string} The role of the user.
   */
  getUserRole() {

    const role = this.tI.userRole;

    return role;

  }


  /**
   * Attaches topics to the current story.
   *
   * This method updates the story form with the provided topics and checks if the new topics
   * are different from the existing ones. If they are different, it updates the story details
   * with the new topics.
   *
   * @param TopicObj - An array of topic objects to be attached to the story. Each topic object
   *                   should have a `topicId` property.
   */
  attachTopicsToStory(TopicObj) {

    const newTopicIds = TopicObj.map(topic => topic.topicId.toString());

    const existingTopicIds = this.storyDetails.topicDetails ? this.storyDetails.topicDetails?.map(topic => topic.topicId.toString()) : [];

    this.storyForm.patchValue({
      topicForm: { topic: TopicObj }
    });

    if (!this.arraysEqual(newTopicIds, existingTopicIds)) {

      this.storyDetails.topicDetails = TopicObj

    }
    return;

  }

  /**
   * Compares two arrays of strings for equality.
   *
   * @param arr1 - The first array of strings to compare.
   * @param arr2 - The second array of strings to compare.
   * @returns `true` if both arrays have the same length and elements in the same order, otherwise `false`.
   */
  arraysEqual(arr1: string[], arr2: string[]): boolean {

    if (arr1.length !== arr2.length) {

      return false;

    }
    return arr1.every((value, index) => value === arr2[index]);

  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * 
   * This method performs the following cleanup tasks:
   * - Completes the `destroy$` observable to prevent memory leaks.
   * - Removes the custom CSS property `--body-tab-height` from the document's root element.
   * - Calls the `unsetBannerSessionHeight` method of `bannerSessionResizeObserverService` to clean up any session-specific banner height settings.
   */
  ngOnDestroy() {

    this.destroy$.complete();
    document.documentElement.style.removeProperty('--body-tab-height');
    this.bannerSessionResizeObserverService.unsetBannerSessionHeight();

  }

  /**
   * Toggles the visibility of the tooltip on mobile devices.
   * 
   * This method checks if the current device is a mobile device
   * and toggles the `showToolTip` property accordingly.
   * 
   * @returns {void}
   */
  toggleTooltip() {

    if (this.isMobile) {

      this.showToolTip = !this.showToolTip;

    }

  }

  /**
   * Displays a tooltip after a delay if the application is not running on a mobile device
   * and the tooltip is not already shown.
   *
   * This method sets a timeout of 500 milliseconds before setting the `showToolTip` property to `true`.
   * The tooltip will only be shown if the `isMobile` property is `false` and the `showToolTip` property is `false`.
   */
  showTooltipEvent() {

    if (!this.isMobile && !this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = true;

      }, 500);

    }

  }

  /**
   * Hides the tooltip after a delay if the current device is not mobile and the tooltip is currently shown.
   * The tooltip will be hidden after 500 milliseconds.
   */
  hideTooltip() {

    if (!this.isMobile && this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = false;

      }, 500);

    }

  }

  /**
   * Toggles the visibility of the topic tooltip on mobile devices.
   * 
   * This method checks if the current device is a mobile device by evaluating
   * the `isMobile` property. If it is a mobile device, it toggles the 
   * `showTopicToolTip` property, which controls the visibility of the topic tooltip.
   */
  toggleTopicTooltip() {

    if (this.isMobile) {

      this.showTopicToolTip = !this.showTopicToolTip;

    }

  }

  /**
   * Displays the topic tooltip with a delay if the application is not running on a mobile device
   * and the tooltip is not already shown.
   *
   * @remarks
   * This method sets a timeout of 500 milliseconds before showing the tooltip.
   *
   * @returns {void}
   */
  showTopicTooltipEvent() {

    if (!this.isMobile && !this.showTopicToolTip) {

      setTimeout(() => {

        this.showTopicToolTip = true;

      }, 500);

    }

  }

  /**
   * Hides the topic tooltip after a delay if the application is not running on a mobile device
   * and the tooltip is currently visible.
   *
   * @remarks
   * This method sets a timeout of 500 milliseconds before hiding the tooltip.
   */
  hideTopicTooltip() {

    if (!this.isMobile && this.showTopicToolTip) {

      setTimeout(() => {

        this.showTopicToolTip = false;

      }, 500);

    }

  }


  /**
   * Toggles the visibility of the subject tooltip on mobile devices.
   * 
   * This method checks if the current device is a mobile device by evaluating the `isMobile` property.
   * If it is a mobile device, it toggles the `showSubjectToolTip` property, which controls the visibility
   * of the subject tooltip.
   */
  toggleSubjectTooltip() {

    if (this.isMobile) {

      this.showSubjectToolTip = !this.showSubjectToolTip;

    }

  }

  /**
   * Displays the subject tooltip with a delay if the application is not running on a mobile device
   * and the tooltip is not already shown.
   *
   * This method sets a timeout of 500 milliseconds before showing the tooltip.
   */
  showSubjectTooltipEvent() {

    if (!this.isMobile && !this.showSubjectToolTip) {

      setTimeout(() => {

        this.showSubjectToolTip = true;

      }, 500);

    }

  }

  /**
   * Hides the subject tooltip after a delay if the current device is not mobile and the tooltip is currently shown.
   * 
   * @remarks
   * This method uses a timeout to delay the hiding of the tooltip by 500 milliseconds.
   * 
   * @returns {void}
   */
  hideSubjectTooltip() {

    if (!this.isMobile && this.showSubjectToolTip) {

      setTimeout(() => {

        this.showSubjectToolTip = false;

      }, 500);

    }

  }

  /**
   * Scrolls the view to the story title field smoothly and sets the selected tab to the first tab.
   * 
   * This method uses the `scrollIntoView` function to bring the `storyTitle` element into view with a smooth scrolling behavior.
   * It also sets the `selectedTab` property to `0`, indicating that the first tab should be selected.
   */
  scrollToField() {

    this.storyTitle?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    this.selectedTab = 0;

  }

  /**
   * Marks the 'title' control of the story form as touched if it is invalid.
   * This method checks if the 'title' control in the story form is invalid.
   * If it is, it marks the control as touched and triggers change detection.
   */
  markStoryNameTouchedIfInvalid() {

    if (this.storyForm.controls.title?.invalid) {

      this.storyForm.controls.title.markAsTouched();

      this.changeDetector.detectChanges();
    }
  }

  /**
   * Marks the story name and topic fields as touched if they are invalid.
   * This method first calls `markStoryNameTouchedIfInvalid` to handle the story name.
   * Then, it checks if the topic control in the `storyForm` is invalid.
   * If the topic control is invalid, it marks the topic field in the `topicForm` as touched.
   * Finally, it triggers change detection to update the view.
   */
  markStoryNameTopicTouchedIfInvalid() {

    this.markStoryNameTouchedIfInvalid();

    if (this.storyForm.controls.topic?.invalid) {

      this.topicChildComponent.topicForm.controls['topic'].markAsTouched();

    }

    this.changeDetector.detectChanges();

  }

  /**
   * Handles the focus event for the Rich Text Editor (RTE).
   * 
   * This method is triggered when the RTE gains focus and ensures that
   * the story name topic subject is marked as touched if it is invalid.
   * 
   * @private
   */
  handleRTEFocus() {

    this.markStoryNameTopicSubjectTouchedIfInvalid();

  }


  /**
   * Marks the description control as touched if it is invalid.
   * This method checks the validity of the description control in the story form.
   * If the control is found to be invalid, it marks the control as touched to trigger validation messages.
   */
  markDescriptionTouchedIfInvalid() {

    if (this.storyForm.controls.description.invalid) {

      this.storyForm.controls.description.markAsTouched();

    }

  }


  /**
   * Marks the 'story name', 'topic', and 'subject' fields as touched if they are invalid.
   * This method ensures that the form controls for 'story name', 'topic', and 'subject' are marked as touched
   * if they are invalid, triggering any associated validation messages to be displayed.
   * Additionally, it triggers change detection to update the view.
   */
  markStoryNameTopicSubjectTouchedIfInvalid() {

    this.markStoryNameTouchedIfInvalid();

    this.markStoryNameTopicTouchedIfInvalid();


    if (this.storyForm.controls.subject?.invalid) {

      this.storyForm.controls.subject.markAsTouched();

    }

    this.changeDetector.detectChanges();

  }


  handleTopicSelection() {

  }



  /**
   * Fetches subjects related to the story title from the server.
   * 
   * This method sends a GET request to the server to retrieve subjects associated with the current story title
   * entered in the form. It updates the `subjectsForTheTitle` property with the response data and sets the 
   * `tagValue` property to the first subject if any subjects are returned. The `isSubjectLoaded` flag is used 
   * to indicate the loading state of the subjects.
   * 
   * @returns {void}
   */
  getSubjectsForTitle() {

    this.isSubjectLoaded = false;

    this.cService.serviceRequestCommon('get', environment.getStoryTitleSubjectAPI, `?storyTitle=${this.storyForm.controls.title.value}`).
      subscribe((res: any) => {

        this.subjectsForTheTitle = res;

        // res.forEach(subject => {

        //   this.subjectsForTheTitle.push(subject);

        //   console.log(subject);

        // });

        if (this.subjectsForTheTitle.length > 0) {

          this.tagValue = [this.subjectsForTheTitle[0]];

        }

      }, (error: any) => {

        console.log(error)

      }).
      add(() => {

        this.isSubjectLoaded = true;

      });


    // this.cService.serviceRequestCommon('post', environment.globalSearch, `?searchString=${encodeURIComponent(this.storyForm.controls.title.value)}`).
    //   subscribe((res: any) => {

    //     this.subjectsForTheTitle = [];

    //     let storySearchResult = (res.storySearchResult || []) as StorySearchResult[];

    //     storySearchResult.forEach(story => {
    //       this.subjectsForTheTitle.push(story.storyTitle);
    //     });


    //   }, (error: any) => {

    //   }).
    //   add(() => {

    //     this.isSubjectLoaded = true;

    //   });

  }


  /**
   * Assigns the details from the form to the storyDetails object.
   * 
   * This method extracts values from the storyForm and assigns them to the corresponding
   * properties of the storyDetails object. It also processes some values to ensure they 
   * are in the correct format or have default values if not provided.
   * 
   * The following properties are assigned:
   * - `storyState`: The state of the story.
   * - `storyAccess`: The access level of the story.
   * - `storyTitle`: The title of the story.
   * - `storyContent`: The content of the story, using `rteValue` if available, otherwise an empty string.
   * - `storyTopics`: The topics associated with the story.
   * - `storyTopicIds`: An array of topic IDs derived from `storyTopics`.
   * - `subject`: The subject of the story, taking the first element from the subject array.
   * - `isGenerateTags`: A boolean indicating whether tags should be generated, defaulting to false if not set.
   * - `storyContentTags`: Tags associated with the story content.
   * - `slackChannelName`: The name of the Slack channel associated with the story.
   */
  assignStoryDetails() {

    const formValue = this.storyForm.value;

    this.storyDetails.storyState = formValue.state; // this.selectedValue;
    this.storyDetails.storyAccess = formValue.access; //this.accessValue;
    this.storyDetails.storyTitle = formValue.title;// this.storyDetails.storyTitle;
    this.storyDetails.storyContent = this.rteValue ? this.rteValue : ''; //(this.rteValue) ? this.rteValue : this.storyDetails.storyContent;
    this.storyDetails.storyTopics = formValue.topicForm.topic; //this.modifyTheObjectArrayToDifferentFormat(); //(this.rteValue) ? this.rteValue : this.storyDetails.storyContent;
    this.storyDetails.storyTopicIds = this.storyDetails.storyTopics.map(topic => topic.topicId.toString());
    this.storyDetails.subject = formValue.subject[0]; //(this.rteValue) ? this.rteValue : this.storyDetails.storyContent;

    this.storyDetails.isGenerateTags = (this.storyDetails.isGenerateTags) ? this.storyDetails.isGenerateTags : false;
    this.storyDetails.storyContentTags = this.tagValues;
    this.storyDetails.slackChannelName = this.slackChannelNames;

    // /this.storyDetails.autoGeneratedStoryId = "HardCoded - IDSAMPLE0001";

  }

  /**
   * Handles the change event for tag values.
   * 
   * @param value - An array of strings representing the new tag values.
   * 
   * If the array contains more than one value, it updates `tagValue` to only include the last value in the array.
   */
  onTagValueChange(value: string[]): void {

    if (value.length > 1) {

      this.tagValue = [value[value.length - 1]];

    }

  }

  /**
   * Handles the event when the topic dropdown open state changes.
   * 
   * @param event - A boolean indicating whether the dropdown is open (true) or closed (false).
   */
  topicDropdownOpenChangeEvent(event: boolean) {
    this.enableScroll = !event;
  }
}


