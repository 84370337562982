<div
   class="banner-container"
   data-component="app/shared/components/banners/announcements">
   <!--------------------- ANNOUNCEMENT BANNER -------------------->

   <section
      class="banner"
      *ngIf="banners.announcement.show">
      <div class="announcements">
         <!-- BANNER INFO -->
         <nz-carousel
            #carouselAnnouncement
            [nzEffect]="'scrollx'"
            [nzDots]="false">
            <div
               nz-carousel-content
               *ngFor="let announcement of announcementList">
               <div class="carousel-item">
                  <p class="heading announcement">
                     <span *ngIf="announcement?.announceLink">
                        Announcement:
                        <a
                           (click)="redirectURL(announcement?.announceLink, true)"
                           [innerHTML]="announcement.title | safeHtml"></a>
                     </span>
                     <span *ngIf="!announcement.announceLink">
                        Announcement:
                        <span [innerHTML]="announcement.title | safeHtml"></span>
                     </span>
                  </p>
               </div>
            </div>
         </nz-carousel>

         <!-- BANNER BUTTONS -->
         <div class="buttons">
            <ng-container *ngIf="announcementList.length > 1">
               <!-- INDEX POSITION OF ANNOUNCEMENT -->
               <span class="index">{{ carouselAnnouncement.activeIndex + 1 }}/{{ announcementList.length }}</span>

               <!-- PREV/NEXT BUTTONS -->
               <button (click)="prev('announcement')">
                  <i
                     nz-icon
                     nzType="left"
                     nzTheme="outline"></i>
               </button>

               <button (click)="next('announcement')">
                  <i
                     nz-icon
                     nzType="right"
                     nzTheme="outline"></i>
               </button>
            </ng-container>

            <!-- EXPAND ANNOUNCEMENT -->

            <button
               class="expand"
               *ngIf="announcementDetails.plainDescription"
               (click)="toggleAnnounceDetails()">
               <i
                  nz-icon
                  [nzType]="banners.announcement.details ? 'up-circle' : 'down-circle'"
                  nzTheme="outline"></i>
            </button>
         </div>
      </div>

      <!-- DETAILS -->
      <div
         *ngIf="banners.announcement.details"
         class="details">
         <p>
            From
            <span class="name">{{ name }}</span>
            on {{ date }}
         </p>

         <p class="content">
            {{ announcementDetails.plainDescription }}
         </p>

         <div
            *ngIf="isAdmin"
            class="footer">
            <button (click)="editAnnouncement(announcementDetails?.announcementId)">
               <i
                  nz-icon
                  nzType="edit"
                  nzTheme="outline"></i>
               Edit / Reannounce
            </button>

            <button
               nz-popconfirm
               nzPopconfirmTitle="Are you sure?"
               (nzOnConfirm)="deleteAnnouncement(announcementDetails?.announcementId)">
               <i
                  nz-icon
                  nzType="delete"
                  nzTheme="outline"></i>
            </button>
         </div>
      </div>
   </section>

   <!--------------------- HOT BANNER -------------------->

   <section
      class="banner"
      *ngIf="banners.hot.show">
      <div class="hot">
         <nz-carousel
            #carouselHot
            [nzEffect]="'scrollx'"
            [nzDots]="false">
            <div
               nz-carousel-content
               *ngFor="let hot of hotList"
               class="carousel-item">
               <p class="heading hot">
                  <span *ngIf="hot.announceLink">
                     Hot Alert:
                     <a (click)="redirectURL(hot.announceLink, false)">{{ hot.title }}</a>
                  </span>
                  <span *ngIf="!hot.announceLink">Hot Alert: {{ hot.title }}</span>
               </p>
            </div>
         </nz-carousel>

         <div class="buttons">
            <ng-container *ngIf="hotList.length > 1">
               <!-- INDEX POSITION OF ANNOUNCEMENT -->
               <span class="index">{{ carouselHot.activeIndex + 1 }}/{{ hotList.length }}</span>

               <!-- PREV/NEXT BUTTONS -->
               <button (click)="prev('hot')">
                  <i
                     nz-icon
                     nzType="left"
                     nzTheme="outline"></i>
               </button>

               <button (click)="next('hot')">
                  <i
                     nz-icon
                     nzType="right"
                     nzTheme="outline"></i>
               </button>
            </ng-container>
         </div>
      </div>
   </section>

   <!--------------------- LOADER --------------------------->

   <app-loader-component
      [isLoaded]="isLoaded"
      [size]="'small'"
      loaderType="SECTION_LOADER"></app-loader-component>
</div>
