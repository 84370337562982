import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { DiscussionComment } from '@models/ncx/groups-discussions';
import { Reply } from '@models/ncx/post';
import { IFunctionAbility } from '@models/users';
import { Common } from '@utilities/common';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';
import { TransferInformationComponent } from '../../TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-html-comments',
  templateUrl: './html-comments.component.html',
  styleUrls: ['./html-comments.component.scss']
})
export class HTMLRichTextCommentsComponent implements OnInit, OnChanges {

  @Input() postVersionGroupId;

  @Input() discussionVersionGroupId;

  getApiURL: string;

  postReplies: any;

  data: any = [];

  isLoaded: boolean;

  replyContent: string;

  rteData: string;

  replyID: any;

  buttonLabel = 'Add Comment';

  getReplyURL: any;

  inputValue: string;

  submitting: boolean;

  avtarImgLink;

  isVisible: boolean;

  googleDocs: string;

  embedlyLink: string;

  isEmbedlyVisible: boolean;

  setFocusFlag: boolean;

  isEmitted: boolean;

  operationType = '';

  customPopOver: boolean;

  showpop: boolean;

  customPopOverUserId: any;

  public functionAbility = {} as IFunctionAbility;

  public userInfoDetails;

  public readonly Common = Common;

  constructor(
    private cService: CommonService, private router: Router,
    private toastService: ToastService,
    private el: ElementRef,
    private sanitized: DomSanitizer,
    private userInfo: TransferInformationComponent
  ) {
  }

  ngOnInit() {

    this.getApiURL = environment.getPostApi;
    this.getReplyURL = environment.getRepliesURL;

    this.userInfo.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });

    this.userInfoDetails = {
      userId: this.userInfo.userInfoDetails.userId,
      role: this.userInfo.userInfoDetails.role
    };

    // tslint:disable-next-line:max-line-length
    this.userInfo.avatarPicUrlDetails.subscribe(avtarLink => {

      if (avtarLink) {

        this.avtarImgLink = avtarLink + `?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

      }

    });

  }

  ngOnChanges() {

    if (this.postVersionGroupId !== null && this.postVersionGroupId !== undefined) {

      this.getApiURL = environment.getPostApi;
      this.getReplyURL = environment.getRepliesURL;
      this.getPostReplies();

    }
    if (this.discussionVersionGroupId !== null && this.discussionVersionGroupId !== undefined) {

      this.getApiURL = environment.getDiscussionURL;
      this.getReplyURL = environment.getDiscussionURL;
      this.getPostReplies();

    }

  }

  setMouseOver() {

    const part = this.el.nativeElement.querySelectorAll('.fr-deletable');

    part.forEach((anchor: HTMLAnchorElement) => {

      anchor.removeEventListener('mouseenter', this.handleAnchorClick.bind(this));
      anchor.addEventListener('mouseenter', this.handleAnchorClick.bind(this));
      anchor.removeEventListener('click', this.handleAnchorClick.bind(this));
      anchor.addEventListener('click', this.handleAnchorClick.bind(this));

    });

  }

  handleAnchorClick(event) {

    this.customPopOver = false;

    this.showpop = true;
    if (event && event.srcElement && event.srcElement.id && event.srcElement.id.startsWith('user-')) {

      const mentionType = event.srcElement.id.split('-')[1];

      this.customPopOverUserId = mentionType;

    } else {

      return;

    }

    // this.customPopOverUserId = event.srcElement.id;
    setTimeout(() => {

      const element: HTMLElement = document.getElementById('triggerClick1') as HTMLElement;

      element.style.left = (event.clientX + 0) + 'px';
      element.style.top = (event.clientY + 0) + 'px';
      this.customPopOver = true;

      // console.log('REPLIES : ', event.layerX, event.layerY, event.offsetX, event.offsetY, event.clientX, event.clientY);
      element.click();

    });

  }

  handleSubmit(): void {

    if (this.isVisible || this.isEmbedlyVisible) {

      return;

    }
    if (!this.rteData || this.rteData === '<p><br></p>') {

      this.toastService.createMessage('warning', 'Please provide the Valid Comment');
      return;

    }
    this.submitting = true;
    const content = this.rteData;

    // this.replyContent = this.rteData;
    if (this.replyID && this.postVersionGroupId && content !== undefined) {

      // this.operationType = 'UPDATE_REPLY';
      this.updatePostReply({ replyContent: content, postVersionGroupId: this.postVersionGroupId }, this.replyID);

    } else if (this.replyID && this.discussionVersionGroupId && content !== undefined) {

      // this.operationType = 'UPDATE_REPLY';
      this.updatePostReply({ commentContent: content, discussionGroupId: this.discussionVersionGroupId }, this.replyID);

    } else if (this.postVersionGroupId && content !== undefined) {

      // this.operationType = 'ADD_REPLY';
      this.replyContent = this.rteData;
      this.savePostReply({ replyContent: content, postVersionGroupId: this.postVersionGroupId });

    } else if (this.discussionVersionGroupId && content !== undefined) {

      // this.operationType = 'ADD_REPLY';
      this.replyContent = this.rteData;
      this.savePostReply({ commentContent: content, discussionGroupId: this.discussionVersionGroupId });

    }
    this.rteData = '';
    this.replyContent = '';
    this.inputValue = '';

  }

  isEditable(userId) {

    return (localStorage.getItem('userId') === userId.toString()) ? true : false;

  }

  goToProfile(user) {

    this.router.navigate(['ncx/profile/:' + user.userId]);

  }

  updateReplyList(type, replyObj) {

    const replies = [];

    if (type === 'delete' && this.postVersionGroupId) {

      this.data.forEach(reply => {

        if (reply.replyId !== replyObj) {

          replies.push(reply);

        }

      });
      this.data = replies;

    } else if (type === 'delete' && this.discussionVersionGroupId) {

      this.data.forEach(reply => {

        if (reply.commentId !== replyObj) {

          replies.push(reply);

        }

      });
      this.data = replies;

    } else if (type === 'update' && this.postVersionGroupId) {

      let obj = this.data.filter(reply => reply.replyId === ((replyObj.replyId) ? replyObj.replyId : this.replyID))[0];

      const index = this.data.findIndex(reply => reply.replyId === ((replyObj.replyId) ? replyObj.replyId : this.replyID));

      if (obj !== null || obj !== undefined) {

        obj = Object.assign({}, replyObj);
        obj.delete = this.isDelete(obj.createUserId.userId);
        obj.edit = this.isEdit(obj.createUserId.userId);

        // this.data = [...this.data.slice(0, index), ...this.data.slice(index + 1, this.data.length) ];
        this.data.splice(index, 1, obj);
        this.replyContent = '';
        this.data = [...this.data];

        // this.data = [obj , ...this.data];

      }

    } else if (type === 'update' && this.discussionVersionGroupId) {

      let obj = this.data.filter(reply => reply.commentId === ((replyObj.commentId) ? replyObj.commentId : this.replyID))[0];

      const index = this.data.findIndex(reply => reply.commentId === ((replyObj.commentId) ? replyObj.commentId : this.replyID));

      if (obj !== null || obj !== undefined) {

        obj = Object.assign({}, replyObj);
        obj.delete = this.isDelete(obj.createUserId.userId);
        obj.edit = this.isEdit(obj.createUserId.userId);

        // this.data = [...this.data.slice(0, index), ...this.data.slice(index + 1, this.data.length) ];
        this.data.splice(index, 1, obj);
        this.replyContent = '';
        this.data = [...this.data];

        // this.data = [obj , ...this.data];

      }

    }

  }

  updateReply(data, replyId) {

    this.isEmitted = false;
    this.replyID = replyId;
    this.operationType = 'UPDATE_REPLY';
    this.buttonLabel = 'Update Comment';
    if (this.postVersionGroupId) {

      this.replyContent = data.replyContent;
      this.rteData = data.replyContent;

    } else if (this.discussionVersionGroupId) {

      this.replyContent = data.commentContent;
      this.rteData = data.commentContent;

    }
    setTimeout(() => {

      this.setFocusFlag = true;
      this.operationType = '';
      this.isEmitted = true;

    }, 200);

  }

  createReplyObject(obj) {

    if (this.postVersionGroupId) {

      const replyObj = {} as Reply;

      replyObj.replyContent = obj.replyContent;
      replyObj.postVersionGroupId = obj.postVersionGroupId;
      return replyObj;

    } else if (this.discussionVersionGroupId) {

      const replyObj = {} as DiscussionComment;

      replyObj.commentContent = obj.commentContent;
      replyObj.discussionGroupId = obj.discussionGroupId;
      replyObj.commentUserMentions = null;
      return replyObj;

    }

  }

  getRTEData(data) {

    this.rteData = data;
    this.googleDocs = '';
    this.embedlyLink = '';

  }

  /* Enabling Google Doc and Embedly Links Start*/
  checkGoogleFlag(event) {

    if (event === 'true') {

      this.isVisible = true;
      document.getElementById('rteCustomLink').click();
      this.googleDocs = '';

    } else {

      this.isVisible = false;
      this.googleDocs = '';

    }

  }

  checkEmbedlyFlag(event) {

    if (event === 'true') {

      this.embedlyLink = '';
      this.isEmbedlyVisible = true;
      document.getElementById('rteCustomLink').click();

    } else {

      this.isEmbedlyVisible = false;
      this.embedlyLink = '';

    }

  }

  getDocLink(value) {

    if (value !== 'close') {

      this.googleDocs = value;
      this.updateRTELinkContent('googleDoc');

    }
    this.isVisible = false;

  }

  getEmbedlyData(value) {

    if (value !== 'close') {

      this.embedlyLink = value;
      this.updateRTELinkContent('embedlyLink');

    }
    this.isEmbedlyVisible = false;

  }

  updateRTELinkContent(type) {

    if (this.rteData === '<p><br></p>') {

      this.rteData = (type === 'googleDoc') ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0"
      src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>` : `<span>${this.embedlyLink}<span>`;

    } else {

      this.rteData += (type === 'googleDoc') ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0"
      src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>` : `<span>${this.embedlyLink}<span>`;

    }

  }

  /* Enabling Google Doc and Embedly Links End*/
  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  /* API's for CRUD Operation in Replies */
  getPostReplies() {

    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${this.postVersionGroupId}/reply`;

    } else if (this.discussionVersionGroupId) {

      queryStr = `/${this.discussionVersionGroupId}/comments`;

    }
    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.postReplies = res;
      const temp = [];

      if (res && res.length) {

        res.forEach(element => {

          element.delete = this.isDelete(element.createUserId.userId);
          element.edit = this.isEdit(element.createUserId.userId);
          if (!element.isDeleted) {

            temp.push(element);

          }

        });

        // temp.map((val) => {val.replyContentVIEW = this.sanitized.bypassSecurityTrustHtml(val.replyContent); });
        this.data = [...temp];
        setTimeout(() => {

          this.setMouseOver();

        }, 1000);

      }

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getPostReplyById(Id) {

    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${Id}`;

    } else if (this.discussionVersionGroupId) {

      queryStr = `/comment${Id}`;

    }
    this.cService.serviceRequestCommon('get', this.getReplyURL, queryStr).subscribe((res: any) => {

      console.log('getPostReplyById : ', res);

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  savePostReply(replyObj) {

    this.isLoaded = false;
    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${this.postVersionGroupId}/reply`;

    } else if (this.discussionVersionGroupId) {

      queryStr = `/${this.discussionVersionGroupId}/comment`;

    }
    const payload = this.createReplyObject(replyObj);

    this.cService.serviceRequestCommon('post', this.getApiURL, queryStr, payload).subscribe((res: any) => {

      this.replyContent = '';
      this.rteData = null;
      if (res) {

        res.delete = this.isDelete(res.createUserId.userId);
        res.edit = this.isEdit(res.createUserId.userId);

      }
      this.data.push(res);
      this.data = [...this.data];
      this.replyID = null;
      this.buttonLabel = 'Add Comment';
      this.isLoaded = true;
      this.isEmitted = true;
      this.operationType = '';
      this.toastService.createMessage('success', 'The Reply has Been Successfully Added.');
      setTimeout(() => {

        this.setMouseOver();

      }, 1000);

    }, () => {

      this.isLoaded = true;
      this.operationType = '';
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  updatePostReply(replyObj, Id) {

    this.isLoaded = false;
    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${Id}`;

    } else if (this.discussionVersionGroupId) {

      queryStr = `/comment/${Id}`;

    }
    this.cService.serviceRequestCommon('put', this.getReplyURL, queryStr, replyObj).subscribe((res: any) => {

      this.replyID = null;
      this.buttonLabel = 'Add Comment';
      this.replyContent = '';
      this.rteData = null;
      if (res) {

        res.delete = this.isDelete(res.createUserId.userId);
        res.edit = this.isEdit(res.createUserId.userId);

      }
      this.updateReplyList('update', res);
      this.isLoaded = true;
      this.isEmitted = true;
      this.operationType = '';
      this.toastService.createMessage('success', 'The Reply has Been Successfully Updated');
      setTimeout(() => {

        this.setMouseOver();

      }, 1000);

    }, () => {

      this.isLoaded = true;
      this.operationType = '';
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  deletePostReply(Id) {

    this.isLoaded = false;
    let queryStr;

    if (this.postVersionGroupId) {

      queryStr = `/${Id}`;

    } else if (this.discussionVersionGroupId) {

      queryStr = `/comment/${Id}`;

    }
    this.cService.serviceRequestCommon('delete', this.getReplyURL, queryStr).subscribe((res: any) => {

      this.updateReplyList('delete', res);
      this.isLoaded = true;
      this.toastService.createMessage('success', 'The Reply has Been Successfully Deleted');

    }, () => {

      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  private isEdit(userId) {

    let editReply = this.functionAbility.fa_edit_reply;

    if (this.userInfoDetails.role.toLowerCase() === AppConstants.userRoleCheck) {

      editReply = (userId === this.userInfoDetails.userId) ? true : false;

    }
    return editReply;

  }

  private isDelete(userId) {

    let deleteReply = this.functionAbility.fa_delete_reply;

    if (this.userInfoDetails.role.toLowerCase() === AppConstants.userRoleCheck) {

      deleteReply = (userId === this.userInfoDetails.userId) ? true : false;

    }
    return deleteReply;

  }

}
