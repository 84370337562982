<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<div class="angle-log">
   <nz-collapse
      nzExpandIconPosition="right"
      nzBordered="false">
      <ng-container *ngFor="let item of angleLogs | defaultOrderKeyvalue">
         <nz-collapse-panel
            [nzHeader]="item.key"
            nzActive="true"
            class="angle-log-collapse">
            <div
               *ngFor="let logValue of item.value"
               class="log-section">
               <div class="post-angle">
                  <ng-container *ngIf="logValue.isAccessOnlyChanged">
                     <span class="description">{{ logValue.changedField }}</span>
                  </ng-container>

                  <ng-container *ngIf="logValue.contentType === 'Angle' && !logValue.isAccessOnlyChanged">
                     <span class="description">
                        {{ logValue.changedField ? logValue.changedField : logValue.contentType }}
                        {{ logValue.changedFields.length > 1 ? 'were' : 'was' }}
                        {{ logValue.actionDescription | lowercase }}
                     </span>
                  </ng-container>

                  <ng-container *ngIf="logValue.contentType !== 'Angle'">
                     <a href="/#/ncx/view-post/:{{ logValue.contentId }}">{{ logValue.contentTitle }}</a>
                     <span>
                        was {{ logValue.actionDescription | lowercase }}
                        {{ logValue.actionDescription === 'Removed' ? 'from' : 'to' }} angle
                     </span>
                  </ng-container>
               </div>

               <div class="post-angle-user">
                  <span
                     class="user-name"
                     nz-popover
                     [nzPopoverContent]="profileCardTemplate1"
                     [nzPopoverPlacement]="'topLeft'">
                     {{ logValue?.createdUser?.displayName }}
                  </span>
                  <ng-template #profileCardTemplate1>
                     <app-profile-overlay [profileData]="logValue.createdUser"></app-profile-overlay>
                  </ng-template>
                  <span class="time">{{ logValue.time }}</span>
               </div>
            </div>
         </nz-collapse-panel>
      </ng-container>
   </nz-collapse>
   <div
      class="notFoundCard"
      *ngIf="angleLogs?.length === 0 && isLoaded">
      <nz-empty [nzNotFoundContent]="contentTpl">
         <ng-template #contentTpl>
            <span>No Angle logs found</span>
         </ng-template>
      </nz-empty>
   </div>
</div>
