import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, UrlTree } from '@angular/router';
import { CreatePostComponent } from '@ncx/posts/create/create-post.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
}

@Injectable()
export class DeactivateGuardService implements CanDeactivate<CreatePostComponent> {

  constructor(
    private router: Router,
    private userInfo: TransferInformationComponent,
    private modalService: NzModalService
  ) {}


  canDeactivate(
    component: CreatePostComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): CanDeactivateType {

    return component.canDeactivate ? component.canDeactivate() : false;
  
  }

}
