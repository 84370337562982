import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AngleLog } from '@models/ncx/angle';
import { CommonService } from '@services/common-service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-angle-log',
  templateUrl: './angle-log.component.html',
  styleUrls: ['./angle-log.component.scss']
})
export class AngleLogComponent implements OnInit, OnDestroy {

  // Fetching Angle Log by Angle Id
  @Input() angleId!: string;

  // Emits when Scroll down the bottom of page
  @Input() scrollDownEvent: Observable<boolean>;

  // API Loader
  isLoaded = true;

  // Angle Log API response
  responseLogs: AngleLog[] = [];

  // Customize Angle Log API response
  angleLogs: { [key: string]: AngleLog[] };

  // Scroll Event unsubscribe in ngondestroy from infinite Observables
  private eventsSubscription: Subscription;

  // Payload variable
  pageStartCount = 1;

  /**
   * Default method of the class that is executed when the class is instantiated
   * Setup Dependency Injection
   */
  constructor(
    private cService: CommonService
  ) { }

  /**
   * ngOnInit Angular Life cycle
   * Angular has initialized all data-bound properties of a directive
   */
  ngOnInit(): void {

    // Make API Call
    this.getAngleLogs();

    // Make API Call when scroll
    this.eventsSubscription = this.scrollDownEvent.subscribe((_) => this.onScrollDown(_));
  
  }

  /**
   * Create Payload based on pageStartCount variable
   */
  createPayload() {

    return {
      'startDate': 0,
      'endDate': 0,
      'pageStartCount': this.pageStartCount,
      'pageLength': 10
    };
  
  }

  /**
   * Fetch the Angle Log based on Angle Id
   * @param isScroll {Boolean}
   */
  getAngleLogs(isScroll = false) {

    this.isLoaded = false;
    const payload = this.createPayload();

    const angleLogUrl = `${environment.getAngleApi}/${this.angleId}/logs`;

    this.cService.serviceRequestCommon('post', angleLogUrl, '', payload).subscribe((angleLogDetails: any) => {

      this.isLoaded = true;
      if (isScroll) {

        if (Array.isArray(angleLogDetails) && angleLogDetails.length) {

          this.responseLogs = [...this.responseLogs, ...angleLogDetails];

          // Transformation Applies
          this.setAngleLogDetails(this.responseLogs);
        
        }
      
      } else {

        this.responseLogs = angleLogDetails;

        // Transformation Applies
        this.setAngleLogDetails(this.responseLogs);
      
      }
    
    });
  
  }

  /**
   * Customize the Angle log based on FIGMA design
   * @param responseLogs {AngleLog}
   *
   */
  setAngleLogDetails(responseLogs: AngleLog[]) {

    const angleValue: { [key: string]: AngleLog[] } = {};

    responseLogs.forEach(responseLog => {

      const key = moment.utc(responseLog.createdDateAndTime).local().format('MM/DD/YYYY');
      
      const angleLog: AngleLog = JSON.parse(JSON.stringify(responseLog));

      let modifiedFields = angleLog.changedFields.map(changedField => {

        const modifiedField = changedField.substring(1);

        if (modifiedField.startsWith('relatedStories')) {

          return 'Related story';
        
        } else if (modifiedField.startsWith('collaborators')) {

          return 'Collaborators';
        
        } else if (modifiedField.startsWith('angleTitle')) {

          return 'Angle title';
        
        } else if (modifiedField.startsWith('angleContent')) {

          return 'Angle description';
        
        } else if (modifiedField.startsWith('access')) {

          return 'Angle was changed from private to public';
        
        } else {

          return modifiedField;
        
        }
      
      });

      modifiedFields = [...new Set(modifiedFields)];
      angleLog.changedField = modifiedFields.join(', ');
      angleLog.time = moment.utc(responseLog.createdDateAndTime).local().format('h:mm a');
      if (!angleValue[key]) {

        angleValue[key] = [];
      
      }
      angleValue[key].push(angleLog);
    
    });
    this.angleLogs = angleValue;
    console.log('angleLogs - ', this.angleLogs);
  
  }

  /**
   * when page scroll down this function make another API call for get another set of logs
   *
   */
  onScrollDown(value: boolean) {

    if (value) {

      this.pageStartCount += 10;
      this.getAngleLogs(true);
    
    }
  
  }

  /**
   * ngOnDestroy Angular Life cycle
   * Use for any custom cleanup that needs to occur when the instance is destroyed
   */
  ngOnDestroy(): void {

    this.eventsSubscription.unsubscribe();
  
  }

}
