<nz-card nzTitle="{{ attachmentTitle }}">
   <div class="attachments">
      <nz-upload
         nzType="drag"
         [nzMultiple]="true"
         [nzLimit]="25"
         [nzSize]="307200"
         nzListType="picture"
         [nzDisabled]="disbaleUpload"
         (nzChange)="handleChange($event)"
         [(nzFileList)]="fileList"
         [nzCustomRequest]="customReq"
         [nzHeaders]="setMediaUploadHeaders"
         [nzBeforeUpload]="beforeUpload">
         <p class="ant-upload-drag-icon">
            <i
               nz-icon
               nzType="inbox"></i>
         </p>
         <p class="ant-upload-text">Click or drag file(s) to this area to upload</p>
         <p class="ant-upload-hint">
            NOTE: Max file size is 307.2MB. You may attach up to 25 files, but their combined size must not exceed
            307.2MB
         </p>
         <p
            class="ant-upload-text"
            *ngIf="disbaleUpload && screenType && screenType !== 'DISCUSSION'">
            If you have selected Reportable as a status, complete the Reportable-Approver section before adding an
            attachment
         </p>
      </nz-upload>
   </div>
</nz-card>

<!-- ELEMENT DETAILS -->
<div
   class="elements"
   *ngIf="!hideElements && !saveDiscussionId">
   <nz-collapse class="element-details">
      <nz-collapse-panel
         nzHeader="Element Details"
         [nzShowArrow]="false"
         [nzActive]="this.attachments.length > 0"
         (nzActiveChange)="drop = !drop"
         [nzExtra]="icon">
         <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input
               nz-input
               placeholder="Link To Source"
               [(ngModel)]="attachmentDetails.linkToSource"
               (ngModelChange)="onChange($event, 'linkToSource')" />
         </nz-input-group>

         <ng-template #prefixTemplateUser>
            <i
               nz-icon
               nzType="link"
               nzTheme="outline"></i>
         </ng-template>

         <div class="elementForm">
            <div nz-row>
               <div
                  style="margin-right: 1%"
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="7"
                  nzXl="8">
                  <div style="margin-bottom: 1%">Cleared for NBCU Partners ?</div>
                  <nz-select
                     style="width: 100%"
                     [(ngModel)]="attachmentDetails.clearanceStatus"
                     (ngModelChange)="onChange($event, 'clearanceStatus')"
                     nzAllowClear
                     nzPlaceHolder="Choose">
                     <nz-option
                        nzValue="yes"
                        nzLabel="Yes"></nz-option>
                     <nz-option
                        nzValue="no"
                        nzLabel="No"></nz-option>
                  </nz-select>
               </div>
               <div
                  style="margin-right: 1%"
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="8"
                  nzXl="6">
                  <div style="margin-bottom: 1%">Date of Capture</div>
                  <nz-date-picker
                     [(ngModel)]="attachmentDetails.captureDate"
                     (ngModelChange)="onChange($event, 'captureDate')"
                     nzPlaceHolder="CaptureDate"
                     style="width: 100%"></nz-date-picker>
               </div>
               <div
                  nz-col
                  nzXs="24"
                  nzSm="24"
                  nzMd="24"
                  nzLg="8"
                  nzXl="9">
                  <div style="margin-bottom: 1%">Is there Mandatory Credit ?</div>
                  <nz-input-group nzCompact>
                     <nz-select
                        (ngModelChange)="onCreditChange($event)"
                        [(ngModel)]="attachmentDetails.isMandatoryCredit"
                        style="width: 24%"
                        nzAllowClear
                        nzPlaceHolder="Choose">
                        <nz-option
                           nzLabel="Yes"
                           nzValue="yes"></nz-option>
                        <nz-option
                           nzLabel="No"
                           nzValue="no"></nz-option>
                     </nz-select>
                     <input
                        style="width: 76%"
                        #creditName
                        id="for-testing"
                        class="for-testing"
                        type="text"
                        nz-input
                        (ngModelChange)="onCreditNameChange($event)"
                        placeholder="Credit goes Here..."
                        [(ngModel)]="attachmentDetails.creditName" />
                  </nz-input-group>
                  <div
                     class="errorMessage"
                     *ngIf="attachmentDetails.isMandatoryCredit === 'yes' && attachmentDetails.creditName === ''">
                     Please Provide Credit Name
                  </div>
               </div>
            </div>
         </div>
      </nz-collapse-panel>

      <ng-template #icon>
         <i
            *ngIf="!drop"
            style="cursor: pointer"
            nz-icon
            nzType="down"
            (click)="showElements()"
            nzTheme="outline"></i>
         <i
            *ngIf="drop"
            style="cursor: pointer"
            nz-icon
            nzType="up"
            (click)="showElements()"
            nzTheme="outline"></i>
      </ng-template>
   </nz-collapse>
</div>
