import { HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { ProfileDetails } from '@models/ncx/settingsItems';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { BreakpointService } from '@services/breakpoint.service';
import { PermissionsService } from '@services/profile/permissions.service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  // History or Profile tab
  currentTab: string = 'Profile';

  // Profile FormGroup
  profileForm: UntypedFormGroup;

  // Loading indicator
  isLoaded: boolean;

  originalprofileDetails: any;

  profileListYourInfo: any = {};

  profilefirstName = '';

  profilemiddleName = '';

  profilelastName = '';

  profileName = '';

  profilepreferredName = '';

  isViewProfileSettings;

  // Profile edit mode
  editProfile = false;

  // Profile in view-only mode
  isViewProfile = false;

  // Is the user following the profile
  isUserFollowing = false;

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: false,
    hidePreviewIconInNonImage: true
  };

  // Profile info
  userId: string;

  ssoId: number;

  userName = '';

  avatarImgUrl: any;

  // Profile image
  fileList: any = [];

  // Max number of bytes for profile image (1MB)
  maxFileSize: number = 1000000;

  // Show Preview Image modal
  previewVisible = false;

  // Preview Image URL
  previewImage = '';

  // API Urls
  getApiURL: string;

  postApiURL: string;

  postAvatarAPIURL: string;

  originalAPIData: any;

  originalJSONData: any;

  @ViewChild('documentEditForm') documentEditForm: FormGroupDirective;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private profileService: PermissionsService,
    private fb: UntypedFormBuilder,
    private userInfo: TransferInformationComponent,
    private commonService: CommonService,
    private toastService: ToastService,
    private msg: NzMessageService,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private breakpointService: BreakpointService,
    private urlRedirectService: UrlRedirectService
  ) {

    this.initProfileForm();

  }

  ngOnInit() {

    const userId = localStorage.getItem('userId');

    this.activatedRoute.params.subscribe((params: Params) => {

      this.exitEditMode();

      if (params && params.userId) {

        const id = params.userId;

        this.userId = id.replace(/[^0-9]/g, '');
        this.getApiURL = `${environment.getProfileAPIURL}/${this.userId}`;
        this.isViewProfile = this.userId !== userId;

      } else {

        this.getApiURL = `${environment.getProfileAPIURL}/${userId}`;
        this.getProfileMappingList();
        this.isViewProfile = false;

      }

      this.getProfileMappingList();

    });

    this.getApiURL = `${environment.getProfileAPIURL}/${localStorage.getItem('userId')}`;
    this.postApiURL = environment.postProfileAPIURL + localStorage.getItem('userId');
    this.postAvatarAPIURL = environment.getSettingsAPIURL;

    this.getFollowingDetails(userId);

  }

  getUrl(url) {

    console.log('url: ' + this.urlRedirectService.getRedirectAPIURL(url));
    return this.urlRedirectService.getRedirectAPIURL(url);
  
  }


  /**
   * Initialize empty profile form
   *
   */
  initProfileForm() {

    this.profileForm = this.fb.group({
      name: this.fb.group({
        firstName: [null, [], null, { updateOn: 'blur' }],
        middleName: [null],
        lastName: [null, [], null, { updateOn: 'blur' }],
        preferredName: [null]
      }),
      phone: this.fb.group({
        workPhone: [null, [], null, { updateOn: 'blur' }],
        personalPhone: [null, [], null, { updateOn: 'blur' }],
        alternatePhone: [null, [], null, { updateOn: 'blur' }]
      }),
      email: this.fb.group({
        workEmail: [null, [], null, { updateOn: 'blur' }],
        alternateEmail: [null, [Validators.email], null, { updateOn: 'blur' }]
      }),
      workInfo: this.fb.group({
        company: [null],
        division: [null],
        group: [null],
        building: [null],
        office: [null],
        address: [null],
        city: [null, [], null, { updateOn: 'blur' }],
        state: [null],
        statetext: [null],
        zip: [null, [], null, { updateOn: 'blur' }],
        country: [null],
        jobTitle: [null, [], null, { updateOn: 'blur' }]
      })
    });

  }

  /**
   * Update Form
   *
   */
  submitForm(): void {

    if (!this.profileForm.valid) {

      this.triggerValidations();

    } else {

      this.isLoaded = false;

      const dataToSave = this.mapValFromUIToBackend(this.profileForm.value);

      console.log('submitForm:', { form: this.profileForm, dataToSave });

      this.commonService.serviceRequestCommon('put', this.postApiURL, '', dataToSave).subscribe((val) => {

        this.toastService.createMessage('success', 'The settings have been changed successfully');

        this.originalprofileDetails = val;

        this.exitEditMode();

        this.mapValues(this.originalJSONData, dataToSave);

      }, (error: any) => {

        console.error('submitForm', { error });

        this.toastService.createMessage('error', 'Error while saving data. Please try again.');

      }).add(() => {

        this.isLoaded = true;

      });

    }

  }

  /**
   * Switch tabs from Profile to History
   *
   */
  switchTab(tab: string) {

    if (!this.userId || !this.ssoId) {

      this.userId = localStorage.getItem('userId');
      this.ssoId = parseInt(localStorage.getItem('ssoId'), 10);

    }

    switch (tab) {

    case 'History':
      this.router.navigate([`ncx/history/:${this.userId}/:${this.ssoId}`]);
      break;

    case 'Profile':
      this.router.navigate(['ncx/profile']);
      break;

    }

  }

  /**
   * Get Followers and Following count
   *
   */
  getFollowingDetails(userId: string) {

    this.commonService.serviceRequestCommon('get', `${environment.getProfileAPIURL}/${userId}/userConnections`).subscribe((res: any) => {

      if (res && res.followingUsers && res.followingUsers.length) {

        const userObj = res.followingUsers.filter(user => parseFloat(user.userId) === parseFloat(this.userId))[0];

        this.isUserFollowing = !!(userObj && userObj.userId);

      }

    }, (error: any) => {

      console.error('Get Following Details', { error });

    });

  }

  /**
   * Follow or Unfollow a user
   *
   */
  followUser(targetUserId: string, type: 'post' | 'delete') {

    const followerUserId = localStorage.getItem('userId');

    if (targetUserId.toString() === followerUserId.toString()) {

      this.toastService.createMessage('error', 'Please select different user to follow');
      return;

    }

    const queryStr = `/${targetUserId}/userFollower/${followerUserId}`;

    console.log('followUser:', { targetUserId, followerUserId, type });

    this.isLoaded = false;

    this.commonService.serviceRequestCommon(type, environment.getProfileAPIURL, queryStr).subscribe(() => {

      const message = type === 'post' ? 'You are now following this user' : 'You have unfollowed this user';

      this.toastService.createMessage('success', message);

      this.isUserFollowing = type === 'post';

    }, (error: any) => {

      console.error('followUser', { error });

      this.toastService.createMessage('error', 'Error saving data. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Format phone number for read-only view
   *
   */
  formatPhoneNumber(phone: string): string {

    if (phone && !!phone.match(/^\d{10}$/)) {

      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;

    } else {

      return phone;

    }

  }

  getProfileMappingList() {

    const api1 = this.commonService.serviceRequestCommon('get', environment.getProfileJSONURL).pipe(catchError((error: any) => {

      if (error.errorMessage) {

        this.toastService.createMessage('error', error.errorMessage);

      }
      return of(error);

    }));

    const api2 = this.commonService.serviceRequestCommon('get', this.getApiURL).pipe(catchError((error: any) => {

      if (error.errorMessage) {

        this.toastService.createMessage('error', error.errorMessage);

      }
      return of(error);

    }));

    this.isLoaded = false;

    forkJoin([api1, api2]).subscribe(([resp1, resp2]) => {

      console.log('getProfileMappingList:', { resp1, resp2 });

      if (resp1.errorCode) {

        this.toastService.createMessage('error', resp1.errorMessage);

      }

      if (resp2.errorCode) {

        this.toastService.createMessage('error', resp2.errorMessage);

      }

      this.originalJSONData = JSON.parse(JSON.stringify(resp1));
      this.originalAPIData = JSON.parse(JSON.stringify(resp2));

      this.isViewProfileSettings = this.originalAPIData.userSettings;

      if (this.isViewProfileSettings) {

        console.log('User Settings', this.isViewProfileSettings);
        this.mapValues(resp1, resp2);

      }

    }, (error) => {

      this.toastService.createMessage('error', error);

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Convert API values to form
   *
   */
  mapValues(JsonData: any, apiData: any) {

    if (!apiData) {

      return;

    }

    console.log('mapValues ', { JsonData, apiData });

    const name = JsonData.data.name.map(val => {

      val.value = apiData.name[val.type]; // apiData.userData.name[val.type]; // for testing mock data
      return val;

    });

    const email = JsonData.data.email.map(val => {

      val.value = apiData.email[val.type];
      return val;

    });

    const phone = JsonData.data.phone.map(val => {

      val.value = apiData.phone[val.type];
      return val;

    });

    const workinfo = JsonData.data.workInfo.map(val => {

      val.value = apiData.workInfo[val.type];
      return val;

    });

    this.profileListYourInfo.name = name;
    this.profilefirstName = name[0].value;
    this.profilemiddleName = name[1].value;
    this.profilelastName = name[2].value;
    this.profilepreferredName = name[3].value;

    this.profileName = this.profilefirstName + ' ' + this.profilemiddleName + ' ' + this.profilelastName;

    this.profileListYourInfo.email = email;
    this.profileListYourInfo.phone = phone;
    this.profileListYourInfo.workinfo = workinfo;

    /*Binding Avatar Url Link to UI */
    this.ssoId = apiData.ssoId;

    if (apiData.profilePictureURL) {

      this.avatarImgUrl = this.urlRedirectService.getRedirectAPIURL(apiData.profilePictureURL + '?random+\=' + Math.random() + '&ncxjwttoken=' + localStorage.getItem('ncxjwttoken'));
    
    } else {

      this.avatarImgUrl = '';

    }

    const avatarObj = {
      size: 0,
      name: 'AvatarPic',
      filename: 'AvatarPic',
      url: this.avatarImgUrl
    };

    this.fileList = [avatarObj];

    this.bindProfileDetails(this.profileListYourInfo);

  }

  /**
   * Convert form value to API
   *
   */
  mapValFromUIToBackend(savedData): ProfileDetails {

    const dataToSave = JSON.parse(JSON.stringify(this.originalAPIData));

    dataToSave.email = savedData.email;
    dataToSave.name = savedData.name;
    dataToSave.phone = savedData.phone;
    dataToSave.workInfo = savedData.workInfo;
    let ImageToUpload = dataToSave.profilePictureURL;

    if (this.avatarImgUrl) {

      ImageToUpload = (this.avatarImgUrl.toString().split('?random')[0]) ? this.avatarImgUrl.toString().split('?random')[0] : ImageToUpload;

    }

    dataToSave.profilePictureURL = ImageToUpload;

    console.log('datatoSave', { dataToSave, original: this.originalAPIData });

    return dataToSave;

  }

  /**
   * Initailize form with API values
   *
   */
  bindProfileDetails(profileListYourInfo) {

    console.log('bindProfileDetails', { profileListYourInfo });

    this.originalprofileDetails = profileListYourInfo;

    const fname = profileListYourInfo.name.filter(a => a.type === 'firstName')[0].value;

    const mname = profileListYourInfo.name.filter(a => a.type === 'middleName')[0].value;

    const lname = profileListYourInfo.name.filter(a => a.type === 'lastName')[0].value;

    const pname = profileListYourInfo.name.filter(a => a.type === 'preferredName')[0].value;

    const phonework = profileListYourInfo.phone.filter(a => a.type === 'workPhone')[0].value;

    const phonepersonal = profileListYourInfo.phone.filter(a => a.type === 'personalPhone')[0].value;

    const phonealternate = profileListYourInfo.phone.filter(a => a.type === 'alternatePhone')[0].value;

    const emailwork = profileListYourInfo.email.filter(a => a.type === 'workEmail')[0].value;

    const emailalternate = profileListYourInfo.email.filter(a => a.type === 'alternateEmail')[0].value;

    const workinfojt = profileListYourInfo.workinfo.filter(a => a.type === 'jobTitle')[0].value;

    const workinfocom = profileListYourInfo.workinfo.filter(a => a.type === 'company')[0].value;

    const workinfogrp = profileListYourInfo.workinfo.filter(a => a.type === 'group')[0].value;

    const workinfobuil = profileListYourInfo.workinfo.filter(a => a.type === 'building')[0].value;

    const workinfoOffi = profileListYourInfo.workinfo.filter(a => a.type === 'office')[0].value;

    const workinfoadd = profileListYourInfo.workinfo.filter(a => a.type === 'address')[0].value;

    const workinfocity = profileListYourInfo.workinfo.filter(a => a.type === 'city')[0].value;

    const workinfostate = profileListYourInfo.workinfo.filter(a => a.type === 'state')[0].value;

    const workinfozip = profileListYourInfo.workinfo.filter(a => a.type === 'zip')[0].value;

    const workinfocountry = profileListYourInfo.workinfo.filter(a => a.type === 'country')[0].value;

    const workinfodiv = profileListYourInfo.workinfo.filter(a => a.type === 'division')[0].value;

    this.profileForm.patchValue({
      name: {
        firstName: fname,
        middleName: mname,
        lastName: lname,
        preferredName: pname

      },
      phone: {
        workPhone: phonework,
        personalPhone: phonepersonal,
        alternatePhone: phonealternate
      },
      email: {
        workEmail: emailwork,
        alternateEmail: emailalternate
      },
      workInfo: {
        company: workinfocom,
        division: workinfodiv,
        group: workinfogrp,
        building: workinfobuil,
        office: workinfoOffi,
        address: workinfoadd,
        city: workinfocity,
        state: workinfostate,
        statetext: workinfostate,
        zip: workinfozip,
        country: workinfocountry,
        jobTitle: workinfojt
      }
    }, { emitEvent: false, onlySelf: true });

    this.triggerValidations();

    this.userName = (pname.split(',').length > 1) ? pname.split(',')[1].trim() + ' ' + pname.split(',')[0] : pname.trim();

    if (!this.isViewProfile) {

      this.userInfo.getSelectedLinkDetails(this.userName);

    }

    setTimeout(() => {

      this.profileForm.patchValue({
        workInfo: {
          state: workinfostate
        }
      }, { emitEvent: false, onlySelf: true });

    }, 500);

  }

  /**
   * Force form to validate
   *
   */
  triggerValidations() {

    this.markFormGroupTouched(this.profileForm);

  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {

    (Object as any).values(formGroup.controls).forEach(control => {

      control.markAsTouched();
      if (control.controls) {

        this.markFormGroupTouched(control);

      }

    });

  }

  /**
   * Set headers for nzUploader
   *
   */
  setMediaUploadHeaders = () => {

    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    };

  };

  /**
   * Before uploading image, validate size and file type
   *
   */
  beforeUpload = (file: NzUploadFile): boolean => {

    const imageTypes = AppConstants.imagetype.split(',');

    const isValidImage = imageTypes.includes(file.type);

    const isValidSize = file.size <= this.maxFileSize;

    if (!isValidImage) {

      this.msg.error('You can only upload png, jpeg, gif, bmp file types');
      return false;

    }

    if (!isValidSize) {

      this.msg.error('Please upload an image less than 1MB');
      return false;

    }

    return true;

  };

  /**
   * Show Preview image in Modal
   *
   */
  handlePreview = (file: NzUploadFile) => {

    this.previewVisible = true;
    this.previewImage = file.url || file.thumbUrl;

  };

  /**
   * Upload new image from nzUploader
   *
   */
  uploadImage = (item: NzUploadXHRArgs) => {

    const userId = localStorage.getItem('userId');

    const messageId = this.commonFunctionsHelper.generateUUID();

    const ncxjwttoken = localStorage.getItem('ncxjwttoken');

    const headers = new HttpHeaders({
      messageId,
      userId,
      ncxjwttoken
    });

    const queryStr = `/ncx-gateway${this.postAvatarAPIURL}${userId}/avatar`;

    const formData = new FormData();

    formData.append('profileImage', item.file as any);

    this.isLoaded = false;

    return this.commonService.post(queryStr, formData, headers).subscribe((event: any) => {

      console.log('event : ', event);

      if (event.status === 'success') {

        item.onSuccess(event.userId, item.file, event);

        this.toastService.createMessage('success', 'The profile image has been saved');

        this.avatarImgUrl = event.picLink + '?random+\=' + Math.random();

        // Replace File List array with the uploaded image
        this.fileList = [
          {
            size: 0,
            name: 'AvatarPic',
            filename: 'AvatarPic',
            url: this.fileList[0].url
          }
        ];

        if (!this.isViewProfile) {

          this.userInfo.getAvtarLinkUrl(this.avatarImgUrl);

        }

      }

      if (event.type === HttpEventType.UploadProgress) {

        if (event.total > 0) {

          (event as any).percent = event.loaded / event.total * 100;

        }

        item.onProgress(event, item.file);

      } else if (event instanceof HttpResponse) {

        item.onSuccess(event.body, item.file, event);

        this.avatarImgUrl = event['picLink'] + '?random+\=' + Math.random();
        this.userInfo.getAvtarLinkUrl(this.avatarImgUrl);

        // Replace File List array with the uploaded image
        this.fileList = [
          {
            size: 0,
            name: 'AvatarPic',
            filename: 'AvatarPic',
            url: this.fileList[0].url
          }
        ];

        this.toastService.createMessage('success', 'The profile image has been saved');

      }

    }, (error: any) => {

      console.log('uploadImage', { error });

      item.onError(error, item.file);

      this.toastService.createMessage('error', 'There was an error saving the profile image.  Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  };

  shouldShowWorkInfo(value: string): boolean {

    if (this.isViewProfile) {

      if (value === 'Job Title' && !this.isViewProfileSettings?.showJobTitle) {

        return false;

      } else if (value === 'Division' && !this.isViewProfileSettings?.showDivision) {

        return false;

      } else if ((value === 'Building' || value === 'City' || value === 'Zip' || value === 'Country'
        || value === 'Address' || value === 'State' || value === 'Office') && !this.isViewProfileSettings?.showWorkLocation) {

        return false;

      }

    }
    return true;

  }

  shouldShowContactDetails(value: string): boolean {

    if (this.isViewProfile) {

      if (value === 'Work' && !this.isViewProfileSettings?.showWorkPhone) {

        return false;

      } else if (value === 'Mobile' && !this.isViewProfileSettings?.showMobilePhone) {

        return false;

      }

    }

    return true;

  }

  /**
   * Hide Preview modal
   *
   */
  hidePreview() {

    this.previewVisible = false;

  }

  /**
   * Edit Profile
   *
   */
  enterEditMode() {

    this.editProfile = true;

  }

  /**
   * Stop editing Profile
   *
   */
  exitEditMode() {

    this.editProfile = false;

  }

  get formLayout(): string {

    return this.breakpointService.isMobile.value ? 'vertical' : 'horizontal';

  }

}
