<nz-modal
   nzWrapClassName="ncx-modal"
   [(nzVisible)]="isinfocenterVisible"
   [nzContent]="modalContent"
   [nzFooter]="modalFooter"
   (nzOnCancel)="handleCancel()"
   [nzMaskClosable]="false">
   <ng-template #modalContent>
      <div [attr.data-component]="'@modals/infocenter-research'">
         <p class="header">InfoCenter Research Request</p>

         <div class="body">
            <section>
               <p class="form-label">
                  Hey Team, can you please provide me with the following help on the story, &quot;{{
                     storyTitle
                  }}&quot;.
               </p>

               <div class="form-element">
                  <textarea
                     rows="6"
                     nz-input
                     [(ngModel)]="inputValue"
                     placeholder="Please type here to describe what you need..."></textarea>
               </div>
            </section>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button
         nz-button
         nzType="default"
         (click)="handleCancel()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         [nzLoading]="!isLoaded"
         (click)="handleOk()">
         Email
      </button>
   </ng-template>
</nz-modal>
