<nz-layout>
   <nz-page-header
      class="site-page-header search-results-page"
      [attr.data-component]="'ncx/search/page/search-results.component.html'"
      nzBackIcon>
      <nz-page-header-title class="search-box-header">
         <nz-input-group
            class="search-box"
            [nzSuffix]="suffixTemplateInfo"
            [nzPrefix]="prefixTemplateUser">
            <input
               #searchInputField
               type="text"
               class="search-input"
               nz-input
               placeholder="Search all of NewsConnect"
               (keydown.enter)="setSearchTerm($event)"
               [(ngModel)]="searchTerm" />
         </nz-input-group>

         <ng-template #prefixTemplateUser>
            <i
               nz-icon
               nzType="search"
               class="search-icon"
               nzTheme="outline"></i>
         </ng-template>
         <ng-template #suffixTemplateInfo>
            <button (click)="clearSearchTerm()">
               <i
                  nz-icon
                  nzType="close-circle"
                  nzTheme="outline"
                  class="close-circle"></i>
            </button>
         </ng-template>
      </nz-page-header-title>

      <nz-page-header-extra>
         <button (click)="toggleFilterDrawer()">
            <i
               nz-icon
               class="filter-icon"
               [class.collapsed]="isFilterDrawerCollapsed"
               nzType="filter"
               nzTheme="outline"></i>
         </button>
      </nz-page-header-extra>
   </nz-page-header>

   <nz-layout [ngClass]="{ 'ant-layout-has-sider': true }">
      <nz-content>
         <div class="search-results-tabs">
            <nz-tabset
               (nzSelectChange)="onChangeTab($event)"
               [(nzSelectedIndex)]="tabIndex">
               <!----------- STORIES ----------->

               <nz-tab nzTitle="Stories">
                  <app-stories-results [isVisible]="tabIndex === 0"></app-stories-results>
               </nz-tab>

               <!----------- POSTS ----------->

               <nz-tab nzTitle="Posts">
                  <app-post-results [isVisible]="tabIndex === 1"></app-post-results>
               </nz-tab>

               <!----------- ANGLES ----------->

               <nz-tab [nzTitle]="titleTemplate">
                  <app-angle-results [isVisible]="tabIndex === 2"></app-angle-results>
                  <ng-template #titleTemplate>
                     Angles
                     <app-beta
                        [isTab]="true"
                        [isTabSelected]="tabIndex === 2 ? true : false"
                        [showBInMobile]="true"></app-beta>
                  </ng-template>
               </nz-tab>

               <!----------- GROUPS ----------->

               <nz-tab nzTitle="Groups">
                  <app-group-results [isVisible]="tabIndex === 3"></app-group-results>
               </nz-tab>

               <!----------- PEOPLE ----------->

               <nz-tab nzTitle="People">
                  <app-people-results [isVisible]="tabIndex === 4"></app-people-results>
               </nz-tab>
            </nz-tabset>
         </div>
      </nz-content>

      <!-- SEARCH FILTERS DESKTOP -->
      <nz-sider
         *ngIf="!isMobile"
         nzCollapsible
         nzCollapsedWidth="0"
         class="search-filter-container"
         [nzWidth]="320"
         [nzCollapsed]="isFilterDrawerCollapsed"
         [nzTrigger]="null">
         <app-search-filters
            [isCollapsed]="isFilterDrawerCollapsed"
            (closeFilter)="closeFilterDrawer()"
            (triggerFilter)="applyFilters()"
            [filterState]="tabOrder[tabIndex]"></app-search-filters>
      </nz-sider>
   </nz-layout>
</nz-layout>

<!-- SEARCH FILTERS MOBILE -->
<nz-drawer
   *ngIf="isMobile"
   nzPlacement="right"
   nzWrapClassName="search-filter-container drawer"
   [nzClosable]="false"
   [nzVisible]="!isFilterDrawerCollapsed"
   [nzMaskClosable]="true"
   [nzWidth]="320"
   (nzOnClose)="closeFilterDrawer()">
   <ng-container *nzDrawerContent>
      <app-search-filters
         [isDrawer]="true"
         [filterState]="tabOrder[tabIndex]"
         (triggerFilter)="applyFilters()"
         (closeFilter)="closeFilterDrawer()"></app-search-filters>
   </ng-container>
</nz-drawer>
