import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TransferInformationComponent } from '../shared/components/TransferInformation/TransferInformation.component';

@Injectable()
export class UtilityService {

  constructor(private userInfo: TransferInformationComponent) {}

  public deepClone(clone): any {

    return JSON.parse(JSON.stringify(clone));
  
  }

  public checkLabelStatus(story, labelStatus) {

    if (labelStatus.length === 0) {

      return true;
    
    } else if (story.storyPosts.length === 0) {

      return false;
    
    } else {

      let returnCheck = false;

      for (const post of story.storyPosts) {

        if (post.postMarkedAs !== null && post.postMarkedAs.length > 0) {

          for (const mark of post.postMarkedAs) {

            returnCheck = labelStatus.some(item => item.order.key === mark);
          
          }
          if (returnCheck) {

            break;
          
          }
        
        }
      
      }
      return returnCheck;
    
    }
  
  }

  public checkMostReplies(story, activityDetails) {

    if (story.storyPosts.length === 0) {

      return activityDetails;
    
    } else {

      const cIndex = activityDetails.findIndex(storyData => story.storyId === storyData.storyId);

      const index = activityDetails.findIndex(data => {

        if (data.storyPosts.length > 0) {

          return story.storyPosts[0].repliesCount > data.storyPosts[0].repliesCount;
        
        }
      
      });

      if (cIndex !== -1) {

        activityDetails.splice(index, 1);
      
      }
      if (index !== -1) {

        activityDetails.splice(index, 0, story);
      
      }
      return activityDetails;
    
    }
  
  }

  public checkMostRepliesAllActivity(story, activityDetails) {

    if (story.initActivityDto == null) {

      return activityDetails;
    
    } else {

      const cIndex = activityDetails.findIndex(storyData => story.storyId === storyData.storyId);

      const index = activityDetails.findIndex(data => {


        /*  if (data.storyPosts.length > 0) {
         return story.storyPosts[0].repliesCount > data.storyPosts[0].repliesCount;
         }*/
        if (data.initActivityDto != null) {

          return story.initActivityDto.repliesCount > data.initActivityDto.repliesCount;
        
        }
      
      });

      if (cIndex !== -1) {

        activityDetails.splice(index, 1);
      
      }
      if (index !== -1) {

        activityDetails.splice(index, 0, story);
      
      }
      return activityDetails;
    
    }
  
  }

  public checkActivity(story, activityTypeStatus) {

    if (activityTypeStatus === 'Following') {

      return story.isUserFollowing;
    
    } else if (activityTypeStatus === 'Authored') {

      const userId = localStorage.getItem('userId');

      return Number(userId) === story.createUser.userId;
    
    }
    return true;
  
  }

  public checkStatus(story, storyState) {

    if (storyState === 'ALL') {

      return true;
    
    }
    return story.storyState === storyState;
  
  }

  public checkSorting(list, sortByField, sortOrder, data) {

    const cloneList = this.deepClone(list);

    if (sortByField === 'NAME') {

      return this.checkPositionFromList(cloneList, 'groupName', sortOrder, data);
    
    } else if (sortByField === 'MEMBERS') {

      return this.checkPositionFromList(cloneList, 'groupMembersCount', sortOrder, data);
    
    } else if (sortByField === 'FOLLOWERS') {

      return this.checkPositionFromList(cloneList, 'groupFollowerCount', sortOrder, data);
    
    } else if (sortByField === 'CREATEDATE') {

      return this.checkPositionFromList(cloneList, 'createDateTime', sortOrder, data);
    
    }
  
  }

  public CheckGroup(groupSelected, data) {

    if (groupSelected === 'ALL GROUPS') {

      return true;
    
    } else if (groupSelected === 'MY GROUPS') {

      const userId = localStorage.getItem('userId');

      return Number(userId) === (data && data.createUserId && data.createUserId.userId) ?
        data.createUserId.userId : data.createUserId;
    
    } else if (groupSelected === 'FOLLOWING') {

      return data.isUserMember;
    
    }
  
  }

  public checkSearchText(searchText, data, fieldSearch) {

    if (searchText === '') {

      return true;
    
    }
    return data[fieldSearch].toLowerCase().includes(searchText.toLowerCase());
  
  }

  private checkPositionFromList(list, field, sortOrder, data) {

    list.push(data);
    const newList = this.sorting(list, field, sortOrder);

    const index = newList.findIndex(group => group.groupId === data.groupId);

    return index;
  
  }

  private sorting(data, sortValue, sortOrder) {

    if (sortValue === 'groupName') {

      return sortOrder === 'ASC' ? data.sort((a, b) => a[sortValue].localeCompare(b[sortValue])) :
        data.sort((a, b) => b[sortValue].localeCompare(a[sortValue]));
    
    } else {

      const isAsc = sortOrder === 'ASC' ? true : false;

      return data.sort((a, b) => {

        if (sortValue === 'createDateTime') {

          return this.compare(new Date(a[sortValue]).getTime(), new Date(b[sortValue]).getTime(), isAsc);
        
        } else {

          return this.compare(a[sortValue], b[sortValue], isAsc);
        
        }
      
      });
    
    }
  
  }

  private compare(a, b, isAsc) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  
  }

  hasAdminAccess(groupMembership) {

    const userId = localStorage.getItem('userId');

    const userRole = this.userInfo.userInfoDetails.role;


    // tslint:disable-next-line:max-line-length
    const isAdmin = groupMembership.filter(member => member.userId.userId !==null && member.userId.userId.toString() === userId.toString() &&
      (member.membershipType === 'OWNER' || member.membershipType === 'ADMIN'));

    console.log('hasAdminAccess : ', groupMembership, isAdmin, userRole);
    if ((isAdmin && isAdmin.length) || (userRole === 'Admin' || userRole === 'ADMIN')) {

      return true;
    
    } else {

      return false;
    
    }
  
  }

  /**
   * Sort algorithum for numeric values to use in javascript .sort() callback
   *
   */
  sortNumeric(a: number, b: number, desc = false): number {

    // force null and undefined to the bottom
    a = a === null || a === undefined ? -Infinity : a;
    b = b === null || b === undefined ? -Infinity : b;

    if (desc) {

      if (a < b) {

        return 1;
      
      }
      if (a > b) {

        return -1;
      
      }
    
    } else {

      if (a > b) {

        return 1;
      
      }
      if (a < b) {

        return -1;
      
      }
    
    }

    return 0;

  }

  /**
   * Sort logic for strings to use in javascript .sort() callback
   *
   */
  sortAlphabetical(a: string, b: string): number {

    return String(a).localeCompare(String(b), undefined, {
      numeric: true,
      sensitivity: 'base',
      ignorePunctuation: true
    });

  }

  /**
   * Write to clipboard
   * Returns true if the clipboard was successfully written to
   *
   *
   */
  writeToClipboard(text: string): Promise<boolean> {

    return new Promise(resolve => {

      if (!('clipboard' in window.navigator)) {

        resolve(false);
      
      }

      try {

        navigator.clipboard.writeText(text).then(() => {

          resolve(true);
        
        }).catch(error => {

          console.log('writeToClipboard', { error });
          resolve(false);
        
        });
      
      } catch (error) {

        console.log('writeToClipboard', { error });
        resolve(false);
      
      }

    });

  }

  /**
   * Formats bytes into closest KB,MB,GB etc
   * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
   *
   *
   */
  formatBytes(bytes: number, decimals = 0): string {

    if (bytes === 0) {

      return '0 B';
    
    }

    const k = 1000;

    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;

  }

  getMailURL(): string {

    return environment.mailPath;    
  
  }

}
