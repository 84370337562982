import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { TranslationService } from '@services/translation-service.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
import { SubSink } from 'subsink';
import { NavigationService } from '@services/navigation-service';
import { NavItem } from '@models/nav-item';
import { PermissionsService } from '@services/profile/permissions.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  isVisible = false;

  username;

  emailTo;

  navDisplay: NavItem[] = [];

  isNavDrawerOpen: boolean = false;

  @Input() activePath: string = '';

  @Input() public menuList;

  private subs = new SubSink();

  // Must watch for breakpoint changes in order to programatically close the navigation
  // drawer if the user resizes their browser from desktop to mobile
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    if (event.target.innerWidth <= 992 /* 992 is the max mobile breakpoint*/ &&
      !this.breakpointService.isMobile.value &&
      !this.cService.isNavDrawerClosed.value) {

      this.cService.isNavDrawerClosed.next(true);

    }

  }

  constructor(
    private tService: TranslationService,
    private userInfo: TransferInformationComponent,
    private Translate: TranslateService,
    private cService: CommonService,
    private permissionsService: PermissionsService,
    private navigationService: NavigationService,
    private breakpointService: BreakpointService,
  ) {}

  ngOnInit() {

    this.subs.sink = this.navigationService.navDisplay$.subscribe(navDisplay => {
      this.navDisplay = navDisplay;
    });

    this.isNavDrawerOpen = !this.cService.isNavDrawerClosed.value;
    this.subs.sink = this.cService.isNavDrawerClosed.subscribe(isClosed => {
      this.isNavDrawerOpen = !isClosed;
    });

    this.subs.sink = this.navigationService.activePath$.subscribe(path => {
      this.activePath = path;
    });

    this.userInfo.currentLinkDetails.subscribe(username => this.username = username);

    this.Translate.use(this.tService.setDefaultLanguage());

    const d = new Date();

    const dFormat = [
        d.getMonth() + 1,
        d.getDate(),
        d.getFullYear(),
      ].join('/') + ' ' +
      [
        d.getHours(),
        d.getMinutes(),
        d.getSeconds(),
      ].join(':');

    this.emailTo = 'mailto:newsconnecthelp@nbcuni.com?subject= ' + dFormat + ' - ' + this.username + ' - NewsConnect help request';

    // If NOT in the mobile breakpoint, let's automatically open the navigation drawer
    // if the user has already set the drawer to the open state
    const isCollapsedValue = localStorage.getItem('isCollapsed');

    if (!this.breakpointService.isMobile.value) {

      this.cService.isNavDrawerClosed.next(isCollapsedValue === 'true');

    }

    // Must trigger a 'resize' event in order to force some AntDesign elements (e.g. Hot Banner carousel)
    // to repaint themselves whenever the navigation drawer opens/closes
    this.subs.sink = this.cService.isNavDrawerClosed.subscribe((isClosed: boolean) => {

      localStorage.setItem('isCollapsed', String(isClosed));
      setTimeout(() => {

        window.dispatchEvent(new Event('resize'));

      }, 250);

    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Close drawer when link is clicked only on Mobile
   *
   */
  closeDrawer() {

    if (this.breakpointService.isMobile.value) {

      this.cService.isNavDrawerClosed.next(true);

    }

  }

  /**
   * Check if a user is allowed to view a link or
   * section according to the approved roles
   *
   */
  canViewLinkByRole(nav: NavItem): boolean {

    const roles = nav.roles;

    if (!roles || !Array.isArray(roles)) {
      return true;
    }

    if (nav.roles.includes('Any')) {
      return true;
    }

    // Special logic for the Admin page
    if (nav.title === 'Admin') {
      return roles.includes('Admin') || roles.includes('ADMIN');
    }

    // All other roles
    return roles.includes(this.permissionsService.role);

  }

  /**
   * Can view the page by the logic existing in the NavItem itself
   * or any other logic contained within this function
   *
   */
  canViewLinkByPage(nav: NavItem): boolean {

    if (nav.showPage && typeof nav.showPage === 'function') {

      return nav.showPage(nav.title, nav.routerLink, this.permissionsService, window.location.href);

    }

    return true;

  }

  /**
   * Optional onClick handler for button navigation elements
   *
   */
  navOnClick(nav: NavItem): void {

    console.log('navOnClick: ', nav);

  }

  /**
   * Determines if the active link should be highlighted in the Nav menu
   *
   */
  isActiveLink(nav: NavItem): boolean {

    const regex = new RegExp(`^${nav.routerLink}`, 'i');

    // This makes the first nav link highlight when the user is on the home page
    const isHomePage = this.activePath === '/' && nav.routerLink === '/ncx/stories-dashboard';

    return !!this.activePath.match(regex) || isHomePage;

  }

  reload() {

    window.location.reload();

  }

  get isLocal(): boolean {

    return !!window.location.href.match(/localhost/);

  }

  get;

}
