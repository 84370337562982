import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({

  // tslint:disable-next-line: component-selector
  selector: 'app-embedly',
  templateUrl: './embedly.component.html',
  styleUrls: ['./embedly.component.scss']
})
export class EmbedlyLinksComponent implements OnInit, OnChanges {

  @Input() isEmbedlyVisible;

  @Output() embedlyUpdated: EventEmitter<any> = new EventEmitter<any>();

  embedlyLink = '';

  constructor() {

    /** Nothing to do */
  }

  ngOnInit() {

    /** Nothing to do */
    console.log('embedly link test ', this.isEmbedlyVisible);

  }

  ngOnChanges() {

    console.log('embedly link test ', this.isEmbedlyVisible);

  }

  handleOk(_event): void {

    this.embedlyUpdated.emit(this.embedlyLink);
    this.isEmbedlyVisible = false;
    this.embedlyLink = '';

  }

  handleCancel(): void {

    console.log('handleCancel Googgle Flag before: ', this.isEmbedlyVisible);
    this.isEmbedlyVisible = false;
    this.embedlyUpdated.emit('close');
    console.log('handleCancel Googgle Flag after: ', this.isEmbedlyVisible);

  }

}
