import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-feeds-filter',
  templateUrl: './feeds-filter.component.html',
  styleUrls: ['./feeds-filter.component.scss']
})
export class FeedsFilterComponent {

    @Input() feedType;

    @Input() orderedBy;

    @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>();

    @Output() emitFeedTypeFilter: EventEmitter<any> = new EventEmitter<any>();

    @Output() emitOrderFilter: EventEmitter<any> = new EventEmitter<any>();

    @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();

    @Input() isDrawer = false;

    radioFeedTypeValue = 'ALL';

    radioFeedOrderValue = 'modifiedDate';

    close() {

      this.closeFilter.emit(true);

    }

    addFilters(filterValue) {

      console.log('Values', filterValue);
      this.emitFeedTypeFilter.emit(filterValue);

    }

    orderFilter(filterValue) {

      this.emitOrderFilter.emit(filterValue);

    }

    clear() {

      this.radioFeedTypeValue = 'ALL';
      this.radioFeedOrderValue = 'modifiedDate';
      const filter = {
        label: 'All',
        value: 'ALL'
      };

      this.emitFeedTypeFilter.emit(filter);
      this.clearFilter.emit(true);

    }

}
