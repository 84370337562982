import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserSettings } from '@models/users';

@Injectable()
export class UserSettingService {

  private userSettingsSubject: Subject<UserSettings> = new Subject<UserSettings>();

  constructor() {
  }

  public addSettings(userSettings: UserSettings) {

    this.userSettingsSubject.next(userSettings);
  
  }

  public getSettings() {

    return this.userSettingsSubject;
  
  }

}
