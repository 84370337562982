<nz-layout>

  <app-standard-header
    title='Back'
    [defaultBackAction]='true'></app-standard-header>

  <nz-layout>
    <nz-content>

      <div class='support-tabs'>
        <nz-tabset
          (nzSelectChange)='onChangeTab($event)'
          [(nzSelectedIndex)]='tabIndex'>

          <!----------- FAQ ----------->

          <nz-tab nzTitle='FAQ'>
            <ng-container *ngTemplateOutlet='help'></ng-container>
            <app-support-faq [isVisible]='tabIndex === 0'></app-support-faq>
          </nz-tab>

          <!----------- TRAINING ----------->

          <nz-tab nzTitle='Training'>
            <ng-container *ngTemplateOutlet='help'></ng-container>
            <app-support-training [isVisible]='tabIndex === 1'></app-support-training>
          </nz-tab>

          <!----------- VERSIONS ----------->

          <nz-tab nzTitle='Versions'>
            <ng-container *ngTemplateOutlet='help'></ng-container>
            <app-support-versions [isVisible]='tabIndex === 2'></app-support-versions>
          </nz-tab>

        </nz-tabset>
      </div>

    </nz-content>

  </nz-layout>
</nz-layout>

<ng-template #help>
  <section class='help'>
    <i nz-icon
       nzType='question-circle'
       nzTheme='outline'></i>
    <div>
            <p><strong>Need Help?</strong> Please reach out to <a href='mailto:newsconnecthelp@nbcuni.com?Subject=NewsConnect Help'>
                      newsconnecthelp&commat;nbcuni.com</a> for further assistance.</p>
    </div>
  </section>
</ng-template>
