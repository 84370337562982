import { User } from '../users';

export interface Post {

  editorialStatus: string[];
  postLegal: string[];
  postId: number;
  storyId: number;
  postType: string;
  postTitle: string;
  postAccessMode: string;
  postContent: string;
  postPlainContent: string;
  isPinnedOnTop: boolean;
  livePostId: number;
  isGenerateTags: boolean;
  doNotSendEmail: boolean;
  postContentTags: string[];
  isPostPublished: boolean;
  postStatus: string;
  postMarkedAs: string[];
  relatedStories: RelatedStory[];
  relatedAngles: {
    angleAccess: string;
    angleId: number;
    angleTitle: string;
    createUserId: number;
    isPrimary: boolean;
    relatedStories: {
      storyId: number;
      storyTitle: string;
      isPrimary: boolean;
      isPinnedOnTop: boolean
    }[]
  }[];
  //topics;
  topicDetails: any[];
  postTopicIds: string[];
  displayLables?: any[];
  isExtraLabel?: boolean;
  extraLabelsCount?: number;
  extraLabels?: any[];
  postElementContentTags: string;
  standardGuidenceContent: any;
  postAttachments: PostAttachment[];
  attachmentDetails: AttachmentDetails;
  snippet?: {
    title: string,
    description: string
  };
  postReplies: [
    {
      replyId: number;
      postGroupId: number;
      replyContent: string;
      replyUserMentions: [
        number
      ];
      createUserId: number;
      updateUserId: number;
      createDateTime: string;
      updateDateTime: string;
      isDeleted: boolean
    }
  ];
  reportableApprover: {
    isOfficial: string,
    seniorApprovalName: string,
    additionalNotes: string
  };
  limitedLicenseApprover: {
    additionalNotes: string
  };
  standardsGuidance: StandardGuidance;
  postVersionNumber: number;
  postVersionState: string;
  postVersionGroupId: number;
  createDateTime: string;
  updateDateTime: string;
  createUserId: number;
  updateUserId: number;
  isDeleted: boolean;
  repliesCount: number;
  createUser: User;
  updateUser: User;
  locked: boolean;
  lockedByUser: User;
}

export interface StandardGuidance {
  notes: string;
  plainNotes?: string;
  createUserId?: number;
  updateUserId?: number;
  createUser?: User;
  updateUser?: User;
  createDateTime?: number;
  updateDateTime?: number;
}

export interface PostVersions {
  pageLength: number;
  pageStartCount: number;
  posts: Post[];
}

export class PostAttachments {

  attachmentId: number = null;

  attachmentName = '';

  attachmentMIMEType = '';

  attachmentPath = '';

  attachmentBinaries = '';

  attachmentPreview = '';

  attachmentPreviewBinaries = '';

  isDeleted = false;

  createDateTime = '';

  updateDateTime = '';

  status: '';

  size: null;

}

export interface PamDetails {
  mediaId: string;
  pamDateTime: string;
  sentToPam: boolean;
  sentToPamUserId: number;
  sentToPamUserFirstName: string;
  sentToPamUserMiddleName: string;
  sentToPamUserLastName: string;

  // Just for displaying items in the list
  attachmentName?: string;
  attachmentIndex?: number;
}

export class AttachmentDetails {

  linkToSource: string;

  clearanceStatus: string;

  captureDate: string;

  isMandatoryCredit: string;

  creditName: string;

  divisions: Division[] = [];

  shows: Show[] = [];

}

export interface RelatedStory {
  storyId: number;
  storyTitle?: string;
  isPrimary: boolean;
  storyAccess: string;
  isPinnedOnTop: boolean;
  createUserId: number;
}

export interface Reply {
  createDateTime: string;
  createUserId: number;
  isDeleted: boolean;
  postVersionGroupId: number;
  replyContent: string;
  replyId: number;
  replyUserMentions: Array<number>;
  updateDateTime: string;
  updateUserId: number;
  userId: number;
}

export interface PostAttachment {
  attachmentId: number;
  attachmentName: string;
  attachmentMediaId: string;
  attachmentMIMEType: string;
  attachmentPath: string;
  attachmentBinaries: string;
  attachmentPreview: string;
  attachmentPreviewBinaries: string;
  attachmentPreviewPath: string,
  isDeleted: boolean;
  createDateTime: string;
  updateDateTime: string;
  createUserId: number;
  updateUserId: number;
  status: string;
  size: number;
  pamDetails: [
    {
      mediaId: string;
      pamDateTime: string;
      sentToPam: boolean;
      sentToPamUserId: number,
      sentToPamUserFirstName: string,
      sentToPamUserMiddleName: string,
      sentToPamUserLastName: string
    }
  ];
}

export class Division {

  id?: number;

  name: string;

}

export class Show {

  id?: number;

  name: string;

}


