import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
  selector: '[headerTemplate]'
})
class HeaderTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) { }

}

@Directive({
  selector: '[subHeaderTemplate]'
})
class SubHeaderTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) { }

}

@Directive({
  selector: '[bodyHeaderTemplate]'
})
class BodyHeaderTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) { }

}

@Directive({
  selector: '[bodyTemplate]'
})
class BodyTemplateDirective {

  @Input() tabTitle!: string;

  @Input() isActive: boolean = false;

  constructor(public templateRef: TemplateRef<unknown>) { }

}

@Directive({
  selector: '[rightSideTemplate]'
})
class RightSideTemplateDirective {

  @Input() tabTitle!: string;

  @Input() isActive: boolean = false;

  constructor(public templateRef: TemplateRef<unknown>) { }

}

@Directive({
  selector: '[footerTemplate]'
})
class FooterTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) { }

}

export {
  HeaderTemplateDirective,
  SubHeaderTemplateDirective,
  BodyHeaderTemplateDirective,
  BodyTemplateDirective,
  RightSideTemplateDirective,
  FooterTemplateDirective
}