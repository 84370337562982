<div class="angle-details">
   <div class="angle-details-split">
      <div class="angle-details-content">
         <div class="angle-details-id">
            <a
               (click)="onViewAngle()"
               [href]="this.angleDetail.angleURL"
               [innerHTML]="'#' + (angleDetail.angleIdSnippet || angleDetail.angleId || '') | safeHtml"></a>
         </div>
         <div class="angle-details-title">
            <span
               class="eye-invisible-icon"
               *ngIf="angleDetail.angleAccess === 'private'">
               <span
                  nz-icon
                  nzType="eye-invisible"
                  nzTheme="outline"></span>
            </span>
            <a
               (click)="onViewAngle()"
               [href]="this.angleDetail.angleURL"
               class="text-ellipsis"
               [innerHTML]="(angleDetail.angleTitleSnippet || angleDetail.angleTitle || '').trim() | safeHtml"></a>
         </div>
         <div class="angle-created-user">
            <span>Created By:</span>
            <span
               *ngIf="angleDetail.createdUser"
               class="user-name"
               nz-popover
               [nzPopoverContent]="profileCardTemplate1"
               [nzPopoverPlacement]="'topLeft'">
               {{ angleDetail.createdUser.displayName }}
            </span>
            <ng-template #profileCardTemplate1>
               <app-profile-overlay [profileData]="angleDetail.createdUser"></app-profile-overlay>
            </ng-template>
            <span>
               on {{ angleDetail.createdDateTime | utcToLocal: 'DATE' }} at
               {{ angleDetail.createdDateTime | utcToLocal: 'TIME' }}
            </span>
         </div>
      </div>
   </div>
</div>
