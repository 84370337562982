<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-collapse
   nzExpandIconPosition="right"
   [nzBordered]="false">
   <nz-collapse-panel
      [nzHeader]="tagsHeader"
      nzActive>
      <div *ngIf="!viewMode">
         <div>
            <nz-input-group
               [nzSuffix]="suffixTemplateInfo"
               [nzPrefix]="prefixTemplateUser"
               class="tag-input-group">
               <input
                  #inputElement
                  nz-input
                  type="text"
                  placeholder="Add Tag"
                  [(ngModel)]="inputValue"
                  class="tag-input"
                  (blur)="handleInputConfirm()"
                  (keydown.enter)="handleInputConfirm()" />
            </nz-input-group>
            <ng-template #prefixTemplateUser></ng-template>
            <ng-template #suffixTemplateInfo>
               <i
                  class="add-tags"
                  nz-tooltip
                  nzTitle="Add Tag"
                  nz-icon
                  nzType="plus-circle"
                  nzTheme="outline"
                  (click)="showInput()"></i>
            </ng-template>
         </div>

         <div
            class="show-tags"
            *ngIf="!viewMode && tagContent?.length > 0">
            <ng-container *ngFor="let tag of tagContent; let i = index">
               <span class="display-tag">
                  <span class="tag-text">{{ tag | lowercase }}</span>
                  <span
                     nz-icon
                     nzType="close"
                     nzTheme="outline"
                     (click)="handleClose(tag)"
                     class="tag-close"></span>
               </span>
            </ng-container>
         </div>

         <button
            class="generate-tags-btn"
            [ngClass]="{ 'tags-added-btn': tagContent?.length > 0 }"
            nz-button
            (click)="generateTags()"
            *ngIf="!viewMode">
            Generate Tags
         </button>

         <span class="generate-tags-auto">
            <label
               class="generate-tags"
               nz-checkbox
               [(ngModel)]="isGenerateTags"
               (ngModelChange)="toggleGenerateFlag($event)">
               Generate Tags Automatically
            </label>
         </span>
      </div>

      <div
         *ngIf="viewMode"
         class="viewTags">
         <ng-Container *ngFor="let item of tagContent">
            <nz-form-label class="tagLabel">{{ item }}</nz-form-label>
         </ng-Container>
      </div>
   </nz-collapse-panel>
</nz-collapse>

<ng-template #tagsHeader>
   <i
      nz-icon
      nzType="tags"></i>
   Tags
</ng-template>
